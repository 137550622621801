import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TypeWriterEffect from 'react-typewriter-effect';
import VulaLogo from '../components/utils/VulaLogo';
import { routes } from './routes';
import JunkDrawer from '../components/Landing/JunkDrawer';

export default function MasterPlan() {
  return (
    <div className="h-full min-h-[100vh] m-auto flex items-center flex-col  ">
      <div className="max-w-[2000px] w-full text-center p-4">
        <div className="py-10 max-w-[800px] m-auto bg-white drop-shadow-xl rounded-xl p-4 ">
          <a
            href={routes.public.landing}
            className="flex justify-center w-full pb-8"
          >
            <VulaLogo size="lg" />
          </a>
          <div className="flex justify-center text-4xl sm:text-6xl font-extrabold text-center text-stone-900">
            <TypeWriterEffect
              startDelay={0}
              cursor="py-1"
              cursorColor="#00000000"
              multiText={[`Master Plan`]}
              typeSpeed={40}
            />
          </div>
          <div className="text-center text-stone-900 text-xl p-4 py-8">
            Close the SME funding gap across Africa.
          </div>
          <div className="text-left text-stone-900 p-4  border border-x-0 border-t-1 border-solid border-stone-200 ">
            <div className=" text-lg font-extrabold pb-4">
              Tools for SMEs → tools for funders → bridge the funding gap.
            </div>
            <div className="py-1">
              SMEs are a ladder toward prosperity, yet the $330B funding gap
              across Africa is crippling their development. $330B is the nominal
              GDPs of Kenya, Angola, Tanzania and Senegal combined.
            </div>
            <div className="py-1">
              Digitisation and automation dramatically reduce the cost for
              financiers to serve and deploy capital, enabling them to handle
              more volume in less time for less cost and hence have an
              overwhelming impact on closing the gap.
            </div>
            <div className="py-1">
              We are going to close the funding gap across Africa by building
              data-thirsty AI tools that help SMEs provide higher quality data
              to funders for better decision making.
            </div>
            <div className="py-1">
              We have started by building a platform that automatically finds
              and matches SMEs with relevant grants, built an intuitive
              data-submission UX for them, and structured that data to enable an
              AI to write high-quality grant applications.
            </div>
            <div className="py-1">
              We are working with financiers (large and small) in South Africa,
              Kenya, Botswana, Nigeria, and Tanzania, as beachheads in
              Anglophone Africa, and have learned that they are willing to pay
              for better dealflow tooling and access to businesses with quality
              data. Regardless of size, these funders have similar needs and we
              can reduce processing time dramatically via digitisation and
              automation, including AI document analysis.
            </div>
            <div className="py-1">
              As we grow via network effects through our early adopters and
              funding partners, new opportunities emerge - syndication, verified
              peer-to-peer lending, and auto-suggested deals with co-funders,
              all reducing risk and friction and increasing collaboration. This
              will push Vula to become the unified platform for all African
              business funding across capital markets, along with a powerful
              business information hub. We anticipate a marketplace of tools and
              services which companies can effortlessly access with their
              structured data for instant onboarding.
            </div>
            <div className="py-1">
              We are a year into our journey, have strong context of the
              problem, Pan-African connections and the technical ability to
              build this system quickly.
            </div>
            <div className="py-1">
              If we capture this opportunity now, the additional 2.5B people
              that will be born in Africa by 2050 will be empowered to turn
              their passions into ventures, making it easier to scaleAfrican
              innovation to global success, and combat inequity and poverty at a
              level never seen before.
            </div>

            <div className="py-1 pt-12">It's time to raise, Africa.</div>
            <div className="py-1">Nic and Alex</div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <JunkDrawer />
      </div>
    </div>
  );
}
