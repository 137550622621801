import React from 'react';

export default function ShareIcon() {
  return (
    <div>
      <svg
        fill="currentColor"
        className="w-6 h-6"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.0001 2.49996C11 1.91348 11.2062 1.34564 11.5825 0.8958C11.9589 0.445958 12.4815 0.142758 13.0588 0.0392456C13.6362 -0.0642662 14.2315 0.038502 14.7408 0.32957C15.25 0.620638 15.6406 1.08147 15.8444 1.63144C16.0481 2.18141 16.0519 2.7855 15.8552 3.33802C15.6585 3.89053 15.2737 4.35629 14.7682 4.6538C14.2627 4.95132 13.6687 5.06164 13.0901 4.96547C12.5115 4.8693 11.9851 4.57276 11.6031 4.12774L4.88518 7.2473C5.03962 7.73667 5.03962 8.26172 4.88518 8.75109L11.6031 11.8706C12.0069 11.401 12.5707 11.0981 13.1852 11.0204C13.7998 10.9428 14.4212 11.096 14.9292 11.4504C15.4372 11.8048 15.7955 12.3351 15.9347 12.9386C16.0739 13.5421 15.984 14.1758 15.6826 14.7168C15.3812 15.2579 14.8896 15.6678 14.3031 15.867C13.7166 16.0663 13.0771 16.0408 12.5083 15.7955C11.9396 15.5502 11.4822 15.1025 11.2248 14.5391C10.9674 13.9758 10.9283 13.337 11.1151 12.7465L4.39719 9.62696C4.06463 10.0145 3.62136 10.2909 3.12699 10.4189C2.63263 10.547 2.1109 10.5206 1.63198 10.3433C1.15306 10.1661 0.739929 9.84637 0.448165 9.42728C0.156402 9.00819 0 8.50982 0 7.99919C0 7.48857 0.156402 6.99019 0.448165 6.57111C0.739929 6.15202 1.15306 5.83233 1.63198 5.65504C2.1109 5.47775 2.63263 5.45137 3.12699 5.57945C3.62136 5.70752 4.06463 5.9839 4.39719 6.37142L11.1151 3.25186C11.0386 3.00856 10.9998 2.75499 11.0001 2.49996Z" />
      </svg>
    </div>
  );
}
