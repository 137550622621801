import React, { useContext, useEffect } from 'react';
import VulaLogo from '../components/utils/VulaLogo';
import Button from '../components/utils/Button';
import { useNavigate } from 'react-router-dom';
import { routes } from './routes';
import { track } from '../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../contexts/LoggedInContext';

interface Props {
  size?: 'full-page' | 'small';
}

export default function Loading(props: Props) {
  const { user, error } = useAuth0();
  const navigate = useNavigate();
  const { company_slug } = useContext(LoggedInContext);
  const [showReloadMessage, setShowReloadMessage] = React.useState(false);
  // start a timer, after 10 seconds show a message to the user to refresh the page
  useEffect(() => {
    const timer = setTimeout(() => {
      // show a message to the user to refresh the page
      setShowReloadMessage(true);
      track('Loading timeout', {
        user_email: user?.email,
        company_slug,
        url: window.location.href,
        error: error?.message,
      });
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={
        'flex justify-center text-center items-center transition-all duration-600 ' +
        (props.size === 'small' ? '  ' : ' min-h-[100vh] w-full ')
      }
    >
      {showReloadMessage ? (
        <div className="flex flex-col items-center justify-center">
          <div className="rotate-[175deg] ">
            <VulaLogo />
          </div>
          <p className="text-2xl font-bold py-4">Something went wrong!</p>
          <p className="text-lg">Please refresh the page or go back home</p>
          {
            // If on the onlogin url, just refresh the page
            window.location.pathname === routes.private.onlogin ? null : (
              <div className="py-4">
                <Button
                  text="Go back home"
                  action={() => {
                    track('Loading timeout - Go home', {
                      user_email: user?.email,
                      company_slug: company_slug,
                      url: window.location.href,
                    });
                    navigate(routes.private.home);
                  }}
                />
              </div>
            )
          }
          <Button
            buttonType="secondary"
            text="Refresh the page"
            action={() => {
              track('Loading timeout - Refresh page', {
                user_email: user?.email,
                company_slug,
                url: window.location.href,
              });
              window.location.reload();
            }}
          />
        </div>
      ) : (
        <div
          className={
            'animate-bounce  ' +
            (props.size === 'small'
              ? 'rounded-xl bg-stone-50 shadow min-h-[20px] min-w-[20px]'
              : 'h-[20px]')
          }
        >
          <VulaLogo />
        </div>
      )}
    </div>
  );
}
