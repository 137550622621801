import React, { useContext } from 'react';
import ButtonCard from './ButtonCard';
import ArrowIcon from '../../Icons/ArrowIcon';
import { useNavigate } from 'react-router-dom';
import { EditPitchContext } from '../../../contexts/EditPitchContext';
import { PageWelcomeMessages } from '../SimpleHelperEnums';
import { LoggedInContext } from '../../../contexts/LoggedInContext';

export default function ViewAsInvestorCard() {
  const navigate = useNavigate();
  const { company_slug } = useContext(LoggedInContext);

  const { setInvestorMode, setEditNotificationMessage } =
    useContext(EditPitchContext);
  return (
    <ButtonCard
      context="pitch"
      action={() => {
        setEditNotificationMessage(PageWelcomeMessages.investorMode);
        setInvestorMode(true);
        navigate('/' + company_slug);
      }}
    >
      <div className="px-4">
        <div className="text-sm font-extrabold">See what investors see</div>
        <div className="text-xs pt-2">
          View your pitch as an investor would see it in a new tab
        </div>
      </div>
      <div className=" pl-4">
        <div className="text-xs font-extrabold rounded-lg px-4 py-1">
          <ArrowIcon poiningDirection="right" />
        </div>
      </div>
    </ButtonCard>
  );
}
