import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaCorpusAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  getConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return config;
  }

  async updateCorpus({
    slug,
    label,
    answer,
    question,
  }: {
    slug: string;
    label: string;
    answer: string;
    question?: string;
  }) {
    const config = this.getConfig();
    // clean answer
    answer.replaceAll('&nbsp;', '').trim();

    return axios.patch(
      `${apiUrl}/corpus/${slug}`,
      { label, answer, question },
      config,
    );
  }

  async addToCorpus({
    slug,
    label,
    answer,
    question,
  }: {
    slug: string;
    label: string;
    answer: string;
    question: string;
  }) {
    const config = this.getConfig();
    // clean answer
    answer.replaceAll('&nbsp;', '').trim();
    return axios.post(
      `${apiUrl}/corpus/${slug}`,
      { qaas: [{ label, answer, question }] },
      config,
    );
  }

  async deleteFromCorpus({ slug, label }: { slug: string; label: string }) {
    const config = this.getConfig();
    return axios.delete(`${apiUrl}/corpus/${slug}/${label}`, config);
  }

  async getFromCorpus({ slug, labels }: { slug: string; labels: string[] }) {
    const config = this.getConfig();
    return axios.get(`${apiUrl}/corpus/${slug}`, {
      ...config,
      params: { labels: labels.join(',') },
    });
  }
}
