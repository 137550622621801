import React from 'react';

export default function StarIcon() {
  return (
    <div>
      <svg
        height="16"
        width="16"
        viewBox="0 0 77 78"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M38.5876 77.1999C37.3805 77.1999 36.3584 76.3312 36.0668 75.1599C34.0197 66.9362 27.9994 57.1247 17.2996 49.0999C12.2876 45.3189 7.19829 42.6977 2.16762 41.3042C0.97179 40.9729 0.0996094 39.9117 0.0996094 38.6708V38.6708C0.0996094 37.4565 0.935061 36.4088 2.10115 36.07C12.6983 32.9917 22.9194 25.6909 29.5996 15.9999C32.738 11.4667 34.8884 7.00945 36.0508 2.42946C36.3474 1.26076 37.3728 0.399902 38.5786 0.399902V0.399902C39.803 0.399902 40.838 1.28641 41.1303 2.47542C43.4741 12.007 50.9209 22.2102 60.7996 29.1999C65.3593 32.4443 70.0727 34.766 74.8725 36.0976C76.0475 36.4236 76.8996 37.4693 76.8996 38.6886V38.6886C76.8996 39.9182 76.0332 40.9686 74.8502 41.3039C64.2501 44.3084 52.5092 53.616 46.4996 63.0999C43.8054 67.3939 42.0327 71.4043 41.1218 75.1311C40.8327 76.314 39.8052 77.1999 38.5876 77.1999V77.1999Z" />
      </svg>
    </div>
  );
}
