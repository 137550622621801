import React, { createContext } from 'react';
import { VulaUserAPI } from '../api/user';
import { ClaimDefinition } from '../pages/Claim';
import { useAuth0 } from '@auth0/auth0-react';
import VulaLogo from '../components/utils/VulaLogo';

interface ContextProps {
  codeLoading: boolean;
  showCodeError: boolean;
  codeErrorMesage?: string;
  claimDefinition?: ClaimDefinition;
  claimCode?: string;
}

export const ClaimContext = createContext<ContextProps>({
  codeLoading: true,
  showCodeError: false,
  codeErrorMesage: '',
  claimDefinition: undefined,
  claimCode: undefined,
});

const ClaimContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [claimDefinition, setClaim] = React.useState<ClaimDefinition>();
  const [showError, setErrorVisibility] = React.useState(false);
  const [codeErrorMesage, setCodeErrorMesage] = React.useState<string>('');
  const [claimCode, setClaimCode] = React.useState<string>();

  // on load call the /users/claim api to get the claim definition
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // get code from url params
    const code = urlParams.get('vula_claim_code');

    // call api to get data
    if (code && code.length) {
      setClaimCode(code);
      (async () => {
        // if loggedin get token
        let token = undefined;

        if (user) {
          token = await getAccessTokenSilently();
        }
        const APItoken = token ? { token } : undefined;
        const api = new VulaUserAPI(APItoken);
        const claim = await api.getClaimDetails(code);

        if (claim.data) {
          setClaim(claim.data);
        } else {
          setErrorVisibility(true);
          setCodeErrorMesage(claim.data.error);
        }
        setLoading(false);
      })().catch(e => {
        setLoading(false);
        setCodeErrorMesage(e.response.data.error || e.error);
        setErrorVisibility(true);
      });
    } else {
      setLoading(false);
      setErrorVisibility(true);
      setCodeErrorMesage('No vula_claim_code provided');
    }
  }, [window.location.search]);

  return (
    <ClaimContext.Provider
      value={{
        codeLoading: loading,
        showCodeError: showError,
        codeErrorMesage,
        claimDefinition,
        claimCode,
      }}
    >
      {showError ? (
        <div
          className="h-full min-h-[100vh] m-auto flex items-center flex-col bg-white justify-center "
          style={{
            // Set background image to the dot.svg file
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%23b0b0b0" /></svg>')`,
            /* Set background repeat to repeat both horizontally and vertically */
            backgroundRepeat: 'repeat',
          }}
        >
          <div className="rounded-2xl bg-white shadow-2xl p-4 sm:p-12 w-[95vw] sm:w-[55vw] ">
            <div className="w-full flex justify-center rotate-[155deg] ">
              <VulaLogo />
            </div>
            <div className="text-4xl font-bold text-center py-12">Oops!</div>
            <div>
              {codeErrorMesage?.length
                ? codeErrorMesage
                : 'Something is wrong with this code. Sorry!'}
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </ClaimContext.Provider>
  );
};

export default ClaimContextProvider;
