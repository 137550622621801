import React from 'react';
import HomeNavBar from '../Home/HomeNavBar';

interface Props {
  headline: string;
  subtitle: string;
  background: string;
  children: React.ReactNode;
}

export default function MyContainer(props: Props) {
  const background = {
    background: 'url(' + props.background + ')',
    backgroundSize: 'cover',
    backgroundColor: 'gray',
    backgroundBlendMode: 'multiply',
  };

  return (
    <div className="h-full min-h-[100vh]">
      <HomeNavBar />
      <div
        className={
          'flex flex-col justify-center items-center bg-cover h-[200px] w-full bg-gray-500 bg-blend-multiply '
        }
        style={background}
      >
        <div className="max-w-[2000px] w-[90%] m-auto  px-4 sm:px-20 text-left">
          <div className="text-6xl font-extrabold text-white">
            {props.headline}
          </div>
          <div className="text-lg font-extrabold text-white">
            {props.subtitle}
          </div>
        </div>
      </div>
      <div className="max-w-[2000px] w-[90%] m-auto px-4 sm:px-20 py-10">
        {props.children}
      </div>
    </div>
  );
}
