import React from 'react';
import ArrowIcon from '../Icons/ArrowIcon';
import Button from './Button';
import VulaLogo from './VulaLogo';

interface Props {
  backNavigation?: () => void;
  backText?: string;
  nextNavigation?: () => void;
  nextText?: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
}
export default function CleanInstructionalPage(props: Props) {
  return (
    <div className="p-2 sm:p-0 min-h-[100vh] sm:max-w-[70%] m-auto flex flex-col justify-center items-center text-center">
      {typeof props.backNavigation === 'function' ? (
        <button
          className=" hidden sm:flex items-center justify-center absolute top-6 left-6"
          onClick={() => (props.backNavigation ? props.backNavigation() : null)} // not sure why i have to do this
        >
          <ArrowIcon />
          <div className="px-2 text-lg font-extrabold ">{props.backText}</div>
        </button>
      ) : null}
      <div className="">
        <VulaLogo />
      </div>
      <div className="flex flex-col justify-start pt-2 sm:pt-8 ">
        <h1 className="text-2xl sm:text-5xl font-extrabold">{props.title}</h1>
      </div>
      <div className="sm:text-xl py-2 sm:py-8 ">{props.subtitle}</div>
      <div className="w-full max-w-2/3 flex flex-row justify-center items-center py-2 sm:py-4">
        {props.children}
      </div>
      {props.nextText && props.nextNavigation ? (
        <Button
          text={props.nextText}
          action={() => (props.nextNavigation ? props.nextNavigation() : null)}
        />
      ) : null}
    </div>
  );
}
