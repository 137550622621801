import React, { useEffect, useState } from 'react';
import InvestorMemo from './InvestorMemo';
import VulaLogo from '../utils/VulaLogo';
import { MemoItem } from '../../contexts/MemoContext';
import { VulaPitchAPI } from '../../api/pitch';
import { PublicWelcomePage } from '../../data/companies';
import { track } from '../../interfaces/mixpanel';
import Page404 from '../../pages/404';
import { VulaCompanyAPI } from '../../api/company';
import Spinner from '../utils/Spinner';
import Button from '../utils/Button';

export default function PublicMemo() {
  const getSlugFromUrl = () => {
    const slug: string = window.location.pathname
      ? window.location.pathname
          .toString()
          .split('/memo')[0]
          .toLowerCase()
          .replaceAll('/', '')
      : '';

    return slug;
  };
  const [slug] = useState<string>(getSlugFromUrl());
  const [show404, setShow404] = useState(false);
  const [publicInfo, setPublicInfo] = useState<PublicWelcomePage | undefined>(
    undefined,
  );
  const [memoItems, setMemoItems] = useState<MemoItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  useEffect(() => {
    (async () => {
      await getPublicPitch();
    })();
  }, []);

  const getPublicPitch = async () => {
    setIsLoading(true);
    const api = new VulaPitchAPI();

    // on page load call the api to get public data
    try {
      const res = await api.getPublicPitch({ slug });
      setPublicInfo(res.data);
      track('Open public memo ' + slug);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 404) {
        // route to 404 page
        setShow404(true);
        track('Incorrect pitch url', { slug });
      }
    }
    setIsLoading(false);
  };

  if (show404) {
    return <Page404 />;
  }

  const checkPasscode = async (passphrase: string) => {
    const api = new VulaCompanyAPI();
    try {
      const res = await api.getMemoWithPassword(slug, passphrase);

      if (res.data?.memoItems?.length && setMemoItems) {
        setMemoItems(res.data?.memoItems);
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-[100vh] min-w-[100vw]">
      <button
        className="flex flex-row justify-center items-center w-full "
        onClick={() => window.open(window.location.origin, '_blank')}
      >
        <VulaLogo />
      </button>

      {isLoading ? (
        <Spinner />
      ) : (
        <InvestorMemo
          isEditable={false}
          company_name={publicInfo?.companyName || ''}
          logo={publicInfo?.logoImg}
          memoItems={memoItems}
          checkPasscode={checkPasscode}
        />
      )}

      {memoItems.length ? (
        copiedToClipboard ? (
          <div className="py-2">Copied to clipboard</div>
        ) : (
          <Button
            text={'Share'}
            // add to clipboard
            action={() => {
              navigator.clipboard.writeText(window.location.href);
              track('Copy pitch link', { slug });
              setCopiedToClipboard(true);
            }}
          />
        )
      ) : null}
    </div>
  );
}
