import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { VulaCompanyAPI } from '../../api/company';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { routes } from '../../pages/routes';
import CleanInstructionalPage from '../utils/CleanInstructionalPage';
import CompanyGeosDefinitions from './CompanyGeosDefinitions';
import CompanyVerticalDefinitions from './CompanyVerticalDefinitions';
import { GrantContext } from '../../contexts/GrantContext';
import { africanCountries, allOtherCountries } from '../../data/countries';
import HalfTextHalfImagePage from '../utils/HalfTextHalfImagePage';
import Spinner from '../utils/Spinner';

export default function VerifyParams() {
  const { company_slug, company_name } = useContext(LoggedInContext);
  const navigate = useNavigate();
  const { companyParams, getCompanyParams } = useContext(GrantContext);
  const [isNewUser, setIsNewUser] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [count, setCount] = useState(0);
  const [loadingParams, setLoadingParams] = useState(false);

  // on load get the company params
  React.useEffect(() => {
    (async () => {
      setLoadingParams(true);

      try {
        // if no company slug, then must be a new user
        if (company_slug === '') {
          navigate(routes.private.onboarding);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        // if 404 then must be a new user
        if (e?.response?.status === 404) {
          setIsNewUser(true);
        }
      }

      setLoadingParams(false);
    })();
  }, []);

  const geosAreValid = () => {
    // check if geos are in the correct format

    // for each param, check if it's in allCountries
    const companyGeos = companyParams?.parameters
      .filter(param => param.type === 'geography')
      .map(param => {
        return param.text;
      });

    const geosInAllCountries = companyGeos?.filter(geo =>
      allOtherCountries
        .concat(africanCountries)
        .map(country => country.name)
        .includes(geo),
    );

    // if geo is not in allCountries, return true
    if (companyGeos?.length === geosInAllCountries?.length) {
      return true;
    }
    // if geos contain All countries, return true
    if (companyGeos?.includes('All countries')) {
      return true;
    }

    return false;
  };

  const markAllAsVerified = async () => {
    if (!geosAreValid()) {
      alert('Something is wrong with your geos. Please check and try again.');
      return;
    }
    // Check there are at least 1 geo and 1 vertical
    const geos = companyParams?.parameters.filter(
      param => param.type === 'geography',
    );
    const verticals = companyParams?.parameters.filter(
      param => param.type === 'vertical',
    );

    if (geos?.length === 0 || verticals?.length === 0) {
      alert('Please select at least 1 vertical and 1 geo');
      return;
    }

    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });

    try {
      await api.markParamsAsVerified(company_slug);
      await api.matchCompanyToGrants(company_slug);
      await getCompanyParams(); // hack for bug that doesn't update the context after moving

      navigate(routes.private.myGrants);
    } catch (e) {
      console.log(e);
      // when 0 grants found take them straight to the home page
      navigate(routes.private.home);
    }
  };

  if (loadingParams || companyParams === undefined) {
    return (
      <HalfTextHalfImagePage
        heading="Analysing..."
        image="https://vula-assets.s3.eu-west-2.amazonaws.com/grant_process.png"
      >
        <div className="flex flex-row justify-center items-center w-full">
          <Spinner />
          <div>
            Vula is reading all your data and trying to define your business.
          </div>
        </div>
      </HalfTextHalfImagePage>
    );
  }

  if (count === 0 && isNewUser) {
    return (
      <CleanInstructionalPage
        title="Welcome to your grant manager."
        subtitle="Get the perfect grants via email, and auto apply with Vula."
        nextNavigation={() => setCount(1)}
        nextText="Next"
      >
        <div className="flex flex-col justify-center items-center sm:w-2/3">
          <div className="m-8 p-8 rounded-2xl shadow-xl bg-gray-100 border border-solid border-2 border-gray-200">
            <b>Vula learns. </b>
            <br />
            <br />
            Each grant you apply for will train Vula to write better grants.
            <br />
            <br />
            Please give us feedback if you think something is wrong or if you
            have any suggestions.
          </div>
        </div>
      </CleanInstructionalPage>
    );
  }

  return (
    <CleanInstructionalPage
      title="Your company details"
      subtitle="Is this right?"
      nextNavigation={() => markAllAsVerified()}
      nextText={`Find matches for ${company_name}`}
    >
      <div className="flex flex-col justify-center items-center w-full">
        <CompanyVerticalDefinitions />
        <CompanyGeosDefinitions />
      </div>
    </CleanInstructionalPage>
  );
}
