import React, { useContext } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import Pill from '../utils/Pill';
import EditablePitchText from '../utils/EditablePitchText';
import PitchContentContainer from './PitchContentContainer';

interface Props {
  id?: string;
}

export default function WhyNow(props: Props) {
  const { privatePitchInfo } = useContext(EditPitchContext);

  if (!privatePitchInfo?.whyNow) return null;

  return (
    <PitchContentContainer id={props.id}>
      <div className="pb-12">
        <Pill>
          <div>Why now?</div>
        </Pill>
      </div>
      <EditablePitchText
        label="why_now"
        text={privatePitchInfo.whyNow}
        classStyling="shadow-xl text-center p-4 sm:py-12 bg-gray-200 rounded-xl whitespace-pre-wrap	"
      />
    </PitchContentContainer>
  );
}
