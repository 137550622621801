import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TypeWriterEffect from 'react-typewriter-effect';

import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import SingleLineInput from '../../components/Onboarding/SingleLineInput';
import { apiUrl } from '../../methods/env';
import { VulaCompanyAPI } from '../../api/company';
import Button from '../../components/utils/Button';
import Spinner from '../../components/utils/Spinner';
import { track } from '../../interfaces/mixpanel';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { useContext, useEffect } from 'react';
import CopyIcon from '../Icons/CopyIcon';
import { GrantContext } from '../../contexts/GrantContext';

interface Props {
  placeholder?: string;
  cardsBackground?: 'dark' | 'light';
  addToPrompt?: string;
  // allow a question to be input outside of this component from a parent component
  question?: string;
  onQuestionChange?: (question: string) => void;
  skipAnimatingRepsonse?: boolean;
}

export default function QAAWithVula(props: Props) {
  const navigate = useNavigate();
  const { company_name, company_slug, isLoading } = useContext(LoggedInContext);
  const {
    selectedGrant,
    storeGrantQA,
    defineSelectedGrant,
    updateGrantStatus,
  } = useContext(GrantContext);
  const { getAccessTokenSilently } = useAuth0();

  // use state to store the question
  const [question, setQuestion] = React.useState('');
  // use state to store the answer
  const [answer, setAnswer] = React.useState('');
  // use state to show loading
  const [loading, setLoading] = React.useState(false);

  // check if question prop has changed and if so set the question
  useEffect(() => {
    if (typeof props.question === 'string') {
      setQuestion(props.question);
      (async () => {
        if (typeof props.question === 'string') {
          await generateAnswer(props.question);
        }
      })();
    } else {
      setQuestion('');
      props.onQuestionChange && props.onQuestionChange('');
    }
  }, [props.question]);

  // check if it is a new user
  useEffect(() => {
    if (!isLoading && company_name === '') {
      navigate(routes.private.onboarding);
    }
  }, [isLoading]);

  useEffect(() => {
    (async () => {
      if (question && answer === '') {
        // send the question to the backend
        setLoading(true);
        setAnswer('');
        try {
          const answer = await generateAnswer(question);

          try {
            // this shouldn't sit here I think - as it will be called every time the question is asked, not only when a grant is queried
            if (selectedGrant?.grant.id) {
              await storeGrantQA(question, answer.data.answer);
            }
            // save the qa in the backend
            // re-get the previous qas
          } catch (error) {
            console.error(error);
          }
          // set the answer in the state
          setAnswer(answer.data.answer);
        } catch (error) {
          setAnswer('Sorry something went wrong, please try again later');
        }
        setLoading(false);
      }
    })();
  }, [question]);

  const generateAnswer = async (question: string) => {
    track('asked_question', {
      question,
      company_slug,
      url: window.location.href,
    });

    // if status is not inprogress then update status
    if (selectedGrant && selectedGrant?.status !== 'inProgress') {
      defineSelectedGrant({
        ...selectedGrant,
        status: 'inProgress',
      });
      updateGrantStatus && (await updateGrantStatus('inProgress'));
    }
    // get api token from auth0
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    const api = new VulaCompanyAPI({ token: auth0Token });
    const prompt = props.addToPrompt ? props.addToPrompt + question : question;
    return await api.generateAnswer({ company_slug, question: prompt });
  };

  return (
    <div className="flex flex-col p-1 pt-2 justify-end w-full  ">
      {question && (
        <div className="flex flex-col justify-end items-end pl-4 sm:pl-12">
          <div className="w-1/4"></div>
          <div
            className={
              'rounded-lg px-4 py-2 text-right w-fit' +
              (props.cardsBackground === 'dark'
                ? ' bg-stone-800'
                : ' bg-stone-200')
            }
          >
            {question}
          </div>
        </div>
      )}
      {loading && (
        <div className="flex flex-col items-start w-full ">
          <div
            className={
              ' rounded-lg px-4 py-2 text-right w-fit' +
              (props.cardsBackground === 'dark'
                ? ' bg-stone-800'
                : ' bg-stone-200')
            }
          >
            <Spinner />
          </div>
        </div>
      )}
      {answer && (
        <div
          className={
            'relative sm:max-w-[85%] rounded-lg p-3 sm:px-8 sm:py-4 my-2 text-left w-fit flex flex-row items-center' +
            (props.cardsBackground === 'dark'
              ? ' bg-stone-800'
              : ' bg-stone-100')
          }
        >
          {props.skipAnimatingRepsonse ? (
            <div className="">{answer}</div>
          ) : (
            <TypeWriterEffect
              startDelay={0}
              cursor=""
              cursorColor="#00000000"
              multiText={[answer]}
              typeSpeed={1}
            />
          )}
          <button
            className={
              'scale-[0.8] ml-2 rounded active:scale-[0.7] ' +
              (props.cardsBackground === 'dark'
                ? ' hover:bg-stone-600'
                : ' hover:bg-stone-400')
            }
            onClick={() => {
              // add to clipboard
              navigator.clipboard.writeText(answer);
            }}
          >
            <CopyIcon />
          </button>
        </div>
      )}

      {!question ? (
        <div className=" flex flex-row w-full justify-end items-center ">
          <SingleLineInput
            placeholder={
              props.placeholder ? props.placeholder : `Ask a question`
            }
            buttonText="Ask Vula"
            onSubmit={(string: string) => setQuestion(string)}
            oneline={true}
            doNotAutoFocus={true}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center  ">
          <Button
            text="Ask another question"
            action={() => {
              setQuestion('');
              setAnswer('');
            }}
          />
        </div>
      )}
    </div>
  );
}
