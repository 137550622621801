import React from "react";
interface Props {
  videoURL: string;
}
export default function VideoContainer(props: Props) {
  return (
    <div className='flex col-span-2 row-span-2 justify-center shadow-3xl rounded-[25px] overflow-hidden'>
      <iframe
        src={props.videoURL}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded video'
        className='w-full lg:w-[1200px] sm:h-[500px]'
      />
    </div>
  );
}
