import React from 'react';

import ProblemStatement from './ProblemStatement';
import TheSolution from './TheSolution';
import MeetUs from './MeetUs';
import WeAre from './WeAre';
import Traction from './Traction';
import OurAsk from './OurAsk';
import OurVision from './OurVision';
import Competitors from './Competitors';
import WhyNow from './WhyNow';

export default function PitchContent() {
  // this is a lil silly to look at but means that we don't manage html ids in a separate place
  // to where the component is
  const content = {
    problem: (id: string) => <ProblemStatement id={id} />,
    solution: (id: string) => <TheSolution id={id} />,
    weAre: (id: string) => <WeAre id={id} />,
    ourAsk: (id: string) => <OurAsk id={id} />,
    traction: (id: string) => <Traction id={id} />,
    vision: (id: string) => <OurVision id={id} />,
    whyNow: (id: string) => <WhyNow id={id} />,
    competition: (id: string) => <Competitors id={id} />,
    meetUs: (id: string) => <MeetUs id={id} />,
  };

  const sectionIds = ['public', ...Object.keys(content)];
  const contentArray = Object.values(content);

  return (
    <>
      {contentArray.map((content, index) => {
        return <div key={index}>{content(sectionIds[index + 1])}</div>;
      })}
    </>
  );
}
