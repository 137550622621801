import axios from 'axios';

import { apiUrl } from '../methods/env';
import { CompanyGrantMatches, NewCompanyParameter } from '../types/Grants';

type Props = {
  token?: string;
};

export class VulaCompanyAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  getConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return config;
  }

  async saveCompanyUrl(url: string) {
    const config = this.getConfig();
    return axios.post(`${apiUrl}/company`, { url: url }, config);
  }

  async saveCompanyNameAndText(name: string, page_text: string) {
    const config = this.getConfig();
    return axios.post(`${apiUrl}/company`, { name, page_text }, config);
  }

  async createPitch(company_slug: string) {
    const config = this.getConfig();
    return axios.post(`${apiUrl}/company/${company_slug}/pitch`, {}, config);
  }

  async genProblemAndSolution(company_slug: string) {
    const config = this.getConfig();
    return axios.get(
      `${apiUrl}/pitch/${company_slug}/problem-and-solution`,
      config,
    );
  }

  async genRestOfPitch(company_slug: string) {
    const config = this.getConfig();
    return axios.get(
      `${apiUrl}/pitch/${company_slug}/generate-rest-of-pitch`,
      config,
    );
  }

  async checkUserIsAdminForCompany(company_slug: string) {
    const config = this.getConfig();
    return axios.get(`${apiUrl}/company/${company_slug}/admin`, config);
  }

  async getCompanyPitchPassword(company_slug: string) {
    const config = this.getConfig();
    return axios.get(
      `${apiUrl}/company/${company_slug}/pitch-passwords`,
      config,
    );
  }

  async updateCompanyPitchPassword(company_slug: string, new_password: string) {
    const config = this.getConfig();
    return axios.patch(
      `${apiUrl}/company/${company_slug}/pitch-password`,
      {
        new_passphrase: new_password,
      },
      config,
    );
  }

  async updateCompanyStructuredData(
    company_slug: string,
    label: string,
    content: string,
  ) {
    const config = this.getConfig();
    return axios.patch(
      `${apiUrl}/company/${company_slug}/structured`,
      {
        label,
        content,
      },
      config,
    );
  }

  async deleteCompanyLogo(company_slug: string) {
    const config = this.getConfig();
    return axios.delete(`${apiUrl}/company/${company_slug}/logo`, config);
  }

  async updateCompanyName(company_name: string, company_slug: string) {
    const config = this.getConfig();
    return axios.patch(
      `${apiUrl}/company/${company_slug}/name`,
      { company_name },
      config,
    );
  }

  async deleteCompanyScrape(company_slug: string) {
    const config = this.getConfig();
    return axios.delete(`${apiUrl}/company/${company_slug}/scrape`, config);
  }

  async deleteAttachment(slug: string, label: string, filename?: string) {
    // to do - delete from s3
    const config = this.getConfig();
    const conditions = filename?.length ? { label, filename } : { label };

    return axios.delete(`${apiUrl}/company/${slug}/attachment`, {
      ...config,
      data: conditions,
    });
  }

  async getCompanyParameters(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/parameters`,
      config,
    );
    return response.data;
  }

  async markParamsAsVerified(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/parameters/verified`,
      {},
      config,
    );
    return response.data;
  }

  async saveCompanyParameters(
    company_slug: string,
    param: NewCompanyParameter,
  ) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/parameters`,
      param,
      config,
    );
    return response;
  }

  async deleteCompanyParameter(company_slug: string, param_id: string) {
    const config = this.getConfig();
    const response = await axios.delete(
      `${apiUrl}/company/${company_slug}/parameters/${param_id}`,
      config,
    );
    return response;
  }

  async getGrantNotificationEmail(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/grants/notification-email`,
      config,
    );
    return response;
  }

  async updateGrantNotificationEmail(company_slug: string, email: string) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/grants/notification-email`,
      { email },
      config,
    );
    return response;
  }

  async getCompanyGrants(company_slug: string): Promise<CompanyGrantMatches> {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/grants`,
      config,
    );
    return response.data;
  }

  async getCompanyGrantAskedQuestions(company_slug: string, grant_id: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/asked_questions`,
      config,
    );
    return response.data;
  }

  async saveCompanyGrantAskedQuestion({
    company_slug,
    grant_id,
    question,
    answer,
  }: {
    company_slug: string;
    grant_id: string;
    question: string;
    answer: string;
  }) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/asked_question`,
      { question, answer },
      config,
    );
    return response;
  }

  async updateCompanyGrantAskedQuestion({
    company_slug,
    grant_id,
    question_id,
    answer,
  }: {
    company_slug: string;
    grant_id: string;
    question_id: string;
    answer: string;
  }) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/asked_question/${question_id}`,
      { answer },
      config,
    );
    return response;
  }

  async deleteCompanyGrantAskedQuestion({
    company_slug,
    grant_id,
    question_id,
  }: {
    company_slug: string;
    grant_id: string;
    question_id: string;
  }) {
    const config = this.getConfig();
    const response = await axios.delete(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/asked_question/${question_id}`,
      config,
    );
    return response;
  }

  async updateGrantStatus(
    company_slug: string,
    grant_id: string,
    status: string,
  ) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/status`,
      { status },
      config,
    );
    return response;
  }

  async matchCompanyToGrants(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/match_grants`,
      config,
    );
    return response;
  }

  async getGrantApplication(company_slug: string, grant_id: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/application`,
      config,
    );
    return response;
  }

  async getGrantCompanyMatchingParameters(
    company_slug: string,
    grant_id: string,
  ) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/matching_parameters`,
      config,
    );
    return response;
  }

  async updateGrantQuestionAnswer(
    company_slug: string,
    grant_id: string,
    question_id: string,
    answer: string,
  ) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/questions/${question_id}`,
      { answer },
      config,
    );
    return response;
  }

  async generateAnswer(generateAnswerProps: generateAnswerProps) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${generateAnswerProps.company_slug}/generate_answer`,
      generateAnswerProps,
      config,
    );
    return response;
  }

  async acceptTermsOfGrant(company_slug: string, grant_id: string) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/grants/${grant_id}/accept_terms`,
      {},
      config,
    );
    return response;
  }

  async getMemoDetails(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/memo`,
      config,
    );
    return response;
  }

  async generateMemo(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/memo/generate`,
      {},
      config,
    );
    return response;
  }

  async getMemoPassword(company_slug: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/memo/password`,
      config,
    );
    return response;
  }

  async updateMemoPassword(company_slug: string, password: string) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/memo/password`,
      { password },
      config,
    );
    return response;
  }

  async getMemoWithPassword(company_slug: string, password: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/memo?password=${password}`,
      config,
    );
    return response;
  }

  async updateMemoPublicity(company_slug: string, is_public: boolean) {
    const config = this.getConfig();
    const response = await axios.patch(
      `${apiUrl}/company/${company_slug}/memo/public`,
      { is_public },
      config,
    );
    return response;
  }

  async deleteMemoRow(company_slug: string, label: string) {
    const config = this.getConfig();
    const response = await axios.delete(
      `${apiUrl}/company/${company_slug}/answer/${label}`,
      config,
    );
    return response;
  }

  async updateCompanyDetails(
    company_slug: string,
    label: string,
    value: string,
  ) {
    const config = this.getConfig();
    const response = await axios.post(
      `${apiUrl}/company/${company_slug}/details`,
      {
        label,
        value,
      },
      config,
    );
    return response;
  }

  // call the backend to take actions based on the referrer code
  async handleReferrerCode(company_slug: string, code: string) {
    const config = this.getConfig();
    const response = await axios.get(
      `${apiUrl}/company/${company_slug}/referrer_code/${code}`,
      config,
    );
    return response;
  }

  async getPresignedUploadURL(slug: string, type: string) {
    const config = this.getConfig();
    return axios.post(
      `${apiUrl}/company/${slug}/attachment/presigned_url`,
      { type },
      config,
    );
  }

  async postUploaded(
    slug: string,
    filename: string, // s3 location
    label: string,
    type: string,
    user_filename: string,
  ) {
    const config = this.getConfig();
    return axios.post(
      `${apiUrl}/company/${slug}/attachment`,
      { filename, label, type, user_filename },
      config,
    );
  }
}

interface generateAnswerProps {
  company_slug: string;
  question: string;
  question_id?: string;
  label?: string;
}
