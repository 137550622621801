import React, { useContext, useEffect, useState } from 'react';
import EditPitchWrapper from '../components/Onboarding/PitchDeckBuilding/EditPitchWrapper';
import ProblemStatement from '../components/Pitch/ProblemStatement';
import { useNavigate } from 'react-router-dom';
import { EditPitchContext } from '../contexts/EditPitchContext';
import TheSolution from '../components/Pitch/TheSolution';
import WeAre from '../components/Pitch/WeAre';
import OurAsk from '../components/Pitch/OurAsk';
import Competitors from '../components/Pitch/Competitors';
import MeetUs from '../components/Pitch/MeetUs';
import OurVision from '../components/Pitch/OurVision';
import Traction from '../components/Pitch/Traction';
import WhyNow from '../components/Pitch/WhyNow';
import PublicPitch from '../components/Pitch/PublicPitch';
import { PageWelcomeMessages } from '../components/Pitch/SimpleHelperEnums';

export default function PitchHomeComponents() {
  const navigate = useNavigate();
  const { getPitchData, privatePitchInfo } = useContext(EditPitchContext);
  const [path, setPath] = useState<Content | undefined>(undefined);
  const [show404, set404] = useState<boolean>(false);

  const content = {
    landing: () => (
      <PublicPitch contentIsVisible checkPassphrase={async () => true} />
    ),
    problem: (id: string) => <ProblemStatement id={id} />,
    solution: (id: string) => <TheSolution id={id} />,
    weAre: (id: string) => <WeAre id={id} />,
    ourAsk: (id: string) => <OurAsk id={id} />,
    traction: (id: string) => <Traction id={id} />,
    vision: (id: string) => <OurVision id={id} />,
    whyNow: (id: string) => <WhyNow id={id} />,
    competition: (id: string) => <Competitors id={id} />,
    meetUs: (id: string) => <MeetUs id={id} />,
  };
  type Content = keyof typeof content;

  // on page load set the path the user is on
  useEffect(() => {
    const path = window.location.pathname;
    const component = path.split('/pitch/')[1];

    if (component) {
      // check if it is a component inside of content and is hence of that type defined below
      if (Object.keys(content).includes(component)) {
        setPath(component as Content);

        // if successful we need to get the data for the pitch if it is not already there
        if (!privatePitchInfo) {
          (async () => await getPitchData())();
        }
      } else {
        // if not a component inside of content, set 404
        set404(true);
      }
    }
  }, [window.location.pathname]);

  const getNextStepOfContent = (currentstep: string) => {
    const keys = Object.keys(content);
    const index = keys.indexOf(currentstep);

    // if at end, send the user back home
    if (index === keys.length - 1) {
      return 'home';
    }
    // else send them to the next steps
    const next = keys[index + 1];
    return next;
  };

  // if path is not a key in content, return 404
  if (show404 || !path) {
    return <div>404</div>;
  }

  return (
    <EditPitchWrapper
      helperMessage={
        PageWelcomeMessages
          ? [path].length
            ? PageWelcomeMessages[path]
            : ''
          : ''
      }
      customNextStep={
        path
          ? () => navigate('/pitch/' + getNextStepOfContent(path))
          : undefined
      }
      customNextStepName={'Next section'}
    >
      {content[path as Content](path as string)}
    </EditPitchWrapper>
  );
}
