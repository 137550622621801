import React, { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { junkDrawLinkns } from '../../data/junk-draw';
import VButton from './Vbutton';
import { addUserToMailingList } from '../../api/marketing';
import { track } from 'mixpanel-browser';

const Footer = () => {
  const [signedUp, setSignedUp] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await addUserToMailingList(inputRef.current?.value || '', 'Vula Community');
    track('community-signup');
    setSignedUp(true);
  };
  return (
    <footer className="mb-10">
      <div className="footer sm:flex gap-12 md:mb-12">
        <div className="flex  flex-1 gap-[60px] mb-8 md:mb-[0px]">
          <div className="">
            <div className="flex flex-wrap lg:flex-nowrap gap-12 text-grey">
              {junkDrawLinkns?.map((section, index) => (
                <div key={index}>
                  <h6 className="text-[14px] whitespace-nowrap text-left pb-4 font-[600] text-dark">
                    {section.title}
                  </h6>
                  <ul className="flex flex-col gap-y-4">
                    {section.links.map((link, index) => (
                      <Link
                        key={index}
                        to={link.link}
                        className="text-[14px] hover:text-primary whitespace-nowrap"
                      >
                        {link.title}
                      </Link>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="w-full">
          <h6 className="text-[14px] text-left font-[600] text-dark mb-3">
            Join our community
          </h6>
          <p className="text-[14px] text-left mb-5">
            We'll send you updates on our progress
          </p>
          <label className="">
            <input
              type="email"
              name=""
              ref={inputRef}
              placeholder="Enter your email"
              className="w-full h-[50px] text-[15px] rounded-lg outline-none border cursor-text pl-4 border-[#C0C2C1] border-solid shadow-inputElementShadow"
            />
          </label>
          <VButton
            className="w-full flex justify-center mt-4"
            type="submit"
            text={`${signedUp ? 'Joined Community' : 'Join Community'}`}
          />
        </form>
      </div>
      <div className="h-[1px] bg-light-gradient my-8" />
      <p className="text-left text-[14px] mt-10">© Vula 2024.</p>
    </footer>
  );
};

export default Footer;
