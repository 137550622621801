import React from 'react';

interface Props {
  children: React.ReactNode;
  condition: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

export default function ConditionalWrapper(props: Props) {
  if (props.condition) {
    return <>{props.wrapper(props.children)}</>;
  } else {
    return <>{props.children}</>;
  }
}
