import React, { useContext } from 'react';
import { ApplicationContextPublic } from '../../contexts/ApplicationContextPublic';
import {
  ApplicationContext,
  LendingProductApplicationQuestion,
  LendingProductApplicationSection,
} from '../../contexts/ApplicationContext';
import LendingApplicationQuestion from './LendingApplicationQuestion';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import Spinner from '../utils/Spinner';

interface Props {
  type?: string;
}

export default function FlexibleSectionOfQuestions(props: Props) {
  // if we are on a public url use the public context, else use the private context
  const {
    formDefinition,
    upsertAnswer,
    partner,
    publicFormIsLoading,
    errors,
    setErrors,
  } = useContext(ApplicationContextPublic);
  const { lendingProductApplicationDefinition, updateAnswerInApplicationJson } =
    useContext(ApplicationContext);
  const { updateCorpus } = useContext(EditPitchContext);
  const [isPublicApplication, setIsPublicApplication] = React.useState(false);

  const [section, setSection] = React.useState<
    LendingProductApplicationSection | undefined
  >(undefined);

  // if it is a public url get the section from the public context,
  React.useEffect(() => {
    if (
      formDefinition &&
      'lendingProductApplicationSections' in formDefinition
    ) {
      setIsPublicApplication(true);
      const section = formDefinition.lendingProductApplicationSections?.filter(
        section => {
          // if prop use that else just filter welcome
          if (props.type) {
            return section.type === props.type;
          }
          return section.type !== 'welcome';
        },
      );
      if (section?.length) {
        setSection(section[0]);
      }
    } else {
      setIsPublicApplication(false);
      // else get the section from the private context
      const section = lendingProductApplicationDefinition?.sections.filter(
        section => {
          // if prop use that else just filter welcome
          if (props.type) {
            return section.type === props.type;
          }
          return section.type !== 'welcome';
        },
      );
      if (section?.length) {
        setSection(section[0]);
      }
    }
  }, [formDefinition, lendingProductApplicationDefinition]);

  const saveAnswer = async (
    question: LendingProductApplicationQuestion,
    answer: string,
  ) => {
    // if it is a public url save the answer in the public context
    if (formDefinition) {
      return upsertAnswer({ ...question, answer });
    } else {
      // else save the answer in the private context
      updateAnswerInApplicationJson(question.id, answer);

      return updateCorpus(question.corpus_label, answer);
    }
  };

  if (publicFormIsLoading) return <Spinner />;

  return (
    <>
      <div>{section?.subtitle}</div>
      <div className="pt-2 sm:pt-4">
        {section?.questions.map(question => (
          <LendingApplicationQuestion
            key={question.id + question.answer}
            question={question}
            saveAnswer={async answer => {
              await saveAnswer(question, answer);
            }}
            setErrors={setErrors}
            errors={errors}
            isPublicApplication={isPublicApplication}
            partner_name={partner.partner_name}
          />
        ))}
      </div>
    </>
  );
}
