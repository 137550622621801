import React, { useEffect, useState } from 'react';
import ContentSection from '../../utils/ContentSection';
import Button from '../../utils/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import { GrantContext } from '../../../contexts/GrantContext';
import { VulaCompanyAPI } from '../../../api/company';

interface Props {
  additionalButton?: React.ReactNode;
}

export default function ReviewBeforeSubmit(props: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = React.useContext(LoggedInContext);
  const {
    selectedGrant,
    defineSelectedGrant,
    setQuestionErrors,
    questionIdsWithErrors,
  } = React.useContext(GrantContext);
  const [isError, setError] = useState(false);

  useEffect(() => {
    if (questionIdsWithErrors.length) {
      setError(true);
    } else {
      setError(false);
    }
  }, [questionIdsWithErrors]);

  const submitApplication = async () => {
    if (checkAllQuestionsHaveAnAnswer()) {
      if (selectedGrant?.grant.id) {
        // update the status of the grant
        const token = await getAccessTokenSilently();
        const api = new VulaCompanyAPI({ token });
        try {
          await api.updateGrantStatus(
            company_slug,
            selectedGrant.grant.id,
            'awaiting',
          );
          defineSelectedGrant({ ...selectedGrant, status: 'awaiting' });
          return;
        } catch (e) {
          console.log(e);
        }
        // deselect the selected grant
        defineSelectedGrant(undefined);
      }
    } else {
      setError(true);
    }
  };

  const checkAllQuestionsHaveAnAnswer = () => {
    const questionIdsWithErrors: string[] = [];
    // loop through each question and check if the question.answer field has length
    selectedGrant?.grant.application_sections?.forEach(section => {
      section.section_questions.forEach(question => {
        if (question.isOptional) return true;

        if (question?.answer?.length) {
          return true;
        } else {
          // add to graant context the id
          questionIdsWithErrors.push(question.id);
          return false;
        }
      });
    });

    if (questionIdsWithErrors.length) {
      setQuestionErrors(questionIdsWithErrors);
      return false;
    } else {
      return true;
    }
  };

  return (
    <ContentSection>
      <div className="flex flex-col justify-end w-full">
        {isError ? (
          <>
            <div className="mb-4 text-xl font-black">
              Looks like there is an error
            </div>
            <div className="mb-2 text-md">
              Check above that all questions have been answered
            </div>
          </>
        ) : (
          <>
            <div className="mb-4 text-xl font-black">
              Last chance to review.
            </div>
            <div className="mb-2 text-md">
              After clicking submit, you will not be able to edit your answers.
              If you are happy with your answers, click the submit button below.
            </div>
            <Button
              action={async () => await submitApplication()}
              text="Submit application"
            />
          </>
        )}
        {props.additionalButton}
      </div>
    </ContentSection>
  );
}
