import React, { useContext } from 'react';

import { EditPitchContext } from '../../contexts/EditPitchContext';
import Pill from '../utils/Pill';
import PitchContentContainer from './PitchContentContainer';
import EditablePitchText from '../utils/EditablePitchText';

interface Props {
  id?: string;
}

export default function ProblemStatement(props: Props) {
  const { privatePitchInfo } = useContext(EditPitchContext);

  if (!privatePitchInfo?.problem) {
    console.log('no data');
    return null;
  }

  return (
    <PitchContentContainer id={props.id}>
      <Pill>The problem</Pill>
      <div className="flex items-center flex-col justify-center p-2">
        <EditablePitchText
          text={privatePitchInfo.problem?.headline}
          label="problem_headline"
          classStyling={
            'max-h-[50vh] p-4 text-2xl md:leading-relaxed font-black text-center whitespace-pre-wrap	' +
            (privatePitchInfo.problem?.headline.length > 50
              ? ' sm:text-4xl'
              : ' sm:text-6xl ')
          }
        />

        <div className=" my-4 sm:m-12">
          <EditablePitchText
            text={privatePitchInfo.problem?.subtitle}
            label="problem_subtitle"
            classStyling="text-center p-4 sm:py-12 bg-gray-200 rounded-xl whitespace-pre-wrap	"
          />
        </div>
      </div>
    </PitchContentContainer>
  );
}
