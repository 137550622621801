import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

export class VulaUserAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  async getUserMe() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(`${apiUrl}/user/me`, config);
  }

  async postUserMe(email: string, referrer?: string, claim_code?: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.post(
      `${apiUrl}/user/me`,
      { email, referrer, claim_code },
      config,
    );
  }

  async deleteUserMe() {
    console.log('deleting');
    // note this doesn't delete them from auth0
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.delete(`${apiUrl}/user/me`, config);
  }

  async getClaimDetails(code: string) {
    if (this.token) {
      return axios.get(`${apiUrl}/user/claim?code=${code}`, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
    } else {
      return axios.get(`${apiUrl}/user/claim?code=${code}`);
    }
  }

  async updateClaimStatus(code: string, isAccepted: boolean) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.put(
      `${apiUrl}/user/claim?code=${code}`,
      { accepted: isAccepted },
      config,
    );
  }

  async checkMissingClaimData(code: string) {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return axios.get(
      `${apiUrl}/user/claim/completion-check?code=${code}`,
      config,
    );
  }
}
