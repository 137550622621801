import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react';
import { VulaCompanyAPI } from '../../api/company';
import { GrantContext } from '../../contexts/GrantContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import DefinitionsFrame from './DefinitionsFrame';

export default function GrantNotificationEmailInput() {
  const { company_slug } = useContext(LoggedInContext);
  const { grantEmail, getCompanyGrantNotificationEmail } =
    useContext(GrantContext);
  const { user, getAccessTokenSilently } = useAuth0();

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    (async () => {
      await getCompanyGrantNotificationEmail();
      if (company_slug.length && !grantEmail && user?.email) {
        await saveEmail();
      }
    })();
  }, []);

  const saveEmail = async () => {
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    try {
      const email = inputRef.current?.value || user?.email;
      if (email?.length && inputRef.current?.value !== user?.email) {
        await api.updateGrantNotificationEmail(company_slug, email);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DefinitionsFrame
      helperText="We will send email notifications to this address when new grant are found or need attention."
      label="Alerts Email"
    >
      {grantEmail ? (
        <input
          key={grantEmail}
          ref={inputRef}
          className="w-full border border-solid border-2 border-gray-300 p-2 rounded-lg"
          type="text"
          placeholder="Enter your email"
          defaultValue={grantEmail}
          onBlur={saveEmail}
        />
      ) : (
        <input
          key={user?.email}
          ref={inputRef}
          className="w-full border border-solid border-2 border-gray-300 p-2 rounded-lg"
          type="text"
          placeholder="Enter your email"
          defaultValue={user?.email ?? ''}
          onBlur={async () => await saveEmail()}
        />
      )}
    </DefinitionsFrame>
  );
}
