// a collection of strings that are used as helpers for the user

export const PageWelcomeMessages = {
  pitchWelcome:
    "Welcome to your pitchsite. Don't forget to scroll down! Edit the text by clicking on text to change it. Simply click off to save.",
  investorMode: 'This is how your pitch looks to investors.',
  onboardingSetPassword:
    'Create a simple custom password that you will share to investors. Make it memorable and related to your companies mission.',
  landing:
    'This is your landing page - your first impression to an investor. Make sure the subtitle makes them want to open your pitch.',
  problem:
    '🧐 Does this sound right? Say why the problem is massive, important and painful for your customers.',
  solution:
    '💪 What is your solution? Why do your customers love it and why is it the best solution for this problem?',
  weAre:
    'The team page. One of the most important slides. You can use an existing image or use our founder flow templates.',
  traction:
    'Show that your solutions are in demand. Try to be as concrete as possible by showing your top 3 metrics. Change the numbers and metrics to suit your stage. Show your momentum with a graph, infographic, or the logos of big customers and partners.',
  ourAsk:
    'How much are you raising? Ideally, aim cover your expansion costs and 18 months of runway. What will the raise enable you to achieve? Be specific about your top 3 goals.',
  vision:
    'What happens if you succeed? What happens if you scale? How will the lives of your customers or the world be different?',
  whyNow:
    'What is special about right now that makes it the perfect time to be growing this business? Think about changes in technology, policy, or customer-base.',
  competition:
    'Who are your two main competitors in this market and how are you unique? Keep it simple, and ensure that your competitors would agree with your analysis. This ensures investors will agree with it too.',
  meetUs: 'Provide a link for investors to set up a chat',
  onboardingShare:
    'Congratulations here is your Vula page! Get your sharing link by clicking here. Share it with other founders that you know to get your initial feedback - there will always be things they spot that you might not!',
};
export const SectionHelperMessages = {
  round:
    "We suggest you put the fund raise round and the amount you're raising here, like 'Series A ($1M)'",
  tagline: 'Your public tagline - make it short, clear and exciting.',
  password:
    'Have a memorable password that you can share with investors for them to view your pitch. ',
};

export const NonStickyHelperMessages = {
  saving: '⏳ Saving',
  saved: '✅ Saved!',
  failed: "❌ That didn't work - try again?",
};
