import React, { useContext } from 'react';
import ClaimPageTemplate from '../components/Claim/ClaimPageTemplate';
import UploadRow from '../components/Claim/UploadRow';
import Button from '../components/utils/Button';
import { ClaimContext } from '../contexts/ClaimContext';
import { useAuth0 } from '@auth0/auth0-react';
import { routes } from './routes';
import { VulaUserAPI } from '../api/user';
import { useNavigate } from 'react-router-dom';
import { LoggedInContext } from '../contexts/LoggedInContext';

export default function ClaimAdditional() {
  const navigate = useNavigate();
  const { loginWithRedirect, user, isLoading, getAccessTokenSilently } =
    useAuth0();
  const { company_id } = React.useContext(LoggedInContext);
  const { claimDefinition, codeLoading } = useContext(ClaimContext);

  const [missingData, setMissingData] = React.useState<
    { title: string; slug: string; location: string }[]
  >([]);

  const login_if_not_logged_in = () => {
    if (!user && !isLoading) {
      loginWithRedirect({
        screen_hint: 'signup',
        redirectUri:
          window.location.origin +
          routes.public.claimAdditional +
          window.location.search +
          '&vula_claim_status=accepted',
      });
    }
  };

  const onLoadActions = () => {
    // get claim status and code
    const urlParams = new URLSearchParams(window.location.search);
    const claim_status = urlParams.get('vula_claim_status');
    const claimCode = urlParams.get('vula_claim_code');

    // if not accepted then redirect to claim page
    if (claim_status !== 'accepted' || !claimCode) {
      const cleanReturnUrl =
        window.location.origin +
        routes.public.claim +
        '?vula_claim_code=' +
        claimCode;
      window.location.href = cleanReturnUrl;
    } else if (!company_id) {
      // this must be a new user and hence we need to send them to onboarding
      navigate(
        routes.private.onboarding +
          '?vula_claim_code=' +
          claimCode +
          '&vula_claim_status=accepted' +
          '&ref=' +
          claimCode.substring(0, 2),
      );
    } else if (claim_status === 'accepted') {
      // if accepted then update the db as the user is also logged in now
      (async () => {
        const token = await getAccessTokenSilently();
        const api = new VulaUserAPI({ token });
        // set as accepted
        await api.updateClaimStatus(claimCode, true);
        await checkMissingData();
      })().catch(e => console.log(e));
    }
  };

  const checkMissingData = async () => {
    // get claim status
    const urlParams = new URLSearchParams(window.location.search);

    const claimCode = urlParams.get('vula_claim_code');
    if (!claimCode) return;

    const token = await getAccessTokenSilently();
    const api = new VulaUserAPI({ token });

    const missingData = await api.checkMissingClaimData(claimCode);
    setMissingData(missingData.data.missingTerms);
  };

  // on load check for accepted claim status
  React.useEffect(() => {
    if (!isLoading && !codeLoading) {
      // check the user is logged in
      login_if_not_logged_in();
      onLoadActions();
    }
  }, [isLoading, codeLoading]);

  // completed uploads
  if (missingData.length === 0 && !isLoading && !codeLoading) {
    return (
      <ClaimPageTemplate title={''} completed={true}>
        <div className="text-center ">
          <div className="font-extrabold pb-4 ">
            {claimDefinition && claimDefinition.dataRequester.name + ' '}
            is now connected to your Vula profile.
          </div>

          <div className="flex justify-center w-full pt-8">
            <Button
              text="Continue"
              action={() => {
                if (claimDefinition?.redirect_url) {
                  window.open(
                    claimDefinition.redirect_url + '?vula_ref=' + company_id,
                    '_blank',
                  );
                }

                navigate(routes.private.onlogin);
              }}
            />
          </div>
        </div>
      </ClaimPageTemplate>
    );
  }

  return (
    <ClaimPageTemplate
      title={
        <div className="font-bold text-md sm:text-xl pb-2 sm:pb-6 sm:w-[85%] m-auto">
          Almost there!
        </div>
      }
    >
      <div className="pb-3 sm:pb-6 px-3  sm:px-6 border border-b-1 border-t-0 border-x-0 border-solid border-stone-200       ">
        <div>
          {claimDefinition?.dataRequester.name} are requesting data that isn't
          on your Vula profile yet.
          <br />
          <br />
          Upload them now to complete the process.
        </div>
      </div>
      {missingData.length &&
        missingData.map(data => {
          return <UploadRow missingData={data} key={data.slug} />;
        })}
      <div className="flex justify-center pt-8">
        <Button
          isDisabled={false}
          asynAction={async () => {
            await checkMissingData();
          }}
          text={'Connect to ' + claimDefinition?.dataRequester.name}
        />
      </div>
    </ClaimPageTemplate>
  );
}
