import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import Button from '../utils/Button';
import ContentSection from '../utils/ContentSection';
import PitchContentContainer from './PitchContentContainer';

export default function PitchEditCompletedButton() {
  const navigate = useNavigate();
  return (
    <PitchContentContainer>
      <ContentSection>
        <Button
          text="Save and go back"
          action={() => navigate(routes.private.home)}
        />
      </ContentSection>
    </PitchContentContainer>
  );
}
