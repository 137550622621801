import React from 'react';
import VulaLogo from '../utils/VulaLogo';
import AskForURL from './AskForURL';

interface Props {
  incrementStep: (number: 1 | -1) => void;
}

export default function TellUsAboutYou(props: Props) {
  return (
    <div className="flex flex-col sm:flex-row max-w-full h-full min-h-[100vh] px-4 sm:px-12 text-left">
      <div className={' flex flex-col    sm:w-full'}>
        <div className=" sm:pt-4 flex flex-row w-full justify-start ">
          <VulaLogo />
        </div>
        <div className="flex items-center h-full ">
          <div className=" w-full ">
            <AskForURL incrementStep={props.incrementStep} />
          </div>
        </div>
      </div>
    </div>
  );
}
