import axios from 'axios';
import { apiUrl } from '../methods/env';

export const addUserToMailingList = async (email: string, listName: string) => {
  const url = apiUrl + '/marketing/subscribeToList';

  try {
    const response = await axios.post(url, {
      email: email,
      listName: listName,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
