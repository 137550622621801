import { useAuth0 } from '@auth0/auth0-react';
import { track } from '../../interfaces/mixpanel';

interface SignInProps {
  type: 'signup' | 'login';
  redirectUri?: string;
}

export function useSingleSignIn() {
  const { loginWithRedirect } = useAuth0();

  const singleSignIn = ({ type, redirectUri }: SignInProps) => {
    if (redirectUri) {
      track(type, { redirectUri: redirectUri });
      loginWithRedirect({
        screen_hint: type,
        appState: { returnTo: redirectUri },
        redirectUri: window.location.origin + redirectUri,
      });
    } else {
      track(type);
      loginWithRedirect({
        screen_hint: type,
        redirectUri:
          window.location.origin + '/onlogin' + window.location.search,
      });
    }
  };

  return { singleSignIn };
}
