import React, { useContext, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import Regenerate from './Regenerate';
import PencilIcon from '../Icons/PencilIcon';
import StarIcon from '../Icons/StarIcon';
import EditTextButtons from './EditTextButtons';

interface Props {
  label?: string; // this is the key in the corpus
  context?: string; // this is the context to add to the prompt during regen (such as other bullet points)
  text?: string; // this is the text to display
  classStyling?: string; // this is the class styling for the text
  onFocus?: () => void; // this is the function to run when the text is focused
  saveFunction?: (text: string) => void; // this is the function to run when the text is saved, if label doesn't exist we must use this
}

export default function EditablePitchText(props: Props) {
  const { editMode, investorMode, updateCorpus } = useContext(EditPitchContext);
  const [showRegen, setShowRegen] = useState(false);

  if (!editMode || investorMode) {
    return (
      <div
        className={props.classStyling}
        dangerouslySetInnerHTML={{
          __html: props.text || '',
        }}
      ></div>
    );
  }

  const saveAnswer = (text: string) => {
    props.saveFunction
      ? props.saveFunction(text)
      : props.label
      ? updateCorpus(props.label, text)
      : console.error('No label or save function so will not save');
  };

  return (
    <div key={props.text} className="group">
      <div className="relative rounded-xl border border-solid border-1 border-stone-100 group-hover/icon:border-stone-300 ">
        {showRegen ? (
          <Regenerate
            previousText={props.text}
            label={props.label}
            saveFunction={text => saveAnswer(text)}
            closeFunction={() => setShowRegen(false)}
            context={props.context}
          />
        ) : (
          <>
            <div
              id={props.label}
              contentEditable={editMode}
              suppressContentEditableWarning={true}
              onFocus={() => (props.onFocus ? props.onFocus() : undefined)}
              onBlur={event => saveAnswer(event.target.innerHTML)}
              className={'p-2 ' + props.classStyling}
              dangerouslySetInnerHTML={{
                __html: props.text || '',
              }}
            ></div>
            <div className="absolute -top-3 -right-1">
              <div className=" w-fit flex flex-row justify-center items-center">
                {props.label && (
                  <>
                    <EditTextButtons
                      icon={<PencilIcon />}
                      tooltipText="Edit"
                      onClick={() =>
                        props.label && document.getElementById
                          ? document?.getElementById(props.label)?.focus()
                          : null
                      }
                      fadeIn={false}
                      hiddenOnMobile={true}
                    />
                    <EditTextButtons
                      icon={<StarIcon />}
                      tooltipText="Regenerate"
                      onClick={() => setShowRegen(true)}
                      fadeIn={true}
                      hiddenOnMobile={false}
                    />
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
