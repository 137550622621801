import React, { useContext, useEffect } from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import PitchSlidesOverview from '../components/Pitch/PitchHome/PitchSlidesOverview';
import TogglePublicity from '../components/Pitch/PitchHome/TogglePublicity';
import EditPasswordCard from '../components/Pitch/PitchHome/EditPasswordCard';
import ViewAsInvestorCard from '../components/Pitch/PitchHome/ViewAsInvestorCard';
import ShareToInvestorsCard from '../components/Pitch/PitchHome/ShareToInvestorsCard';
import { track } from '../interfaces/mixpanel';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { EditPitchContext } from '../contexts/EditPitchContext';
import Loading from './Loading';
import { VulaCompanyAPI } from '../api/company';
import { useAuth0 } from '@auth0/auth0-react';

export default function PitchHome() {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug, onboardingProgress } = useContext(LoggedInContext);
  const { getPitchData, privatePitchInfo, userIsAdmin } =
    useContext(EditPitchContext);

  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    track('Pitch home page loaded', {
      company_slug,
    });

    if (onboardingProgress !== 'completed') {
      (async () => {
        setIsLoading(true);
        const token = await getAccessTokenSilently();
        const api = new VulaCompanyAPI({ token });
        await api.genProblemAndSolution(company_slug);
        await api.genRestOfPitch(company_slug);
        setIsLoading(false);
      })();
    } else if (!privatePitchInfo) {
      (async () => {
        await getPitchData();
      })();
    }
  }, []);

  if (!company_slug || !userIsAdmin) {
    return <Loading size="full-page" />;
  }

  if (isLoading) {
    return <Loading size="full-page" />;
  }

  return (
    <div className="px-2">
      <HomeNavBar />
      <div className="text-left w-full max-w-[1200px] m-auto py-12">
        <h1 className="font-extrabold text-4xl">Your pitch</h1>
        <h3 className="text-lg pt-4">
          View and edit your pitch by clicking on it below
        </h3>
        <div className="sm:pt-12 flex flex-col sm:flex-row items-center">
          <div className="w-full sm:w-3/4  ">
            <PitchSlidesOverview />
          </div>
          <div className="pt-12 sm:pt-0 flex flex-col">
            <EditPasswordCard />
            <TogglePublicity />
            <ViewAsInvestorCard />
            <ShareToInvestorsCard />
          </div>
        </div>
      </div>
    </div>
  );
}
