import React from 'react';
import OpenCaretIcon from '../Icons/OpenCaretIcon';
import ClosedCaretIcon from '../Icons/ClosedCaretIcon';
import UploadZone from '../utils/UploadZone';
import CheckIcon from '../Icons/CheckIcon';
import EditableMemoText from '../Memo/EditableMemoText';
import { VulaCompanyAPI } from '../../api/company';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { VulaCorpusAPI } from '../../api/corpus';

interface Props {
  missingData: MissingData;
}

interface MissingData {
  title: string;
  slug: string;
  location: string;
}

export default function UploadRow(props: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = React.useContext(LoggedInContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const [uploadedUrl, setUploadedUrl] = React.useState<string>('');
  const [edittingText, setEdittingText] =
    React.useState<string>('Insert answer here');
  const [isAnswered, setIsAnswered] = React.useState(false);

  React.useEffect(() => {
    if (uploadedUrl.length > 0 || edittingText !== 'Insert answer here') {
      setIsAnswered(true);
    }
  }, [uploadedUrl, edittingText]);

  return (
    <div>
      <button
        onClick={() => (props.missingData.title ? setIsOpen(!isOpen) : null)}
        className="px-4 sm:px-8 flex flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 items-center hover:bg-stone-100 w-full "
      >
        <div className="relative w-full items-center">
          <div className="items-center w-full flex justify-center items-center px-4">
            {isAnswered && (
              <div className="pr-4">
                <CheckIcon />
              </div>
            )}
            <div
              className={
                'text-sm sm:text-md px-4' + (isOpen ? ' font-extrabold' : '')
              }
            >
              {props.missingData.title}
            </div>
          </div>
          <div className="absolute right-0 top-0 py-1 flex justify-end ">
            {isOpen ? <OpenCaretIcon /> : <ClosedCaretIcon />}
          </div>
        </div>
      </button>
      {isOpen &&
        (props.missingData.location === 'attachments' ? (
          <div className="relative text-sm sm:text-md bg-stone-100 px-4 sm:px-8 flex justify-center flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 ">
            {uploadedUrl && uploadedUrl.includes('.png') ? (
              <img src={uploadedUrl} alt={props.missingData.slug} />
            ) : (
              <UploadZone
                label={props.missingData.slug}
                afterUpload={url => {
                  setUploadedUrl(url);
                }}
              />
            )}
          </div>
        ) : props.missingData.location === 'corpus' ||
          props.missingData.location === 'company_details' ? (
          <div className="relative text-sm sm:text-md bg-white px-4 sm:px-8 flex justify-center flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 ">
            <EditableMemoText
              text={edittingText}
              saveAnswer={async text => {
                setEdittingText(text);
                const token = await getAccessTokenSilently();
                if (props.missingData.location === 'company_details') {
                  const api = new VulaCompanyAPI({ token });
                  await api.updateCompanyStructuredData(
                    company_slug,
                    props.missingData.slug,
                    text,
                  );
                } else if (props.missingData.location === 'corpus') {
                  const api = new VulaCorpusAPI({ token });
                  await api.updateCorpus({
                    slug: company_slug,
                    label: props.missingData.slug,
                    answer: text,
                  });
                }
              }}
              label={props.missingData.slug}
            />
          </div>
        ) : (
          <div className="relative text-sm sm:text-md bg-stone-100 px-4 sm:px-8 flex justify-center flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 ">
            Something went wrong
          </div>
        ))}
    </div>
  );
}
