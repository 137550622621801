import React, { useContext, useEffect, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { enumsCorpusQuestions, pitchEnums } from '../../enums/pitch';

import EditablePitchText from '../utils/EditablePitchText';

export default function WhyUs() {
  const {
    editMode,
    investorMode,
    updateCorpus,
    addToCorpus,
    privatePitchInfo,
  } = useContext(EditPitchContext);

  const defaultWhyYouText =
    'Explain why you are the best team. Show your team are experts, are impressive and are exactly the best people for this business';
  const [whyYouText, setwhyYouText] = useState(
    privatePitchInfo?.whyUs?.length
      ? privatePitchInfo?.whyUs
      : defaultWhyYouText,
  );
  const [initialStoreInCorpus, setInitialStoreInCorpus] = useState(
    !privatePitchInfo?.whyUs,
  );

  // watch for changes in the corpus
  useEffect(() => {
    if (privatePitchInfo?.whyUs?.length) {
      setwhyYouText(privatePitchInfo?.whyUs);
    }
  }, [privatePitchInfo?.whyUs]);

  //  whenever the text content changes update the corpus
  useEffect(() => {
    // if whyyou text is the default or is not empty
    if (whyYouText !== defaultWhyYouText && whyYouText.length) {
      // if first time ever - add to corpus
      if (initialStoreInCorpus) {
        addToCorpus(
          pitchEnums.why_us,
          whyYouText,
          enumsCorpusQuestions[pitchEnums.why_us],
        );
        setInitialStoreInCorpus(false);
      } else {
        updateCorpus(pitchEnums.why_us, whyYouText);
      }
    }
  }, [whyYouText]);

  if (!privatePitchInfo?.whyUs?.length && (!editMode || investorMode)) {
    return null;
  }

  return (
    <div className="p-4 text-sm">
      <EditablePitchText
        label="why_us"
        text={whyYouText}
        classStyling=" shadow-xl text-center p-4 sm:py-12 bg-gray-200 rounded-xl whitespace-pre-wrap	"
      />
    </div>
  );
}
