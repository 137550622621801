import React from 'react';

interface Props {
  steps: {
    title: string;
    time: number;
  }[];
  parentDelay: number; // Add this prop
}

export default function AnimateBulletPoints(props: Props) {
  return (
    <div>
      {/* animate in  */}
      <ul className="max-w-md space-y-2 text-gray-500 list-inside dark:text-gray-400">
        {props.steps.map((step, index) => (
          <li
            key={index}
            className="opacity-0 animate-fade-in-up flex items-center"
            style={{
              animationDelay: props.parentDelay + index + 's',
            }}
          >
            <svg
              className="w-4 h-4 me-2 text-green-500 dark:text-green-400 flex-shrink-0 mr-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
            {step.title}
          </li>
        ))}
      </ul>
    </div>
  );
}
