import React, { useContext, useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import axios, { AxiosProgressEvent } from 'axios';
import { ApplicationContextPublic } from '../../contexts/ApplicationContextPublic';
import Spinner from './Spinner';

interface Props {
  label: string;
  afterUpload: (uploaded_filenames: string[]) => void;
  fileTypes?: string[];
}

// A component that allows users to drag and drop or select multiple files to upload and it will show a progress bar for each file
// and once uploaded it will show the file to the user as an icon and the file name
export default function UploadMultiple(props: Props) {
  const { getPresignedUrl } = useContext(ApplicationContextPublic);

  // uploading states
  const [error, setError] = useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [showUploadingSpinner, setShowUploadingSpinner] = useState(false);

  const getFileMimeType = (title: string) => {
    if (title.includes('.pdf')) {
      return 'application/pdf';
    } else if (title.includes('.mp4')) {
      return 'video/mp4';
    } else if (
      title.includes('.png') ||
      title.includes('.jpeg') ||
      title.includes('.jpg')
    ) {
      return 'image/png';
    } else if (title.includes('.gif')) {
      return 'image/gif';
    } else if (title.includes('.webp')) {
      return 'image/webp';
    } else {
      return 'application/octet-stream';
    }
  };

  const uploadFile = async (file: File, type: string, index: number) => {
    try {
      // get presigned url
      const presignedResUrl = await getPresignedUrl({
        label: props.label + '_' + index,
        type: type,
        summary: file.name,
      });

      const mimeType = getFileMimeType(file.name);
      const options = {
        onUploadProgress: (p: AxiosProgressEvent) => {
          if (p.total) {
            const percentageUploaded = Math.round((p.loaded * 100) / p.total);
            setPercentageUploaded(percentageUploaded);
          }
        },
        headers: {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: presignedResUrl,
          'Content-Type': mimeType,
        },
      };

      // Record the file in the db
      await axios.put(presignedResUrl, file, options).catch(error => {
        setError(true);
        console.log(error);
      });
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  const onDrop = async (files: File[]) => {
    if (files.length === 0) return;
    setClickedUpload(true);
    // get presigned url
    try {
      // sequentially upload files but await them all
      for (let i = 0; i < files.length; i++) {
        await uploadFile(files[i], files[i].type, i);
        if (i !== files.length - 1) {
          // wait 3 seconds between uploads
          setShowUploadingSpinner(true);
          await new Promise(resolve => setTimeout(resolve, 3000));
          setShowUploadingSpinner(false);
        }
      }

      const newFileNames = files.map(file => file.name) as string[];

      // add file names to state
      setUploadedFileNames([...uploadedFileNames, ...newFileNames]);

      // call after upload
      props.afterUpload([...uploadedFileNames, ...newFileNames]);
      // reset error
      setError(false);
      setClickedUpload(false);
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    // get the error message
    const error = rejectedFiles[0].errors[0].message;
    const filename = rejectedFiles[0].file.name;

    setRejectReason(
      error + ' for ' + filename + '. Please try again with a different file.',
    );
  };

  const defineFileTypesAllowed = () => {
    // default should be:
    if (!props.fileTypes) {
      return {
        'image/*': ['.jpeg', '.png'],
        'application/pdf': ['.pdf'],
        'text/*': ['.txt'],
      };
    }

    let dropZoneInput: { [key: string]: string[] } = {};
    // if fileTypes are passed in, then use that to define the file types object
    if (props.fileTypes) {
      props.fileTypes.forEach(fileType => {
        if (fileType === 'image') {
          dropZoneInput['image/*'] = ['.jpeg', '.png'];
        } else if (fileType === 'pdf') {
          dropZoneInput['application/pdf'] = ['.pdf'];
        } else if (fileType === 'text') {
          dropZoneInput['text/*'] = ['.txt'];
        } else if (fileType === 'video') {
          dropZoneInput['video/mp4'] = ['.mp4'];
        }
      });
    }

    return dropZoneInput;
  };

  return (
    <div className="min-w-[200px] sm:min-w-[330px] sm:p-2 p-1">
      <Dropzone
        onDrop={files => onDrop(files)}
        onFileDialogOpen={() => setClickedUpload(true)}
        onFileDialogCancel={() => setClickedUpload(false)}
        accept={defineFileTypesAllowed()}
        maxSize={15 * 1024 * 1024}
        minSize={0}
        onDropRejected={reject => {
          onDropRejected(reject);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className=" cursor-pointer w-full h-full min-h-[100px] sm:min-h-[150px] border border-1 border-dashed border-stone-300 bg-stone-50 rounded-xl text-stone-400 flex flex-col justify-center items-center"
          >
            <div className="w-full flex items-center justify-center flex-wrap">
              {
                // loop through uploaded files and display them
                uploadedFileNames.map((fileName, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center p-2">
                      <img
                        src="https://www.svgrepo.com/show/532762/file-check-alt.svg"
                        alt="file"
                        className="w-10 h-10 mr-2"
                      />
                      <p className="truncate">{fileName}</p>
                    </div>
                  );
                })
              }
            </div>
            <div>
              <input className="" {...getInputProps()} />
              <div className="sm:p-4 p-8 w-full h-full flex flex-col justify-center items-center">
                <p>
                  {showUploadingSpinner ? (
                    <Spinner />
                  ) : rejectReason ? (
                    rejectReason
                  ) : error ? (
                    'Something went wrong!'
                  ) : percentageUploaded === 100 ? (
                    'Upload completed'
                  ) : percentageUploaded > 0 ? (
                    percentageUploaded.toString() + '% uploaded'
                  ) : clickedUpload ? (
                    'Opening your files'
                  ) : (
                    'Drop files here, or click to select files (max 1Mb)'
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
