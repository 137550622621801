import React, { useState, useEffect } from 'react';
import Button from '../components/utils/Button';

import { track } from '../interfaces/mixpanel';
import LandingNavbar from '../components/Landing/LandingNavbar';
import HomeNavBar from '../components/Home/HomeNavBar';
import { useAuth0 } from '@auth0/auth0-react';

export default function Page404() {
  const { isAuthenticated } = useAuth0();
  const [showReportFeedback, setFeedback] = useState(false);
  const [emailErrorChased, setEmailErrorChased] = useState(false);
  const emailInput = React.createRef<HTMLInputElement>();

  // on load, track it
  useEffect(() => {
    track('404 loaded', { url: window.location.href });
  }, []);

  const checkEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      askForFollowup();
    }
  };

  const reportIssue = () => {
    setFeedback(true);
  };

  const askForFollowup = () => {
    if (emailInput && emailInput.current && emailInput.current.value) {
      const email = emailInput.current.value;
      console.log(email);
      track('404 - request for follow up', { email: email });
      setEmailErrorChased(true);
    }
  };

  return (
    <div>
      {isAuthenticated ? <HomeNavBar /> : <LandingNavbar />}

      <div className=" flex flex-col justify-center ">
        <div className="text-8xl pt-40 font-extrabold h-full">404</div>
        <div className="text-3xl font-extrabold h-full">
          That url isn't right
        </div>
        <div className="text-2xl font-extrabold h-full pt-4">You typed </div>
        <div className="text-2xl font-thin italic pt-2">
          vula.vc/{window.location.pathname.replace('/', '')}
        </div>

        {showReportFeedback ? (
          <div className="flex flex-col justify-center p-8 sm:w-2/3 items-center text-center m-auto pt-12">
            <div className="sm:w-1/2">
              <b>Thanks for reporting</b>
              <br />
            </div>
            {!emailErrorChased ? (
              <>
                <div className="sm:w-1/2">
                  We have recieved your notification and are looking into it, if
                  you would like to be updated add your email below:
                </div>
                <input
                  ref={emailInput}
                  className="shadow rounded sm:pt-8 p-4 px-6 sm:p-4 sm:px-8  text-gray-700 font-extrabold text-sm sm:text-lg sm:w-1/2"
                  id="email"
                  type="text"
                  placeholder="example@email.com"
                  maxLength={40}
                  onKeyDown={e => checkEnterKeyPress(e)}
                />

                <Button
                  text="Request feedback"
                  action={() => askForFollowup()}
                />
              </>
            ) : null}
          </div>
        ) : (
          <div className="flex justify-center flex-col  items-center pt-12">
            <a className="py-4" href="/">
              <Button text="Go to Vula" />
            </a>
            <Button
              text="Report issue"
              buttonType="secondary"
              action={() => reportIssue()}
            />
          </div>
        )}
      </div>
    </div>
  );
}
