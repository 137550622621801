import React, { useContext } from 'react';
import { GrantContext } from '../../../contexts/GrantContext';
import OpenCaretIcon from '../../Icons/OpenCaretIcon';
import ClosedCaretIcon from '../../Icons/ClosedCaretIcon';
import PreviouslyAskedQuestion from './PreviouslyAskedQuestion';

export default function PreviouslyAskedQuestions() {
  const { getPreviousQAs, previousQAs } = useContext(GrantContext);

  const [isShown, setIsShown] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      await getPreviousQAs();
    })();
  }, []);

  React.useEffect(() => {
    if (previousQAs.length > 0) {
      setIsShown(true);
    }
  }, [previousQAs]);

  return (
    <div className=" mx-4 sm:mx-12 mb-12 border border-solid border-stone-200  rounded-xl">
      <div className=" ">
        <button
          className="hover:bg-stone-100 py-2 sm:py-8 px-8 w-full  text-lg font-extrabold    flex items-center    "
          onClick={() => setIsShown(!isShown)}
        >
          {isShown ? <OpenCaretIcon /> : <ClosedCaretIcon />}
          <div className="pl-2">Previously asked questions </div>
        </button>
        {isShown &&
          previousQAs.map(qa => {
            return (
              <PreviouslyAskedQuestion
                key={qa.id}
                question_id={qa.id}
                question={qa.question}
                answer={qa.answer}
              />
            );
          })}
      </div>
    </div>
  );
}
