import React from 'react';

export default function PencilIcon() {
  return (
    <div className="">
      <svg
        width="16"
        height="16"
        viewBox="0 0 982 982"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M788.868 8.98477C783.113 3.23183 775.31 0 767.173 0C759.036 0 751.232 3.23183 745.478 8.98477L644.397 110.068L871.905 337.582L972.985 236.56C975.842 233.71 978.11 230.324 979.657 226.595C981.204 222.867 982 218.87 982 214.834C982 210.798 981.204 206.801 979.657 203.073C978.11 199.344 975.842 195.958 972.985 193.107L788.868 8.98477ZM828.514 380.974L601.007 153.46L202.087 552.392H214.791C222.93 552.392 230.735 555.625 236.49 561.38C242.244 567.135 245.477 574.941 245.477 583.079V613.766H276.164C284.302 613.766 292.107 617 297.862 622.754C303.617 628.509 306.85 636.315 306.85 644.454V675.141H337.536C345.674 675.141 353.479 678.374 359.234 684.129C364.989 689.884 368.222 697.689 368.222 705.828V736.515H398.908C407.047 736.515 414.852 739.748 420.607 745.503C426.361 751.258 429.594 759.063 429.594 767.202V779.906L828.514 380.974ZM370.186 839.317C368.895 835.883 368.229 832.245 368.222 828.576V797.889H337.536C329.397 797.889 321.592 794.656 315.837 788.901C310.083 783.146 306.85 775.341 306.85 767.202V736.515H276.164C268.025 736.515 260.22 733.282 254.465 727.527C248.71 721.772 245.477 713.967 245.477 705.828V675.141H214.791C206.653 675.141 198.848 671.908 193.093 666.153C187.338 660.398 184.105 652.592 184.105 644.454V613.766H153.419C149.75 613.76 146.112 613.095 142.679 611.802L131.693 622.727C128.769 625.672 126.472 629.18 124.942 633.038L2.1975 939.909C-0.0346504 945.486 -0.581079 951.595 0.625958 957.479C1.833 963.364 4.74042 968.764 8.98778 973.012C13.2351 977.259 18.6357 980.167 24.5198 981.374C30.404 982.581 36.513 982.035 42.0895 979.802L348.951 857.054C352.809 855.524 356.317 853.227 359.262 850.303L370.186 839.317Z" />
      </svg>
    </div>
  );
}
