import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import ApplyForAutoGrant from './ActionSections/ApplyForAutoGrant';
import DoYouWantToApply from './ActionSections/DoYouWantToApply';
import GetVulasHelp from './ActionSections/GetVulasHelp';

export default function NewGrantSummary() {
  const { selectedGrant } = useContext(GrantContext);

  if (!selectedGrant?.grant) {
    return null;
  }

  // if an auto grant
  if (selectedGrant?.grant?.application_sections?.length) {
    return <ApplyForAutoGrant />;
  }

  return (
    <div>
      <DoYouWantToApply />

      <GetVulasHelp />
    </div>
  );
}
