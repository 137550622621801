import React from 'react';
import Button from '../utils/Button';

interface Props {
  declineAction: () => void;
  declineText: string;
  acceptAction: () => void;
  acceptText: string;
  dataProcessingAgreement: string;
}

export default function AcceptTCs(props: Props) {
  const [acceptTerms, setAcceptTerms] = React.useState(false);

  return (
    <div className="flex flex-col justify-center w-full">
      <div className="flex flex-row justify-center items-center pt-4 sm:pt-8">
        <button
          className="flex flex-row hover:bg-stone-100 p-2 rounded"
          onClick={() => {
            setAcceptTerms(!acceptTerms);
          }}
        >
          <input
            type="checkbox"
            className="mr-2 sm:mr-4 w-6 h-6"
            checked={acceptTerms}
            onChange={() => {
              setAcceptTerms(!acceptTerms);
            }}
          />

          <div className="text-sm sm:text-md">
            I accept the{' '}
            <a
              className="underline hover:no-underline"
              href={props.dataProcessingAgreement}
              target="_blank"
              rel="noreferrer"
            >
              Data Processing Agreement
            </a>
          </div>
        </button>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center w-full pt-4 sm:px-12">
        <div className="py-2 sm:py-0 sm:pr-4 order-last sm:order-first">
          <Button
            buttonType="secondary"
            text={props.declineText}
            action={props.declineAction}
          />
        </div>
        <Button
          isDisabled={!acceptTerms}
          text={props.acceptText}
          action={props.acceptAction}
        />
      </div>
    </div>
  );
}
