import React from 'react';
import InProgressCard from '../components/Home/InProgressCard';
import { useNavigate } from 'react-router-dom';
import { routes } from './routes';

export default function InvestorsHome() {
  const navigate = useNavigate();
  const [showVula, setShowVula] = React.useState(false);

  if (showVula) {
    navigate(routes.private.invest + 'vula');
  }
  return (
    <div className="text-left">
      <div className="p-4 rounded-xl bg-stone-100 w-full shadow mt-12">
        <div className="text-2xl font-bold pb-12 ">Opportunities</div>
        <InProgressCard
          headline="Vula"
          subtitle="Review dataroom"
          background="https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp"
          onClick={() => setShowVula(true)}
        />
      </div>
      <div className="p-4 rounded-xl bg-stone-100 w-full shadow mt-12">
        <div className="text-2xl pb-12 font-bold">Portfolio</div>
        <div className="text-xl ">None</div>
      </div>
      <div className="p-4 rounded-xl bg-stone-100 w-full shadow mt-12">
        <div className="text-2xl font-bold pb-12">Rejected</div>
        <div className="text-xl ">None</div>
      </div>
    </div>
  );
}
