import React, { useContext } from 'react';
import {
  ApplicationContext,
  LendingProductApplicationQuestion,
} from '../../../contexts/ApplicationContext';
import TableItemsQuestion from './TableItemsQuestion';
import LendingApplicationQuestion from '../LendingApplicationQuestion';
import { EditPitchContext } from '../../../contexts/EditPitchContext';
import UploadQuestions from './UploadQuestions';
interface Props {
  question: LendingProductApplicationQuestion;
  index: number;
}
export default function RepeatingQuestions(props: Props) {
  const { updateAnswerInApplicationJson } = useContext(ApplicationContext);
  const { updateCorpus, updateCompanyDetails } = useContext(EditPitchContext);

  const { question, index } = props;

  if (question.type === 'table') {
    return (
      <TableItemsQuestion
        question={{ ...question }}
        saveAnswer={async text => {
          updateAnswerInApplicationJson(question.id, text);
          await updateCorpus(question.corpus_label, text);
        }}
      /> // overwrite the label with the unique label
    );
  } else if (question.type === 'long_question') {
    return (
      <LendingApplicationQuestion
        question={question}
        saveAnswer={async text => {
          updateAnswerInApplicationJson(question.id, text);
          await updateCorpus(question.corpus_label, text);
        }}
      />
    );
  } else if (question.type === 'upload') {
    return (
      <UploadQuestions
        question={{ ...question }}
        removeCardBackground
        saveAnswer={async text => {
          updateAnswerInApplicationJson(question.id, text);
        }}
      />
    );
  } else {
    return <div>error</div>;
  }
}
