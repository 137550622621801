import React from 'react';
import { addUserToMailingList } from '../../api/marketing';
import { track } from '../../interfaces/mixpanel';
import { sendNotification } from '../../api/monitoring';

interface Props {
  completedMessage: string;
  placeholder: string;
  buttonText: string;
  listName?: string;
  notify?: notifyProps;
}

interface notifyProps {
  message: string; // MESSAGE WILL ALWAYS BEEN APENEDED WITH "email: " + email + ", "
  subject: string;
}

export default function MailingListComponent(props: Props) {
  const [signedUp, setSignedUp] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <div className="max-w-[500px] m-auto rounded-xl bg-stone-800 hover:bg-stone-700 flex flex-row p-4">
      {signedUp ? (
        <div className="text-white text-stone-300 text-center w-full text-m py-4 ">
          {props.completedMessage}
        </div>
      ) : (
        <>
          <input
            className="w-full text-white p-2 my-2 rounded-xl border border-solid border-1 border-stone-500 active:border-stone-100 hover:border-stone-100 focus:border-stone-100"
            placeholder={props.placeholder}
            ref={inputRef}
          />
          <button
            className=" bg-stone-500 font-extrabold text-m text-white px-4 rounded-full my-2 mx-2"
            // make text stay on one line
            style={{ whiteSpace: 'nowrap' }}
            onClick={async () => {
              // add to email list
              if (props.listName) {
                await addUserToMailingList(
                  inputRef.current?.value || '',
                  props.listName,
                );
                track('Signup ' + props.listName, {
                  email: inputRef.current?.value || '',
                });
              }

              // notify us
              if (props.notify) {
                // notify us
                await sendNotification(
                  props.notify.message +
                    '            email: ' +
                    inputRef.current?.value || '',
                  props.notify.subject,
                );
              }

              setSignedUp(true);
            }}
          >
            {props.buttonText}
          </button>
        </>
      )}
    </div>
  );
}
