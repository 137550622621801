import React, { useState } from 'react';
import VulaLogo from '../utils/VulaLogo';
import UploadMultiple from '../utils/UploadMultiple';

import Button from '../utils/Button';
import AnimateCardsEntry from '../utils/AnimateCardsEntry';

interface Props {
  incrementStep: (number: 1 | -1) => void;
}

export default function UploadOnboardingDocuments(props: Props) {
  const [showConsuming, setShowConsuming] = useState(false);
  const [uploaded_filenames, setUploaded_filenames] = useState<string[]>([]);

  // if no docs uploaded, go to next step
  React.useEffect(() => {
    if (uploaded_filenames.length === 0 && showConsuming === true) {
      props.incrementStep(1);
    }
  }, [showConsuming]);

  return (
    <div className="flex flex-col sm:flex-row max-w-full h-full  px-4 sm:px-12 text-left">
      <div
        className={' flex flex-col items-center justify-center    sm:w-full'}
      >
        <div className=" sm:pt-4 flex flex-row w-full justify-center my-10">
          <VulaLogo />
        </div>

        <div className="flex flex-col justify-center items-center h-full">
          {showConsuming ? (
            <div className=" flex flex-col justify-center text-center w-full">
              <div className=" flex justify-center items-center">
                <img
                  src="/assets/raw.gif"
                  alt="Vula Logo"
                  className="max-w-[40%] rounded-full sm:w-full shadow"
                />
              </div>
              <h1 className="text-3xl font-bold text-stone-900 py-4 w-full text-center">
                Vula is reading your documents
              </h1>
              <AnimateCardsEntry cardTitles={uploaded_filenames} />
              {/* After all titles are rendered, then show a next button */}
              <div
                className="opacity-0 w-full flex flex-col justify-center items-center animate-fade-in-up"
                style={{
                  animationDelay: 5 * uploaded_filenames.length + 's',
                  transitionDelay: 5 * uploaded_filenames.length + 's',
                  zIndex: uploaded_filenames.length,
                }}
              >
                <Button text="Next" action={() => props.incrementStep(1)} />
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-xl shadow p-8 ">
              <h1 className="text-3xl font-bold text-stone-900 py-4">
                Upload some documents
              </h1>
              <div className="sm:w-2/3 py-4">
                Vula can help you get started by uploading some documents for it
                to read, store and reference when applying for funding later.
                <br />
                <br />
                Upload pitch decks, business plans, previous grant applications
                or any other documents that you think will help Vula understand
                your business better.
              </div>
              <UploadMultiple
                label={'onboarding_documents'}
                afterUpload={uploaded_filenames => {
                  setUploaded_filenames(uploaded_filenames);
                }}
              />
              <div className="py-4">
                <p className="text-sm text-stone-600">
                  Please upload multiple documents
                </p>
              </div>
              <Button text="Next" action={() => setShowConsuming(true)} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
