import React, { useContext } from 'react';

import { MemoContext } from '../../../contexts/MemoContext';
import { EditPitchContext } from '../../../contexts/EditPitchContext';

interface Props {
  action?: (() => void) | (() => Promise<void>);
  children: React.ReactNode;
  context: 'pitch' | 'memo';
  allowWhenNotPublic?: boolean;
  isToggle?: boolean;
}

export default function ButtonCard(props: Props) {
  const { pitchIsPublic } = useContext(EditPitchContext);
  const { memoIsPublic } = useContext(MemoContext);

  const [isPublic, setIsPublic] = React.useState(false);

  React.useEffect(() => {
    if (props.context === 'pitch') {
      setIsPublic(pitchIsPublic);
    } else {
      setIsPublic(memoIsPublic);
    }
  }, [pitchIsPublic, memoIsPublic, props.context]);

  return (
    <div
      className={
        (isPublic ? ' hover:scale-[1.01] ' : '') + ' px-2 sm:px-8 py-2'
      }
    >
      <button
        disabled={
          props.allowWhenNotPublic
            ? false
            : props.isToggle
            ? false
            : isPublic
            ? false
            : true
        }
        onClick={props.action ? props.action : undefined}
        className={
          ' border border-solid border-stone-200 shadow p-2 rounded-lg w-full h-[100px] flex flex-row justify-between items-center text-left' +
          (isPublic || props.allowWhenNotPublic
            ? '  bg-white '
            : ' bg-stone-200') +
          (isPublic && props.action
            ? ' cursor-pointer hover:bg-stone-100'
            : ' cursor-default')
        }
      >
        {props.children}
      </button>
    </div>
  );
}
