import React from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import SubmitApplicationPublic from './SubmitApplicationPublic';
import AlreadyHaveAVulaAccount from './AlreadyHaveAVulaAccount';
import VulaLogo from '../../utils/VulaLogo';
import FlexibleSectionOfQuestions from '../FlexibleSectionOfQuestions';
import VButton from '../../utils/Vbutton';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../methods/UIHelpers';

export default function PublicApplicationForm() {
  const {
    applicationStepName,
    partner,
    formDefinition,
    setApplicationStepName,
    urlToScrape,
  } = React.useContext(ApplicationContextPublic);
  const navigate = useNavigate();

  if (!formDefinition || formDefinition.error) {
    return (
      <div className="w-full bg-white">
        <div className="rotate-[175deg] ">
          <VulaLogo />
        </div>
        <p className="text-2xl font-bold py-4">Something wwwwent wrong!</p>
        {formDefinition?.error && (
          <p className="text-base font-semibold">{formDefinition.error}</p>
        )}
      </div>
    );
  }

  const renderSwitchOnStepName = () => {
    switch (applicationStepName) {
      case 'error-company-exists':
        return (
          <div className="min-h-[40vh] flex justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-4 my-auto px-2 sm:px-4">
              <VulaLogo />
              <p className="text-center">{urlToScrape}</p>
              <p className="text-xl font-semibold">
                Looks like this company has already got a Vula account.
              </p>
              <p className="text-base font-semibold">
                Please log in your Vula account to apply for this programme.
              </p>
              <VButton
                onClick={() =>
                  navigate(
                    `/applications/${partner?.partner_name}/${partner?.product_code}`,
                  )
                }
                type="button"
                text="Login to Vula"
              />
              <p
                onClick={() => setApplicationStepName('')}
                className="text-center underline text-sm text-strokeLine/90 cursor-pointer"
              >
                Back
              </p>
            </div>
          </div>
        );
      case 'partner-is-reviewing':
        return (
          <div className="min-h-[40vh] flex justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-4 my-auto px-2 sm:px-4">
              {typeof formDefinition !== 'undefined' &&
                'partner_logo' in formDefinition && (
                  <img
                    src={formDefinition?.partner_logo as string}
                    alt="partner logo"
                    className="h-16 "
                  />
                )}

              <p className="text-xl font-semibold">
                {capitalizeFirstLetter(partner?.partner_name || '')} is
                reviewing your application
              </p>
              <p className="text-base font-semibold">
                You will be notified once your application has been reviewed
              </p>
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className="px-2 sm:px-4">
              <FlexibleSectionOfQuestions />
              <SubmitApplicationPublic />
            </div>

            {applicationStepName === '' && (
              <>
                <div className="flex items-center w-full mt-4">
                  <div className="w-full mx-4 border border-1 border-stone-100 border-solid rounded-xl " />
                </div>
                <AlreadyHaveAVulaAccount />
              </>
            )}
          </>
        );
    }
  };

  return (
    <div className="py-2 p-1 shadow rounded-xl overflow-hidden bg-white w-full text-start h-auto transition-all duration-300">
      {renderSwitchOnStepName()}
    </div>
  );
}
