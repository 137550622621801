import React, { useContext } from 'react';
import ButtonCard from './ButtonCard';
import { track } from '../../../interfaces/mixpanel';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaPitchAPI } from '../../../api/pitch';
import SimpleToggle from '../../utils/SimpleToggle';
import { EditPitchContext } from '../../../contexts/EditPitchContext';

export default function TogglePublicity() {
  const { getAccessTokenSilently } = useAuth0();

  const { company_slug, getUserData } = useContext(LoggedInContext);
  const { pitchIsPublic, getPitchPasswords, pitchPasswords } =
    useContext(EditPitchContext);

  const setPitchPublicity = async (isPublic: boolean) => {
    track('Public Pitch toggle clicked');

    if (!pitchPasswords) {
      alert('You need to set a password to make your pitch public');
      return;
    }

    const token = await getAccessTokenSilently();
    const api = new VulaPitchAPI({ token });
    try {
      const res = await api.setPitchPublic(company_slug, isPublic);
      await getUserData();
      await getPitchPasswords();
      return res.data;
    } catch (error) {
      return;
    }
  };
  return (
    <ButtonCard
      context="pitch"
      isToggle
      action={async () => await setPitchPublicity(!pitchIsPublic)}
    >
      <div className="px-4">
        <div className="text-sm font-extrabold">
          Your pitch is {pitchIsPublic ? '' : 'not'} live
        </div>
        <div className="text-xs pt-2">
          {!pitchPasswords
            ? 'Create a password to share your pitch with investors.'
            : pitchIsPublic
            ? 'Investors can see your pitch if they have the link and password'
            : 'No-one can see your pitch. Turn it on to share it.'}
        </div>
      </div>
      <div className="scale-[0.66] ">
        <SimpleToggle
          leftText={''}
          rightText={''}
          stateChange={async () => {
            await setPitchPublicity(!pitchIsPublic);
          }}
          initialState={pitchIsPublic}
          leftIsOff={true}
        />
      </div>
    </ButtonCard>
  );
}
