import React, { useState, useEffect } from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaLendingAPI } from '../api/lending';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';
import { EditPitchContext } from '../contexts/EditPitchContext';
import Button from '../components/utils/Button';
import { VulaCorpusAPI } from '../api/corpus';
import { sendNotification } from '../api/monitoring';
import Loading from './Loading';
import ApproximateFundingRequirement from '../components/Applications/ApproximateFundingRequirement';
import EstimatedAnnualRevenue from '../components/Applications/EstimatedAnnualRevenue';
import ApplicationStatusCards from '../components/Applications/ApplicationStatusCards';
import ApplicationQuestionCriteriaQuestion from '../components/Applications/ApplicationQuestionCriteriaQuestion';
import { currencies, Currency } from '../data/currencies';

export default function Applications() {
  const { getAccessTokenSilently } = useAuth0();
  const { updateCorpus } = React.useContext(EditPitchContext);
  const { company_slug, isLoading } = React.useContext(LoggedInContext);

  const [applications, setApplications] = useState<{
    [key: string]: {
      // status
      owner: { name: string; logo: string; url: string };
      name: string;
      id: string;
      slug: true;
      // product_description: true;
      image: string;
      status: string;
    }[];
  }>({});
  const [estimatedAnnualRevenue, setEstimatedAnnualRevenue] = useState('');
  const [desiredLoanAmount, setDesiredLoanAmount] = useState('');
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [otherFundingInfo, setOtherFundingInfo] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    currencies[0],
  ); // Default to USD
  const [loadingData, setLoadingData] = useState(true);
  const [questions_to_answer, setQuestionsToAnswer] = useState<
    | {
        id: string;
        label: string;
        question: string;
      }[]
    | null
  >(null);

  // Fetch lending products and company corpus data
  useEffect(() => {
    if (isLoading) return;
    (async () => {
      const token = await getAccessTokenSilently();
      await fetchApplications();

      const corpusAPI = new VulaCorpusAPI({ token });
      const corpus = await corpusAPI.getFromCorpus({
        slug: company_slug,
        labels: [
          'estimated_annual_revenue',
          'desired_funding_amount',
          'other_funding_info',
        ],
      });
      // response will be an array of objects (label, answer, question)
      const corpusData = corpus.data;
      const estimated_annual_revenue = corpusData.find(
        (item: { label: string }) => item.label === 'estimated_annual_revenue',
      );
      const desired_funding_amount = corpusData.find(
        (item: { label: string }) => item.label === 'desired_funding_amount',
      );
      setEstimatedAnnualRevenue(estimated_annual_revenue?.content || '');
      setDesiredLoanAmount(desired_funding_amount?.content || '');
      if (estimated_annual_revenue?.content) {
        // set the currency to the one saved in the corpus (always the first character)
        const currency = estimated_annual_revenue?.content[0];
        const newCurrency = currencies.find(c => c.symbol === currency);
        if (newCurrency) setSelectedCurrency(newCurrency);
      }
      setOtherFundingInfo(
        corpusData.find(
          (item: { label: string }) => item.label === 'other_funding_info',
        )?.content || '',
      );
      if (
        estimated_annual_revenue?.content &&
        desired_funding_amount?.content
      ) {
        setDetailsSubmitted(true);
      }

      setLoadingData(false);
    })();
  }, [isLoading]);

  const fetchApplications = async () => {
    const token = await getAccessTokenSilently();
    const api = new VulaLendingAPI({ token });
    // Fetch questions to answer and match any new ones
    const { questions_to_answer } = (
      await api.match_company_to_lending_products(company_slug)
    ).data;
    setQuestionsToAnswer(questions_to_answer);
    if (questions_to_answer?.length) {
      track('Lending Applications questions to answer', { company_slug });
    }
    // Fetch lending products
    const products = await api.getLendingProducts(company_slug);
    setApplications(products.data);
  };

  if (isLoading || loadingData) {
    return <Loading size="full-page" />;
  }

  const handleSubmit = async () => {
    track('Lending Applications further info submitted', {
      company_slug,
      estimated_annual_revenue: estimatedAnnualRevenue,
      desired_funding_amount: desiredLoanAmount,
      currency: selectedCurrency.code,
    });
    await fetchApplications();
    setDetailsSubmitted(true);
    sendNotification(
      `${company_slug} has submitted further info for lending applications. ` +
        `${estimatedAnnualRevenue} is their estimated annual revenue and ` +
        `${desiredLoanAmount} is their desired loan amount in ${selectedCurrency.code}.`,
      'Lending Applications further info submitted',
    );
  };

  const currencySelector = () => (
    <div className="max-w-[500px] m-auto my-2 bg-stone-100 rounded drop-shadow px-4 py-2">
      <div className="text-sm sm:text-xl pb-2">Select Currency</div>
      <select
        className="w-full py-2 px-4 bg-gray-200 rounded-xl"
        value={selectedCurrency.code}
        onChange={e => {
          const newCurrency = currencies.find(c => c.code === e.target.value);
          if (newCurrency) setSelectedCurrency(newCurrency);
        }}
      >
        {currencies.map(currency => (
          <option key={currency.code} value={currency.code}>
            {currency.name} ({currency.symbol})
          </option>
        ))}
      </select>
    </div>
  );

  const renderVulaIsSearching = () => {
    return (
      <div className=" sm:py-4">
        <h1 className=" text-xl sm:text-2xl font-extrabold py-8">
          Vula is still searching for you...
        </h1>
        <div>
          <p className="text-sm sm:text-lg">
            You will be notified when we have found good options for you.
            <br />
            We are searching based on the information you have provided.
          </p>
        </div>
        {/* allow people to edit the info they gave */}

        <div className="text-sm sm:text-m py-2">
          If you need to, you can edit the information you provided:
        </div>

        <div className="w-fit">
          {currencySelector()}
          {
            <EstimatedAnnualRevenue
              initialValue={estimatedAnnualRevenue || ''}
              onSave={(value: string) => {
                setEstimatedAnnualRevenue(value);
                updateCorpus('estimated_annual_revenue', value);
              }}
              currency={selectedCurrency}
            />
          }
          {
            <ApproximateFundingRequirement
              initialValue={desiredLoanAmount || ''}
              onSave={(value: string) => {
                setDesiredLoanAmount(value);
                updateCorpus('desired_funding_amount', value);
              }}
              currency={selectedCurrency}
            />
          }
        </div>

        <div className="text-sm sm:text-m py-2">
          If there is any other relevant information you would like to share,
          enter it here:
        </div>
        <div
          id="other_info"
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={event => {
            updateCorpus('other_funding_info', event.target.innerHTML);
          }}
          className={'mb-4 min-h-[80px] p-2 bg-stone-100 rounded-xl '}
          dangerouslySetInnerHTML={{
            __html: otherFundingInfo || '',
          }}
        ></div>
      </div>
    );
  };

  if (!applications || Object.keys(applications).length === 0) {
    if (detailsSubmitted === false) {
      track('Lending Applications further info request', { company_slug });
      return (
        <>
          <HomeNavBar />
          <div className="max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left">
            <div className="sm:py-4">
              <h1 className="text-xl sm:text-5xl font-extrabold py-8">
                Tell Vula more to get started
              </h1>
              <p className="text-sm sm:text-xl py-2">
                We find you funding options, but need a bit more info to get
                going. The more accurate this is, the better matches we can
                find.
              </p>

              {currencySelector()}
              <EstimatedAnnualRevenue
                initialValue={estimatedAnnualRevenue || ''}
                onSave={(value: string) => {
                  setEstimatedAnnualRevenue(value);
                  updateCorpus('estimated_annual_revenue', value);
                }}
                currency={selectedCurrency}
              />
              <ApproximateFundingRequirement
                initialValue={desiredLoanAmount || ''}
                onSave={(value: string) => {
                  setDesiredLoanAmount(value);
                  updateCorpus('desired_funding_amount', value);
                }}
                currency={selectedCurrency}
              />

              <div className="flex justify-center mt-6">
                <Button
                  isDisabled={!estimatedAnnualRevenue || !desiredLoanAmount}
                  text="Submit"
                  action={async () => await handleSubmit()}
                />
              </div>
            </div>
          </div>
        </>
      );
    } else if (questions_to_answer?.length) {
      return (
        <>
          <HomeNavBar />
          <div className="max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left">
            <div className="sm:py-4">
              <h1 className="text-xl sm:text-5xl font-extrabold py-8">
                Vula needs more info
              </h1>
              <p className="text-sm sm:text-xl py-2">
                We need some more information to find the best funding options
                for you. Please answer the following questions:
              </p>
              <ApplicationQuestionCriteriaQuestion
                questions={questions_to_answer}
                updateCorpus={updateCorpus}
                onComplete={async () => {
                  await fetchApplications();
                  setDetailsSubmitted(true);
                }}
                fetchApplications={fetchApplications}
              />
            </div>
          </div>
        </>
      );
    } else {
      // if present and they havent got applications say we are looking for options
      track('Lending Applications View', { company_slug });
      return (
        <>
          <HomeNavBar />
          <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left  ">
            <div className="flex flex-row  w-full items-center sm:py-4">
              {renderVulaIsSearching()}
              <div className="sm:w-1/2 flex justify-center items-center px-4">
                <img
                  src="/assets/raw.gif"
                  alt="Vula Logo"
                  className="w-[80%] rounded-full "
                />
              </div>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <HomeNavBar />
      <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left ">
        <div className="flex flex-row  w-full items-center sm:py-4">
          <h1 className=" text-xl sm:text-5xl font-extrabold py-4">
            Applications
          </h1>
        </div>
        <ApplicationStatusCards applications={applications} />
        {questions_to_answer && questions_to_answer?.length > 0 ? (
          <div className="py-4 pt-8">
            <h1 className="text-sm sm:text-xl font-bold">Further questions</h1>
            <div className="text-sm  py-4">
              We have more partners that you may be eligible for. We need some
              more information to find the best funding options for you. <br />
              Please answer the following questions:
            </div>

            <ApplicationQuestionCriteriaQuestion
              key={questions_to_answer.length}
              questions={questions_to_answer}
              updateCorpus={updateCorpus}
              onComplete={async () => {
                await fetchApplications();
                setDetailsSubmitted(true);
              }}
              fetchApplications={fetchApplications}
            />
          </div>
        ) : (
          renderVulaIsSearching()
        )}
      </div>
    </>
  );
}
