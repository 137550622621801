import React from 'react';

interface Props {
  size?: 'sm' | 'm' | 'lg';
  type?: 'sun' | 'dark' | 'light' | 'sun-white-text' | 'os' | 'animated';
}

export default function VulaLogo(props: Props) {
  const size =
    props.size === 'lg'
      ? ' w-16 sm:w-28'
      : props.size === 'sm'
      ? 'w-8 sm:w-10'
      : 'w-10 sm:w-12';

  if (props.type === 'animated')
    return (
      <div className="flex justify-center items-center">
        <img
          src="/assets/raw.gif"
          alt="Vula Logo"
          className={
            '  rounded-full  ' +
            (props.size === 'lg'
              ? ' h-[20rem] w-[20rem]'
              : props.size === 'sm'
              ? ' h-28 w-28'
              : 'h-48 w-48')
          }
        />
      </div>
    );

  return (
    <img
      src={
        props.type === 'dark' || props.type === 'light'
          ? 'vula-logo.png'
          : props.type === 'sun-white-text'
          ? '/vula-sun-white-text.png'
          : props.type === 'os'
          ? '/vula-os.png'
          : '/vula-sun.png'
      }
      alt="logo of vula"
      className={
        'hover:fill-current ' +
        size +
        (props.type === 'light' ? ' filter invert' : '')
      }
    />
  );
}
