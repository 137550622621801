import React, { useContext } from 'react';

import ContentSection from '../../utils/ContentSection';
import VulaLogo from '../../utils/VulaLogo';
import Regenerate from '../../utils/Regenerate';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../utils/Button';
import { GrantContext } from '../../../contexts/GrantContext';
import BinIcon from '../../Icons/BinIcon';

interface Props {
  question_id: string;
  question: string;
  answer: string;
}

export default function PreviouslyAskedQuestion(props: Props) {
  // deconstruct props
  const { question_id, question, answer } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user } = useAuth0<any>();

  const { updateGrantQA, deleteGrantQA } = useContext(GrantContext);

  // state
  const [showRegen, setShowRegen] = React.useState(false);

  return (
    <ContentSection background="black" key={question}>
      <div className="relative  min-w-full">
        <div className="absolute top-0 right-0 ">
          <button
            className="hover:bg-stone-700 p-2 rounded-full fill-white hover:fill-red-200"
            onClick={async () => {
              await deleteGrantQA(question_id);
            }}
          >
            <BinIcon />
          </button>
        </div>
        <div className="flex flex-col min-w-full">
          <div className="flex flex-row  items-center align-center">
            {user.picture && (
              <img
                className="rounded-full mx-1"
                src={user.picture}
                alt={user.name}
                style={{ width: '50px', height: '50px' }}
              />
            )}
            <div className="pl-4 text-white text-sm sm:text-lg font-extrabold pr-4">
              {question}
            </div>
          </div>
          <div className="flex flex-end min-w-full pt-4 items-center text-right text-sm sm:text-lg">
            <div className="flex min-w-full flex-row">
              <div className="flex flex-col w-full ">
                {showRegen ? (
                  <Regenerate
                    previousText={answer}
                    saveFunction={async regen_answer => {
                      await updateGrantQA(question_id, regen_answer);
                      setShowRegen(false);
                    }}
                  />
                ) : (
                  <>
                    <div className="text-stone-200 p-2">{answer}</div>
                    <div className="flex flex-row justify-end w-full">
                      <button
                        className="px-4 underline hover:no-underline"
                        onClick={() => {
                          navigator.clipboard.writeText(answer);
                        }}
                      >
                        Copy text
                      </button>
                      <Button
                        text="Regenerate answer"
                        action={() => setShowRegen(true)}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="hidden sm:block flex items-center">
                <div className=" h-[50px] w-[50px] bg-stone-50  rounded-xl p-2 m-2">
                  <VulaLogo />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContentSection>
  );
}
