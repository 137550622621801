import React from 'react';
import { track } from '../interfaces/mixpanel';
import { routes } from './routes';

import JunkDrawer from '../components/Landing/JunkDrawer';
import VulaLogo from '../components/utils/VulaLogo';
import MailingListComponent from '../components/utils/MailingListComponent';
import FAQs from '../components/Landing/FAQs';

export default function LandingPageOS() {
  React.useEffect(() => {
    setTimeout(() => {
      // track landing page loaded after delay to only call it when user stays
      track('OS Landing Page Loaded', {
        referrer: document.referrer,
      });
    }, 3000);
  }, []);

  return (
    <div
      className="h-full min-h-[100vh] m-auto flex items-center flex-col bg-stone-900 "
      style={{
        // Set background image to the dot.svg file
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
        /* Set background repeat to repeat both horizontally and vertically */
        backgroundRepeat: 'repeat',
      }}
    >
      {/* header */}
      <div className="max-w-[2600px] w-full p-2 sm:p-0">
        <div className="w-full flex justify-center pt-4 ">
          <button
            className="relative hover:bg-stone-700 hover:bg-opacity-[0.5] transition-all duration-300 p-4 pb-2 rounded scale-[2]"
            onClick={
              // redirect the user to the home page?
              () => {
                window.location.href = routes.public.landing;
              }
            }
          >
            <VulaLogo type="os" />
          </button>
        </div>
        {/* POSTER */}

        <div className="max-w-[1200px] m-auto">
          <h1 className="font-extrabold text-5xl sm:text-8xl pt-12 sm:pt-32 text-stone-50 transition-opacity duration-500 ease-in-out  animate-fade-in-up">
            The OS for SME funding
          </h1>
          <h2 className="max-w-[600px] m-auto font-bold text-lg py-12 sm:py-20 text-stone-50 transition-opacity duration-500 ease-in-out  animate-fade-in-up">
            The Operating System to accept applications, make decisions and
            deploy funding to African SMEs.
          </h2>
        </div>

        {/* INCENTIVES */}
        <div className="max-w-[1200px] m-auto flex sm:flex-row flex-col items-center justify-center">
          {[
            {
              emoji: '⚡️',
              title: '10x faster',
              description:
                'Create, assess, and process applications 10x faster than traditional methods.',
            },
            {
              emoji: '📢',
              title: 'Serve more SMEs',
              description: 'Increase the volume of SMEs in your pipeline.',
            },
            {
              emoji: '🗂️',
              title: 'Better data',
              description:
                'SMEs provide more relevant data with our AI application assistant.',
            },
          ].map((item, index) => {
            return (
              <div
                key={index}
                className="bg-stone-100 rounded-xl shadow-xl shadow-stone-500 p-4 m-4 sm:max-w-[33%] w-4/5 text-stone-800 sm:h-[200px] animate-fade-in-up"
              >
                <div className="ring ring-1 ring-solid ring-stone-300 shadow-lg rounded-full h-12 w-12 m-auto flex justify-center items-center">
                  <div className="text-3xl ">{item.emoji}</div>
                </div>
                <div className="text-xl py-2 font-bold">{item.title}</div>
                <div className="">{item.description}</div>
              </div>
            );
          })}
        </div>

        {/* Image */}
        <div className="sm:min-h-[60vh]">
          <div className="hidden sm:block pt-4 max-w-[1200px] m-auto transition-opacity duration-500 ease-in-out  animate-fade-in-up">
            <div className="rounded-lg bg-stone-50 overflow-hidden h-full w-full">
              {/* <img src="https://cron.com/images/product/cron-2023-02-28@2x.png" /> */}
              <img src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/partners_inbox.png" />
            </div>
          </div>
        </div>

        {/* MANAGE */}

        <div className="max-w-[1200px] m-auto pt-20 sm:pt-40">
          <div className="text-3xl sm:text-6xl text-white">
            Never miss a beat
          </div>
          <div className="text-lg sm:text-xl text-white max-w-[600px] m-auto pt-8">
            With VulaOS, you can manage your programmes and products in one
            place, without needing a tech team.
          </div>
        </div>

        <div className="max-w-[1200px] m-auto pt-20">
          <div className="bg-stone-50 rounded-2xl p-1">
            {/* Row 1 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="sm:m-1 p-3 bg-stone-200 sm:w-fit sm:px-20 sm:h-[200px] items-center flex text-center rounded-xl animate-text  bg-gradient-to-r  to-[#DF988D] via-[#E3AB58] from-[#BCC0CF] duration-[1000ms] ">
                <div className="w-20 sm:w-40">
                  <VulaLogo type="os" size="lg" />
                </div>
                <div className="text-4xl w-full font-extrabold text-white ">
                  {' '}
                  management
                </div>
              </div>
              <div className="mt-1 group overflow-hidden relative sm:m-1 bg-stone-200 w-full  sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <img
                    className="p-3 m-auto rounded-xl overflow-hidden  w-full sm:w-[70%]"
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/kanban.png"
                  />
                  {/* title */}
                  <div className="p-3 pt-0 font-bold ">
                    Get the eagle's eye view on your funnel
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block p-3 max-w-[500px] text-center m-auto  ">
                    View all open applications, see which team member is in
                    charge, and where they are in the process.
                  </div>
                </div>
              </div>
            </div>
            {/* Row 2 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="group overflow-hidden relative mt-1 sm:m-1 bg-stone-200 w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute  pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <img
                    className="p-3 m-auto rounded-xl overflow-hidden  sm:w-[50%]"
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/New+product.png"
                  />
                  {/* title */}
                  <div className="p-3 font-bold ">
                    Create new products and programmes
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    Vula simplifies the creation of new products and programmes.
                    Just ask and Vula does the rest.
                  </div>
                </div>
              </div>

              <div className="group overflow-hidden relative mt-1 sm:m-1 bg-stone-200 w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute  pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <img
                    className="p-3 m-auto rounded-xl overflow-hidden  sm:w-[70%]"
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/integrations.png"
                  />
                  {/* title */}
                  <div className="p-3 font-bold ">Connect to your tools</div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    VulaOS can connect to your existing tools, making
                    integration with CRMs and KYC tools breeze.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Onboard */}
        <div className="max-w-[1200px] m-auto pt-40 sm:pt-80">
          <div className="text-3xl sm:text-6xl text-white">
            Get applications in minutes
          </div>
          <div className="text-lg sm:text-xl text-white max-w-[800px] m-auto pt-8">
            Vula helps SMEs find the opportunities they need to know about.
            <br /> Create an open application for your existing channels and
            invite SMEs already on Vula's platform to apply.
          </div>
        </div>

        <div className="max-w-[1200px] m-auto pt-20">
          <div className="bg-stone-50 rounded-2xl p-1">
            {/* Row 1 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="sm:m-1 p-3 bg-stone-200 sm:w-fit sm:px-20 sm:h-[200px] items-center flex text-center rounded-xl animate-text  bg-gradient-to-r  to-[#DF988D] via-[#E3AB58] from-[#BCC0CF] duration-[1000ms] ">
                <div className="w-20 sm:w-40">
                  <VulaLogo type="os" size="lg" />
                </div>
                <div className="text-4xl w-full font-extrabold text-white ">
                  {' '}
                  onboarding
                </div>
              </div>
              <div className="group overflow-hidden relative m-1 bg-stone-200 sm:w-full  sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <img
                    className="p-3 m-auto  sm:w-1/2"
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/apply-with-vula.png"
                  />
                  {/* title */}
                  <div className="sm:p-3 sm:pt-5 font-bold  ">
                    One click apply for SMEs
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    SMEs who already have a Vula account can apply with one
                    click, as we already have their information and documents.
                  </div>
                </div>
              </div>
            </div>
            {/* Row 2 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="group overflow-hidden relative m-1 bg-stone-200 sm:w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <div className="text-xl sm:text-3xl my-2 sm:my-10 p-4 px-8 rounded-full bg-white w-fit m-auto font-bold ">
                    🌐 your-url.com/apply
                  </div>
                  {/* title */}
                  <div className="p-3 pt-2 font-bold  ">
                    Digitize your applications
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    Host your applications on your custom-branded website url,
                    and let SMEs apply from there.
                  </div>
                </div>
              </div>

              <div className="group overflow-hidden relative m-1 bg-stone-200 sm:w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <img
                    className="px-3 m-auto  sm:w-[80%]"
                    src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/UPLOAD-CHECKS.png"
                  />
                  {/* title */}
                  <div className="p-3 pt-0 font-bold  ">
                    Document upload checks
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    Warn SMEs when their uploads are not correct, and help them
                    to fix it.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Onboard */}
        <div className="max-w-[1200px] m-auto pt-40 sm:pt-80">
          <div className="text-3xl sm:text-6xl text-white">
            Review applications in minutes
          </div>
          <div className="text-lg sm:text-xl text-white max-w-[800px] m-auto pt-8">
            Not days. VulaOS dramatically reduces your cost-to-serve, and helps
            you to make better decisions more often.
          </div>
        </div>

        <div className="max-w-[1200px] m-auto pt-20">
          <div className="bg-stone-50 rounded-2xl p-1">
            {/* Row 1 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="m-1 p-3 bg-stone-200 sm:w-full sm:px-20 sm:h-[200px] items-center flex text-center rounded-xl animate-text  bg-gradient-to-r  to-[#DF988D] via-[#E3AB58] from-[#BCC0CF] duration-[1000ms] ">
                <div className="flex justify-center items-center">
                  <div className="w-20 sm:w-40">
                    <VulaLogo type="os" size="lg" />
                  </div>
                  <div className="text-4xl font-extrabold text-white ">
                    {' '}
                    assessment
                  </div>
                </div>
              </div>
              <div className="group overflow-hidden relative sm:m-1 bg-stone-200 sm:w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <div className="rounded overflow-hidden">
                    <img
                      className="px-3 pt-1  m-auto  sm:w-[65%]"
                      src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/Vula-assess.png"
                    />
                  </div>
                  {/* title */}
                  <div className="p-3  font-bold sm:text-xl ">
                    Automatic document analysis
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    VulaOS automatically extracts and scores SMEs to prioritize
                    your most attractive deals.
                  </div>
                </div>
              </div>
            </div>
            {/* Row 2 */}
            <div className="w-full flex flex-col sm:flex-row">
              <div className="group overflow-hidden relative mt-1 sm:m-1 bg-stone-200 sm:w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <div className="rounded overflow-hidden">
                    <img
                      className="p-3  m-auto  sm:w-[85%]"
                      src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/custom-scores.png"
                    />
                  </div>
                  {/* title */}
                  <div className="p-3  font-bold sm:text-xl ">
                    Automate actions
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    VulaOS can be modified to take actions based on the data it
                    receives so it can process while you sleep, let you know
                    when it's done and let your agents do the rest.
                  </div>
                </div>
              </div>
              <div className="group overflow-hidden relative mt-1 sm:m-1 bg-stone-200 w-full sm:h-[200px] items-center flex text-center rounded-xl">
                <div className="w-full sm:absolute pb-2 top-0 group-hover:top-[-100px] ease-in-out transition-all duration-500 delay-200">
                  {/* image */}
                  <div className="rounded overflow-hidden">
                    <img
                      className="p-3  m-auto  sm:w-[85%]"
                      src="https://vula-assets.s3.eu-west-2.amazonaws.com/new_landing_page_assets/askVula.png"
                    />
                  </div>
                  {/* title */}
                  <div className="p-3 font-bold sm:text-xl ">
                    Chat with your history
                  </div>
                  {/* hidden subtitle */}
                  <div className="hidden sm:block group-hover:text-stone-900 text-stone-200 transition-all duration-300 p-3 max-w-[500px] text-center m-auto  ">
                    VulaOS can be trained on your historical data, and can help
                    all your agents.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Already set up? */}
        <div className="max-w-[1200px] m-auto py-20 sm:py-40">
          <div className=" shadow-lg rounded-full h-20 w-20 m-auto flex justify-center items-center">
            <div className="text-7xl ">🌱</div>
          </div>
          <div className="text-3xl sm:text-6xl text-white">Start today</div>
          <div className="text-lg sm:text-xl text-white max-w-[800px] m-auto pt-8">
            Power up your funding process with VulaOS tools - APIs, backend and
            frontend tools. Get started below and we will reach out to you for a
            free consultation.
          </div>
          <div className="flex flex-col justify-center w-full pt-12">
            <div className="w-full m-auto">
              <MailingListComponent
                completedMessage="We will be in touch shortly."
                placeholder="Email"
                buttonText="Submit"
                listName="VulaOS landing"
                notify={{ subject: 'New VulaOS landing signup', message: '' }}
              />
            </div>
            <div className="py-3 text-sm text-white underline hover:no-underline">
              <a href={routes.public.demoCall} target="_blank" rel="noreferrer">
                Or book a demo
              </a>
            </div>
          </div>
        </div>

        {/* FAQs */}
        <div className="max-w-[1200px] m-auto pt-20">
          <div className="text-3xl sm:text-6xl text-white">FAQs</div>
          <div className="text-lg sm:text-xl text-white max-w-[800px] m-auto pt-8">
            If you have any questions, we have answers.
          </div>
        </div>
        <div
          className="grid         grid-cols-1 sm:grid-cols-3  max-w-[1200px] m-auto pt-10 sm:pt-20 gap-2 sm:gap-4 
        "
        >
          {/* review and also add a link from the main page and add a tracking event */}
          {[
            {
              question: 'What is VulaOS?',
              answer:
                'VulaOS is a platform, set of tools and a set of APIs that helps funders (of any type) quickly open and accept applications, dramatically reduce the time it takes to assess them, and make the management of the process simple.',
            },
            {
              question: 'Can VulaOS integrate with my existing systems?',
              answer:
                'Yes, VulaOS can connect to your existing tools, making integration with CRMs and KYC tools breeze. We have pre-built integrations as well as an integration team to help set up any custom elements. The team can provide extract, transform and load (ETL) services for the relevant data on your current system. ',
            },
            {
              question: "How does VulaOS work with Vula's SME product?",
              answer:
                'We offer tools for both sides - SME funders and SMEs. The goal is to empower SMEs to own their data and have their data work for them, and hence incentivize them to look after their data and increase its quality and structure. SMEs interact with applications that come from VulaOS.',
            },
            {
              question: 'How much does VulaOS cost?',
              answer:
                'The price depends on the products you will use and integration service you will need. Speak to our integration team to get an estimate.',
            },
            {
              question: 'How much time does VulaOS save?',
              answer:
                'This depends on the products use, but we estimate we save between 40-50% of time in setting up, 60-80% in assessment time and 30-50% in management.',
            },
            {
              question: 'Do you do trial periods?',
              answer:
                'Yes, Vula offers paid trial periods to explore the features and functionality of VulaOS and determine if it meets your needs.',
            },
          ].map((item, index) => {
            return (
              <FAQs
                key={item.question}
                index={index}
                question={item.question}
                answer={item.answer}
              />
            );
          })}
        </div>
        <JunkDrawer light />
      </div>
    </div>
  );
}
