// The logic on errors for environment variables

// API url is mandatory
if (!process.env.REACT_APP_API_URL) {
  throw new Error("REACT_APP_API_URL is missing");
}
export const apiUrl = process.env.REACT_APP_API_URL;

// All AUTH0 config is mandatory
if (process.env.REACT_APP_AUTH0_DOMAIN === undefined) {
  throw new Error("REACT_APP_AUTH0_DOMAIN is missing");
}
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;

if (process.env.REACT_APP_AUTH0_CLIENT_ID === undefined) {
  throw new Error("REACT_APP_AUTH0_CLIENT_ID is missing");
}
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

if (process.env.REACT_APP_AUTH0_REDIRECT_URL === undefined) {
  throw new Error("REACT_APP_AUTH0_REDIRECT_URL is missing");
}
export const auth0RedirectURL = process.env.REACT_APP_AUTH0_REDIRECT_URL;
