import React, { useState } from 'react';
import Button from '../utils/Button';
import SendIcon from '../Icons/SendIcon';

interface Props {
  placeholder: string;
  onSubmit: (text: string) => void;
  buttonText: string;
  caveatLink?: () => void;
  validator?: (text: string) => string | boolean;
  oneline?: boolean;
  initialValue?: string;
  secondButton?: {
    text: string;
    action: () => void;
  };
  doNotAutoFocus?: boolean;
}

export default function SingleLineInput(props: Props) {
  const [validationProblem, setValidationStatus] = useState(false);
  const [buttonIsDisabled, setButtonAsDisabled] = useState(false);
  const textInput = React.createRef<HTMLInputElement>();

  const checkEnterKeyPress = (event: KeyboardEvent) => {
    setValidationStatus(false);
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      onSubmission();
    }
  };

  const onSubmission = () => {
    setButtonAsDisabled(true);

    if (textInput && textInput.current && textInput.current.value) {
      const text = textInput.current.value.trim();

      if (props.validator) {
        if (props.validator(text)) {
          props.onSubmit(text);
        } else {
          setValidationStatus(true);
        }
      } else {
        console.log('no validator given for single line input!');
        props.onSubmit(text);
      }
    } else {
      // move the cursor to the text input
      textInput.current?.focus();
    }
    setButtonAsDisabled(false);
  };

  return (
    <div className=" flex justify-end w-full flex-col text-left">
      <div className="w-full flex flex-row items-center justify-end ">
        <input
          ref={textInput}
          autoFocus={!props.doNotAutoFocus}
          type="text"
          className={
            'sm:w-[75%] sm:my-4 px-4 py-2 rounded-full bg-white  ' +
            (props.oneline ? 'text-sm sm:text-lg mx-4' : '')
          }
          placeholder={props.placeholder}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onKeyDown={e => checkEnterKeyPress(e)}
          defaultValue={props.initialValue ? props.initialValue : undefined}
        />
        {props.oneline ? (
          <>
            <button
              className="hover:bg-stone-100 border border-solid border-1 border-stone-200 rounded-lg px-4 py-2 text-sm sm:text-lg"
              onClick={onSubmission}
              disabled={buttonIsDisabled}
            >
              <SendIcon />
            </button>
          </>
        ) : null}
      </div>
      <div className=" text-sm text-red-500 text-left">
        {validationProblem
          ? 'Something is wrong with this - please try again.'
          : ' '}
      </div>
      {props.oneline ? null : (
        <div className="w-fit py-2 flex flex-col sm:flex-row items-start sm:items-center ">
          <Button
            text={props.buttonText}
            action={onSubmission}
            isDisabled={buttonIsDisabled}
          />
          {props.secondButton ? (
            <div className="py-2 sm:p-2">
              <Button
                text={props.secondButton.text}
                action={() => props.secondButton?.action()}
              />
            </div>
          ) : null}
        </div>
      )}
      {props.caveatLink ? <>{props.caveatLink()}</> : null}
    </div>
  );
}
