import React from 'react';
import ArrowIcon from '../Icons/ArrowIcon';

interface Props {
  children: React.ReactNode;
  backNavName?: string;
  backNavAction?: (number: -1) => void;
}
export default function EditModeContainer(props: Props) {
  return (
    <div className="sticky top-0 left-0 z-50  w-full grid grid-cols-6 sm:grid-cols-8	sm:gap-2">
      {props.backNavName && typeof props.backNavAction !== 'undefined' ? (
        <button
          className=" hidden sm:flex justify-center flex-row items-center"
          onClick={() => props.backNavAction?.(-1)}
        >
          <ArrowIcon />
          <div className="px-2 text-lg font-extrabold ">
            {props.backNavName}
          </div>
        </button>
      ) : (
        <div></div>
      )}
      <div className="col-span-6 p-4 -mt-1 mx-2 bg-stone-900 rounded-b-xl flex justify-between items-center text-white">
        {props.children}
      </div>
      <div className="hidden sm:flex ">{''}</div>
    </div>
  );
}
