import React from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import ContentSection from '../utils/ContentSection';

export default function AwaitingGrantSummary() {
  const { selectedGrant } = React.useContext(GrantContext);

  return (
    <ContentSection background="colourful" centerContent>
      <div className="sm:p-8">
        <div className="text-white text-4xl font-extrabold ">⏳</div>
        <div className="text-2xl font-extrabold">
          Your grant application is being reviewed by the grant organiser.
        </div>
        <div className="text-md pt-8">Vula has found no updates yet.</div>
        <div className="text-md pt-2">
          Application submitted: {selectedGrant?.grant.updatedAt}
        </div>
      </div>
    </ContentSection>
  );
}
