import React from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import Button from '../utils/Button';

import { apiUrl } from '../../methods/env';
import { VulaCompanyAPI } from '../../api/company';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import ReviewBeforeSubmit from './ActionSections/ReviewBeforeSubmit';
import UploadZone from '../utils/UploadZone';
import NewTabIcon from '../Icons/NewTabIcon';

export default function GrantApplicationQuestions() {
  const {
    selectedGrant,
    defineSelectedGrant,
    updateAnswer,
    questionIdsWithErrors,
  } = React.useContext(GrantContext);
  const { company_slug } = React.useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  const generateAnswer = async (question: string, question_id: string) => {
    // get api token from auth0
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    const api = new VulaCompanyAPI({ token: auth0Token });
    await api.generateAnswer({ company_slug, question, question_id });
  };

  if (!selectedGrant?.grant?.application_sections?.length) {
    return null;
  }

  return (
    <>
      {selectedGrant?.grant?.application_sections.map(section => (
        <div key={section.section_title} className="p-8">
          <h2 className="text-xl font-extrabold">{section.section_title}</h2>
          {section.section_questions.map(question => (
            <div className="flex flex-row items-center" key={question.id}>
              <div className="w-full py-4">
                {/* Question */}
                <div className="flex flex-col text-left">
                  <div className="flex flex-row items-center ">
                    {question?.link?.length ? (
                      <a
                        className=""
                        href={question.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className=" flex justify-center items-center m-2 px-2 text-sm bg-stone-100 hover:bg-stone-200 rounded h-12 w-12">
                          <NewTabIcon />
                        </div>
                      </a>
                    ) : null}
                    <div className="text-md">
                      {question.question}
                      {question.isOptional ? (
                        <span className="text-stone-500 text-sm px-2">
                          Optional
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {question?.subtext ? (
                    <p className="text-sm text-stone-500 py-2">
                      {question.subtext}
                    </p>
                  ) : null}
                  {/* Answer */}
                  <div className="flex flex-row items-center  w-full">
                    <div className="w-full">
                      {question.type === 'text' && (
                        <div className="flex flex-col sm:flex-row  w-full items-center">
                          <div
                            contentEditable={true}
                            onBlur={e => {
                              updateAnswer &&
                                updateAnswer(e.target?.innerHTML, question.id);
                            }}
                            className={
                              'w-full h-full overflow-auto  p-1 sm:p-2 rounded border border-solid shadow-lg ' +
                              (questionIdsWithErrors.includes(question.id)
                                ? ' border-red-400 focus:border-stone-400'
                                : '  border-stone-200')
                            }
                            dangerouslySetInnerHTML={{
                              __html: question.answer || '',
                            }}
                          ></div>
                          {question.answer_source === 'gpt' &&
                            (!question?.answer || !question.answer.length) && (
                              <div className="p-2">
                                <Button
                                  rounded={true}
                                  asynAction={async () => {
                                    // use the human gen question if it exists, otherwise use the question
                                    const questionToAsk =
                                      question.gpt_askable_question
                                        ? question.gpt_askable_question
                                        : question.question +
                                          ' ' +
                                          question?.subtext;

                                    await generateAnswer(
                                      questionToAsk,
                                      question.id,
                                    );
                                    defineSelectedGrant(selectedGrant);
                                  }}
                                  text="Generate answer"
                                  buttonType="secondary"
                                />
                              </div>
                            )}
                        </div>
                      )}
                      {question.type === 'select' && (
                        <select
                          className="min-w-[250px] py-1 hover:rounded hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-solid border-gray-300 "
                          onChange={e =>
                            updateAnswer &&
                            updateAnswer(e.target.value, question.id)
                          }
                          defaultValue={question.answer}
                        >
                          {question.options
                            ? question.options.map(option => (
                                <option key={option}>{option}</option>
                              ))
                            : null}
                        </select>
                      )}
                      {question.type === 'upload' ? (
                        question.answer?.length ? (
                          <Button
                            text="Open file"
                            buttonType="secondary"
                            action={() =>
                              window.open(question.answer, '_blank')
                            }
                          />
                        ) : (
                          <UploadZone
                            label={question.label || question.question}
                            afterUpload={() => console.log('uploaded')}
                          />
                        )
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}

      <ReviewBeforeSubmit />
    </>
  );
}
