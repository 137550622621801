import React from 'react';

import VulaLogo from './VulaLogo';

interface Props {
  heading: string;
  children?: React.ReactNode;
  image?: string;
  imageShouldFillAllSpace?: boolean;
}

export default function HalfTextHalfImagePage(props: Props) {
  // use state to show image loading state
  const [imageIsLoaded, setLoaded] = React.useState(false);
  return (
    <div className="flex h-full min-h-[100vh] w-full bg-white">
      <div
        className={
          'min-h-[100vh] w-full flex items-center sm:p-12 bg-cover sm:bg-none ' +
          (props.image ? ' sm:w-1/2 ' : '')
        }
      >
        <div className=" m-4 p-4 rounded-xl h-max">
          <div className="flex flex-col justify-between sm:justify-start pt-4 sm:pt-12 ">
            <VulaLogo />
          </div>
          <div className="flex flex-col justify-start  text-left pt-4 sm:pt-12">
            <h1
              className={
                ' font-extrabold ' +
                (props.heading.length > 12
                  ? ' text-4xl sm:text-6xl '
                  : ' text-5xl sm:text-8xl')
              }
            >
              {props.heading}
            </h1>
          </div>
          {props.children ? (
            <div className="flex flex-col justify-start w-fit pt-4 sm:pt-12">
              {props.children}
            </div>
          ) : null}
        </div>
      </div>
      {props.image ? (
        <div className="hidden sm:flex w-1/2 ">
          <div className=" m-auto p-4 sm:p-12 h-[100vh] ">
            {!imageIsLoaded ? (
              <div
                className={
                  'shadow-xl rounded-3xl bg-slate-700 animate-pulse min-h-full min-w-full w-1/2 h-full ' +
                  (props.imageShouldFillAllSpace
                    ? ' object-cover'
                    : ' object-contain')
                }
              >
                <div className=" "></div>
              </div>
            ) : null}
            <img
              className={
                !imageIsLoaded
                  ? 'hidden'
                  : 'shadow-xl min-h-full min-w-full w-full rounded-3xl  ' +
                    (props.imageShouldFillAllSpace
                      ? ' object-cover'
                      : ' object-contain')
              }
              alt="lets go!"
              src={props.image}
              onLoad={() => {
                setLoaded(true);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
