import React, { useContext, useState } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import Dropzone from 'react-dropzone';
import { ApplicationContextPublic } from '../../contexts/ApplicationContextPublic';

interface Props {
  label: string | undefined | null;
  afterUpload?: (url: string) => void;
}

export default function UploadZone(props: Props) {
  const { getPresignedUrl } = useContext(ApplicationContextPublic);
  const [error, setError] = useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0);

  //get mime type
  const getFileMimeType = (title: string) => {
    if (title.includes('.pdf')) {
      return 'application/pdf';
    } else if (title.includes('.mp4')) {
      return 'video/mp4';
    } else if (
      title.includes('.png') ||
      title.includes('.jpeg') ||
      title.includes('.jpg')
    ) {
      return 'image/png';
    } else if (title.includes('.gif')) {
      return 'image/gif';
    } else if (title.includes('.webp')) {
      return 'image/webp';
    } else {
      return 'application/octet-stream';
    }
  };

  const onDrop = async (files: File[]) => {
    // get presigned url

    try {
      // Get presigned S3 url
      const type = files[0].type;

      const presignedUrl = await getPresignedUrl({
        label: props?.label || '',
        type: type,
        summary: files[0].name,
      });

      const mimeType = getFileMimeType(files[0].name);
      const options = {
        onUploadProgress: (p: AxiosProgressEvent) => {
          if (p.total) {
            const percentageUploaded = Math.round((p.loaded * 100) / p.total);
            setPercentageUploaded(percentageUploaded);
          }
        },
        headers: {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: presignedUrl,
          'Content-Type': mimeType,
        },
      };
      await axios
        .put(presignedUrl, files[0], options)
        .then(async () => {
          if (props.afterUpload) {
            try {
              // remove the query params from the url
              const location = presignedUrl.split('?')[0].split('.com/')[1];
              props.afterUpload(location);
            } catch (error) {
              console.log(error);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  return (
    <div className="min-w-[200px] sm:min-w-[330px] sm:p-2 p-1">
      <Dropzone
        maxFiles={1}
        onDrop={files => onDrop(files)}
        onFileDialogOpen={() => setClickedUpload(true)}
        onFileDialogCancel={() => setClickedUpload(false)}
        accept={{
          // 'image/*': ['.jpeg', '.png'],
          // 'video/mp4': ['.mp4', '.MP4'],
          'application/pdf': ['.pdf'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className=" cursor-pointer w-full h-full min-h-[100px] sm:min-h-[150px] border border-1 border-dashed border-stone-400 bg-stone-50 rounded-xl text-stone-400 flex justify-center items-center"
          >
            <input className="" {...getInputProps()} />
            <div className="sm:p-4 p-8 w-full h-full flex flex-col justify-center items-center">
              <p>
                {error
                  ? 'Something went wrong!'
                  : percentageUploaded === 100
                  ? 'Upload completed'
                  : percentageUploaded > 0
                  ? percentageUploaded.toString() + '% uploaded'
                  : clickedUpload
                  ? 'Opening your files'
                  : 'Drop the file here, or click to select the file'}
              </p>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
