import React from 'react';

interface Props {
  children: React.ReactNode;
  slim?: boolean;
}
export default function Pill(props: Props) {
  return (
    <div className="flex justify-center">
      <div
        className={
          'inline text-white font-black bg-gradient-to-br from-[#BCC0CF] via-[#E3AB58] to-[#DF988D] rounded-xl' +
          (props.slim ? ' p-1 px-2' : ' p-2 px-4')
        }
      >
        {props.children}
      </div>
    </div>
  );
}
