import React from 'react';
import Pill from '../components/utils/Pill';
import ContentSection from '../components/utils/ContentSection';
import { track } from '../interfaces/mixpanel';
import ShareIcon from '../components/Icons/ShareIcon';
import AbilitiesToggles from '../components/Landing/AbilitiesToggles';
import { sendNotification } from '../api/monitoring';

export default function Syndication() {
  // state
  const [submitted, setSubmitted] = React.useState(false);
  const [copiedToClip, setCopiedToClip] = React.useState(false);
  const [visibleToggleId] = React.useState(0);
  // ref
  const emailRef = React.useRef<HTMLInputElement>(null);
  const amountRef = React.useRef<HTMLInputElement>(null);
  const geoRef = React.useRef<HTMLSelectElement>(null);
  // statics
  const abilities = [
    {
      id: 1,
      title: 'What is the problem for businesses?',
      subtitle:
        'Extreme difficulty in accessing debt finance in a timely and convenient way, even for the most successful African businesses. Drawn-out, repetitive, wasteful application processes, KYC, negotiations. All these kill time. Businesses are happy to pay a little more for concrete rates, easier application and quicker service.',
    },
    {
      id: 2,
      title: 'Why we think syndication is the answer?',
      subtitle:
        'Lending markets are not liquid enough across Africa. Syndication was expensive and slow, so only worked on big deals. Only then would the benefits of risk-sharing, transparency of progress and fractional access be gained by financiers. Vula reduces the expense and processing time of syndication, making it accessible for much smaller deals and a wider range of funders.',
    },
    {
      id: 3,
      title: 'How does Vula work?',
      subtitle: `With Vula AI-powered platform, lenders can alert and team up with partners they trust to leverage collective intelligence for pre-vetted deals, reduce redundancy and cost of KYC, and diversify risk through higher volume. Vula also captures impact data, enabling you to measure the tangible difference your investment makes.`,
    },

    {
      id: 4,
      title: 'How to get involved?',
      subtitle: `Vula is inviting a select group of financiers to partake in our early access programmes in Tanzania and South Africa, with investment thresholds ranging from $5k to $50k. `,
    },
  ];

  // on load record view
  React.useEffect(() => {
    track('Syndication page view');
  }, []);

  const notifyUs = async () => {
    await sendNotification(
      `email: ${emailRef.current?.value}, geo: ${geoRef.current?.value}`,
      'New Syndication Interest',
    );
  };

  return (
    <div className="min-w-[100vw] min-h-[100vh]">
      <div className="w-full h-full flex flex-col sm:flex-row justify-center items-between">
        <div className="relative sm:w-1/2 min-h-[100vh] h-full flex flex-col justify-center bg-stone-900 text-stone-50 text-left px-8 sm:px-20 py-4 sm:py-12">
          <div className="absolute top-0 left-0 px-8 sm:px-20 py-10">
            <Pill>Syndication</Pill>
          </div>
          <div className="h-full">
            <div className="py-12">
              <div className="text-5xl  font-extrabold ">
                Share risk and increase dealflow with Vula syndicates
              </div>
            </div>
            <div className="text-sm">
              <div>
                African businesses require a far greater access to lending. We
                believe syndication can help.
                <br />
                <br />
                Historically, loan syndication has been exclusive to massive
                deals because of:
              </div>
              <ul className="pl-8 list-disc">
                <li>increased admin and legal costs,</li>
                <li>complicated communciation, </li>
                <li>creating long lead times.</li>
              </ul>
              <div className="py-4">
                Using Vula's AI, these barriers no longer exist.
              </div>
            </div>
            <div className="pt-4 text-black">
              {abilities.map(ability => (
                <AbilitiesToggles
                  key={ability.id}
                  ability={ability}
                  onSelected={() => {
                    console.log('ability.id, ability.content.webm');
                  }}
                  currentSelectedId={visibleToggleId}
                  hackMode={true}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="sm:w-1/2  h-full flex flex-col justify-center bg-stone-50 text-stone-800 text-left m-auto px-8 sm:px-20">
          <div className="w-full flex justify-center py-10">
            <img
              src="https://vula-assets.s3.eu-west-2.amazonaws.com/TLGxVula.png"
              alt="TLG Capital and Vula logo"
              className="max-w-[200px] max-h-[200px]"
            />
          </div>
          <div className=" ">
            <div className="text-center w-[70%] m-auto">
              <a
                href="https://vula.vc/"
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                Vula
              </a>{' '}
              is the AI powered platform that makes this possible.
              <br />
              <br />
              Be part of our early access programmes in Tanzania and South
              Africa. Limited spaces available for a select group of
              impact-minded investors.
            </div>
          </div>

          <ContentSection background="colourful" shadow={true}>
            <div className="flex flex-col w-full">
              {submitted ? (
                <>
                  <div className="text-xl font-bold pb-2">
                    We be in touch very soon.
                  </div>
                  <div className="text-xl font-bold pb-2">
                    Please share to others who may be interested.
                  </div>
                  <button
                    className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                    onClick={() => {
                      track('Syndication waiting list share', {
                        email: emailRef.current?.value,
                      });
                      if (navigator?.share) {
                        navigator.share({
                          title: 'Syndicate Loaning',
                          text: 'Something interesting? Loan syndication across Africa using AI',
                          url: 'https://vula.vc/syndication',
                        });
                      } else {
                        navigator.clipboard.writeText(
                          'Something interesting? Loan syndication across Africa using AI - https://vula.vc/syndication',
                        );
                        setCopiedToClip(true);
                      }
                    }}
                  >
                    <div className="fill-stone-200 flex flex-row justify-center ">
                      <ShareIcon />
                      <span className="px-4">
                        {copiedToClip ? 'Copied to clipboard!' : 'Share'}
                      </span>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <div className="text-2xl text-center w-full m-auto font-bold pb-2">
                    Get early access
                  </div>

                  <div className=" pt-4  text-stone-800">
                    <input
                      ref={emailRef}
                      type="text"
                      placeholder="Email"
                      className="w-full text-stone-500  border bg-stone-200 border-stone-300 rounded-md px-4 py-2 sm:text-md text-sm"
                    />
                  </div>

                  <div>
                    <select
                      className="mt-2 w-full border bg-stone-200 border-stone-300 rounded-md px-4 py-2 flex flex-row text-stone-500  sm:text-md text-sm"
                      name="cars"
                      id="cars"
                      placeholder="Where are you operating"
                      ref={geoRef}
                    >
                      {/* placeholder "where are you operating" */}
                      <option value="" disabled selected>
                        Where do you want to fund?
                      </option>
                      <option value="Tanzania">Tanzania</option>
                      <option value="South Africa">South Africa</option>
                      <option value="Both SA & TZ">
                        Both the Tanzania and South Africa
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>

                  <div className="pt-2">
                    <button
                      className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                      onClick={async () => {
                        await notifyUs();
                        track('Syndication waiting list', {
                          email: emailRef.current?.value,
                          amount: amountRef.current?.value,
                          geo: geoRef.current?.value,
                        });
                        setSubmitted(true);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </>
              )}
            </div>
          </ContentSection>
        </div>
      </div>
    </div>
  );
}
