
import { VulaCompanyAPI } from '../api/company';
import { track } from '../interfaces/mixpanel';

interface GenerateText {
  prompt: string;
  company_slug: string;
  label: string;
  auth0Token: string;
  context?: string;
  previousText?: string;
}

export const generateText = async (props: GenerateText) => {
  try {
    const { prompt, company_slug, label, context, auth0Token, previousText } =
      props;

    const gpt_prompt =
      //   if context use it
      (context ? context : '') +
      // if previous text use it
      (previousText
        ? 'previous answer:' + previousText + '. Please improve this answer: '
        : '') +
      //   add prompt
      prompt;

    const api = new VulaCompanyAPI({
      token: auth0Token,
    });
    const answer = await api.generateAnswer({
      company_slug,
      question: gpt_prompt,
      label: label,
    });
    return answer.data.answer;
  } catch (error) {
    console.log(error);
    track('errorInGeneration', {
      error: error,
    });
    return 'Something went wrong. Please try again.';
  }
};
