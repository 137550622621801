import React, { useState } from 'react';
import VulaLogo from '../utils/VulaLogo';
import { Link } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { useSingleSignIn } from '../../libs/sso-auth';
import { Hamburger } from '../Icons/Hamburger';
import { CancelIcon } from '../Icons/CancelIcon';
import VButton from '../utils/Vbutton';

export default function Navbar() {
  const [open, setOpen] = useState<boolean>(false);

  const { singleSignIn } = useSingleSignIn();

  const navLinks = [
    { title: 'SME funders', route: routes.public.os },
    { title: 'Grant providers', route: routes.public.submitGrant },
    { title: 'Login', action: true },
  ];
  return (
    <nav className={'max-w-full sticky m-auto top-0 z-50'}>
      <div className="flex justify-between items-center py-3 px-4">
        <div className="pl-2 sm:pl-4 w-1/3">
          <VulaLogo />
        </div>

        <div className="hidden lg:flex flex-row justify-center items-center text-sm">
          <ul className="flex items-center">
            {navLinks.map(({ title, action, route }, index) => {
              if (action) {
                return (
                  <li
                    className="cursor-pointer hover:text-primary mr-5"
                    onClick={() => singleSignIn({ type: 'login' })}
                  >
                    {title}
                  </li>
                );
              }
              return (
                <li
                  key={index}
                  className="mr-5 hover:text-primary whitespace-nowrap"
                >
                  <Link to={route!}>{title}</Link>
                </li>
              );
            })}
          </ul>
          <div className="hidden sm:block">
            <VButton
              type="button"
              text="Get Started"
              onClick={() => singleSignIn({ type: 'login' })}
            />
          </div>
        </div>
      </div>

      <div
        className={`lg:hidden z-[2] fixed top-[16px] right-[20px]`}
        onClick={() => setOpen(prev => !prev)}
      >
        <div>
          {!open && <Hamburger />}
          {open && <CancelIcon />}
        </div>
      </div>
      <ul
        className={`fixed w-[100vw] h-[100vh] bg-white flex flex-col lg:hidden top-[54px] left-0 z-20 p-[20px] ${
          !open ? '-translate-x-full' : ''
        } transition-transform duration-500 ease-in-out`}
      >
        {navLinks.map(({ title, action, route }, index) => {
          if (action) {
            return (
              <li
                className="cursor-pointer hover:text-primary mr-5"
                onClick={() => singleSignIn({ type: 'login' })}
              >
                {title}
              </li>
            );
          }
          return (
            <li
              key={index}
              className="mr-5 hover:text-primary whitespace-nowrap"
            >
              <Link onClick={() => setOpen(prev => !prev)} to={route!}>
                {title}
              </Link>
            </li>
          );
        })}
        <div className="lg:hidden mt-4">
          <VButton
            type="button"
            text="Get Started"
            onClick={() => singleSignIn({ type: 'login' })}
          />
        </div>
      </ul>
      <div
        className={`fixed lg:hidden border-b-2 border-dark bg-white left-0 z-[1] top-0 h-[56px] md:h-[66px] w-full`}
      >
        <div className="pt-2.5 pl-3 sm:pl-4 w-1/3">
          <VulaLogo />
        </div>
      </div>
    </nav>
  );
}
