import React from 'react';
import StandAlonePage from '../components/Applications/Public/StandAlonePage';
import PublicApplicationIframe from '../components/Applications/Public/PublicApplicationIframe';

export default function PublicApplication() {
  // if the url is /i/apply/:partner_name/:product_code show the iframe version, else show the standalone version
  if (window.location.pathname.includes('/i/apply')) {
    return <PublicApplicationIframe />;
  } else {
    return <StandAlonePage />;
  }
}
