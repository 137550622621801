import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  apiUrl,
  auth0ClientId,
  auth0Domain,
  auth0RedirectURL,
} from '../../methods/env';

interface Props {
  children: React.ReactNode;
}
export default function Auth0ProviderWithRedirectCallback(props: Props) {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRedirectCallback = (appState: any) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={auth0RedirectURL}
      audience={apiUrl}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {props.children}
    </Auth0Provider>
  );
}
