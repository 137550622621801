import React from 'react';

interface Props {
  fill?: string;
}
export default function OpenCaretIcon(props: Props) {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 15"
        height="15"
        width="15"
      >
        <path
          d="M13.125 0.9375C13.3736 0.9375 13.6121 1.03627 13.7879 1.21209C13.9637 1.3879 14.0625 1.62636 14.0625 1.875V13.125C14.0625 13.3736 13.9637 13.6121 13.7879 13.7879C13.6121 13.9637 13.3736 14.0625 13.125 14.0625H1.875C1.62636 14.0625 1.3879 13.9637 1.21209 13.7879C1.03627 13.6121 0.9375 13.3736 0.9375 13.125V1.875C0.9375 1.62636 1.03627 1.3879 1.21209 1.21209C1.3879 1.03627 1.62636 0.9375 1.875 0.9375H13.125ZM1.875 0C1.37772 0 0.900806 0.197544 0.549175 0.549175C0.197544 0.900806 0 1.37772 0 1.875L0 13.125C0 13.6223 0.197544 14.0992 0.549175 14.4508C0.900806 14.8025 1.37772 15 1.875 15H13.125C13.6223 15 14.0992 14.8025 14.4508 14.4508C14.8025 14.0992 15 13.6223 15 13.125V1.875C15 1.37772 14.8025 0.900806 14.4508 0.549175C14.0992 0.197544 13.6223 0 13.125 0L1.875 0Z"
          fill={props.fill || 'black'}
        />
        <path
          d="M3.32252 10.0361C3.35955 10.1185 3.4196 10.1884 3.49544 10.2375C3.57128 10.2865 3.65969 10.3126 3.75002 10.3126H11.25C11.3404 10.3127 11.4288 10.2866 11.5047 10.2375C11.5806 10.1884 11.6406 10.1185 11.6777 10.0361C11.7147 9.95367 11.7272 9.8623 11.7135 9.77298C11.6998 9.68367 11.6606 9.6002 11.6006 9.53264L7.85064 5.31389C7.80666 5.26432 7.75267 5.22465 7.69223 5.19747C7.63179 5.1703 7.56628 5.15625 7.50002 5.15625C7.43375 5.15625 7.36824 5.1703 7.3078 5.19747C7.24736 5.22465 7.19337 5.26432 7.14939 5.31389L3.39939 9.53264C3.33942 9.60022 3.30026 9.68369 3.28662 9.77301C3.27298 9.86233 3.28545 9.95368 3.32252 10.0361Z"
          fill={props.fill || 'black'}
        />
      </svg>
    </div>
  );
}
