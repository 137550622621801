import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import CogIcon from '../Icons/CogIcon';

export default function Profile() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { user, isAuthenticated, isLoading } = useAuth0<any>();
  const navigate = useNavigate();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated ? (
    <div className="flex justify-between flex-row items-center ">
      <button
        className="flex flex-row items-center "
        onClick={() => navigate(routes.private.mySettings)}
      >
        {user.picture ? (
          <img
            className="rounded-full mx-1"
            src={user.picture}
            alt={user.name}
            style={{ width: '25px', height: '25px' }}
          />
        ) : (
          <div className="hidden sm:flex font-bold">{user.name}</div>
        )}
        <div className="mx-1">
          <CogIcon />
        </div>
      </button>
    </div>
  ) : (
    <></>
  );
}
