import React from 'react';
import Pill from '../components/utils/Pill';
import ContentSection from '../components/utils/ContentSection';
import { track } from '../interfaces/mixpanel';
import ShareIcon from '../components/Icons/ShareIcon';

export default function FX() {
  // state
  const [submitted, setSubmitted] = React.useState(false);
  const [copiedToClip, setCopiedToClip] = React.useState(false);
  // ref
  const emailRef = React.useRef<HTMLInputElement>(null);
  const amountRef = React.useRef<HTMLInputElement>(null);

  // on load record view
  React.useEffect(() => {
    track('Depo waiting list view');
  }, []);

  return (
    <div className="min-w-[100vw] min-h-[100vh]">
      <div className="w-full h-full flex flex-col sm:flex-row justify-center items-between">
        <div className="relative sm:w-1/2 min-h-[100vh] h-full flex flex-col justify-center bg-stone-900 text-stone-50 text-left px-8 sm:px-20 py-4 sm:py-12">
          <div className="absolute top-0 left-0 px-8 sm:px-20 py-10">
            <Pill>Deposits</Pill>
          </div>
          <div className="h-full">
            <div className="py-20">
              <div className="text-2xl ">Have cash at rest?</div>
              <div className="text-5xl  font-extrabold py-4">
                Make your money work for you.
              </div>
            </div>
            <div>
              Big business banks in the US give huge interest on deposits.
              <br />
              <br />
              <b>Now you can get access too.</b>
              <br />
              <br />
              Get up to 6% interest on 180-day deposits in the US - that's extra
              team members, bigger marketing campaigns, or cash on hand. 
              <br />
              <br />
              There's no such thing as small business. Play big.
            </div>
          </div>
        </div>
        <div className="sm:w-1/2  h-full flex flex-col justify-center bg-stone-50 text-stone-800 text-left m-auto px-8 sm:px-20">
          <div className="w-full flex justify-center py-10">
            <img
              src="https://vula-assets.s3.eu-west-2.amazonaws.com/TLGxVula.png"
              alt="TLG Capital and Vula logo"
              className="max-w-[200px] max-h-[200px]"
            />
          </div>
          <div className=" ">
            <div className=" ">
              <a
                href="https://tlgcapital.com/"
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                TLG Capital
              </a>{' '}
              is offering access to USD bank deposit accounts with interest.
            </div>
            <div className=" pt-8">
              <a
                href="https://vula.vc/"
                target="_blank"
                rel="noreferrer"
                className="underline hover:no-underline"
              >
                Vula
              </a>{' '}
              automates the admin to make this scale for you.
            </div>
          </div>

          <ContentSection background="colourful" shadow={true}>
            <div className="flex flex-col w-full">
              {submitted ? (
                <>
                  <div className="text-xl font-bold pb-2">
                    We be in touch very soon.
                  </div>
                  <div className="text-xl font-bold pb-2">
                    Please share to others who may be interested.
                  </div>
                  <button
                    className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                    onClick={() => {
                      track('Depo waiting list share', {
                        email: emailRef.current?.value,
                      });
                      if (navigator?.share) {
                        navigator.share({
                          title: 'Deposits getting interest',
                          text: 'I just signed up to the waiting list for TLG deposits with Vula. Interested?',
                          url: 'https://vula.vc/fx',
                        });
                      } else {
                        navigator.clipboard.writeText(
                          'https://vula.vc/deposits',
                        );
                        setCopiedToClip(true);
                      }
                    }}
                  >
                    <div className="fill-stone-200 flex flex-row justify-center ">
                      <ShareIcon />
                      <span className="px-4">
                        {copiedToClip ? 'Copied to clipboard!' : 'Share'}
                      </span>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <div className="text-2xl font-bold pb-2">
                    Join the waiting list
                  </div>
                  <div className="text-">
                    We'll be in touch to start the process.
                  </div>
                  <div className=" pt-4  text-stone-800">
                    <input
                      ref={emailRef}
                      type="text"
                      placeholder="Email"
                      className="w-full text-stone-500  border bg-stone-200 border-stone-300 rounded-md px-4 py-2 sm:text-md text-sm"
                    />
                  </div>
                  <div className="pt-2">
                    <div className="w-full border bg-stone-200 border-stone-300 rounded-md px-4 py-2 flex flex-row">
                      <span className=" text-stone-500 pr-2">$</span>
                      <input
                        ref={amountRef}
                        type="text"
                        placeholder="Amount looking to deposit (USD)"
                        className="w-full text-stone-500 sm:text-md text-sm"
                        // turn number into number with commas
                        onChange={e => {
                          try {
                            if (e.target.value) {
                              e.target.value = parseInt(
                                e.target.value.replaceAll(',', ''),
                              ).toLocaleString('en-GB');
                            }
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="pt-2">
                    <button
                      className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                      onClick={() => {
                        track('Depo waiting list', {
                          email: emailRef.current?.value,
                          amount: amountRef.current?.value,
                        });
                        setSubmitted(true);
                      }}
                    >
                      Join waiting list
                    </button>
                  </div>
                </>
              )}
            </div>
          </ContentSection>
        </div>
      </div>
    </div>
  );
}
