import React from 'react';

export default function ArrowIcon() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 0 25.3043 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20ZM20 10C19.684 10.0002 19.3716 10.0665 19.0828 10.1948C18.794 10.3231 18.5353 10.5104 18.3234 10.7448C18.1114 10.9791 17.9509 11.2553 17.8521 11.5554C17.7534 11.8556 17.7186 12.1731 17.75 12.4875L18.625 21.255C18.6544 21.5994 18.812 21.9203 19.0666 22.1541C19.3212 22.3879 19.6543 22.5176 20 22.5176C20.3457 22.5176 20.6788 22.3879 20.9334 22.1541C21.188 21.9203 21.3456 21.5994 21.375 21.255L22.25 12.4875C22.2814 12.1731 22.2466 11.8556 22.1479 11.5554C22.0491 11.2553 21.8886 10.9791 21.6766 10.7448C21.4647 10.5104 21.206 10.3231 20.9172 10.1948C20.6284 10.0665 20.316 10.0002 20 10ZM20.005 25C19.342 25 18.7061 25.2634 18.2372 25.7322C17.7684 26.2011 17.505 26.837 17.505 27.5C17.505 28.163 17.7684 28.7989 18.2372 29.2678C18.7061 29.7366 19.342 30 20.005 30C20.668 30 21.3039 29.7366 21.7728 29.2678C22.2416 28.7989 22.505 28.163 22.505 27.5C22.505 26.837 22.2416 26.2011 21.7728 25.7322C21.3039 25.2634 20.668 25 20.005 25Z" />
      </svg>
    </div>
  );
}
