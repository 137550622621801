import React, { useContext, useEffect, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { TractionHeadlines, TractionPage } from '../../data/companies';
import {
  attachmentEnums,
  enumsCorpusQuestions,
  pitchEnums,
} from '../../enums/pitch';
import Button from '../utils/Button';
import EditableImageContainer from '../utils/EditableImageContainer';
import Pill from '../utils/Pill';
import UploadZone from '../utils/UploadZone';
import PitchContentContainer from './PitchContentContainer';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import PulsingOutlineWrapper from '../utils/PulsingOutlineWrapper';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function Traction(props: Props) {
  const {
    userIsAdmin,
    editMode,
    updateCorpus,
    addToCorpus,
    privatePitchInfo,
    pageSlug,
    investorMode,
    getPitchData,
  } = useContext(EditPitchContext);
  const [tractionEditting, setTractionEditing] = useState(false);
  const [uploadedImageURL, setUploadedImageURL] = useState('');
  const [tractionData, setTractionData] = useState<TractionHeadlines[]>([]);
  const [initialStoreInCorpus, setIntitalStoreInCorpus] = useState(false);

  const defaultTractionHeadlines = [
    { headline: '10%', marker: 'user growth (MoM)' },
    { headline: '10%', marker: 'revenue growth (MoM)' },
    { headline: '10k', marker: 'products delivered' },
  ];

  // on load
  useEffect(() => {
    // add textual content to state
    if (privatePitchInfo?.traction?.tractionHeadlines) {
      setTractionData(privatePitchInfo.traction?.tractionHeadlines);
    } else {
      setIntitalStoreInCorpus(true);
      setTractionData(defaultTractionHeadlines);
    }
  }, [initialStoreInCorpus]);

  useEffect(() => {
    // if traction updates, update the state
    if (privatePitchInfo?.traction?.tractionHeadlines) {
      setTractionData(privatePitchInfo.traction?.tractionHeadlines);
      setIntitalStoreInCorpus(false);
    }
  }, [privatePitchInfo?.traction]);

  const updateTraction = (
    index: number,
    event: React.FocusEvent,
    key: 'headline' | 'marker',
  ) => {
    const text = event.target.innerHTML;
    const newTractionData = [...tractionData];
    newTractionData[index][key] = text;

    // Stringify the data to compare
    const answer = JSON.stringify(tractionData);
    const defaultAnswer = JSON.stringify(defaultTractionHeadlines);
    if (answer === defaultAnswer) {
      return;
    }

    if (
      tractionData.length // if traction data is not empty
    ) {
      // spot changes to traction data and update the db
      // if first time ever - add to corpus
      if (initialStoreInCorpus) {
        addToCorpus(
          pitchEnums.traction,
          answer,
          enumsCorpusQuestions[pitchEnums.traction],
        );
        setIntitalStoreInCorpus(false);
      } else {
        updateCorpus('traction', answer);
      }
    }
  };

  const renderTractionHeadlineCards = (areDefault: boolean) => {
    return (
      <div className="w-full flex flex-row">
        {tractionData.map((headline, index) => {
          return (
            <div
              key={index}
              className="m-1 sm:m-2  sm:p-4 w-full text-center sm:min-h-[200px] bg-gray-100 rounded-xl flex flex-col justify-center items-center "
            >
              <ConditionalWrapper
                condition={areDefault}
                wrapper={children => (
                  <PulsingOutlineWrapper>{children}</PulsingOutlineWrapper>
                )}
              >
                <div
                  className="text-xl font-extrabold sm:text-8xl"
                  contentEditable={userIsAdmin && editMode && !investorMode}
                  suppressContentEditableWarning={true}
                  onBlur={event => updateTraction(index, event, 'headline')}
                >
                  {headline.headline}
                </div>
              </ConditionalWrapper>
              <ConditionalWrapper
                condition={areDefault}
                wrapper={children => (
                  <PulsingOutlineWrapper>{children}</PulsingOutlineWrapper>
                )}
              >
                <div
                  className="text-xs sm:text-md"
                  contentEditable={userIsAdmin && editMode && !investorMode}
                  suppressContentEditableWarning={true}
                  onBlur={event => updateTraction(index, event, 'marker')}
                >
                  {headline.marker}
                </div>
              </ConditionalWrapper>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTractionHeadlines = () => {
    const userHasGeneratedHeadlines = initialStoreInCorpus ? false : true;

    // if user is not admin and has not generated headlines, show nothing
    if (!userHasGeneratedHeadlines && !userIsAdmin) return null;

    // if user is admin and has not generated headlines, show default headlines with pulsing outline
    if (userIsAdmin && !userHasGeneratedHeadlines) {
      return renderTractionHeadlineCards(true);
    }

    // if userHasGeneratedHeadlines show them
    if (userHasGeneratedHeadlines) {
      return renderTractionHeadlineCards(false);
    }
  };

  const renderTractionChart = (traction: TractionPage) => {
    const chart = traction?.tractionChart
      ? traction.tractionChart
      : uploadedImageURL
      ? uploadedImageURL
      : undefined;

    if (investorMode && !chart) return null;

    if (typeof chart === 'string') {
      return (
        <div className="flex justify-center sm:w-3/4 h-[600px] sm:w-full">
          <EditableImageContainer
            image={chart}
            label={attachmentEnums.traction}
            altText="A chart showing progress"
          />
        </div>
      );
    }

    if (userIsAdmin && editMode) {
      return renderUploadTractionChart();
    }
  };

  const renderUploadTractionChart = () => {
    return (
      <div className="relative">
        <div className="absolute flex justify-center w-full">
          {/* <img
            src="https://vula-assets.s3.eu-west-2.amazonaws.com/companies-data/vula/traction"
            className="h-[400px]"
            alt="A representation of a chart showing progress"
          /> */}
        </div>
        <div
          className="absolute flex justify-center w-full sm:h-[400px] rounded-xl m-4 "
          style={{ backdropFilter: 'blur(4px)' }}
        >
          <div className="p-20 text-center flex flex-col items-center justify-center  shadow-2xl rounded-xl bg-stone-50">
            <div className="text-sm p-4 sm: max-w-[700px]">
              <div className="text-xl font-extrabold pb-4">
                Add a graph to show your momentum
                <br />
                (or an infographic, or the logos of big customers and partners).
              </div>
            </div>
            <UploadZone
              label="traction"
              afterUpload={async url => {
                setUploadedImageURL(url);
                await getPitchData();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTraction = (traction: TractionPage) => {
    return (
      <div className="text-left w-full">
        <div className="w-full flex justify-center">
          <Pill>Traction</Pill>
        </div>
        {renderTractionHeadlines()}
        {renderTractionChart(traction)}
      </div>
    );
  };

  // if traction data exists
  if (privatePitchInfo?.traction) {
    return (
      <PitchContentContainer id={props.id}>
        <div className="w-full  flex flex-col items-center">
          {renderTraction(privatePitchInfo.traction)}
        </div>
      </PitchContentContainer>
    );
  }

  // if traction data does not exist and investorMode - show nothing
  if (investorMode) {
    return null;
  }

  // if traction data does not exist and editMode
  if (!privatePitchInfo?.traction && userIsAdmin && editMode) {
    return (
      <PitchContentContainer id={props.id}>
        <div className="relative float-left sm:h-[700px] w-full">
          <div
            className="overflow-hidden absolute  sm:h-[700px] top-0 left-0 w-full  rounded-2xl flex flex-col items-center p-4  "
            style={{ backdropFilter: 'blur(4px)' }}
          >
            {renderTraction({
              tractionHeadlines: defaultTractionHeadlines,
            })}
          </div>
          {tractionEditting || pageSlug === '/onboarding' ? null : (
            <div
              className="absolute  sm:h-[700px] h-full z-10 shadow-2xl rounded-2xl top-0 left-0 w-full "
              style={{ backdropFilter: 'blur(20px)' }}
            >
              <div className="w-full h-full flex justify-center items-center">
                <Button
                  text="Add traction section"
                  action={() => {
                    setIntitalStoreInCorpus(true);
                    setTractionEditing(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </PitchContentContainer>
    );
  }

  return null;
}
