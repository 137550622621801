import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import { init as initialiseMixpanelTracking } from './interfaces/mixpanel';

import { routes } from './pages/routes';
import LandingPage from './pages/LandingPage';
import Launch from './pages/Launch';
import Home from './pages/Home';
import Auth0ProviderWithRedirectCallback from './interfaces/auth0/Auth0WithRedirect';
import ProtectedRoute from './interfaces/auth0/ProtectedRoute';
import { OnLogin } from './interfaces/auth0/OnLogin';
import LoggedInProvider from './contexts/LoggedInContext';
import Onboarding from './pages/Onboarding';
import MyGrants from './pages/MyGrants';
// import MyMatches from './pages/MyMatches';
import MySettings from './pages/MySettings';
import SharePitch from './pages/SharePitch';
import GrantProvider from './contexts/GrantContext';
import VerifyParams from './components/Grants/VerifyParams';
import Ask from './pages/Ask';
import PublicGrant from './pages/PublicGrant';
import PitchHome from './pages/PitchHome';
import EditPitchProvider from './contexts/EditPitchContext';
import PitchHomeComponents from './pages/PitchHomeComponents';
import IOSWarning from './pages/iosWarning';
import Memo from './pages/Memo';
import MemoContextProvider from './contexts/MemoContext';
import FX from './pages/FX';
import Syndication from './pages/Syndication';
import Depo from './pages/Depo';
import Claim from './pages/Claim';
import ClaimContextProvider from './contexts/ClaimContext';
import ClaimAdditional from './pages/ClaimAdditional';
import Applications from './pages/Applications';
import ApplicationPage from './pages/ApplicationPage';
import { ApplicationContextProvider } from './contexts/ApplicationContext';
import Careers from './pages/Careers';
import MasterPlan from './pages/MasterPlan';
import WhitePaper from './pages/WhitePaper';
import PressRoom from './pages/PressRoom';
import Signup from './pages/Signup';
import LogIn from './pages/LogIn';
import PublicGrantExplainer from './pages/PublicGrantExplainer';
import WhitePaper1 from './pages/WhitePaper1';
import DemoCall from './pages/DemoCall';
import CustomUrlNavigator from './pages/CustomUrlNavegator';
import LandingPageOS from './pages/LandingPageOS';
import InvestorsDataroomView from './pages/InvestorsDataroomView';
import UploadTest from './pages/UploadTest';
import IntroCall from './pages/IntroCall';
import PublicApplication from './pages/PublicApplication';
import PublicApplicationIframe from './components/Applications/Public/PublicApplicationIframe';
import { ApplicationContextPublicProvider } from './contexts/ApplicationContextPublic';

initialiseMixpanelTracking();

function App() {
  return (
    <Auth0ProviderWithRedirectCallback>
      <LoggedInProvider>
        <EditPitchProvider>
          <div className="App min-h-[100vh] min-w-[99vw] bg-stone-50 text-stone-900">
            <Routes>
              <Route path={routes.public.landing} element={<LandingPage />} />
              <Route path={routes.public.os} element={<LandingPageOS />} />
              {/* auth routes */}
              <Route path={routes.public.launch} element={<Launch />} />
              <Route path={routes.public.signup} element={<Signup />} />
              <Route path={routes.public.login} element={<LogIn />} />
              <Route path={routes.private.onlogin} element={<OnLogin />} />

              {/* pages */}
              <Route path={routes.public.iosWarning} element={<IOSWarning />} />
              <Route path={routes.public.fx} element={<FX />} />
              <Route
                path={routes.public.syndication}
                element={<Syndication />}
              />
              <Route path={routes.public.deposits} element={<Depo />} />
              <Route path={routes.public.careers} element={<Careers />} />
              <Route path={routes.public.masterPlan} element={<MasterPlan />} />
              <Route
                path={routes.public.publicApplicationsIframe}
                element={
                  <ApplicationContextPublicProvider>
                    <PublicApplicationIframe />
                  </ApplicationContextPublicProvider>
                }
              />
              <Route
                path={routes.public.publicApplications}
                element={
                  <ApplicationContextPublicProvider>
                    <PublicApplication />
                  </ApplicationContextPublicProvider>
                }
              />
              <Route
                path={routes.public.whitePapers}
                element={<WhitePaper />}
              />
              <Route
                path={routes.public.whitePaper1}
                element={<WhitePaper1 />}
              />
              <Route path={routes.public.pressRoom} element={<PressRoom />} />
              <Route path={routes.public.demoCall} element={<DemoCall />} />
              <Route path={routes.public.introCall} element={<IntroCall />} />
              <Route
                path={routes.public.grants}
                element={<PublicGrantExplainer />}
              />
              <Route
                path={routes.public.submitGrant}
                element={<PublicGrantExplainer />}
              />

              <Route
                path={routes.private.home}
                element={
                  <ProtectedRoute>
                    <Home />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.onboarding}
                element={
                  <ProtectedRoute>
                    <Onboarding />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.public.claim}
                element={
                  <ClaimContextProvider>
                    <Claim />
                  </ClaimContextProvider>
                }
              />

              <Route
                path={routes.public.claimAdditional}
                element={
                  <ProtectedRoute>
                    <ClaimContextProvider>
                      <ClaimAdditional />
                    </ClaimContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.pitchHome}
                element={
                  <ProtectedRoute>
                    <PitchHome />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.pitchHomeComponents}
                element={
                  <ProtectedRoute>
                    <PitchHomeComponents />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.myGrants + '/*'}
                element={
                  <ProtectedRoute>
                    <GrantProvider>
                      <MyGrants />
                    </GrantProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.myGrantsVerify}
                element={
                  <ProtectedRoute>
                    <GrantProvider>
                      <VerifyParams />
                    </GrantProvider>
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path={routes.myMatches}
                element={
                  <ProtectedRoute>
                    <MyMatches />
                  </ProtectedRoute>
                }
              /> */}
              <Route
                path={routes.private.mySettings}
                element={
                  <ProtectedRoute>
                    <GrantProvider>
                      <MySettings />
                    </GrantProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.sharePitch}
                element={
                  <ProtectedRoute>
                    <SharePitch />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.ask}
                element={
                  <ProtectedRoute>
                    <Ask />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.memo}
                element={
                  <ProtectedRoute>
                    <MemoContextProvider>
                      <Memo />
                    </MemoContextProvider>
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.invest}
                element={
                  <ProtectedRoute>
                    <InvestorsDataroomView />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.upload}
                element={
                  <ProtectedRoute>
                    {/* // todo remove */}
                    <UploadTest />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.applicationsHome}
                element={
                  <ProtectedRoute>
                    <Applications />
                  </ProtectedRoute>
                }
              />
              <Route
                path={routes.private.applicationPage}
                element={
                  <ProtectedRoute>
                    <ApplicationContextProvider>
                      <ApplicationPage />
                    </ApplicationContextProvider>
                  </ProtectedRoute>
                }
              />

              <Route
                path={routes.public.publicGrants}
                element={
                  <GrantProvider>
                    <PublicGrant />
                  </GrantProvider>
                }
              />
              {/* Route all to example pitches and do 404 logic there */}
              <Route
                path="/*"
                element={
                  // note that you can be logged in and viewing a pitch page hence needs provider
                  <CustomUrlNavigator />
                }
              />
            </Routes>
          </div>
        </EditPitchProvider>
      </LoggedInProvider>
    </Auth0ProviderWithRedirectCallback>
  );
}

export default App;
