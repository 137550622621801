import React, { useState } from 'react';
import { track } from '../../interfaces/mixpanel';
import Button from '../utils/Button';

interface Props {
  contentIsVisible: boolean;
  checkPassphrase: (passphrase: string) => Promise<boolean>;
}

export default function PitchPassword(props: Props) {
  //   state
  const [showPassphraseError, changeVisibilityOfPassphraseError] =
    useState(false);

  //   references
  const passcodeInput = React.createRef<HTMLInputElement>();

  const checkPasscode = async () => {
    if (passcodeInput?.current?.value) {
      const isPassphraseCorrect = await props.checkPassphrase(
        passcodeInput.current.value,
      );

      if (!isPassphraseCorrect) {
        changeVisibilityOfPassphraseError(true);
        setTimeout(() => {
          changeVisibilityOfPassphraseError(false);
        }, 3000);
      }
    }
  };

  const checkEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      checkPasscode();
    }
  };

  if (window.location.pathname.includes('onboarding')) {
    return null;
  }

  return (
    <div className={'flex flex-col p-2 items-center w-full '}>
      <div
        className={props.contentIsVisible ? ' opacity-0 invisible m-0 h-0' : ''}
      >
        <p
          className={
            ' text-red-700' + (showPassphraseError ? '  mb-2 ' : ' hidden ')
          }
        >
          Please try again
        </p>
        <div className="flex flex-col  py-2 items-center ">
          <input
            autoFocus={true}
            ref={passcodeInput}
            className="shadow rounded w-full m-2 p-2 px-4 sm:px-8 text-center text-gray-700 font-extrabold text-sm sm:text-lg"
            type="text"
            placeholder="Enter passcode"
            maxLength={20}
            onKeyDown={e => checkEnterKeyPress(e)}
          />
          <Button text="Open" action={() => checkPasscode()} />
        </div>
        <button
          onClick={() =>
            track('Request pitch access', {
              company: window.location.pathname.split('/')[0],
            })
          }
        >
          <a
            className="text-sm underline hover:no-underline "
            //eslint-disable-next-line
            href={`mailto:${'nic@vula.vc'}?subject=Access to ${
              window.location.pathname.split('/')[0]
            }&body=I would like access to ${
              window.location.pathname.split('/')[0]
            } memo site.`}
          >
            Request access
          </a>
        </button>
      </div>
    </div>
  );
}
