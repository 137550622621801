import React, { useEffect, useState } from 'react';
import OpenCaretIcon from '../Icons/OpenCaretIcon';
import ClosedCaretIcon from '../Icons/ClosedCaretIcon';
import { track } from '../../interfaces/mixpanel';

interface Props {
  ability: {
    id: number;
    title: string;
    subtitle: string;
    // eslint-disable-next-line
    content?: any; // irrelevant for this component
  };
  hackMode?: boolean; // so we dont track in context and use focus instead to open and close
  onSelected: () => void;
  currentSelectedId: number;
}

export default function AbilitiesToggles(props: Props) {
  const [isShown, setIsShown] = useState(
    props.ability.id === props.currentSelectedId,
  );

  useEffect(() => {
    setIsShown(props.ability.id === props.currentSelectedId);
  }, [props.currentSelectedId]);

  const renderButton = () => {
    return (
      <button
        key={props.ability.id + props.currentSelectedId}
        className={
          'group my-2 p-5 w-full min-w-[200px] flex items-center flex-col ring ring-solid -ring-1 ring-stone-200  rounded-xl transition-all duration-200  transform  ' +
          (isShown && !props.hackMode ? '  ring-orange-500 bg-white ' : ' ') +
          (props.hackMode ? ' bg-stone-900 text-white ' : ' bg-stone-50')
        }
        onClick={() => {
          track('Abilities Toggles', {
            ability: props.ability.title,
          });
          setIsShown(true);
          props.onSelected();
        }}
      >
        <div className="w-full flex flex-row items-center justify-between text-left">
          <div className="w-full text-sm font-semibold ">
            {props.ability.title}
          </div>
          {isShown ? (
            <OpenCaretIcon fill={props.hackMode ? 'white' : undefined} />
          ) : (
            <ClosedCaretIcon fill={props.hackMode ? 'white' : undefined} />
          )}
        </div>

        <div
          className={
            ' w-full text-xs font-light transition-all duration-200 ease-in-out transform text-left ' +
            (isShown
              ? 'pt-4'
              : 'h-[0px] sm:block text-stone-50 overflow-hidden ') + // Removed the redundant space
            (props.hackMode ? ' group-focus:block hidden ' : ' ')
          }
        >
          {props.ability.subtitle}
        </div>
      </button>
    );
  };

  return <div>{renderButton()}</div>;
}
