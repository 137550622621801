import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import ActiveGrantReader from './ActiveGrantReader';
import GrantInboxDivider from './GrantInboxDivider';

export default function GrantInbox() {
  const { grantMatches, getCompanyGrantMatches, selectedGrant } =
    useContext(GrantContext);

  // on mount, get the grant matches
  React.useEffect(() => {
    (async () => await getCompanyGrantMatches())();
  }, []);

  return (
    <div className="max-w-[1500px] m-auto py-2  break-words	">
      <div className="flex flex-row rounded-xl shadow-2xl bg-gray-100 overflow-auto">
        <div
          className={
            'flex flex-col w-full sm:min-w-[350px] sm:max-w-[350px] rounded-l-xl border border-1 border-gray-100 border-solid overflow-hidden ' +
            (selectedGrant ? ' hidden sm:block' : '')
          }
        >
          <GrantInboxDivider
            type="inProgress"
            grantMatches={grantMatches?.inProgress ?? []}
          />
          <GrantInboxDivider
            type="new"
            grantMatches={grantMatches?.new ?? []}
            animateIn={true}
          />
          <GrantInboxDivider
            type="archived"
            grantMatches={grantMatches?.archived ?? []}
          />
          <GrantInboxDivider
            type="awaiting"
            grantMatches={grantMatches?.awaiting ?? []}
          />
        </div>

        <ActiveGrantReader />
      </div>
    </div>
  );
}
