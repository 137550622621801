import React from 'react';
import Spinner from './Spinner';

interface Props {
  text: string;
  action?: () => void;
  asynAction?: () => Promise<null | undefined | void>;
  buttonType?: 'secondary';
  rounded?: boolean;
  isDisabled?: boolean;
  showLoading?: boolean;
  hoveroverText?: string;
}

export default function Button(props: Props) {
  // show loading spinner if showLoading is true
  const [showLoading, setShowLoading] = React.useState(false);

  const whenClicked = async () => {
    if (props.action) {
      props.action();
    }

    if (props.asynAction) {
      setShowLoading(true);
      setTimeout(async () => {
        if (props.asynAction) {
          await props.asynAction();
        }
        setShowLoading(false);
      }, 1000);
    }
  };

  return (
    <div className="">
      <button
        disabled={props.isDisabled}
        onClick={async () => await whenClicked()}
        className={
          'overflow-hidden flex justify-center align-center inline border border-solid border-1  border-stone-50 active:scale-[0.98]' +
          (props.buttonType === 'secondary'
            ? ' text-stone-900 bg-stone-100 border-stone-300  '
            : ' bg-stone-900 ') +
          (props.rounded ? ' rounded-full ' : ' rounded-lg ') +
          (props.isDisabled
            ? 'opacity-30 cursor-not-allowed'
            : 'cursor-pointer')
        }
      >
        <div className="p-0.5 ">
          <div
            className={
              `group p-2 px-4 sm:p-2 sm:px-8 overflow-hidden relative  ` +
              (props.isDisabled
                ? 'opacity-30 cursor-not-allowed'
                : 'cursor-pointer') +
              (props.rounded ? ' rounded-full ' : ' rounded-md ')
            }
          >
            <div className="relative font-extrabold text-center ">
              {showLoading ? (
                <Spinner />
              ) : (
                <>
                  {/* Main text */}
                  <div
                    className={
                      (props.hoveroverText &&
                        'group-hover:opacity-0 delay-500') +
                      (props.buttonType === 'secondary'
                        ? ' text-black bg-stone-50  '
                        : ' text-transparent bg-clip-text  bg-gradient-to-r from-purple-400 to-pink-600')
                    }
                  >
                    {props.text}
                  </div>

                  {/* Hover-over text */}
                  {props.hoveroverText && (
                    <div className="absolute w-full delay-500 top-0 h-0 opacity-0 transition-all duration-300 group-hover:opacity-100 group-hover:h-full">
                      <div
                        className={
                          props.buttonType === 'secondary'
                            ? ' text-stone-900 bg-stone-50  '
                            : ' text-transparent bg-clip-text  bg-gradient-to-r from-purple-400 to-pink-600'
                        }
                      >
                        {props.hoveroverText}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
}
