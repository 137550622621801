import React from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';

interface Props {
  tab: { section_title: string; type: string };
}

export default function SectionNavigationButtons(props: Props) {
  const { setVisibleTab, visibleTab } = React.useContext(ApplicationContext);

  const { tab } = props;
  return (
    <button
      key={tab.section_title}
      onClick={() => setVisibleTab(tab)}
      className={
        'bg-stone-100 hover:bg-stone-200 rounded-lg p-2 mx-2' +
        (tab.section_title === visibleTab?.section_title
          ? ' shadow-inner border border-1 border-solid border-stone-300 bg-stone-50 '
          : ' drop-shadow ')
      }
    >
      {tab.section_title}
    </button>
  );
}
