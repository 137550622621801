import axios from 'axios';

import { apiUrl } from '../methods/env';

type Props = {
  token?: string;
};

interface GetPitchParams {
  slug: string;
  passphrase?: string;
}

export class VulaPitchAPI {
  private token?: string;

  constructor(props?: Props) {
    this.token = props?.token;
  }

  getConfig() {
    const config = {
      headers: { Authorization: `Bearer ${this.token}` },
    };
    return config;
  }

  async getPublicPitch({ slug }: { slug: string }) {
    const config = this.getConfig();
    if (config.headers.Authorization !== 'Bearer undefined') {
      return axios.get(`${apiUrl}/pitch/public/${slug}`, config);
    }
    return axios.get(`${apiUrl}/pitch/public/${slug}`);
  }
  async getPitch({ slug, passphrase }: GetPitchParams) {
    if (passphrase) {
      return axios.get(`${apiUrl}/pitch/${slug}?passphrase=${passphrase}`);
    } else {
      // don't need passphrase if calling for your own pitch
      const config = this.getConfig();
      return axios.get(`${apiUrl}/pitch/${slug}`, config);
    }
  }

  async setPitchPublic(slug: string, publicPitch: boolean) {
    const config = this.getConfig();
    return axios.post(
      `${apiUrl}/pitch/${slug}/publish`,
      { publicPitch },
      config,
    );
  }
  async createPassword(slug: string, new_password: string) {
    const config = this.getConfig();
    return axios.post(
      `${apiUrl}/pitch/${slug}/password`,
      { new_password },
      config,
    );
  }

  async getElevatorPitch(slug: string) {
    const config = this.getConfig();
    return axios.get(`${apiUrl}/pitch/${slug}/elevator-pitch`, config);
  }

  async updateElevatorPitch(slug: string, elevator_pitch: string) {
    const config = this.getConfig();
    return axios.patch(
      `${apiUrl}/pitch/${slug}/elevator-pitch`,
      { elevator_pitch },
      config,
    );
  }
}
