import React from 'react';

interface Props {
  helperText: string;
  label: string;
  children: React.ReactNode;
  errorText?: string;
  showError?: boolean;
}

// this is the box that the company params are shown in
export default function DefinitionsFrame({
  helperText,
  label,
  children,
  errorText,
  showError,
}: Props) {
  return (
    <div className="flex flex-col items-center text-center align-middle	 w-full mt-12">
      <div className="w-full grid sm:grid-cols-8 gap-4">
        <div className="order-1"></div>
        <div className="order-3 sm:order-2 sm:col-span-7 text-xs">
          {helperText}
          {showError ? (
            <>
              <br />
              <br />
              <span className="text-red-500">{errorText}</span>
            </>
          ) : null}
        </div>

        <div className="order-2 sm:order-3 px-4">{label}</div>
        <div className="order-4 sm:col-span-7 ">{children}</div>
      </div>
    </div>
  );
}
