import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import UploadMultiple from '../components/utils/UploadMultiple';

export default function UploadTest() {
  return (
    <div>
      <HomeNavBar />
      <UploadMultiple
        label={'rag_test'}
        afterUpload={url => {
          console.log(url);
        }}
      />
    </div>
  );
}
