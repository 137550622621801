export const pitchEnums: { [slug: string]: string } = {
  raise_target: 'raise_target',
  raise_goals: 'raise_goals',
  traction: 'traction',
  we_are: 'we_are', // no question as only an image
  why_now: 'why_now',
  why_us: 'why_us', // no question as created in onboarding
};

// in truth this probably shouldn't be stored here but in the backend instead
export const enumsCorpusQuestions: { [slug: string]: string } = {
  raise_target: 'How much money are you trying to raise?',
  raise_goals: 'What would you do with the money you are trying to raise?',
  traction:
    'What metrics show you have strong traction with your early customers?',
  why_now:
    'What recent advances in technology, regulations, or customer behaviour make this the right time to launch this company?',
  why_us:
    'Why are you the best people to build this company and grow it to be huge?',
};

export const attachmentEnums: { [slug: string]: string } = {
  welcome: 'welcome',
  logo: 'logo',
  solution: 'solution',
  we_are: 'we_are',
  traction: 'traction',
};

export const allPitchSections: string[] = [
  'welcome',
  'the_problem',
  'the_solution',
  'we_are',
  'our_ask',
  'traction',
  'vision',
  'why_now_why_us',
  'competition',
  'meet_us',
];
