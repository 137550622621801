import React, { useContext, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../../interfaces/mixpanel';
import { modifyToAbsoluteUrl } from '../../methods/UIHelpers';
import Button from '../utils/Button';
import PitchContentContainer from './PitchContentContainer';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function MeetUs(props: Props) {
  const {
    editMode,
    addToStructuredData,
    setEditNotificationMessage,
    privatePitchInfo,
    publicPitchInfo,
    investorMode,
  } = useContext(EditPitchContext);
  const { company_slug } = useContext(LoggedInContext);
  const [linkVisibility, setLinkVisibility] = useState(false);
  const [uploadedLink, setUploadedLink] = useState('');

  //   references
  const calendarInput = React.createRef<HTMLInputElement>();

  const showBookingScreen = () => {
    track('View calendar', { company: publicPitchInfo?.companyName });
    setLinkVisibility(true);
  };

  const makeEmbedLinkFromURL = (link: string) => {
    const allowedEmbeds = [
      'calendly.com',
      'zcal.co',
      '/bookings',
      '/meetings',
      'meetings.hubspot.com',
    ];
    // modify to valid url
    const absoluteUrl = modifyToAbsoluteUrl(link);
    // given a zcal link, format it from
    // https://zcal.co/alexgoff
    // to
    // https://zcal.co/emb/alexgoff?embed=1&embedType=iframe
    if (absoluteUrl.includes('zcal.co')) {
      // split to get name
      const userName = absoluteUrl.split('/').at(-1);
      return 'https://zcal.co/emb/' + userName + '?embed=1&embedType=iframe';
    } else if (allowedEmbeds.some(string => absoluteUrl.includes(string))) {
      return absoluteUrl;
    } else {
      return '';
    }
  };

  const saveCalendarLink = () => {
    if (calendarInput?.current?.value) {
      const link = makeEmbedLinkFromURL(calendarInput?.current?.value);
      if (link.length) {
        addToStructuredData(company_slug, 'calendar_link', link);
        setUploadedLink(link);
        return;
      }
    }
    setEditNotificationMessage(
      '❌ Link was not an allowed calendar link - message us if you need help',
    );
  };

  const renderFalseCalendly = () => {
    return (
      <div>
        <div className="bg-stone-50 rounded-xl flex flex-row w-full p-4 border border-solid border-2px border-gray-300">
          {renderFalseCalendlyHeader()}
        </div>
        <div className="bg-stone-50 rounded-xl flex sm:flex-row flex-col w-full p-4 border border-solid border-2px border-gray-300">
          {renderFalseCalendlyCard()}
          {renderFalseCalendlyCard()}
        </div>
      </div>
    );
  };

  const renderFalseCalendlyCard = () => {
    return (
      <div className="rounded-lg sm:w-1/2 h-[150px] border border-solid border-2px border-gray-300 p-2 m-2">
        <div className="flex flex-row">
          <div className="m-2 rounded-full h-[20px] w-[20px]  border border-solid border-[4px] border-blue-400"></div>
          <div className="text-left p-2">
            <div className="font-extrabold">20 minute meeting</div>
            <div>With Nic and Alex</div>
            <div>Google Meet</div>
          </div>
        </div>
      </div>
    );
  };

  const renderFalseCalendlyHeader = () => {
    return (
      <div className="rounded-lg  h-[150px] border border-solid border-2px border-gray-300 p-4 m-2 flex flex-row">
        <img
          src={publicPitchInfo?.logoImg}
          alt="logo"
          className="w-[75px] h-[75px]"
        />
        <div>
          <div className="text-lg font-extrabold">
            {publicPitchInfo?.companyName}
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarBooking = (url: string) => {
    return (
      <div className="w-full">
        <iframe
          src={url}
          loading="lazy"
          title="zcal link to create meetings"
          className="min-h-[600px] "
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
          }}
          id="zcal-iframe"
        ></iframe>
      </div>
    );
  };

  // just uploaded their link
  if (uploadedLink.length) {
    return (
      <PitchContentContainer id={props.id}>
        <div className=" bg-[#FAFBFC] w-full max-h-[700px] flex flex-col justify-center">
          <div className="text-4xl font-extrabold py-12 ">Let's talk</div>
          {renderCalendarBooking(uploadedLink)}
        </div>
      </PitchContentContainer>
    );
  }
  // if no link and user is admin
  if (!privatePitchInfo?.contact?.link && editMode && !investorMode) {
    return (
      <PitchContentContainer id={props.id}>
        <div className="m-auto min-h-[500px] flex flex-col justify-center items-center">
          {renderFalseCalendly()}
        </div>

        <div
          className="absolute top-0 z-10 sm:max-h-[700px] sm:w-full sm:m-auto shadow-2xl shadow-xl rounded-2xl h-full "
          style={{ backdropFilter: 'blur(4px)' }}
        >
          <div className="flex flex-col justify-center items-center h-full rounded-2xl shadow-lg p-4 bg-stone-50">
            <div className="text-sm p-4 sm:max-w-[700px]">
              <div className="text-xl font-extrabold pb-4">
                Allow investors to reach out to you!
              </div>
              <div className="max-w-[600px] py-4">
                We recommend{' '}
                <a
                  className="underline hover:no-underline"
                  target={'_blank'}
                  rel={'noreferrer'}
                  href="https://zcal.co/"
                >
                  zcal.co
                </a>{' '}
                or{' '}
                <a
                  className="underline hover:no-underline"
                  target={'_blank'}
                  rel={'noreferrer'}
                  href="https://calendly.com/"
                >
                  calendly.com
                </a>{' '}
                as both are free!
                <br /> This will helps investors book time directly in your
                calendar.
              </div>
              <input
                autoFocus={true}
                ref={calendarInput}
                className="my-2 py-2 sm:min-w-[200px] shadow rounded w-full m-2 p-2 px-4 sm:px-8  text-gray-700 font-extrabold text-sm sm:text-lg"
                type="url"
                placeholder="www.zcal.co/name or www.calendly.com/name"
                // onKeyDown={e => checkEnterKeyPress(e)}
              />
              <div className="w-full flex justify-center">
                <Button
                  text="Add meeting link"
                  action={() => saveCalendarLink()}
                />
              </div>
            </div>
          </div>
        </div>
      </PitchContentContainer>
    );
  }

  // if no link, show mailto request
  if (!privatePitchInfo?.contact?.link) {
    return (
      <PitchContentContainer id={props.id}>
        {publicPitchInfo?.logoImg ? (
          <div className="w-full ">
            <img
              className="m-auto max-h-[400px] py-8"
              src={publicPitchInfo.logoImg}
              alt={publicPitchInfo?.companyName}
            />
          </div>
        ) : (
          <div className="font-extrabold text-4xl py-12">
            {publicPitchInfo?.companyName}
          </div>
        )}
        <a
          className="w-full flex justify-center text-sm underline hover:no-underline"
          //eslint-disable-next-line
          href={`mailto:nic@vula.vc?subject=Intro to ${publicPitchInfo?.companyName}\&body=I would like an intro to ${publicPitchInfo?.companyName} for further discussion.`}
          onClick={() =>
            track('Request introduction', {
              companyName: publicPitchInfo?.companyName,
            })
          }
        >
          <Button text="Request introduction" />
        </a>
      </PitchContentContainer>
    );
  }

  // link exists, show false card until the click and then show iframe
  return (
    <PitchContentContainer id={props.id}>
      <section className="w-full flex flex-col text-center">
        <div className=" bg-[#FAFBFC] w-full flex flex-col justify-center">
          <div className="text-4xl font-extrabold py-12 ">Let's talk</div>
          {linkVisibility ? (
            renderCalendarBooking(privatePitchInfo.contact.link)
          ) : (
            <div className="relative min-h-[700px] lg:px-32 flex flex-col justify-center items-center">
              {renderFalseCalendly()}
              <div className="absolute backdrop-blur-md	w-full h-full flex justify-center items-center">
                <Button
                  text="Book meeting"
                  action={() => showBookingScreen()}
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </PitchContentContainer>
  );
}
