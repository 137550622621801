import React, { useContext, useState } from 'react';

import { EditPitchContext } from '../../contexts/EditPitchContext';
import { attachmentEnums } from '../../enums/pitch';

import Button from '../utils/Button';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import EditableImageContainer from '../utils/EditableImageContainer';
import Pill from '../utils/Pill';
import PulsingDotWrapper from '../utils/PulsingDotWrapper';
import UploadZone from '../utils/UploadZone';
import VideoContainer from '../utils/VideoContainer';
import PitchContentContainer from './PitchContentContainer';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import EditablePitchText from '../utils/EditablePitchText';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function TheSolution(props: Props) {
  const { editMode, privatePitchInfo, investorMode, getPitchData } =
    useContext(EditPitchContext);
  const { onboardingProgress } = useContext(LoggedInContext);
  const [showInstructions, setInstructionsVis] = useState(false);
  const [uploadedImageURL, setUploadedImageURL] = useState('');

  if (!privatePitchInfo) {
    return null;
  }

  const renderSolutionUpload = () => {
    if (investorMode) return null;
    if (onboardingProgress !== 'completed') return null;
    if (!editMode) return null;
    return (
      <div className="relative flex justify-center items-center relative sm:w-1/2 w-full  h-[600px] m-4">
        <div className="absolute bg-stone-200 rounded-2xl h-[600px] w-full"></div>
        <div
          className="absolute z-10  shadow-2xl rounded-2xl h-full h-[600px] w-full"
          style={{ backdropFilter: 'blur(20px)' }}
        >
          <div className="w-full h-full flex justify-center items-center p-4">
            {showInstructions ? (
              <div
                className="m-2 flex flex-col justify-center items-center rounded-2xl shadow-lg p-4 bg-stone-50"
                style={{ backdropFilter: 'blur(20px)' }}
              >
                <div className="text-xl font-extrabold pb-4">
                  Show off what you do!
                </div>
                <div className="max-w-[600px]">
                  We recommend showing a simple image of what you do - your app,
                  service or product.
                </div>

                <UploadZone
                  label="solution"
                  afterUpload={async url => {
                    setUploadedImageURL(url);
                    await getPitchData();
                  }}
                />
              </div>
            ) : (
              <ConditionalWrapper
                wrapper={children => (
                  <PulsingDotWrapper>{children}</PulsingDotWrapper>
                )}
                condition={editMode}
              >
                <Button
                  text="Add solution image"
                  action={() => setInstructionsVis(true)}
                />
              </ConditionalWrapper>
            )}
          </div>
        </div>
      </div>
    );
  };

  const image = uploadedImageURL
    ? uploadedImageURL
    : privatePitchInfo?.solution?.img
    ? privatePitchInfo?.solution?.img
    : undefined;
  return (
    <PitchContentContainer id={props.id}>
      <div className="hidden sm:block ">
        <Pill>The solution</Pill>
      </div>
      <div className="w-full h-full flex items-center flex-col justify-center ">
        <div className={'py-12 w-full flex flex-col sm:flex-row items-center '}>
          <div className="block sm:hidden ">
            <Pill>The solution</Pill>
          </div>

          <div className={'flex flex-col items-center p-2 w-full'}>
            <EditablePitchText
              label="solution_headline"
              text={privatePitchInfo.solution.headline}
              classStyling="text-2xl sm:text-4xl font-extrabold py-6 sm:pb-8 sm:pr-4 whitespace-pre-wrap	"
            />

            <div className="sm:mt-12  ">
              <EditablePitchText
                label="solution_subtitle"
                text={privatePitchInfo.solution.subtitle}
                classStyling=" whitespace-pre-wrap	p-6"
              />
            </div>
          </div>
          {privatePitchInfo?.solution?.video ? (
            <VideoContainer videoURL={privatePitchInfo.solution.video} />
          ) : null}
          {!image ? (
            renderSolutionUpload()
          ) : (
            <div className=" sm:p-12 flex justify-center max-w-full w-full">
              <div className="rounded-2xl sm:rounded-2xl overflow-hidden shadow-3xl w-full max-h-[90vh]">
                <EditableImageContainer
                  image={image}
                  label={attachmentEnums.solution}
                  altText="A visual explanation of the companies solution"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </PitchContentContainer>
  );
}
