import React, { useState, useEffect, ChangeEvent } from 'react';

interface ApproximateFundingRequirementProps {
  initialValue: string;
  onSave: (value: string) => void;
  currency: { code: string; symbol: string };
}

const ApproximateFundingRequirement: React.FC<ApproximateFundingRequirementProps> = ({ 
  initialValue, 
  onSave, 
  currency 
}) => {
  const [value, setValue] = useState<string>(initialValue);

  useEffect(() => {
    if (value) {
      const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
      if (!isNaN(numericValue)) {
        setValue(formatCurrency(numericValue.toString()));
      }
    }
  }, [currency]);

  const formatCurrency = (amount: string): string => {
    const number = parseFloat(amount.replace(/[^0-9.]/g, ''));
    if (isNaN(number)) return currency.symbol;
    return currency.symbol + number.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const formatted = formatCurrency(event.target.value);
    setValue(formatted);
  };

  const handleBlur = (): void => {
    onSave(value);
  };

  return (
    <div className="max-w-[500px] m-auto my-2 bg-stone-100 rounded drop-shadow px-4 py-2">
      <div className="text-sm sm:text-xl pb-2">Approximate funding requirement?</div>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className="w-full py-2 px-4 bg-gray-200 rounded-xl"
        placeholder={`${currency.symbol}0`}
      />
    </div>
  );
};

export default ApproximateFundingRequirement;

