import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState, useEffect } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../../interfaces/mixpanel';
import Button from '../utils/Button';

interface Props {
  contentIsVisible: boolean;
  checkPassphrase: (passphrase: string) => Promise<boolean>;
}

export default function PitchPassword(props: Props) {
  // context
  const {
    editMode,
    userIsAdmin,
    pageSlug,
    investorMode,
    publicPitchInfo,
    getPitchPasswords,
  } = useContext(EditPitchContext);
  const { company_name } = useContext(LoggedInContext);
  const { user } = useAuth0();

  //   state
  const [showPassphraseError, changeVisibilityOfPassphraseError] =
    useState(false);

  //   references
  const passcodeInput = React.createRef<HTMLInputElement>();

  // onload get the pitch password
  useEffect(() => {
    if (editMode) {
      (async () => {
        await getPitchPasswords();
      })();
    }
  }, [editMode]);

  const checkPasscode = async () => {
    if (passcodeInput?.current?.value) {
      const isPassphraseCorrect = await props.checkPassphrase(
        passcodeInput.current.value,
      );

      if (!isPassphraseCorrect) {
        changeVisibilityOfPassphraseError(true);
        setTimeout(() => {
          changeVisibilityOfPassphraseError(false);
        }, 3000);
      }
    }
  };

  const checkEnterKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      checkPasscode();
    }
  };

  if (window.location.pathname.includes('onboarding')) {
    return null;
  }

  return (
    <div
      className={
        'flex flex-col m-8 items-center transition-all ease-in duration-700 opacity-100 w-full '
      }
    >
      {pageSlug === 'onboarding' || investorMode ? null : userIsAdmin &&
        editMode ? null : (
        <>
          <div
            className={
              props.contentIsVisible ? ' opacity-0 invisible m-0 h-0' : ''
            }
          >
            <p
              className={
                ' text-red-700 mb-2 ' +
                (showPassphraseError ? '' : ' invisible')
              }
            >
              Please try again
            </p>
            <div className="flex flex-col lg:flex-row mt-8 items-center ">
              <input
                autoFocus={true}
                ref={passcodeInput}
                className="sm:min-w-[200px] shadow rounded w-full m-2 p-2 px-4 sm:px-8  text-gray-700 font-extrabold text-sm sm:text-lg"
                id="password"
                type="text"
                placeholder="Passcode"
                onKeyDown={e => checkEnterKeyPress(e)}
              />
              <Button text="Open" action={() => checkPasscode()} />
            </div>
            <button
              onClick={() =>
                track('Request pitch access', {
                  company: company_name,
                })
              }
            >
              <a
                className="text-sm underline hover:no-underline"
                //eslint-disable-next-line
                href={`mailto:${
                  user?.email ? user.email : 'nic@vula.vc'
                }?subject=Access to ${
                  publicPitchInfo?.companyName
                }&body=I would like access to ${
                  publicPitchInfo?.companyName
                } pitch site.`}
              >
                Request access
              </a>
            </button>
          </div>
        </>
      )}
    </div>
  );
}
