import React from 'react';

interface Props {
  emoji: string;
}

export default function EmojiBubble(props: Props) {
  return (
    <div className="bg-stone-100 rounded-full flex justify-center items-center text-center h-12 w-12 text-4xl ">
      {props.emoji}
    </div>
  );
}
