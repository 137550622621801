import React, { useContext, useEffect, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';

import { IconBulletList } from '../../data/companies';
import { enumsCorpusQuestions, pitchEnums } from '../../enums/pitch';
// import { convertToReadableNumber } from '../../methods/UIHelpers';

// import TreemapChart from '../Charts/TreemapChart';
import Button from '../utils/Button';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import IconBulletPoints from '../utils/IconBulletPoints';
import Pill from '../utils/Pill';
import PitchContentContainer from './PitchContentContainer';
import PulsingOutlineWrapper from '../utils/PulsingOutlineWrapper';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function OurAsk(props: Props) {
  const {
    userIsAdmin,
    editMode,
    addToCorpus,
    updateCorpus,
    privatePitchInfo,
    publicPitchInfo,
    pageSlug,
    investorMode,
  } = useContext(EditPitchContext);

  const [showContent, setShowContent] = useState(false);
  const [editCorpus, setEdittingCorpus] = useState(
    privatePitchInfo?.ourAsk?.raisingTarget && privatePitchInfo.ourAsk?.goals
      ? true
      : false,
  );
  const [target, setTarget] = useState('$Xk');

  const generateDefaultTarget = () => {
    if (privatePitchInfo?.ourAsk?.raisingTarget) {
      return privatePitchInfo?.ourAsk?.raisingTarget;
    }

    const series = publicPitchInfo?.series;
    // if series is seed set as $250k, if series is A set as $1m, if preseed set as $100k, if series is B set as $5m
    if (series === 'Preseed') {
      return '$Xk';
    }
    if (series === 'Seed') {
      return '$Xk';
    }
    if (series === 'Series A') {
      return '$Xm';
    }
    if (series === 'Series B') {
      return '$Xm';
    }

    return '$Xm';
  };

  const defaultGoals = [
    {
      // svgUrl:
      //   'https://cdn.jsdelivr.net/npm/@mdi/svg@7.0.96/svg/account-school.svg',
      text: 'Reach 2,000 active users and $2k MRR by Q3.',
    },
    {
      // svgUrl:
      //   'https://cdn.jsdelivr.net/npm/@mdi/svg@7.0.96/svg/arrow-expand-all.svg',
      text: 'Hire 3 new developers to create v2 of the platform.',
    },
    {
      // svgUrl:
      //   'https://cdn.jsdelivr.net/npm/@mdi/svg@7.0.96/svg/account-group.svg',
      text: 'Perfect our AI matching algorithm to ensure top performance.',
    },
  ];

  useEffect(() => {
    const defaultTarget = generateDefaultTarget();
    setTarget(defaultTarget);
  }, []);

  useEffect(() => {
    const defaultTarget = generateDefaultTarget();
    setTarget(defaultTarget);
  }, [privatePitchInfo, publicPitchInfo]);

  //  whenever the text content changes update the corpus
  const updateCorpusTarget = (event: React.FocusEvent) => {
    const text = event.target.innerHTML;
    if (text?.length) {
      // If the text is the default text, don't update the corpus
      if (text === target) {
        return;
      }
      // spot changes to data and update the db
      if (text === privatePitchInfo?.ourAsk?.raisingTarget) {
        return;
      }
      // if first time ever - add to corpus
      if (!editCorpus) {
        addToCorpus(
          pitchEnums.raise_target,
          text,
          enumsCorpusQuestions[pitchEnums.raise_target],
        );
        setEdittingCorpus(true);
      } else {
        updateCorpus(pitchEnums.raise_target, text);
      }
    }
  };

  const renderAsk = (
    bullets: IconBulletList[] | string | undefined,
    target: string,
  ) => {
    return (
      <div className="relative float-left sm:h-[700px] pt-8 w-full">
        <div className="w-full h-full flex flex-col justify-center items-center sm:py-20">
          <Pill>We are raising</Pill>
          <div className="flex justify-center items-center flex-col sm:flex-row items-center w-full sm:h-[80%] ">
            <div className="sm:w-1/3 sm:my-12 sm:mr-12">
              <ConditionalWrapper
                wrapper={children => (
                  <PulsingOutlineWrapper>{children}</PulsingOutlineWrapper>
                )}
                condition={editMode}
              >
                <div
                  className="text-5xl sm:text-8xl font-extrabold whitespace-pre-wrap	"
                  contentEditable={userIsAdmin && editMode}
                  suppressContentEditableWarning={true}
                  onBlur={event => updateCorpusTarget(event)}
                >
                  {target}
                </div>
              </ConditionalWrapper>
            </div>
            {Array.isArray(bullets) && bullets.length ? (
              <IconBulletPoints
                bulletPoints={bullets}
                initialStoreInCorpus={!editCorpus}
                corpusEnum={pitchEnums.raise_goals}
              />
            ) : (
              <IconBulletPoints
                bulletPoints={defaultGoals}
                initialStoreInCorpus={!editCorpus}
                corpusEnum={pitchEnums.raise_goals}
              />
            )}
          </div>
        </div>
        {pageSlug !== 'onboarding' && // don't show add button on onboarding
        !privatePitchInfo?.ourAsk?.raisingTarget &&
        !privatePitchInfo?.ourAsk?.goals && // don't show add button if there is already content
        userIsAdmin && // only show to admins
        !showContent ? (
          <div
            className="absolute z-10 shadow-2xl rounded-2xl w-full h-full top-0 h-[700px]  "
            style={{ backdropFilter: 'blur(20px)' }}
          >
            <div className="w-full h-full flex justify-center items-center">
              <Button
                text="Add ask section"
                action={() => {
                  setShowContent(true);
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  if (privatePitchInfo?.ourAsk?.askImg) {
    return (
      <PitchContentContainer id={props.id}>
        <div className="flex justify-center items-start flex-col sm:flex-row">
          <img
            className="max-w-[900px] w-full"
            src={privatePitchInfo?.ourAsk.askImg}
            alt={privatePitchInfo?.ourAsk.raisingTarget.toString()}
          />
        </div>
      </PitchContentContainer>
    );
  }

  if (
    (!editMode || investorMode) &&
    (!privatePitchInfo?.ourAsk?.goals ||
      !privatePitchInfo?.ourAsk?.raisingTarget)
  ) {
    return null;
  }

  return (
    <PitchContentContainer id={props.id}>
      {renderAsk(privatePitchInfo?.ourAsk?.goals || defaultGoals, target)}
    </PitchContentContainer>
  );
}
