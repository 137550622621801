import React, { useContext } from 'react';
import PitchSlidesOverviewCard from './PitchSlidesOverviewCard';
import { useNavigate } from 'react-router-dom';
import { EditPitchContext } from '../../../contexts/EditPitchContext';

export default function PitchSlidesOverview() {
  const { publicPitchInfo, privatePitchInfo } = useContext(EditPitchContext);
  const navigate = useNavigate();
  const content = [
    'landing',
    'problem',
    'solution',
    'weAre',
    'ourAsk',
    'traction',
    'vision',
    'whyNow',
    'competition',
    'meetUs',
  ];

  const checkIfCompleted = (section: string) => {
    switch (section) {
      case 'landing':
        if (!publicPitchInfo?.logoImg) {
          return 'Add your logo';
        }
        if (!publicPitchInfo?.welcomeContent) {
          return 'Add a welcome image or video';
        }
        return null;
      case 'problem':
        return null;
      case 'solution':
        if (
          !privatePitchInfo?.solution.img ||
          !privatePitchInfo?.solution.video
        ) {
          return 'Add a solution image or video';
        }
        return null;
      case 'weAre':
        if (!privatePitchInfo?.weAreImage) {
          return 'Use our template for your team slide';
        }
        return null;
      case 'ourAsk':
        if (!privatePitchInfo?.ourAsk?.raisingTarget) {
          return 'Add your raising target';
        }
        return null;
      case 'traction':
        if (
          !privatePitchInfo?.traction?.tractionHeadlines ||
          !privatePitchInfo?.traction?.tractionHeadlines.length
        ) {
          return 'Show the traction you have!';
        }

        return null;
      case 'vision':
        if (!privatePitchInfo?.vision) {
          return 'Show your vision!';
        }
        return null;
      case 'whyNow':
        return null;
      case 'competition':
        if (!privatePitchInfo?.compets?.img) {
          return 'Explain your uniqueness!';
        }
        return null;
      case 'meetUs':
        if (!privatePitchInfo?.contact?.link) {
          return 'Help investors reach you';
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <div className="p-2 grid gap-2 sm:grid-cols-3 grid-cols-2 ">
      {content.map(section => {
        return (
          <PitchSlidesOverviewCard
            key={section}
            action={() => navigate('/pitch/' + section)}
            sectionTitle={section}
            warningMessage={checkIfCompleted(section)}
          />
        );
      })}
    </div>
  );
}
