import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../utils/Button';
import { routes } from '../../pages/routes';
import AfricaIcon from '../Icons/Africa';

interface Props {
  applications: {
    [key: string]: {
      // status
      owner: { name: string; logo?: string; url?: string };
      name: string;
      id: string;
      slug: true;
      // product_description: true;
      image: string;
      status: string;
    }[];
  };
}
export default function ApplicationStatusCards(props: Props) {
  const navigate = useNavigate();
  const renderSubmittedApplications = () => {
    if (!props.applications) return null;
    if (!props.applications['submitted']) return null;
    const submittedApplications = props.applications['submitted'];
    if (submittedApplications?.length === 0) return null;
    return (
      <>
        {/* for submitted applications show a gray bar with cards that have a white pill label saying waiting */}
        <div className="flex items-center justify-between">
          <h1 className="text-sm sm:text-xl pb-2 font-bold">
            Submitted Applications
          </h1>
        </div>

        <div className="flex flex-col bg-stone-200 rounded-xl drop-shadow p-2 mb-6 border border-1 border-stone-300 border-solid ">
          <div className="h-[100px] overflow-x-scroll flex flex-row ">
            {submittedApplications.map(application => (
              <div
                key={application.id}
                className="w-2/3 sm:w-1/4 flex flex-col justify-between bg-black rounded-lg p-4 mr-2 "
                style={{
                  backgroundImage: `url(${application.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  // overlay the image with a black background
                  backgroundBlendMode: 'multiply',
                }}
              >
                <div className="ml-auto ">
                  <img
                    src={application.owner?.logo}
                    alt={application.name}
                    className="h-3 ml-auto"
                  />
                  <p className="mt-1 text-stone-100 text-xs ">
                    {application.name}
                  </p>
                </div>
                <div className="  w-full text-right">
                  <p className="text-xs w-fit ml-auto text-stone-800 bg-stone-100 rounded-full px-6 py-1 ">
                    Waiting response
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  };

  const renderNewApplications = () => {
    if (!props.applications) return null;
    if (!props.applications['new']) return null;
    if (props.applications['new'].length === 0) return null;

    //   render a large card with a white pill label saying new
    return (
      <>
        <div className="flex items-center justify-between">
          <h1 className="text-sm sm:text-xl pb-2 font-bold">New matches</h1>
        </div>

        <div className=" overflow-x-scroll flex flex-row ">
          {props.applications['new'].map(application => (
            <div
              key={application.id}
              className=" w-[250px] sm:w-[550px] bg-white rounded-2xl p-2 mr-2 inline-block flex-shrink-0"
              style={{
                backgroundImage: `url(${application.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backgroundBlendMode: 'multiply',
              }}
            >
              <div className="p-4 rounded-lg bg-stone-50 bg-opacity-70  flex flex-col justify-between">
                <div className="py-2">
                  <img
                    src={application.owner?.logo}
                    alt={application.name}
                    className="h-10 drop-shadow"
                  />
                  <p className="mt-1 text-stone-900 text-xs sm:text-lg font-bold">
                    {application.name}
                  </p>
                  {/* <p className="mt-1 text-stone-900 text-xs h-full">
                    {application.product_description}
                  </p> */}
                </div>
                <div className="pt-8  w-full text-right">
                  <Button
                    text="Start Application"
                    action={() => {
                      navigate(
                        routes.private.applicationsHome +
                          application.owner.name +
                          '/' +
                          application.slug,
                      );
                    }}
                  ></Button>
                </div>
              </div>
            </div>
          ))}
          {/* add a more coming soon card  with a nice transitional background*/}
          <div className=" w-[250px] sm:w-[550px]  rounded-2xl p-2 mr-2 inline-block flex-shrink-0 bg-gradient-to-br from-stone-800 to-violet-400">
            <div className="p-4 flex justify-center flex-col">
              <div className="fill-stone-200 ">
                <AfricaIcon size="large" />
              </div>
              <div className="mt-1 text-2xl text-stone-200  font-bold">
                More coming soon...
              </div>
              <div className="mt-1 text-stone-200 text-xs">
                We are working hard to bring you more opportunities. We will
                notify you when they are available or check back soon.
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {renderSubmittedApplications()}
      {renderNewApplications()}
    </div>
  );
}
