import { useAuth0 } from '@auth0/auth0-react';

import React, { useContext, useState } from 'react';
import { VulaCompanyAPI } from '../../api/company';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { apiUrl } from '../../methods/env';

interface Props {
  reportLoading?: (isLoading: boolean) => void;
  isOnboarding?: boolean;
}

export default function CompanyNameChange(props: Props) {
  const { company_name, company_slug, setCompanyName, setCompanySlug } =
    useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  const companyNameInput = React.createRef<HTMLInputElement>();
  const [errorText, setErrorText] = useState('');

  const updateCompanyName = async () => {
    setErrorText('');
    const new_name = companyNameInput.current?.value;
    // update company name if needed
    if (!new_name?.length) {
      setErrorText('Company name cannot be blank');
    } else if (new_name !== company_name) {
      // tell parent we are loading
      props.reportLoading && props.reportLoading(true);

      // Prep calling api
      const auth0Token = await getAccessTokenSilently({
        audience: apiUrl,
      });
      const api = new VulaCompanyAPI({ token: auth0Token });

      if (props.isOnboarding && company_name.length === 0) {
        // we are on onboarding and no company has been created yet, so we must be a user without a website
        try {
          const res = await api.saveCompanyNameAndText(new_name, '');
          setCompanySlug(res.data.company_slug);
          setCompanyName(new_name);

          props.reportLoading && props.reportLoading(false);
        } catch (error: any) {
          if (error?.response?.data?.error) {
            setErrorText(JSON.stringify(error.response.data.error));
          } else {
            setErrorText(JSON.stringify(error.message));
          }
          props.reportLoading && props.reportLoading(false);
        }
      } else {
        // edit company name that we scraped
        try {
          const res = await api.updateCompanyName(new_name, company_slug);
          setCompanySlug(res.data.company_slug);
          setCompanyName(new_name);
          props.reportLoading && props.reportLoading(false);
        } catch (error: unknown) {
          if (error instanceof Error) {
            setErrorText(JSON.stringify(error.message));
          }
          props.reportLoading && props.reportLoading(false);
        }
      }
    }
  };

  return (
    <div className="text-left w-full py-4 ">
      <div className="flex flex-row justify-start items-center">
        <div
          className=" text-stone-500 max-w-1/8 w-full pr-4"
          style={{ width: 'max-content' }}
        >
          Name
        </div>
        <input
          ref={companyNameInput}
          type="text"
          className="w-full p-1 sm:p-2 rounded border border-solid border-stone-200 shadow-2xl"
          placeholder={'Your company name'}
          defaultValue={company_name}
          minLength={4}
          onBlur={() => updateCompanyName()}
        />
      </div>
      {errorText.length ? (
        <div className="h-6">
          <span className="text-xs text-red-600">{errorText}</span>
        </div>
      ) : null}
    </div>
  );
}
