import React from 'react';
import PreviouslyAskedQuestions from './PreviouslyAskedQuestions';
import AskVulaGrantQuestions from './AskVulaGrantQuestions';

export default function GetVulasHelp() {
  return (
    <>
      <AskVulaGrantQuestions />
      <PreviouslyAskedQuestions />
    </>
  );
}
