import React from 'react';
import Button from '../utils/Button';
import { track } from '../../interfaces/mixpanel';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import SectionNavigation from './SectionNavigation';

export default function SubmitApplication() {
  const {
    lendingProductApplicationDefinition,
    updateApplicationStatus,
    allRequiredAnswersAreCompleted,
  } = React.useContext(ApplicationContext);
  const { company_slug } = React.useContext(LoggedInContext);

  const [submitted, setSubmitted] = React.useState(false);

  if (!lendingProductApplicationDefinition) return null;

  // if the status is already submitted, show the submitted message
  if (lendingProductApplicationDefinition?.status?.status === 'submitted')
    return (
      <>
        <div className="text-3xl font-bold pt-4">🚀 Application submitted</div>
        <div className="text py-4 sm:w-1/2">
          You will be notified via email once your application has been
          received, and will be able to track the progress of your application
          in the dashboard.
        </div>
      </>
    );

  if (submitted)
    return (
      <>
        <div className="text-3xl font-bold pt-4">🚀 Application submitted</div>
        <div className="text py-4 w-1/2">
          You will be notified via email once your application has been
          received, and will be able to track the progress of your application
          in the dashboard.
        </div>
      </>
    );
  return (
    <>
      <div className="pb-40">
        <div className="text-3xl font-bold pt-4 pb-4">
          Submit your application
        </div>
        <div className="text-lg font-extrabold">What happens next?</div>
        <ul className="list-disc list-inside">
          <li className="py-2">
            Your reviewer will be immediately notified of your application.
          </li>
          <li className="py-2">
            Vula will assist them in reviewing your application.
          </li>
          <li className="py-2">
            You will get email updates on the progress of your application.
          </li>
        </ul>

        {!allRequiredAnswersAreCompleted && (
          <div className="text-m text-red-600 sm:w-1/2 font-extrabold py-4">
            You have not completed all the required questions. <br />
            Please complete all the required questions.
          </div>
        )}
        <Button
          isDisabled={!allRequiredAnswersAreCompleted}
          text="Submit"
          action={async () => {
            setSubmitted(true);
            track('Application Submitted', {
              company_slug,
              lender_slug: lendingProductApplicationDefinition?.owner.name,
              product_slug: lendingProductApplicationDefinition?.name,
            });
            if (updateApplicationStatus) {
              await updateApplicationStatus('submitted');
            }
          }}
        />
      </div>

      <SectionNavigation />
    </>
  );
}
