import React, { useContext, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { attachmentEnums } from '../../enums/pitch';
import Button from '../utils/Button';
import EditableImageContainer from '../utils/EditableImageContainer';
import UploadZone from '../utils/UploadZone';
import PitchContentContainer from './PitchContentContainer';
import WhyUs from './WhyUs';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function WeAre(props: Props) {
  const {
    editMode,
    userIsAdmin,
    privatePitchInfo,
    investorMode,
    getPitchData,
  } = useContext(EditPitchContext);
  const [uploadedImageURL, setUploadedImageURL] = useState('');

  const renderWeAre = (imageURL: string) => {
    return (
      <div className="flex flex-col items-center">
        <div className="p-2 max-h-[65vh] w-fit">
          <div className="flex flex-col sm:flex-row justify-center items-center">
            <div
              className={
                privatePitchInfo?.whyUs?.length && investorMode
                  ? 'sm:w-[66%]'
                  : 'm-auto w-full'
              }
            >
              <EditableImageContainer
                image={imageURL}
                altText="Explanation of how the founders met"
                label={attachmentEnums.we_are}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderWeAreInstructions = () => {
    return (
      <div className="flex flex-col justify-center items-center rounded-2xl shadow-lg p-4 bg-stone-50">
        <div className="text-sm p-4 sm:max-w-[700px]">
          <div className="text-xl font-extrabold pb-4">Your team</div>
        </div>
        <div className="pb-4" style={{ backdropFilter: 'blur(20px)' }}>
          <Button
            text="Use our templates"
            action={() =>
              window.open(
                'https://docs.google.com/presentation/d/1xFCQSZsbJtrF9ciLPgwI5lNzykKvb0VJkWfgWPzyde0/edit?usp=sharing',
                '_blank',
              )
            }
          />
        </div>
        <UploadZone
          label="we_are"
          afterUpload={async url => {
            setUploadedImageURL(url);
            await getPitchData();
          }}
        />
      </div>
    );
  };

  // if new image is uploaded
  if (uploadedImageURL.length) {
    return (
      <PitchContentContainer id={props.id}>
        {renderWeAre(uploadedImageURL)}
      </PitchContentContainer>
    );
  }

  if (privatePitchInfo?.weAreImage) {
    return (
      <PitchContentContainer id={props.id}>
        {renderWeAre(privatePitchInfo?.weAreImage)}
      </PitchContentContainer>
    );
  }

  // if user is in investor mode, do not show add image button
  if (investorMode) {
    return null;
  }

  // if user is editting, and there is no image, show add instructions
  if (userIsAdmin && editMode && !privatePitchInfo?.weAreImage) {
    return (
      <PitchContentContainer id={props.id}>
        <div className="relative float-left h-[700px] w-full flex flex-col sm:flex-row">
          <div
            className={
              privatePitchInfo?.whyUs?.length
                ? 'sm:w-[50%]'
                : investorMode || !userIsAdmin
                ? 'hidden'
                : 'sm:w-[50%]'
            }
          >
            <WhyUs />
          </div>
          <div
            className="relative h-[700px] top-0 left-0 rounded-2xl w-full h-full flex flex-col items-center bg-opacity-60"
            style={{ backdropFilter: 'blur(4px)' }}
          >
            <div className="hidden sm:block">
              {renderWeAre(
                'https://vula-assets.s3.eu-west-2.amazonaws.com/companies-data/vula/our-story',
              )}
            </div>
            <div
              className="absolute top-0 bottom-0 m-auto shadow-xl z-10 rounded-2xl w-full h-full "
              style={{ backdropFilter: 'blur(4px)' }}
            >
              <div className=" w-full h-full flex justify-center items-center">
                <>{renderWeAreInstructions()}</>
              </div>
            </div>
          </div>
        </div>
      </PitchContentContainer>
    );
  }

  // unsure this can happen
  return null;
}
