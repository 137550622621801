import React from 'react';
import { ClaimParty } from '../../pages/Claim';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../contexts/LoggedInContext';

interface Props {
  share_claim_type: string;
  dataOwner: ClaimParty;
  dataRequester: ClaimParty;
  dataHolder: ClaimParty;
  completed?: boolean;
}

export default function ClaimLogoHeader(props: Props) {
  const { user } = useAuth0();
  const { company_logo } = React.useContext(LoggedInContext);
  return (
    <div className="relative pb-4 sm:pb-8 sm:max-w-[65%] w-full m-auto">
      <div className="relative">
        <div className="flex flex-row items-center justify-between ">
          {props.share_claim_type === 'new_account' ? null : (
            <>
              <div className="z-20 p-0 sm:p-1 rounded-full bg-stone-50 w-20 h-20 flex items-center justify-center shadow">
                <img
                  className="rounded-full"
                  src={props.dataRequester.logo}
                  alt={props.dataRequester.name}
                />
              </div>
              <div className="z-20 p-1 sm:p-2 rounded-full bg-stone-50 w-14 h-14 flex items-center justify-center  shadow ">
                <img
                  className="rounded-full"
                  src={props.dataHolder.logo}
                  alt={props.dataHolder.name}
                />
              </div>
            </>
          )}
          <div
            className={
              'z-20 p-1 sm:p-2 rounded-full bg-stone-50 w-20 h-20 flex items-center justify-center  shadow' +
              (props.share_claim_type === 'new_account'
                ? ' w-24 h-24 m-auto'
                : '')
            }
          >
            {props.dataOwner.logo.length ? (
              <img
                className="rounded-full"
                src={props.dataOwner.logo}
                alt={props.dataOwner.name}
              />
            ) : (
              <img
                src={
                  company_logo ||
                  user?.picture ||
                  'https://www.svgrepo.com/show/506667/person.svg'
                }
                alt="Logo Placeholder"
                className="rounded-full "
              />
            )}
          </div>
          {/* add lines between the logos */}
          {props.share_claim_type === 'new_account' ? null : (
            <div className=" z-10 absolute w-full left-0 bottom-0 h-full flex items-center justify-center p-4">
              <div
                className={
                  'border border-1 w-full ' +
                  (props.completed ? 'border-solid ' : 'border-dotted')
                }
              ></div>
              <div className="border border-solid border-1 w-full"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
