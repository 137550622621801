import React, { useEffect } from 'react';

import RequestRow from '../components/Claim/RequestRow';
import ClaimLogoHeader from '../components/Claim/ClaimLogoHeader';
import AcceptTCs from '../components/Claim/AcceptTCs';
import Loading from './Loading';
import { track } from '../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import { routes } from './routes';
import { ClaimContext } from '../contexts/ClaimContext';
import { useNavigate } from 'react-router-dom';
import { LoggedInContext } from '../contexts/LoggedInContext';
import LoginButton from '../interfaces/auth0/LoginButton';
import VulaLogo from '../components/utils/VulaLogo';

export interface ClaimDefinition {
  share_claim_type: string;
  dataProcessingAgreement: string;
  dataOwner: ClaimParty;
  dataRequester: ClaimParty;
  dataHolder: ClaimParty;
  dataRequestedHeadline: string;
  dataRequested: ClaimData[];
  rewardsForDataHeadline?: string;
  rewardsForData?: ClaimData[];
  redirect_url?: string;
}
export interface ClaimParty {
  name: string;
  logo: string;
  url: string;
}

export interface ClaimData {
  title: string;
  description?: string;
  icon: string;
}

export default function Claim() {
  const navigate = useNavigate();
  const { loginWithRedirect, user } = useAuth0();
  const { company_name } = React.useContext(LoggedInContext);
  const { claimDefinition, codeLoading } = React.useContext(ClaimContext);
  const [redirectUri, setRedirectUri] = React.useState<string>('');

  // onload check the vula_claim_code and add additionally a ref="" to the url - of the first two characters ie tx
  useEffect(() => {
    // get claim status and code
    const urlParams = new URLSearchParams(window.location.search);
    const claimCode = urlParams.get('vula_claim_code');

    // add ref= to the url
    if (claimCode) {
      const cleanReturnUrl =
        window.location.origin +
        routes.public.claim +
        '?vula_claim_code=' +
        claimCode +
        '&ref=' +
        claimCode.substring(0, 2);
      // set the url without reloading the page
      window.history.replaceState({}, '', cleanReturnUrl);
    }

    // store the redirect uri for login
    const redirectUri =
      routes.public.claimAdditional +
      window.location.search +
      '&vula_claim_status=accepted';
    setRedirectUri(redirectUri);
  }, []);

  if (codeLoading) {
    <Loading size="full-page" />;
  }

  if (!claimDefinition) {
    return <Loading size="full-page" />;
  }

  if (claimDefinition.share_claim_type === 'new_account') {
    return (
      <div
        className="h-full min-h-[100vh] m-auto flex items-center justify-center flex-col bg-white relative "
        style={{
          // Set background image to the dot.svg file
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%23b0b0b0" /></svg>')`,
          /* Set background repeat to repeat both horizontally and vertically */
          backgroundRepeat: 'repeat',
        }}
      >
        <div className="absolute top-4 ">
          <VulaLogo size="sm" />
        </div>
        <div className="rounded-3xl bg-white drop-shadow-2xl p-4 sm:p-12 w-[95vw] sm:w-[40vw] ">
          <ClaimLogoHeader {...claimDefinition} />
          <div className="font-bold text-md sm:text-3xl pb-2 sm:pb-3 sm:w-[85%] m-auto">
            👋 {claimDefinition.dataOwner.name}
          </div>

          {claimDefinition.rewardsForDataHeadline &&
            claimDefinition.rewardsForData && (
              <>
                <div className=" shadow-lg py-4 sm:py-8 px-12 bg-stone-100 rounded-xl my-4 ">
                  <span className="font-bold">
                    {claimDefinition.rewardsForDataHeadline} grants
                  </span>{' '}
                  are waiting for you
                </div>

                <div className="border border-1 border-solid border-stone-200 border-x-0 border-t-0"></div>
                {claimDefinition.rewardsForData?.map(claimData => (
                  <RequestRow key={claimData.title} claimData={claimData} />
                ))}
              </>
            )}
          <div className=" m-auto text-center py-8    ">
            <div className="w-full flex justify-center">
              <VulaLogo size="sm" />
            </div>
            <div className="text-left w-full text-center">
              Claim your free Vula account now.
            </div>
          </div>
          <div className="flex justify-center w-full flex-col items-center">
            <LoginButton
              type="signup"
              customText="Signup"
              redirectUri={routes.private.onlogin + window.location.search}
            />

            <div className="mt-2 pt-2 text-xs ">
              Not {claimDefinition.dataOwner.name}? <br />{' '}
              <a
                className="underline hover:no-underline"
                href={routes.public.signup}
              >
                Create your own here.
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="h-full min-h-[100vh] m-auto flex items-center justify-center flex-col bg-white "
      style={{
        // Set background image to the dot.svg file
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%23b0b0b0" /></svg>')`,
        /* Set background repeat to repeat both horizontally and vertically */
        backgroundRepeat: 'repeat',
      }}
    >
      <div className="rounded-2xl bg-white shadow-2xl p-4 sm:p-12 w-[95vw] sm:w-[45vw] ">
        <ClaimLogoHeader {...claimDefinition} />

        <div className="font-bold text-md sm:text-xl pb-2 sm:pb-6 sm:w-[85%] m-auto">
          <a
            className="font-extrabold underline hover:no-underline text-blue-900"
            href={claimDefinition.dataRequester.url}
            target="_blank"
            rel="noreferrer"
          >
            {claimDefinition.dataRequester.name}
          </a>{' '}
          wants access to your {''}
          <a
            className="font-extrabold underline hover:no-underline text-blue-900"
            href={claimDefinition.dataHolder.url}
            target="_blank"
            rel="noreferrer"
          >
            {claimDefinition.dataHolder.name}
          </a>{' '}
          data.
        </div>

        <div className="text-left bg-stone-50 w-full rounded-xl py-4 sm:py-8 shadow">
          <div className="text-sm sm:text-lg sm:pb-8 pb-4  pl-4 sm:pl-8 border border-1 border-solid border-stone-200 border-x-0 border-t-0">
            {claimDefinition.dataRequestedHeadline}
          </div>
          {claimDefinition.dataRequested.map(claimData => (
            <RequestRow key={claimData.title} claimData={claimData} />
          ))}
          {claimDefinition.rewardsForDataHeadline &&
            claimDefinition.rewardsForData && (
              <>
                <div className=" text-sm sm:text-lg py-4 sm:py-8 pt-6 sm:pt-12  pl-4 sm:pl-8 border border-1 border-solid border-stone-200 border-x-0 border-t-0">
                  {claimDefinition.rewardsForDataHeadline}
                </div>
                {claimDefinition.rewardsForData?.map(claimData => (
                  <RequestRow key={claimData.title} claimData={claimData} />
                ))}
              </>
            )}

          {
            // if already logged in and has an account, show the accept/decline buttons
            company_name.length ? (
              <AcceptTCs
                dataProcessingAgreement={
                  claimDefinition.dataProcessingAgreement
                }
                declineAction={() => {
                  track('Decline claim', {
                    url: window.location.href,
                  });
                  window.location.href = claimDefinition.dataHolder.url;
                }}
                declineText="Decline"
                acceptAction={() => {
                  track('Accept claim', {
                    url: window.location.href,
                  });

                  if (user) {
                    navigate(redirectUri);
                  } else {
                    loginWithRedirect({
                      screen_hint: 'signup',
                      appState: {
                        returnTo: redirectUri,
                      },

                      redirectUri: window.location.origin + redirectUri,
                    });
                  }
                }}
                acceptText={'Authorize ' + claimDefinition?.dataRequester.name}
              />
            ) : (
              <>
                <div className="pb-3 sm:pb-6 px-5 m-auto text-center  sm:px-6 pt-6 sm:pt-10      ">
                  <div>
                    {claimDefinition.dataRequester.name} gives you{' '}
                    <span className="underline">free access</span> to Vula:
                    <br />
                    your personal one-click, AI assisted applications for loans,
                    grants and more.
                    <br /> Login or signup to continue.
                  </div>
                </div>
                <div className="flex justify-center w-full flex-row items-center">
                  <LoginButton type="signup" customText="Signup" />
                  <div className="mx-2"> </div>
                  <LoginButton type="login" redirectUri={redirectUri} />
                </div>
              </>
            )
            // if not logged in, show the login and signup buttons
          }
        </div>
      </div>
    </div>
  );
}
