import React, { useContext, useState } from 'react';

import { EditPitchContext } from '../../contexts/EditPitchContext';
// components
import Pill from '../utils/Pill';
import PitchPassword from './PitchPassword';
import ConditionalWrapper from '../utils/ConditionalWrapper';
import PulsingDotWrapper from '../utils/PulsingDotWrapper';
// constants
import { SectionHelperMessages } from './SimpleHelperEnums';
import EditablePitchText from '../utils/EditablePitchText';
import { useAuth0 } from '@auth0/auth0-react';

interface Props {
  checkPassphrase: (passphrase: string) => Promise<boolean>;
  contentIsVisible: boolean;
}

export default function PublicPitch(props: Props) {
  const { isAuthenticated } = useAuth0();
  const { getPitchData, publicPitchInfo, privatePitchInfo, investorMode } =
    useContext(EditPitchContext);
  const [width, setWidth] = useState(0);

  const { editMode, updateCorpus, setEditNotificationMessage } =
    useContext(EditPitchContext);

  // on load, if user is logged in, get the pitch data
  React.useEffect(() => {
    if (isAuthenticated) {
      (async () => await getPitchData())();
    }
  }, []);

  const renderTopPillSection = () => {
    return (
      <ConditionalWrapper
        wrapper={children => <PulsingDotWrapper>{children}</PulsingDotWrapper>}
        condition={editMode && !investorMode}
      >
        <Pill>
          <div className="flex flex-row items-center">
            <div
              contentEditable={editMode && !investorMode}
              suppressContentEditableWarning={true}
              onFocus={() =>
                setEditNotificationMessage(SectionHelperMessages.round)
              }
              onBlur={event =>
                updateCorpus('raise_target', event.target.innerHTML)
              }
            >
              {privatePitchInfo?.ourAsk?.raisingTarget
                ? privatePitchInfo?.ourAsk?.raisingTarget
                : ''}
            </div>
            {privatePitchInfo?.ourAsk?.raisingTarget ? (
              <div className="px-2">-</div>
            ) : (
              ''
            )}
            <div
              contentEditable={editMode && !investorMode}
              suppressContentEditableWarning={true}
              onFocus={() =>
                setEditNotificationMessage(SectionHelperMessages.round)
              }
              onBlur={event => updateCorpus('round', event.target.innerHTML)}
            >
              {publicPitchInfo?.series === 'undefined'
                ? 'Seed'
                : publicPitchInfo?.series}
            </div>
          </div>
        </Pill>
      </ConditionalWrapper>
    );
  };

  const renderCompanyPreview = () => {
    function handleImageLoad(
      event: React.SyntheticEvent<HTMLImageElement, Event>,
    ) {
      const imgWidth = event.currentTarget.width;
      setWidth(imgWidth);
    }

    return (
      <div
        className={
          'order-last sm:order-first items-center h-full flex flex-col sm:justify-center sm:px-8 sm:pb-4 w-full'
        }
      >
        {width && width < 500 ? (
          <div className="font-extrabold text-4xl py-4 sm:py-12">
            {publicPitchInfo?.companyName}
          </div>
        ) : null}
        {publicPitchInfo?.logoImg ? (
          <img
            className="sm:max-w-1/2 w-auto h-auto max-h-[50vh] py-8"
            src={publicPitchInfo.logoImg}
            alt={publicPitchInfo.companyName}
            onLoad={handleImageLoad}
          />
        ) : publicPitchInfo?.companyName ? ( // feel like this is redundant due to the above but skipping for now
          <div className="font-extrabold text-4xl py-4 sm:py-12">
            {publicPitchInfo?.companyName}
          </div>
        ) : null}

        <div className="w-full sm:max-w-[50vw]">
          <EditablePitchText
            label="tagline"
            text={publicPitchInfo?.tagline}
            classStyling=" text-xl p-2"
          />
        </div>
        <PitchPassword
          contentIsVisible={props.contentIsVisible}
          checkPassphrase={passphrase => props.checkPassphrase(passphrase)}
        />
      </div>
    );
  };

  return (
    <div
      id="public"
      className={
        'text-center items-center sm:justify-center flex flex-col p-4 sm:p-16 ' +
        (privatePitchInfo?.problem
          ? ' h-full sm:h-[75vh]'
          : ' h-full min-h-[100vh]')
      }
    >
      {renderTopPillSection()}

      <div className="items-center flex  flex-col sm:flex-row w-full h-full px-2 sm:px-6 ">
        {renderCompanyPreview()}
      </div>
    </div>
  );
}
