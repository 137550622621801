import React, { createContext } from 'react';
import { VulaLendingAPI } from '../api/lending';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from './LoggedInContext';
import Loading from '../pages/Loading';

export interface LendingProductApplicationDefinition {
  status?: {
    id: string;
    type: string;
    status: string;
  };
  owner: {
    id: string;
    logo: string;
    name: string;
    url: string;
  };
  id: string;
  image: string;
  name: string;
  slug: string;
  description: string;
  aip_agreement_url?: string;
  sections: LendingProductApplicationSection[];
}

export interface LendingProductApplicationSection {
  id: string;
  type: string;
  title: string;
  subtitle: string;
  order: number;
  stage: string;
  questions: LendingProductApplicationQuestion[];
}

export interface LendingProductApplicationQuestion {
  id: string;
  type: string;
  question: string;
  generation_prompt: string | null;
  additional_info: string | null;
  enable_vula: boolean;
  corpus_type: string | null;
  corpus_subtype: string | null;
  corpus_label: string;
  order: number;
  required: boolean;
  options: string[] | null;
  answer: string | null;
  repeating_label: string | null;
  repeating_index: string | null;
}

interface ContextProps {
  visibleTab: { section_title: string; type: string } | undefined;
  setVisibleTab: React.Dispatch<
    React.SetStateAction<{ section_title: string; type: string } | undefined>
  >;
  lendingProductApplicationDefinition: LendingProductApplicationDefinition | null;
  applicationSectionNames: { section_title: string; type: string }[];
  moveToNextSection: (count: 1 | -1) => void;
  allRequiredAnswersAreCompleted: boolean;
  updateAnswerInApplicationJson: (questionId: string, answer: string) => void;
  updateApplicationStatus?: (status: string) => void;
  removeQuestionFromApplicationJson: (questionId: string) => void;
  addQuestionToApplicationJson: (
    question: LendingProductApplicationQuestion[],
    adjacentQuestionId: string,
  ) => void;
}

export const ApplicationContext = createContext<ContextProps>({
  visibleTab: undefined,
  setVisibleTab: () => void 0,
  lendingProductApplicationDefinition: null,
  applicationSectionNames: [],
  moveToNextSection: () => void 0,
  allRequiredAnswersAreCompleted: false,
  updateAnswerInApplicationJson: () => void 0,
  updateApplicationStatus: () => void 0,
  removeQuestionFromApplicationJson: () => void 0,
  addQuestionToApplicationJson: () => void 0,
});

export const ApplicationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug, isLoading } = React.useContext(LoggedInContext);
  const [isContextLoading, setIsLoading] = React.useState<boolean>(true);

  const [
    lendingProductApplicationDefinition,
    setLendingProductApplicationDefinition,
  ] = React.useState<LendingProductApplicationDefinition | null>(null);

  const [applicationSectionNames, setApplicationSectionNames] = React.useState<
    { section_title: string; type: string }[]
  >([]);
  const [visibleTab, setVisibleTab] = React.useState<
    { section_title: string; type: string } | undefined
  >(applicationSectionNames?.[0]);

  const [allRequiredAnswersAreCompleted, setAllRequiredAnswersAreCompleted] =
    React.useState<boolean>(false);

  // on load retrieve the specific lending product application definition via api
  React.useEffect(() => {
    (async () => {
      await getLendingProductDefinition();
    })();
  }, [company_slug, isLoading]);

  React.useEffect(() => {
    // check if all questions are completed when the application definition changes
    checkIfAllRequiredAnswersAreCompleted();

    // when lending product application definition changes, update the section names
    updateSectionNames(lendingProductApplicationDefinition);
  }, [lendingProductApplicationDefinition]);

  const getLendingProductDefinition = async () => {
    const { partner_name, product_code } = getLendingProductFromUrl();
    const token = await getAccessTokenSilently();
    const api = new VulaLendingAPI({ token });
    const lendingProduct = await api.getLendingProduct(
      company_slug,
      partner_name,
      product_code,
    );
    setLendingProductApplicationDefinition(lendingProduct.data);
    const sectionNames = updateSectionNames(lendingProduct.data);
    setVisibleTab(sectionNames?.[0]);

    setIsLoading(false);
  };

  const getLendingProductFromUrl = () => {
    const url = window.location.href;
    const splitUrl = url.split('/');
    const partner_name = splitUrl[splitUrl.length - 2];
    const product_code = splitUrl[splitUrl.length - 1];
    return { partner_name, product_code };
  };

  const updateSectionNames = (
    lendingProductApplicationDefinition:
      | LendingProductApplicationDefinition
      | undefined
      | null,
  ) => {
    // we assume the sections are already ordered by section_order
    const allSectionNames = lendingProductApplicationDefinition?.sections?.map(
      section => {
        return { section_title: section.title, type: section.type };
      },
    );

    if (!allSectionNames) return;
    const sectionNames =
      allSectionNames.filter(
        (section): section is { section_title: string; type: string } => {
          return section !== undefined && section.section_title.length > 0;
        },
      ) || [];

    setApplicationSectionNames([
      ...sectionNames,
      { type: 'submit', section_title: 'Submit' },
    ]);

    return sectionNames;
  };

  const checkIfAllRequiredAnswersAreCompleted = () => {
    const nonAnsweredQuestions =
      lendingProductApplicationDefinition?.sections.flatMap(section =>
        section.questions.filter(
          question => question.required && !question.answer,
        ),
      );

    // if none are answered set all required answers are completed to true
    if (nonAnsweredQuestions?.length === 0) {
      setAllRequiredAnswersAreCompleted(true);
    }

    // console.log('non-answer', nonAnsweredQuestions);
  };

  const moveToNextSection = (count: 1 | -1) => {
    console.log('visibleTab', visibleTab);
    const currentSectionIndex = applicationSectionNames?.findIndex(
      section => section.section_title === visibleTab?.section_title,
    );
    console.log('currentSectionIndex', currentSectionIndex);
    // if undefined, set to first section
    if (currentSectionIndex === undefined) {
      return setVisibleTab(applicationSectionNames?.[0] || '');
    }
    // if last section, go back to start
    const nextSectionName =
      applicationSectionNames?.[currentSectionIndex + count];
    console.log('nextSectionName', nextSectionName);
    if (!nextSectionName) {
      return setVisibleTab(applicationSectionNames?.[0] || '');
    }
    // else set new tab
    setVisibleTab(nextSectionName);
  };

  const updateAnswerInApplicationJson = async (
    questionId: string,
    answer: string,
  ) => {
    // update the application json that is held in state
    const updatedApplicationJson = JSON.parse(
      JSON.stringify(lendingProductApplicationDefinition),
    ) as LendingProductApplicationDefinition;
    if (!updatedApplicationJson?.sections) return;
    // find the question in the application json sections (dont use flatmap)
    const sectionIndex = updatedApplicationJson?.sections?.findIndex(
      section =>
        section.questions.findIndex(question => question.id === questionId) !==
        -1,
    );
    if (sectionIndex === undefined) return;
    const questionIndex = updatedApplicationJson?.sections?.[
      sectionIndex
    ].questions.findIndex(question => question.id === questionId);
    if (questionIndex === undefined) return;
    updatedApplicationJson.sections[sectionIndex].questions[
      questionIndex
    ].answer = answer;

    setLendingProductApplicationDefinition(updatedApplicationJson);
    checkIfAllRequiredAnswersAreCompleted();
  };

  const removeQuestionFromApplicationJson = async (questionId: string) => {
    // update the application json that is held in state
    const updatedApplicationJson = JSON.parse(
      JSON.stringify(lendingProductApplicationDefinition),
    ) as LendingProductApplicationDefinition;
    if (!updatedApplicationJson?.sections) return;
    // find the question in the application json sections (dont use flatmap)
    const sectionIndex = updatedApplicationJson?.sections?.findIndex(
      section =>
        section.questions.findIndex(question => question.id === questionId) !==
        -1,
    );
    if (sectionIndex === undefined) return;
    const questionIndex = updatedApplicationJson?.sections?.[
      sectionIndex
    ].questions.findIndex(question => question.id === questionId);
    if (questionIndex === undefined) return;
    updatedApplicationJson.sections[sectionIndex].questions.splice(
      questionIndex,
      1,
    );

    setLendingProductApplicationDefinition(updatedApplicationJson);
  };

  const addQuestionToApplicationJson = async (
    questions: LendingProductApplicationQuestion[],
    adjacentQuestionId: string,
  ) => {
    // update the application json that is held in state
    const updatedApplicationJson = JSON.parse(
      JSON.stringify(lendingProductApplicationDefinition),
    ) as LendingProductApplicationDefinition;
    if (!updatedApplicationJson?.sections) return;
    // find the question in the application json sections (dont use flatmap)
    const sectionIndex = updatedApplicationJson?.sections?.findIndex(
      section =>
        section.questions.findIndex(
          question => question.id === adjacentQuestionId,
        ) !== -1,
    );

    if (sectionIndex === undefined) return;

    const questionIndex = updatedApplicationJson?.sections?.[
      sectionIndex
    ].questions.findIndex(question => question.id === adjacentQuestionId);

    if (questionIndex === undefined) return;

    updatedApplicationJson.sections[sectionIndex].questions.splice(
      questionIndex + 1,
      0,
      ...questions,
    );

    setLendingProductApplicationDefinition(updatedApplicationJson);
    checkIfAllRequiredAnswersAreCompleted();
  };

  const updateApplicationStatus = async (status: string) => {
    if (!lendingProductApplicationDefinition?.status?.id) {
      console.log('Error updating application status: no application id');
      return;
    }
    const token = await getAccessTokenSilently();
    const api = new VulaLendingAPI({ token });
    await api.updateStatusOfLendingProductApplicationForCompany({
      application_id: lendingProductApplicationDefinition?.status?.id,
      company_slug,
      status,
    });
    await getLendingProductDefinition();
  };

  return (
    <ApplicationContext.Provider
      value={{
        visibleTab,
        setVisibleTab,
        lendingProductApplicationDefinition,
        applicationSectionNames,
        moveToNextSection,
        allRequiredAnswersAreCompleted,
        updateAnswerInApplicationJson,
        updateApplicationStatus,
        removeQuestionFromApplicationJson,
        addQuestionToApplicationJson,
      }}
    >
      {isContextLoading ? (
        <Loading />
      ) : !isContextLoading && !lendingProductApplicationDefinition ? (
        'No application found'
      ) : (
        children
      )}
    </ApplicationContext.Provider>
  );
};
