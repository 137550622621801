import React from 'react';
import VulaLogo from '../../utils/VulaLogo';
import Spinner from '../../utils/Spinner';

interface Props {
  messages: string[];
}
export default function ReadingBanner(props: Props) {
  const [visibleMessage, setVisibleMessage] = React.useState<string>(
    props.messages[0],
  );

  React.useEffect(() => {
    let i = 0;
    const interval = setInterval(() => {
      if (i < props.messages.length) {
        setVisibleMessage(props.messages[i]);
        i = i + 1;
      } else {
        clearInterval(interval);
      }
    }, 5000); // best guess at how long it'll take to process
    return () => clearInterval(interval);
    // after all are shown stop the interval
  }, [props.messages]);

  return (
    <>
      <div className="sm:w-full">
        <VulaLogo type="animated" size="m" />
      </div>
      <div className="flex justify-center text-center sm:w-full">
        <div className="text-lg text-left flex items-center animate fade-in">
          {visibleMessage}
          <div className="scale-[0.7]">
            <Spinner />
          </div>
        </div>
      </div>
    </>
  );
}
