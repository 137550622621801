export const smeContents = [
  {
    title: 'Match with',
    key: 'matching',
    highlightText: 'funding',
    emoji: '🔮',
    paragraph: `Every day, Vula searches the web for new grants and opportunities. By sharing insights about your business, you enable Vula to identify funding that perfectly matches your needs.`,
    visualContent: {
      poster:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.png',
      src1: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.webm',
      src2: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/MatchWithGrants.mp4',
    },
  },
  {
    title: 'Apply with',
    key: 'applying',
    highlightText: 'ease',
    emoji: '🧈',
    paragraph: `Vula drafts answers for your application forms using your existing business materials. Simply share your website, upload memos, or provide relevant documents. Vula turns your hard work into polished responses—no blank pages needed.`,
    visualContent: {
      poster:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.png',
      src1: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.webm',
      src2: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.mp4',
    },
  },
  {
    title: 'Never repeat yourself',
    key: 'repeating',
    emoji: '🤬',
    paragraph: ` With 90% of applications asking similar questions, Vula acts as your company’s knowledge base, auto-filling answers you’ve already provided. Easily refine and update your responses as your business evolves, enhancing your chances of securing funding.`,
    visualContent: {
      poster:
        'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.png',
      src1: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.webm',
      src2: 'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/generate-answer.mp4',
    },
  },
];
