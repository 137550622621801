import React, { createContext } from 'react';
import {
  LendingProductApplicationSection,
  LendingProductApplicationQuestion,
} from './ApplicationContext';
import { PublicFormsAPI } from '../api/public-forms';
import { track } from '../interfaces/mixpanel';
import { sendNotification } from '../api/monitoring';

export type FormDefinition = {
  partner_logo: string;
  banner_img: string;
  title: string;
  description: string;
  estimated_time: string;
  bg_color: string;
  apply_with_vula_url: string;
  lendingProductApplicationSections: LendingProductApplicationSection[] | null;
  expiry?: string;
  error?: never; // Ensures error doesn't exist in this case
};

type ErrorsType = Record<string, string>;
interface ContextProps {
  partner: {
    partner_name: string | null;
    product_code: string | null;
  };
  formDefinition:
    | FormDefinition
    | {
        error: string;
      }
    | undefined;
  publicFormIsLoading: boolean;
  startApplicationWithUrl: (url: string) => void;
  applicationStepName: ApplicationStepNames;
  company: PublicGeneratedCompany;
  upsertAnswer: (
    question_and_answer: LendingProductApplicationQuestion,
  ) => void;
  errors: ErrorsType;
  setErrors: React.Dispatch<React.SetStateAction<ErrorsType>>;
  getPresignedUrl: ({
    label,
    summary,
    type,
  }: {
    label: string;
    summary: string;
    type: string;
  }) => Promise<string>;
  startApplicationWithUpload: () => void;
  allRequiredAnswersAreCompleted: boolean;
  setApplicationStepName: React.Dispatch<
    React.SetStateAction<ApplicationStepNames>
  >;
  submitApplication: () => void;
  resetForm: () => void;
  urlToScrape?: string; // stored only for resubmit functionality
  resubmitApplicationWithUrl: () => void;
  renew_expired_token: () => Promise<string | void>;
}

export type PublicGeneratedCompany =
  | null
  | undefined
  | {
      logo: string;
      id: string;
      company_slug: string;
      company_full_name: string;
    };
export type ApplicationStepNames =
  | ''
  | 'readingWebsite'
  | 'readingComplete'
  | 'hide'
  | 'readingDocuments'
  | 'completed'
  | 'error'
  | 'error-company-exists'
  | 'partner-is-reviewing';

export const ApplicationContextPublic = createContext<ContextProps>(
  {} as ContextProps,
);

export const ApplicationContextPublicProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const PublicFormsApi = new PublicFormsAPI();

  //---------- State ↓ --------------------------------------------
  const [companySudoToken, setCompanySudoToken] = React.useState<string>('');
  const [company, setCompany] =
    React.useState<PublicGeneratedCompany>(undefined);
  const [applicationStepName, setApplicationStepName] =
    React.useState<ApplicationStepNames>('');
  const [errors, setErrors] = React.useState<ErrorsType>({});
  const [publicFormIsLoading, setPublicFormIsLoading] =
    React.useState<boolean>(true);
  const [partner, setPartner] = React.useState<{
    partner_name: string;
    product_code: string | null;
  }>({
    partner_name: '',
    product_code: null,
  });

  const [formDefinition, setFormDefinition] = React.useState<
    | FormDefinition
    | {
        error: string;
      }
    | undefined
  >(undefined);

  const [allRequiredAnswersAreCompleted, setAllRequiredAnswersAreCompleted] =
    React.useState<boolean>(false);

  const [applicationStatus, setApplicationStatus] = React.useState<
    'new' | 'submitted'
  >('new');

  const [urlToScrape, setUrlToScrape] = React.useState<string | undefined>(
    undefined,
  );

  //---------- Effects  ↓ --------------------------------------------

  // when the company is set, store it in the local storage
  React.useEffect(() => {
    if (companySudoToken) {
      localStorage.setItem('vula-sudo-token', companySudoToken);
    }
  }, [companySudoToken]);

  // on load get the token
  React.useEffect(() => {
    // check if there is a token in the url using params on 'token'
    const urlParams = new URLSearchParams(window.location.search);
    const url_token = urlParams.get('token');
    if (url_token) {
      setCompanySudoToken(url_token);
      localStorage.setItem('vula-sudo-token', url_token);
      (async () => await getApplicationStatus(url_token))();
      setPublicFormIsLoading(true);
    } else {
      // check if there is a token in the local storage
      const token = localStorage.getItem('vula-sudo-token');
      if (token) {
        setCompanySudoToken(token);
        (async () => await getApplicationStatus(token))();
        setPublicFormIsLoading(true);
      }
    }
    // must be a new application so is blank
  }, []);

  // when application status updates, set the application step name
  React.useEffect(() => {
    if (applicationStatus === 'submitted') {
      setApplicationStepName('completed');
    }
  }, [applicationStatus]);

  // onload get the partner and product code from the url
  React.useEffect(() => {
    // the url for public applications can be in the following formats:
    // /apply/:partner_name/:product_code
    // /apply/:partner_name
    // /i/apply/:partner_name/:product_code
    // /i/apply/:partner_name

    // get the partner_name and product_code from the url
    const url = window.location.pathname
      .replace('/i/apply/', '')
      .replace('/apply/', '');
    const url_parts = url.split('/');
    const partner_name = url_parts[0];
    const product_code = url_parts[1] || null;
    setPartner({ partner_name, product_code });
  }, []);

  // get the form definition from the backend, once the partner and product_code are set
  React.useEffect(() => {
    if (companySudoToken) {
      setApplicationStepName('hide');
    } else {
      setApplicationStepName('');
    }

    const { partner_name, product_code } = partner;

    // Prevent fetching form definition if it already exists
    if (
      formDefinition &&
      'lendingProductApplicationSections' in formDefinition &&
      formDefinition.lendingProductApplicationSections?.length
    ) {
      return;
    }

    if (partner_name) {
      // Fetch form definition if partner_name exists
      (async () => {
        setPublicFormIsLoading(true);
        try {
          const response = await PublicFormsApi.getPublicForm({
            partner_name,
            product_code,
          });
          setFormDefinition(response.data);

          // Set partner details in state
          setPartner({
            partner_name: response.data.partner_name,
            product_code: response.data.product_code,
          });

          track('Public Form - loaded', {
            partner_name: response.data.partner_name,
            product_code: response.data.product_code,
          });

          // Load saved answers if available
          const savedAnswers = JSON.parse(
            localStorage.getItem(`formAnswers${partner_name}`) || '{}',
          );
          if (savedAnswers) {
            setFormDefinition(prevFormDefinition => {
              if (!prevFormDefinition || 'error' in prevFormDefinition)
                return prevFormDefinition;

              const updatedSections =
                prevFormDefinition.lendingProductApplicationSections?.map(
                  section => {
                    const updatedQuestions = section.questions.map(question => {
                      const savedAnswer = savedAnswers[question.id];
                      return savedAnswer
                        ? { ...question, answer: savedAnswer.answer }
                        : question;
                    });
                    return { ...section, questions: updatedQuestions };
                  },
                );

              return {
                ...prevFormDefinition,
                lendingProductApplicationSections: updatedSections || null,
              };
            });
          }
        } catch (err) {
          console.error('getPublicForm', err);
          track('Public Form - error loading', {
            partner_name: partner_name,
            product_code: product_code,
          });
          sendNotification(
            `Public Form - error loading
            Partner: ${partner_name}
            Product Code: ${product_code}`,
            'Error loading public form',
          );
          setApplicationStepName('error');
          setFormDefinition(undefined);
        } finally {
          setPublicFormIsLoading(false);
        }
      })();
    }
  }, [partner]);

  // if the form definition changes, check if all required answers are completed
  React.useEffect(() => {
    const savedAnswers = JSON.parse(
      localStorage.getItem(`formAnswers${partner.partner_name}`) || '{}',
    );

    setAllRequiredAnswersAreCompleted(
      checkIfAllRequiredAnswersAreCompleted(savedAnswers),
    );
  }, [formDefinition]);

  //------- Functions ↓ --------------------------------------------

  const checkIfAllRequiredAnswersAreCompleted = (answers: {
    [key: string]: LendingProductApplicationQuestion;
  }) => {
    if (
      formDefinition &&
      'lendingProductApplicationSections' in formDefinition &&
      formDefinition.lendingProductApplicationSections?.length
    ) {
      const questions =
        formDefinition.lendingProductApplicationSections[1]?.questions;

      // Return true if all required questions are answered and have no errors

      return questions.every(q => {
        const isAnswered =
          !q.required || (answers[q.id] && answers[q.id].answer);
        return isAnswered;
      });
    }

    return false;
  };

  const getPrefilledApplicationFormWithToken = async (
    partner_name: string,
    product_code: string,
    token: string,
  ) => {
    await PublicFormsApi.getPrefilledApplicationFormWithToken({
      partner_name,
      product_code,
      token,
    })
      .then(response => {
        setFormDefinition(prevFormDefinition => {
          const newDefinition = response.data;
          return newDefinition;
        });

        // if the company exists, set the company
        if (response.data.company.id) {
          setCompany(response.data.company);
          setApplicationStepName(prev =>
            prev === 'completed' ? prev : 'readingComplete',
          );
        }

        // if there are no questions, then set the application as being reviewed by the partner
        if (
          !response.data.lendingProductApplicationSections ||
          !response.data.lendingProductApplicationSections.length
        ) {
          setApplicationStepName('partner-is-reviewing');
        }

        setPublicFormIsLoading(false);
      })
      .catch(err => {
        console.error('getPrefilledApplicationFormWithToken', err);
        if (err.response.data.error) {
          setFormDefinition({ error: err.response.data.error.toString() });
        }
        setApplicationStepName('error');
      });
  };

  const startApplicationWithUrl = async (url: string) => {
    // store the url to scrape in case the user wants to resubmit
    setUrlToScrape(url);

    if (!partner.partner_name) return console.error('Partner name is not set');
    track('Public Form - reading website', { scraped_url: url });

    setApplicationStepName('readingWebsite');
    // call the endpoint to create  a company
    await PublicFormsApi.startApplication({
      partner_name: partner.partner_name,
      product_code: partner.product_code,
      url,
    })
      .then(response => {
        // store the token
        setCompanySudoToken(response.data.token);

        // update the questions
        setFormDefinition(prevFormDefinition => {
          // The form definition should exist already at this point
          if (!prevFormDefinition) return prevFormDefinition;
          const newDefinition = {
            ...prevFormDefinition,
            lendingProductApplicationSections:
              response.data.companyLendingApplication,
          };

          return newDefinition;
        });

        setCompany(response.data.company);

        setCompanySudoToken(response.data.token);

        // set the application step name to the next step
        setApplicationStepName('readingComplete');
      })
      .catch(err => {
        console.error('startApplicationWithUrl', err);
        if (err.response.data.error === 'Company already registered') {
          track('Public Form - error company exists', {
            partner_name: partner.partner_name,
            product_code: partner.product_code,
            url,
          });
          sendNotification(
            `Public Form - error-company-exists
            Partner: ${partner.partner_name}
            Product Code: ${partner.product_code}
            URL: ${url}`,
            'Company already registered',
          );

          setApplicationStepName('error-company-exists');
        } else {
          track('Public Form - error scraping website', {
            partner_name: partner.partner_name,
            product_code: partner.product_code,
            url,
          });
          sendNotification(
            `Public Form - error scraping website
            Partner: ${partner.partner_name}
            Product Code: ${partner.product_code}
            URL: ${url}`,
            'Company already registered',
          );
          setApplicationStepName('error');
        }
      });
  };

  const resubmitApplicationWithUrl = async () => {
    if (!urlToScrape) return console.error('No url to scrape');
    await startApplicationWithUrl(urlToScrape);
  };

  const startApplicationWithUpload = async () => {
    // a token and dummy company is already created on the backend
    // as such, all we do here is show the different header and call the backend to wait until the docs are processed
    setApplicationStepName('readingDocuments');
    const token =
      companySudoToken || localStorage.getItem('vula-sudo-token') || '';

    if (!token) {
      console.error('No token found');
      return;
    }

    const MAX_WAIT_TIME = 1.5 * 60 * 1000;
    const startTime = Date.now();

    const checkDocuments = async () => {
      try {
        const response = await PublicFormsApi.areAllDocsAnalysed({ token });
        if (response.data) {
          if (partner.partner_name && partner.product_code) {
            await getPrefilledApplicationFormWithToken(
              partner.partner_name,
              partner.product_code,
              token,
            );
          }
          setApplicationStepName('readingComplete');
          return true;
        }
      } catch (err) {
        console.error('startApplicationWithUpload', err);
        sendNotification(
          `Public Form - error reading documents
          Partner: ${partner.partner_name}
          Product Code: ${partner.product_code}
          Token: ${token}`,
          'Error reading documents',
        );
        setApplicationStepName('error');
        return true;
      }
      return false;
    };

    while (!(await checkDocuments())) {
      await new Promise(resolve => setTimeout(resolve, 5 * 1000));
      if (Date.now() - startTime > MAX_WAIT_TIME) {
        setApplicationStepName('error');
        sendNotification(
          `Public Form - error reading documents
          Partner: ${partner.partner_name}
          Product Code: ${partner.product_code}
          Token: ${token}`,
          'Error reading documents',
        );

        return;
      }
    }
  };

  const startApplicationWithoutData = async () => {
    if (!partner.partner_name) return console.error('Partner name is not set');
    if (companySudoToken) {
      return console.error('Company sudo token already set');
    }
    // call the endpoint to create  a company and get a token
    return PublicFormsApi.startApplication({
      partner_name: partner.partner_name,
      product_code: partner.product_code,
    })
      .then(response => {
        // store the token
        setCompanySudoToken(response.data.token);
        setCompany(response.data.company);
        track('Public Form - new user with no data', {
          partner_name: partner.partner_name,
          product_code: partner.product_code,
        });

        return response.data.token;
      })
      .catch(err => {
        track('Public Form - error in new user with no data', {
          partner_name: partner.partner_name,
          product_code: partner.product_code,
        });
        sendNotification(
          `Public Form - error in new user with no data
          Partner: ${partner.partner_name}
          Product Code: ${partner.product_code}
          token: ${companySudoToken}
          `,
          'Error in new user with no data',
        );

        console.error('startApplicationWithoutData', err);
        setApplicationStepName('error');
        return 'error';
      });
  };

  const upsertAnswer = async (
    question_and_answer: LendingProductApplicationQuestion,
  ) => {
    if (!companySudoToken) {
      // hide instructions
      setApplicationStepName('hide');
    }

    // Save the answer to local storage
    const savedAnswers = JSON.parse(
      localStorage.getItem(`formAnswers${partner.partner_name}`) || '{}',
    );
    savedAnswers[question_and_answer.id] = {
      ...question_and_answer,
      answer: question_and_answer.answer,
    };
    localStorage.setItem(
      `formAnswers${partner.partner_name}`,
      JSON.stringify(savedAnswers),
    );

    validateAnswer(
      question_and_answer.id,
      question_and_answer.answer,
      savedAnswers,
    );
  };

  const validateAnswer = (
    questionId: string,
    value: string | null,
    savedAnswers: {
      [key: string]: LendingProductApplicationQuestion;
    },
  ) => {
    // Fetch the question from the provided answers
    const question = savedAnswers[questionId];
    if (!question || value === null) return;

    let error = '';

    // Check for required fields
    if (question.required && !value) {
      error = 'This field is required';
    } else if (
      question.corpus_label &&
      question.corpus_label.includes('email') &&
      !/^[\w.+-]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
    ) {
      error = 'Invalid email format';
    }

    setErrors!(prev => ({ ...prev, [questionId]: error }));
    const check = checkIfAllRequiredAnswersAreCompleted(savedAnswers);
    setAllRequiredAnswersAreCompleted(check);
  };

  const getPresignedUrl = async ({
    label,
    summary,
    type,
  }: {
    label: string;
    summary: string;
    type: string;
  }) => {
    let token = companySudoToken || localStorage.getItem('vula-sudo-token');
    // if the company token is not set, then this must be someone uploading a document before creating a company
    if (!token) {
      token = await startApplicationWithoutData();
    }

    if (!token) return console.error('No token found');

    const presignedRes = await PublicFormsApi.getPresignedUrl({
      token,
      label: label,
      type: type,
      summary: summary,
    });

    return presignedRes.data.url;
  };

  const submitApplication = async () => {
    if (!companySudoToken) {
      console.error('No token found');
      return;
    }

    const token = companySudoToken;
    const allAnswers = JSON.parse(
      localStorage.getItem(`formAnswers${partner.partner_name}`) || '{}',
    ) as Record<string, LendingProductApplicationQuestion>;

    try {
      for (const [id, answer] of Object.entries(allAnswers)) {
        if (answer.type === 'upload') {
          continue;
        }
        await PublicFormsApi.updateAnswer({
          token,
          question_id: id,
          answer: answer.answer,
        });
      }
      // Submit the application after all eligible answers are sent

      await PublicFormsApi.submitApplication({ token });
      setApplicationStepName('completed');
    } catch (err) {
      console.error('submitApplication', err);
      setApplicationStepName('error');
    } finally {
      // Clear the local storage
      localStorage.removeItem(`formAnswers${partner.partner_name}`);
    }
  };

  const getApplicationStatus = async (token: string) => {
    if (!token) {
      token = companySudoToken;
      if (!token) {
        return console.error('No token found');
      }
    }

    // call the endpoint to get the application status
    await PublicFormsApi.getApplicationStatus({ token })
      .then(response => {
        setApplicationStatus(response.data.status);
      })
      .catch(err => {
        console.error('getApplicationStatus', err);
      });
  };

  const resetForm = async () => {
    localStorage.removeItem('vula-sudo-token');
    localStorage.removeItem(`formAnswers${partner.partner_name}`),
      setCompanySudoToken('');
    setCompany(undefined);
    setErrors({});
    setApplicationStepName('');
    // loop through all the questions and set the answers to ""
    setFormDefinition(prevFormDefinition => {
      if (!prevFormDefinition || 'error' in prevFormDefinition)
        return prevFormDefinition;
      const newSections =
        prevFormDefinition.lendingProductApplicationSections?.map(section => {
          const newQuestions = section?.questions.map(question => {
            return {
              ...question,
              answer: '',
            };
          });
          return {
            ...section,
            questions: newQuestions,
          };
        });

      return {
        ...prevFormDefinition,
        lendingProductApplicationSections: newSections || null,
      };
    });
    // clear the token from the url
    const url = new URL(window.location.href);
    url.searchParams.delete('token');
    window.history.replaceState({}, '', url.toString());

    window.location.reload();
  };

  const renew_expired_token = async () => {
    if (!companySudoToken) return console.error('No token found');
    if (!partner.partner_name) return console.error('No partner name found');
    if (!partner.product_code) return console.error('No product code found');

    return await PublicFormsApi.renewExpiry({
      partner_name: partner.partner_name,
      product_code: partner.product_code,
      token: companySudoToken,
    })
      .then(() => {
        // tell the user to check their emails
        return 'Check your email for the new link.';
      })
      .catch(err => {
        console.error('renew_expired_token', err);
        return 'Error renewing token. Please get in touch with us.';
      });
  };

  return (
    <ApplicationContextPublic.Provider
      value={{
        partner,
        formDefinition,
        publicFormIsLoading,
        startApplicationWithUrl,
        applicationStepName,
        setApplicationStepName,
        company,
        errors,
        setErrors,
        upsertAnswer,
        getPresignedUrl,
        startApplicationWithUpload,
        allRequiredAnswersAreCompleted,
        submitApplication,
        resetForm,
        urlToScrape,
        resubmitApplicationWithUrl,
        renew_expired_token,
      }}
    >
      {children}
    </ApplicationContextPublic.Provider>
  );
};
