import React from 'react';
import {
  africanCountries,
  getCountryEmojiFromName,
} from '../../data/countries';

interface Props {
  grant_params: GrantParameter[];
}

interface GrantParameter {
  type: string;
  text: string;
}

export default function GrantParameters(props: Props) {
  const [isAllCountries, setIsAllCountries] = React.useState(false);

  React.useEffect(() => {
    const allCountries = props.grant_params.filter(
      param => param.text === 'All countries',
    );
    if (allCountries.length > 0) {
      setIsAllCountries(true);
    }
  }, [props.grant_params]);

  const renderPill = (param: GrantParameter) => {
    if (param.text === 'All countries') {
      return africanCountries.map(country => {
        return (
          <div
            key={param.text}
            className="min-w-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-2 rounded-full"
          >
            <div className="px-2">
              {country.emoji && country.emoji + ' '}
              {country.name}
            </div>
          </div>
        );
      });
    }
    const emoji = getCountryEmojiFromName(param.text);
    return (
      <div
        key={param.text}
        className="min-w-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-2 rounded-full"
      >
        <div className="px-2">
          {emoji && emoji + ' '}
          {param.text}
        </div>
      </div>
    );
  };
  return (
    <div className="p-4">
      <div className="pl-2 font-extrabold text-2xl">Verticals</div>
      <div className="flex flex-wrap ">
        {props.grant_params &&
          props.grant_params
            .filter(param => param.type === 'vertical')
            .map(param => {
              return renderPill(param);
            })}
      </div>
      <div className="pl-2 pt-4 font-extrabold text-2xl">Geographies</div>
      {isAllCountries && (
        <div className="pl-2 text-lg">
          All African countries are encouraged to apply
        </div>
      )}
      <div className="flex flex-wrap ">
        {props.grant_params &&
          props.grant_params
            .filter(param => param.type === 'geography')
            .map(param => {
              return renderPill(param);
            })}
      </div>
    </div>
  );
}
