import React from 'react';
import {
  capitalizeFirstLetter,
  getRandomNumber,
} from '../../../methods/UIHelpers';
import AlertIcon from '../../Icons/AlertIcon';

interface Props {
  sectionTitle: string;
  action: () => void;
  warningMessage?: string | null;
}

export default function PitchSlidesOverviewCard(props: Props) {
  const [rotations] = React.useState([
    getRandomNumber(360),
    getRandomNumber(360),
    getRandomNumber(360),
  ]);
  return (
    <button
      key={props.sectionTitle}
      onClick={() => props.action()}
      className="hover:scale-[0.99] bg-stone-50 border border-solid border-stone-200 shadow p-2 rounded-lg  min-w-[120px] max-w-[220px] min-h-[100px]  max-h-[130px] relative  group "
      style={{
        background:
          'linear-gradient(' +
          rotations[0] +
          'deg, #DF988D, rgba(255,0,0,0)70.71%),            linear-gradient(' +
          rotations[1] +
          'deg, #BCC0CF, rgba(0,255,0,0) 70.71%),            linear-gradient(' +
          rotations[2] +
          'deg, #E3AB58, rgba(0,0,255,0) 70.71%)',
      }}
    >
      {props.warningMessage ? (
        <div className="absolute top-0 left-0">
          <div className=" text-left group-hover:bg-stone-800 m-1 p-1 rounded-xl text-white text-sm">
            <span className="hidden sm:block sm:group-hover:hidden sm:fill-black">
              <AlertIcon />
            </span>
            <span className="text-xs text-stone-900 sm:text-white sm:group-hover:block sm:hidden sm:px-2">
              {props.warningMessage}
            </span>
          </div>
        </div>
      ) : null}
      <div className="w-full h-full p-2 relative">
        <div className="absolute bottom-0 left-0 sm:left-auto sm:right-0">
          <div className="text-sm font-extrabold">
            {capitalizeFirstLetter(props.sectionTitle)}
          </div>
        </div>
      </div>
    </button>
  );
}
