import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import GrantSummary from './GrantSummary';
import InProgressGrantSummary from './InProgressGrantSummary';
import NewGrantSummary from './NewGrantSummary';
import ArrowIcon from '../Icons/ArrowIcon';
import AwaitingGrantSummary from './AwaitingGrantSummary';
import DeclinedGrantFeedback from './ActionSections/DeclinedGrantFeedback';
import { track } from '../../interfaces/mixpanel';

export default function ActiveGrantReader() {
  const { selectedGrant, defineSelectedGrant } = useContext(GrantContext);

  // on load record view
  React.useEffect(() => {
    track('Active Grant Reader view', {
      grantId: selectedGrant?.grant.id,
    });

    // reset the users view so that the title is at the top of the page
    window.scrollTo(0, 50);
  }, [selectedGrant?.grant.id]);

  return (
    <div
      className={
        'relative  sm:block bg-white w-full border border-1 border-gray-100 border-solid text-left overflow-auto' +
        (selectedGrant ? '' : ' hidden')
      }
    >
      <button
        onClick={() => defineSelectedGrant(undefined)}
        className={
          'sm:hidden p-2 flex flew-row items-center w-full border border-solid border-stone-200 border-t-0 border-x-0 border-b-1'
        }
      >
        <ArrowIcon />
        <div className="px-2 font-extrabold ">Back to inbox</div>
      </button>
      <GrantSummary />
      {selectedGrant?.status === 'new' ? (
        <NewGrantSummary />
      ) : selectedGrant?.status === 'inProgress' ? (
        <InProgressGrantSummary />
      ) : selectedGrant?.status === 'awaiting' ? (
        <AwaitingGrantSummary />
      ) : selectedGrant?.status === 'archived' ? (
        <DeclinedGrantFeedback />
      ) : (
        <div className="w-full flex justify-center text-center flex-col p-4 px-12">
          <div className="pt-8 text-4xl font-extrabold">
            Welcome to your funding inbox
          </div>
          <div className="pt-4">
            Watch the video below to learn how to use your funding inbox
          </div>

          <video
            key={'5GrantInboxTips'}
            controls={true}
            controlsList="nodownload"
            autoPlay
            loop={true}
            muted
            preload="metadata"
            poster={
              'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/5GrantInboxTips.png'
            }
            playsInline={true}
            className="w-full h-full object-cover rounded-xl pt-20"
          >
            {/* <!-- Fallback content for non-supported browsers --> */}
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/5GrantInboxTips.webm'
              }
              type="video/webm"
            />
            <source
              src={
                'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/5GrantInboxTips.mp4'
              }
              type="video/mp4"
            />
            {/* <!-- Provide a download link for users who can't play the video --> */}
            {/* Your browser does not support the video tag. You can{' '}
            <a href="app_intro.mp4">download the video here</a>. */}
          </video>
        </div>
      )}
    </div>
  );
}
