import React, { useContext } from 'react';
import Button from '../utils/Button';
import { MemoContext } from '../../contexts/MemoContext';

export default function GenerateMemoCard() {
  const { generateMemo } = useContext(MemoContext);

  return (
    <div className={' hover:scale-[1.01]  px-2 sm:px-8 py-2'}>
      <div className="bg-white border border-solid border-stone-200 shadow p-2 rounded-lg w-full h-[100px] flex flex-row justify-between items-center text-left">
        <div className="flex flex-row items-center justify-between w-full">
          <div className="pr-2 sm:pr-4 ">
            <div className="text-sm font-extrabold">Generate your memo</div>
            <div className="text-xs pt-2">
              Click to generate a first draft for you to edit
            </div>
          </div>
          <Button
            text="🦾 Generate"
            asynAction={async () => {
              await generateMemo();
            }}
          />
        </div>
      </div>
    </div>
  );
}
