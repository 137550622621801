import React from 'react';
import { track } from '../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';

import { routes } from './routes';
import { Navigate } from 'react-router-dom';

import Loading from './Loading';
import LandingNavbar from '../components/Landing/LandingNavbar';
import LandingHeader from '../components/Landing/LandingHeader';
import CommonTheme from '../components/Landing/CommonTheme';
import JunkDrawer from '../components/Landing/JunkDrawer';
import VideoComponent from '../components/utils/VideoComponent';
import { smeContents } from '../data/sme-contents';
import { MarkIcon } from '../components/Icons/MarkIcon';
import VButton from '../components/utils/Vbutton';
import Footer from '../components/utils/Footer';

export default function LandingPage() {
  const { isAuthenticated, isLoading, error } = useAuth0();

  React.useEffect(() => {
    setTimeout(() => {
      track('Landing Page Loaded', {
        referrer: document.referrer,
      });
    }, 3000);
  }, []);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: routes.private.home,
        }}
      />
    );
  }

  return (
    <div className="h-full min-h-[100vh] m-auto flex items-center flex-col bg-white dot-background px-4">
      <div className="max-w-[2800px] w-full h-full">
        <LandingNavbar />
        <LandingHeader />

        <div id="SMEContent" className="max-w-[820px] mx-auto">
          <CommonTheme />
          <div className="h-[1px] bg-light-gradient my-20 md:mt-40 md:mb-32" />

          {smeContents.map(
            ({
              emoji,
              key,
              paragraph,
              title,
              visualContent,
              highlightText,
            }) => (
              <>
                <div
                  key={key}
                  className="flex flex-col items-center justify-center text-center max-w-[]"
                >
                  <h1 className="text-[36px] lg:text-[52px] font-bold text-dark">
                    {title}{' '}
                    <span className="bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
                      {highlightText}
                    </span>{' '}
                    {emoji}
                  </h1>

                  <div className=" m-auto text-lg max-w-[530px] mt-4">
                    {paragraph}
                  </div>
                  <div className="mt-8 bg-stone-800 rounded-[20px] p-8">
                    <VideoComponent
                      key="matching"
                      poster={visualContent.poster}
                      src1={visualContent.src1}
                      src2={visualContent.src2}
                    />
                  </div>
                </div>
                <div className="h-[1px] bg-light-gradient my-20 md:mt-40 md:mb-32" />
              </>
            ),
          )}

          <div className="md:flex gap-[1px] md:justify-center">
            <div className="flex flex-col items-center justify-center">
              <h1 className="text-[36px] lg:text-[52px] font-bold text-dark">
                Unlock Your{' '}
                <span className="bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
                  Business Potential
                </span>{' '}
                with Vula{' '}
              </h1>

              <p className=" m-auto text-lg mt-4">
                Vula streamlines your application process by auto-filling
                answers from your existing materials, saving you time on
                repetitive questions. Each day, Vula finds new grants and
                funding opportunities tailored to your business. Share insights
                to refine your responses and enhance your chances of securing
                the support you need to thrive.
              </p>
              {[
                'Never repeat yourself',
                'Match with funding',
                'Apply with ease',
              ].map((text, index) => (
                <div key={index}>
                  <div className="flex gap-4 mt-6 items-center justify-center">
                    <p className="flex items-center gap-2 font-semibold text-dark whitespace-nowrap">
                      <span className="h-[36px] w-[36px] bg-btnBackground rounded-[100px] shadow-btnShadow">
                        <span className="flex items-center justify-center pt-[6px]">
                          <MarkIcon />
                        </span>
                      </span>
                      {text}
                    </p>
                  </div>
                </div>
              ))}

              <a
                href={`${routes.public.signup}${window.location.search || ''}`}
                className="mt-10"
              >
                <VButton type="button" text="Get started free" />
              </a>
            </div>
          </div>
        </div>

        <div className="max-w-[1096px] mx-auto mt-72">
          <Footer />
        </div>
      </div>
    </div>
  );
}
