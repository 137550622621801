import React from 'react';

export default function IntroCall() {
  return (
    <div>
      <div className="w-full">
        <h1 className="text-4xl font-bold text-center pt-4">
          Are you an SME funder? <br /> Let's chat.
        </h1>

        <iframe
          src="https://zcal.co/i/PAfkzBPL?embed=1&embedType=iframe"
          loading="lazy"
          className="min-h-[1000px]  "
          style={{
            border: 'none',
            width: '100%',
            height: '100%',
          }}
          id="zcal-invite"
        ></iframe>
      </div>
    </div>
  );
}
