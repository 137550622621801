import React from 'react';
import { GrantContext } from '../../../contexts/GrantContext';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import Button from '../../utils/Button';
import { track } from '../../../interfaces/mixpanel';

export default function DeclinedGrantFeedback() {
  const { company_slug } = React.useContext(LoggedInContext);
  const { selectedGrant, updateGrantStatus, defineSelectedGrant } =
    React.useContext(GrantContext);
  const [feedbackGiven, setfeedbackGiven] = React.useState(false);
  const textRef = React.createRef<HTMLTextAreaElement>();

  // check if the grant is out of date
  const checkGrantOutOfDate = () => {
    if (selectedGrant?.grant.deadline && selectedGrant?.grant.id) {
      const deadline = new Date(selectedGrant?.grant.deadline);
      const today = new Date();
      if (deadline < today) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <div
      className="bg-stone-900 rounded-xl p-4 m-4 sm:m-12"
      key={selectedGrant?.status}
    >
      {checkGrantOutOfDate() ? (
        <div className="flex flex-col py-4">
          <div className="text-white text-xl font-extrabold ">
            This grant's deaadline has passed.
          </div>
          <div>
            <div className="text-white pt-4">
              Was this unexpected or not? Tell us! We use this feedback to help
              grant organisations improve their processes and improve Vula for
              you.
            </div>
            <textarea
              ref={textRef}
              className="w-full h-32 border border-stone-500 rounded-lg p-4 mt-2 bg-stone-100 text-stone-900"
              placeholder="Your feedback"
            />
            <div className="flex flex-col sm:flex-row justify-start pt-4">
              <Button
                text="Submit feedback"
                action={() => {
                  setfeedbackGiven(true);
                  track('Deadline Passed Grant Feedback', {
                    grant: selectedGrant?.grant.id,
                    company: company_slug,
                    feedback: textRef.current?.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      ) : feedbackGiven ? (
        <div className="flex flex-row py-4">
          <div className="text-white text-2xl font-extrabold ">
            Thank you for your feedback.
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-row py-4">
            "<div className="text-white text-4xl font-extrabold ">💪</div>
            <div className="pl-4 text-white text-2xl font-extrabold ">
              Tell us why you don't want to apply.
            </div>
          </div>
          <div className="text-white pt-4">
            We use this feedback to help grant organisations improve their
            processes and improve Vula for you.
            <br /> We want to do better and we want grants to be easier.
          </div>
          <textarea
            ref={textRef}
            className="w-full h-32 border border-stone-500 rounded-lg p-4 mt-2 bg-stone-100 text-stone-900"
            placeholder="Your feedback"
          />
          <div className="flex flex-col sm:flex-row justify-start pt-4">
            <Button
              text="Submit feedback"
              action={() => {
                setfeedbackGiven(true);
                track('Declined Grant Feedback', {
                  grant: selectedGrant?.grant.id,
                  company: company_slug,
                  feedback: textRef.current?.value,
                });
              }}
            />
            <div className="pt-4 sm:pt-0 sm:pl-4">
              <Button
                text="Move back to inbox"
                buttonType="secondary"
                action={async () => {
                  if (updateGrantStatus && selectedGrant) {
                    defineSelectedGrant({
                      ...selectedGrant,
                      status: 'new',
                    });
                    await updateGrantStatus('new');
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
