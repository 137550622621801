import React, { useContext } from 'react';

import { EditPitchContext } from '../../contexts/EditPitchContext';
import IconBulletPoints from '../utils/IconBulletPoints';
import Pill from '../utils/Pill';
import PitchContentContainer from './PitchContentContainer';
import EditablePitchText from '../utils/EditablePitchText';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function OurVisionComponent(props: Props) {
  const { privatePitchInfo } = useContext(EditPitchContext);

  if (!privatePitchInfo?.vision) {
    return null;
  }
  return (
    <PitchContentContainer id={props.id}>
      {typeof privatePitchInfo.vision === 'string' ? (
        <div className="">
          <div className="w-full flex justify-center">
            <Pill>Vision</Pill>
          </div>

          <div className="pt-20">
            <EditablePitchText label="vision" text={privatePitchInfo.vision} />
          </div>
        </div>
      ) : privatePitchInfo.vision.img ? (
        <img src={privatePitchInfo.vision.img} alt="vision doc from founder" />
      ) : privatePitchInfo.vision.bullets ? (
        <>
          <div className="text-4xl font-extrabold">Vision</div>
          <IconBulletPoints bulletPoints={privatePitchInfo.vision.bullets} />
        </>
      ) : null}
    </PitchContentContainer>
  );
}
