import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { VulaCompanyAPI } from '../../api/company';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { attachmentEnums } from '../../enums/pitch';
import { apiUrl } from '../../methods/env';
import UploadZone from './UploadZone';

interface Props {
  image: string;
  label: string;
  altText: string;
}

export default function EditableImageContainer(props: Props) {
  const { company_slug, setCompanyLogo } = useContext(LoggedInContext);
  const { editMode, investorMode, getPitchData } = useContext(EditPitchContext);

  const { getAccessTokenSilently } = useAuth0();

  const [errorText, setErrorText] = useState('');

  const deleteImage = async () => {
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    try {
      const api = new VulaCompanyAPI({ token: auth0Token });

      if (props.label === attachmentEnums.logo) {
        await api.deleteCompanyLogo(company_slug);
      } else {
        await api.deleteAttachment(company_slug, props.label, props.image);
      }
    } catch (error) {
      setErrorText(JSON.stringify(error));
    }
  };

  if (!props.image) {
    return (
      <UploadZone
        label={props.label}
        afterUpload={async url => {
          setCompanyLogo(url);

          await getPitchData();
        }}
      />
    );
  }

  return (
    <div className="relative w-full h-full border border-1 border-solid border-[#00000000] hover:border-gray-300 rounded-xl ">
      <div className="flex justify-center items-center h-full" id={props.image}>
        <img
          className=" object-contain h-full w-full "
          alt={props.altText}
          src={props.image}
          id={props.altText}
        />
        {(editMode && !investorMode) || props.label === attachmentEnums.logo ? (
          <button
            onClick={() => deleteImage()}
            className="m-2 flex items-center justify-center w-full max-w-[20px] max-h-[20px] text-center square rounded-full bg-stone-100 hover:bg-red-400 absolute top-0 right-0 ring-solid ring-1 ring-black"
          >
            <div>&#215;</div>
          </button>
        ) : null}
        {errorText ? (
          <div className="text-sm text-red-500">{errorText}</div>
        ) : null}
      </div>
    </div>
  );
}
