import React, { useContext } from 'react';
import AlertIcon from '../Icons/AlertIcon';
import { ApplicationContextPublic } from '../../contexts/ApplicationContextPublic';
import { formatDate } from '../../methods/UIHelpers';

export default function WarnOfExpiry() {
  const { formDefinition } = useContext(ApplicationContextPublic);

  if (formDefinition && 'error' in formDefinition) {
    return null;
  } else if (
    formDefinition &&
    'expiry' in formDefinition &&
    formDefinition.expiry
  ) {
    return (
      <div className="max-w-[700px] m-auto">
        <div className="bg-white shadow-lg rounded-lg flex items-center border border-stone-100 border-solid border-1">
          <div className="m-4 w-10 h-10 shadow bg-stone-100 rounded-full flex items-center justify-center  ">
            <AlertIcon />
          </div>
          <div className="p-4">
            <h1 className="text-m font-bold">This link will expire</h1>
            <p className="text-sm">
              Your application will expire {formatDate(formDefinition.expiry)}.
              Please save your answers to avoid losing your progress.
            </p>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
