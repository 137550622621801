import React, { useContext } from 'react';
import Button from '../../utils/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import { VulaCompanyAPI } from '../../../api/company';
import { GrantContext } from '../../../contexts/GrantContext';
import ContentSection from '../../utils/ContentSection';
import { track } from '../../../interfaces/mixpanel';

export default function ApplyForAutoGrant() {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = useContext(LoggedInContext);
  const {
    selectedGrant,
    getCompanyGrantMatches,
    updateGrantStatus,
    defineSelectedGrant,
  } = useContext(GrantContext);

  const acceptTerms = async () => {
    if (!selectedGrant?.grant) return null;

    // get token from auth0
    const token = await getAccessTokenSilently();

    // call api to accept terms
    const api = new VulaCompanyAPI({ token });
    try {
      await api.acceptTermsOfGrant(company_slug, selectedGrant.grant.id);
      if (updateGrantStatus) {
        defineSelectedGrant({
          ...selectedGrant,
          status: 'inProgress',
        });
        await updateGrantStatus('inProgress').then(async () => {
          await getCompanyGrantMatches();
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ContentSection background="colourful" centerContent>
      <div className="flex flex-col">
        <div className="flex flex-row py-4 items-center align-center">
          <div className="text-white text-4xl font-extrabold ">✦</div>
          <div className="pl-4 text-white text-2xl font-extrabold ">
            Want Vula to prewrite and submit this grant?
          </div>
        </div>
        <div className="text-white pt-4">
          All you will need to do is review the answers. We will charge a 3.5%
          success fee on the grant amount. You will only pay this fee if you are
          awarded the grant.
          <br />
          <br />
          If you would like Vula to write this grant for you, please click the
          button below.
        </div>
        <div className="flex flex-row items-center align-center pt-8">
          <div className="">
            <Button text="Start grant" asynAction={() => acceptTerms()} />
          </div>
          <div className="pl-8">
            <Button
              text="No thanks"
              buttonType="secondary"
              action={async () => {
                if (updateGrantStatus && selectedGrant) {
                  defineSelectedGrant({
                    ...selectedGrant,
                    status: 'archived',
                  });
                  await updateGrantStatus('archived');
                }
                track('Declined Grant', {
                  grant: selectedGrant?.grant.id,
                  company: company_slug,
                });
              }}
            />
          </div>
        </div>
      </div>
    </ContentSection>
  );
}
