import React, { useState } from 'react';
import Button from '../utils/Button';

interface Props {
  questions: {
    id: string;
    question: string;
    label: string;
  }[];
  updateCorpus: (label: string, value: string, question: string) => void;
  onComplete: () => void;
  fetchApplications: () => void;
}

export default function ApplicationQuestionCriteriaQuestion(props: Props) {
  const { questions, updateCorpus } = props;

  const [visibleQuestions, setVisibleQuestions] = useState(questions);
  const [currentAnswer, setCurrentAnswer] = useState('');

  // when props change update the visible questions
  React.useEffect(() => {
    setVisibleQuestions(questions);
  }, [questions]);

  const handleSubmit = async (index: number) => {
    // remove the question from the array
    setVisibleQuestions(prev => prev.filter((_, i) => i !== index));
    // update the corpus
    await updateCorpus(
      visibleQuestions[index].label,
      currentAnswer,
      visibleQuestions[index].question,
    );

    await props.fetchApplications();
  };

  const skipQuestion = (index: number) => {
    // shuffle this question to the end of the array
    const newQuestions = [...visibleQuestions];
    const question = newQuestions.splice(index, 1)[0];
    newQuestions.push(question);
    setVisibleQuestions(newQuestions);
    return;
  };

  if (visibleQuestions.length === 0) {
    props.onComplete();
  }

  return (
    <div
      className="relative flex justify-center"
      id={'v' + visibleQuestions.length}
      key={'v' + visibleQuestions.length}
    >
      {visibleQuestions.map((question, index) => (
        <div
          id={question.id + question.label}
          key={question.id + question.label}
          className={`max-w-[500px] w-full m-auto bg-white rounded-xl p-6 transition-all transform duration-300 animate-fade-in border border-solid border-1 border-stone-300`}
          style={{
            position: 'absolute',
            zIndex: questions.length - index, // Stacking order
            // Adjust the overlap distance, but if index is over 5 don't overlap just hide it behind the others
            marginTop: `${index > 4 ? 0 : index * 20}px`,
            marginLeft: 'auto',
            marginRight: 'auto',
            // scale the card based on the index, but if index is over 5 don't scale it
            scale: index > 4 ? 0.1 : (1 - index * 0.18).toString(),
          }}
        >
          <p className="text-sm font-bold pb-2 ml-1">{question.question}</p>
          <input
            key={question.id + question.label}
            className="w-full py-2 px-4 bg-gray-200 rounded-md"
            type="text"
            onBlur={e => {
              setCurrentAnswer(e.target.value);
            }}
          />

          <div className="flex flex-row justify-between items-center ">
            <div className="mt-2 flex flex-row items-center">
              <Button asynAction={() => handleSubmit(index)} text="Submit" />
              <button
                onClick={() => skipQuestion(index)}
                className=" text-xs text-stone-500 ml-2 p-3 px-8 hover:no-underline underline rounded-lg hover:bg-stone-100 border border-0.5 border-stone-50 border-solid transition-all duration-300"
              >
                Skip
              </button>
            </div>
            <div className="rounded-full px-4 py-0.5 bg-stone-100 text-xs text-stone-500  ">
              {index + 1} of {visibleQuestions.length}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
