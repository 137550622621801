import React from 'react';
import { routes } from '../../pages/routes';
import VButton from '../utils/Vbutton';

export default function LandingHeader() {
  return (
    <div className=" flex justify-center text-left mt-[180px]">
      <div className="max-w-[1400px] w-full flex justify-center">
        <div className=" flex flex-col items-center justify-center text-center w-full pb-12">
          <div className="flex flex-col items-center justify-center text-center">
            <h1 className="text-[32px] lg:text-[52px] font-bold text-dark">
              African Funding <br />
              <span className="bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
                Fixed.
              </span>
            </h1>

            <div className={' m-auto text-lg max-w-[530px] mt-4'}>
              Vula connects SMEs to funding fast—less time searching, more time
              growing.
            </div>

            <a
              href={`${routes.public.signup}${window.location.search || ''}`}
              className={'mt-4 '}
            >
              <VButton type="button" text="Get started free" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
