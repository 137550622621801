import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '../../components/utils/Button';
import Spinner from '../../components/utils/Spinner';
import { capitalizeFirstLetter } from '../../methods/UIHelpers';
import { track } from '../mixpanel';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';

interface Props {
  type: 'signup' | 'login';
  buttonType?: 'secondary' | 'plain';
  skipWarning?: boolean;
  customText?: string;
  redirectUri?: string;
}
export default function LoginButton(props: Props) {
  const { loginWithRedirect } = useAuth0();
  const [spinnerVis, showSpinnerVis] = useState(false);

  if (spinnerVis) {
    return <Spinner />;
  }

  const signIn = () => {
    if (props.redirectUri) {
      // login the user but take them to this url after
      track(props.type, { redirectUri: props.redirectUri });
      showSpinnerVis(true);
      loginWithRedirect({
        screen_hint: props.type,
        appState: {
          returnTo: props.redirectUri,
        },

        redirectUri: window.location.origin + props.redirectUri,
      });
    } else {
      track(props.type);
      showSpinnerVis(true);
      loginWithRedirect({
        screen_hint: props.type,
        redirectUri:
          window.location.origin + '/onlogin' + window.location.search,
      });
    }
  };

  if (props.buttonType === 'plain') {
    return (
      <button
        className="hover:underline no-underline px-4"
        onClick={() => signIn()}
      >
        {props.type === 'signup' ? 'Get started' : 'Login'}
      </button>
    );
  } else {
    return (
      <Button
        buttonType={props.buttonType}
        text={
          props.customText
            ? props.customText
            : props.type === 'signup'
            ? 'Get started'
            : 'Login'
        }
        action={() => signIn()}
      ></Button>
    );
  }
}
