import React from 'react';
import ButtonCard from './ButtonCard';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../pages/routes';
import ShareIcon from '../../Icons/ShareIcon';

export default function ShareToInvestorsCard() {
  const navigate = useNavigate();

  return (
    <ButtonCard
      context="pitch"
      action={() => {
        navigate(routes.private.sharePitch);
      }}
    >
      <div className="px-4">
        <div className="text-sm font-extrabold">Share your pitch</div>
        <div className="text-xs pt-2">
          Share your pitch with investors by sending them the link and password
        </div>
      </div>
      <div className=" pl-4">
        <div className="text-xs font-extrabold rounded-lg px-4 py-1">
          <ShareIcon />
        </div>
      </div>
    </ButtonCard>
  );
}
