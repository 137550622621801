import React from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import UploadMultiplePublic from '../../utils/UploadMultiplePublic';

export default function CreateACompanyViaPublicForms() {
  const { startApplicationWithUrl, startApplicationWithUpload } =
    React.useContext(ApplicationContextPublic);
  const textInput = React.createRef<HTMLInputElement>();
  const [text, setText] = React.useState('');
  const [uploadedFileNames, setUploadedFileNames] = React.useState<string[]>(
    [],
  );

  // update the text state with the value of the input field
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const checkEnterKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      await submit();
    }
  };

  const submit = async () => {
    if (text.length) {
      await startApplicationWithUrl(text);
    }
  };

  const submitFiles = async (filenames: string[]) => {
    setUploadedFileNames(filenames);
    await startApplicationWithUpload();
  };

  return (
    <>
      <h2 className="text-lg sm:text-xl font-bold pl-2 ">Apply with AI</h2>
      <div className="text-xs sm:text-sm pl-2 ">
        Provide your website or upload a pitch deck and we’ll prefill the form
        with your info.
      </div>

      {/* Interactive - separate into parent component (message bar) and subcomponent of CreateCompanyFromDocsOrUrl */}
      <div className="flex flex-col justify-start bg-white py-3 rounded-xl sm:text-sm text-xs ">
        {/* Website input */}
        <div className="flex flex-row justify-start items-center my-2 px-1 sm:pl-2 ">
          <input
            ref={textInput}
            type="text"
            placeholder="🔎    your-website.com"
            onKeyDown={e => checkEnterKeyPress(e)}
            className=" text-start bg-stone-50 pl-4 sm:pl-8 mr-2 sm:mr-4 w-full p-2 border border-stone-200 border-solid rounded-xl "
            onChange={handleChange}
          />
          <button
            disabled={
              // Enable the button if either the text input has content or files have been uploaded
              !(text.length > 0)
            }
            className={
              'flex items-center rounded-xl w-fit p-2  px-4 bg-stone-900 text-white hover:bg-stone-700 hover:shadow transition-all duration-300 border border-stone-200 border-solid whitespace-nowrap' +
              (!(text.length > 0) // Enable the button if either the text input has content or files have been uploaded
                ? ' opacity-20 cursor-not-allowed'
                : '')
            }
            onClick={async () => {
              await submit();
            }}
          >
            {/* this button submits the website and/or documents for an AI to review and fill out the form questions. Suggest text tht is clear for a user to understand for the button text*/}
            <div className="">
              <div className="font-bold whitespace-nowrap flex items-center">
                Submit
                <span className="sm:block hidden sm:pl-1">
                  for AI to fill out
                </span>
              </div>
            </div>
          </button>
        </div>
        {/* Upload */}
        <UploadMultiplePublic
          label={'onboarding_documents'}
          afterUpload={async filenames => {
            await submitFiles(filenames);
          }}
          fileTypes={['pdf', 'text']}
        />
        <div className="flex flex-col justify-center items-center w-full">
          <div className="hidden sm:block text-xs  pt-1 text-center">
            AI will fill out the form for you
          </div>
        </div>
      </div>
      {/* divider for manual */}
      <div className="flex flex-row justify-center items-center">
        <div className="w-full ml-4 mr-12 border border-1 border-stone-100 border-solid rounded-xl " />
        <div className="flex justify-center items-center text-center w-full sm:text-m text-sm whitespace-nowrap">
          or complete manually
        </div>
        <div className="w-full mr-4 ml-12 border border-1 border-stone-100 border-solid rounded-xl " />
      </div>
    </>
  );
}
