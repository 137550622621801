import React, { useContext } from 'react';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import Spinner from '../../utils/Spinner';

export default function SubmitApplicationPublic() {
  const {
    allRequiredAnswersAreCompleted,
    submitApplication,
    applicationStepName,
    resetForm,
  } = useContext(ApplicationContextPublic);

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  if (
    applicationStepName === 'completed' ||
    applicationStepName === 'readingWebsite' ||
    applicationStepName === 'readingDocuments'
  ) {
    return null;
  }

  return (
    <div className="w-full flex justify-center flex-col">
      <button
        disabled={!allRequiredAnswersAreCompleted || isSubmitting}
        className={
          'flex items-center rounded-xl w-fit m-auto p-3  px-4 bg-stone-900 text-sm text-white hover:bg-stone-700 hover:shadow transition-all duration-300 border border-stone-200 border-solid whitespace-nowrap ' +
          (!allRequiredAnswersAreCompleted
            ? ' opacity-20 cursor-not-allowed'
            : '')
        }
        onClick={async () => {
          setIsSubmitting(true);
          await submitApplication();
          setIsSubmitting(false);
        }}
      >
        {isSubmitting ? (
          <div className="flex justify-center scale-[0.7] px-4">
            <Spinner />
          </div>
        ) : (
          <div className="">
            <div className="font-bold p-1">Apply</div>
          </div>
        )}
      </button>
      {applicationStepName === '' ? null : (
        <button
          onClick={() => {
            resetForm();
          }}
        >
          <div className="underline text-xs hover:text-stone-400 text-stone-300 pt-3 transition-all duration-300">
            Reset form
          </div>
        </button>
      )}
    </div>
  );
}
