import React from 'react';
import JunkDrawer from '../components/Landing/JunkDrawer';
import VulaLogo from '../components/utils/VulaLogo';
import ContentSection from '../components/utils/ContentSection';
import { track } from '../interfaces/mixpanel';
import { sendNotification } from '../api/monitoring';
import { routes } from './routes';

export default function PublicGrantExplainer() {
  const [submitted, setSubmitted] = React.useState(false);

  const emailRef = React.useRef<HTMLInputElement>(null);
  const urlRef = React.useRef<HTMLInputElement>(null);

  const notifyUs = async () => {
    await sendNotification(
      `email: ${emailRef.current?.value}, url: ${urlRef.current?.value}`,
      'New grant submission',
    );
  };

  return (
    <div className="h-full min-h-[100vh] max-w-full m-auto flex items-center flex-col bg-white ">
      <div className=" w-full text-center">
        <div className="w-full bg-stone-900 text-stone-50 text-left py-20 px-4 ">
          <div className="max-w-[800px] m-auto ring ring-8 ring-white ring-solid rounded-2xl bg-stone-900 ">
            <div className="w-full flex flex-col sm:flex-row items-center relative">
              <div className="relative h-full sm:pr-8 w-1/3 flex justify-start sm:justify-end ">
                <div className="h-full z-10 sm:pl-8 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                  <a href={routes.public.os}>
                    <div className="min-h-[100px] sm:min-h-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center  items-center ">
                      <VulaLogo type="light" />
                    </div>
                  </a>
                </div>
              </div>
              <div className="pt-14 sm:py-12  px-2 sm:px-0 w-full flex flex-col text-center sm:text-left justify-center sm:justify-start text-left m-auto ">
                <h1
                  className="text-5xl sm:text-6xl py-4 font-extrabold "
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Grants
                </h1>
              </div>
            </div>
          </div>

          <div className="max-w-[600px] m-auto pt-20">
            <div className="text-4xl font-extrabold">
              Vula makes it easy for grantmakers to match with the most suitable
              African SMEs and startups.
            </div>
            <div className="pt-4">
              Our AI matches and qualifies African founders with new
              opportunities every day, for free.
              <br />
              <br />
              You can add your grant or opportunity to our database by simply
              giving us a link to a webpage with more info about it.
              <br />
              <br />
              If you have a different opportunity to be listed on Vula or want
              to find out how we can help your grant get higher quality
              applications,{' '}
              <a
                href="mailto:nic@vula.vc"
                target="_blank"
                rel="noreferrer"
                className="underline hover:decoration-stone-200 hover:text-stone-200"
              >
                send us an email
              </a>{' '}
              and we’ll get in touch.
            </div>
          </div>
          <div className="max-w-[600px] m-auto pt-4">
            <ContentSection background="colourful" shadow={true}>
              <div className="flex flex-col w-full">
                {submitted ? (
                  <>
                    <div className="text-xl font-bold pb-2">
                      Thank you! Vula will review and we will be in touch to let
                      you know when it is live.
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-2xl text-center w-full m-auto font-bold pb-2">
                      Submit a grant
                    </div>

                    <div className=" pt-4  text-stone-800">
                      <input
                        ref={emailRef}
                        type="text"
                        placeholder="Your email"
                        className="w-full text-stone-500  border bg-stone-200 border-stone-300 rounded-md px-4 py-2 sm:text-md text-sm"
                      />
                    </div>
                    <div className=" pt-4  text-stone-800">
                      <input
                        ref={urlRef}
                        type="text"
                        placeholder="The grant url"
                        className="w-full text-stone-500  border bg-stone-200 border-stone-300 rounded-md px-4 py-2 sm:text-md text-sm"
                      />
                    </div>

                    <div className="pt-2">
                      <button
                        className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                        onClick={async () => {
                          track('Grant submission', {
                            email: emailRef.current?.value,
                            url: urlRef.current?.value,
                          });
                          setSubmitted(true);
                          notifyUs();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            </ContentSection>
            <div className="py-4">
              <div className="text-lg font-bold">
                Grant providers we are currently helping:
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 pt-4">
                {[
                  'https://miyamotointernational.com/wp-content/uploads/usaid-logo.png',
                  'https://lessonresearch.net/wp-content/uploads/2018/08/bill-melinda-gates-foundation-logo-png-transparent.png',
                  'https://www.drupal.org/files/LOGO%20OYW%20%28BLUE%29.png',
                ].map(url => (
                  <div
                    key={url}
                    className="m-auto w-[80%] sm:w-full max-h-[120px] p-2 flex justify-center items-center "
                  >
                    {/* make the image overlap so the center of the image is show and everything else is hidden */}
                    <img
                      src={url}
                      className="filter grayscale hover:grayscale-0 transition-all duration-500 ease-in-out object-cover object-center rounded-md"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Vula OS ad */}
          <a href={routes.public.os} target="_blank" rel="noreferrer">
            <button
              className="flex flex-col sm:flex-row text-left justify-start sm:justify-center sm:items-center mt-32 rounded-xl bg-stone-800 transition-all duration-500 hover:bg-stone-700 p-8 w-full max-w-[1000px] m-auto"
              style={{
                // Set background image to the dot.svg file
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
                /* Set background repeat to repeat both horizontally and vertically */
                backgroundRepeat: 'repeat',
              }}
            >
              <div className=" sm:mx-8 flex justify-start sm:justify-center items-center ">
                <VulaLogo type="os" size="lg" />
              </div>
              <div className="sm:pl-0 pl-2">
                <div className="text-3xl font-extrabold pb-4">
                  80% faster operations?
                </div>
                <div className="">
                  <span className="underline hover:no-underline">
                    Click here
                  </span>{' '}
                  to have a look at VulaOS - the operating system that can save
                  80% of application, review and processing time.
                </div>
              </div>
            </button>
          </a>
        </div>
      </div>

      <div className="w-full">
        <JunkDrawer />
      </div>
    </div>
  );
}
