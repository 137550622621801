import React, { useEffect, useState } from 'react';
import AnimateBulletPoints from './AnimateBulletPoints';

interface Props {
  cardTitles: string[];
}

export default function AnimateCardsEntry(props: Props) {
  const [titles, setTitles] = useState<string[]>(props.cardTitles);

  //   once all titles are rendered show a completed card
  useEffect(() => {
    if (titles.length === props.cardTitles.length) {
      setTitles([...titles, 'Completed']);
    }
  }, [titles]);

  //  step by step show cards with titles, animating each on in on top of the other
  return (
    <div className="relative flex flex-row justify-center items-center w-full min-h-[300px] ">
      {titles.map((title, index) => (
        <div
          key={index}
          className="opacity-0 absolute top-0 w-full flex flex-col justify-center items-center animate-fade-in-up"
          style={{
            animationDelay: 5 * index + 's',
            transitionDelay: 5 * index + 's',
            zIndex: index,
          }}
        >
          <div
            className={`  p-8 bg-white rounded-xl min-h-[250px] w-full shadow flex items-center justify-center `}
          >
            <div className="flex items-center justify-between w-full h-full">
              <div
                className={
                  'flex items-center h-[200px]  m-auto ' +
                  (title === 'Completed' ? ' ' : ' w-1/2')
                }
              >
                {title === 'Completed' ? (
                  <svg
                    className="w-6 h-6 me-2 text-green-500 flex-shrink-0 mr-2"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                  </svg>
                ) : (
                  <img
                    src="https://www.svgrepo.com/show/532762/file-check-alt.svg"
                    alt="file"
                    className="w-14 h-14 mr-2"
                  />
                )}
                <h1 className="text-2xl font-bold text-stone-900 text-left pr-3 truncate">
                  {title}
                </h1>
              </div>
              {title === 'Completed' ? null : (
                <div className="border border-l-1 border-r-0 border-y-0 border-stone-200 border-solid h-[180px] items-center flex w-1/2 pl-8">
                  <AnimateBulletPoints
                    parentDelay={5 * index}
                    key={title}
                    steps={[
                      { title: 'Reading', time: 1 },
                      { title: 'Analyzing', time: 3 },
                      { title: 'Storing', time: 5 },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
