import React, { FC, ReactNode } from 'react';
import LoadingSpinner from '../Icons/LoadingSpinner';

interface buttonProp {
  text: string;
  frontIcon?: ReactNode;
  backIcon?: ReactNode;
  small?: boolean;
  inverted?: boolean;
  disabled?: boolean;
  style?: {
    [key: string]: string;
  };
  loading?: boolean;
  className?: string;
  type: 'submit' | 'button';
  onClick?: () => void;
}
const VButton: FC<buttonProp> = ({
  backIcon,
  text,
  frontIcon,
  onClick,
  type,
  small,
  style,
  inverted,
  disabled,
  className,
  loading,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      type={type}
      className={`${className} flex items-center transition-all duration-700 text-[15px] rounded-[10px] gap-2.5 outline-none shadow-btnShadow whitespace-nowrap border-none font-semibold ${
        small ? 'py-1 px-4 h-6' : 'py-2 px-4 h-8'
      }   
      ${
        inverted
          ? 'bg-white text-black'
          : disabled || loading
          ? 'bg-btnBackground opacity-80 cursor-not-allowed'
          : ' bg-btnBackground text-white hover:bg-btnHover'
      } `}
      disabled={disabled}
    >
      {loading && (
        <span className="chat-spinner">
          <LoadingSpinner />
        </span>
      )}
      {backIcon} {text} {frontIcon}
    </button>
  );
};

export default VButton;
