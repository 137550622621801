import React, { useContext, useEffect, useState } from 'react';
import ButtonCard from './ButtonCard';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaCompanyAPI } from '../../../api/company';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import Spinner from '../../utils/Spinner';
import { EditPitchContext } from '../../../contexts/EditPitchContext';

export default function ShareCard() {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = useContext(LoggedInContext);
  const { getPitchPasswords, pitchPasswords } = useContext(EditPitchContext);

  const [passphrase, cachePassphrase] = useState('');
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const passcodeUpdateInput = React.createRef<HTMLInputElement>();

  useEffect(() => {
    (async () => {
      const pass = await getPitchPasswords();
      if (pass) cachePassphrase(pass);
    })();
  }, []);

  // after 2 seconds hide saved message
  useEffect(() => {
    if (saved) {
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  const updatePitchPassword = async (newPassword: string) => {
    setSaving(true);
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    try {
      if (newPassword) {
        const res = await api.updateCompanyPitchPassword(
          company_slug,
          newPassword,
        );
        if (res.status === 200) {
          cachePassphrase(newPassword);
          await getPitchPasswords();
        }
      } else {
        // console.log('updatePitchPassword', 'no value');
      }

      setSaving(false);
      setSaved(true);
    } catch (error) {
      setSaving(false);
      setSaved(false);
    }
  };

  return (
    <ButtonCard context="pitch" allowWhenNotPublic={true}>
      <div className="w-full text-sm">
        <div className=" font-extrabold pl-2 py-1 ">
          {passphrase.length
            ? 'Edit your current pitch password'
            : 'Set a password for your pitch'}
        </div>
        <div className="flex flex-row  justify-between items-center w-full">
          <input
            className={
              'font-extrabold  p-2 mx-1 rounded-lg w-full text-left border border-solid border-stone-200 bg-stone-50'
            }
            ref={passcodeUpdateInput}
            type="text"
            placeholder={
              typeof pitchPasswords === 'string'
                ? pitchPasswords
                : 'Add a new password here'
            }
            onBlur={async e => {
              const newPassword = e.target.value;
              if (newPassword !== passphrase && newPassword.length > 0) {
                await updatePitchPassword(newPassword);
              }
            }}
            maxLength={20}
          />
          {saving ? <Spinner /> : saved ? <div>Saved</div> : null}
        </div>
      </div>
    </ButtonCard>
  );
}
