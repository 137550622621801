import React from 'react';
import ReactMarkdown from 'react-markdown';

// Define types for our custom components
type CustomComponentProps = {
  node: any;
  children: React.ReactNode;
  [key: string]: any;
};

// Define the props for our MarkdownRenderer component
interface MarkdownRendererProps {
  content: string;
}

export const MarkdownText: React.FC<MarkdownRendererProps> = ({ content }) => {
  const components = {
    h1: ({ node, children, ...props }: CustomComponentProps) => (
      <h1 className="text-xl font-bold mb-4" {...props}>
        {children}
      </h1>
    ),
    h2: ({ node, children, ...props }: CustomComponentProps) => (
      <h2 className="text-lg font-semibold mb-3" {...props}>
        {children}
      </h2>
    ),
    h3: ({ node, children, ...props }: CustomComponentProps) => (
      <h3 className="text-lg font-bold mb-2" {...props}>
        {children}
      </h3>
    ),
    p: ({ node, children, ...props }: CustomComponentProps) => (
      <p className="mb-4" {...props}>
        {children}
      </p>
    ),
    ul: ({ node, children, ...props }: CustomComponentProps) => (
      <ul className="list-disc pl-5 mb-4" {...props}>
        {children}
      </ul>
    ),
    ol: ({ node, children, ...props }: CustomComponentProps) => (
      <ol className="list-decimal pl-5 mb-4" {...props}>
        {children}
      </ol>
    ),
    li: ({ node, children, ...props }: CustomComponentProps) => (
      <li className="mb-1" {...props}>
        {children}
      </li>
    ),
    a: ({
      node,
      children,
      ...props
    }: CustomComponentProps &
      React.AnchorHTMLAttributes<HTMLAnchorElement>) => (
      <a className="text-blue-500 hover:underline" {...props}>
        {children}
      </a>
    ),
    blockquote: ({ node, children, ...props }: CustomComponentProps) => (
      <blockquote className="border-l-4 border-gray-300 pl-4 italic" {...props}>
        {children}
      </blockquote>
    ),
    code: ({
      node,
      inline,
      children,
      ...props
    }: CustomComponentProps & { inline?: boolean }) =>
      inline ? (
        <code className="bg-gray-100 rounded px-1" {...props}>
          {children}
        </code>
      ) : (
        <code className="block bg-gray-100 p-2 rounded" {...props}>
          {children}
        </code>
      ),
  };

  return (
    <ReactMarkdown
      // eslint-disable-next-line
      // @ts-ignore
      components={components}
    >
      {content}
    </ReactMarkdown>
  );
};
