import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function TopicCard(props: Props) {
  return (
    <div className="m-1 my-10  m-auto w-[99%] max-w-[800px] bg-stone-800 rounded-[20px] text-stone-100 text-left p-8">
      {props.children}
    </div>
  );
}
