import React from 'react';
import VulaLogo from '../../utils/VulaLogo';
import { sendNotification } from '../../../api/monitoring';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import Button from '../../utils/Button';

export default function SomethingWrongPublicForm() {
  const { formDefinition, renew_expired_token, resetForm } = React.useContext(
    ApplicationContextPublic,
  );
  const [renew_string, setRenewString] = React.useState<string | null>(null);
  // on load send an email to Alex to fix the issue
  React.useEffect(() => {
    // send email using monitoring endpoint
    (async () => {
      // send email to Alex
      await sendNotification(
        `Error in public form at this url:${window.location.href}  ${
          formDefinition && 'error' in formDefinition
            ? formDefinition.error
            : ''
        }`,
        'Public form error',
        'a',
      );
    })();
  }, []);

  const renewToken = async () => {
    // renew the token
    if (formDefinition && 'error' in formDefinition) {
      if (formDefinition.error === 'Token has expired') {
        const string = await renew_expired_token();
        console.log(string);
        setRenewString(string || 'Something went wrong');
      }
    }
  };

  return (
    <div className="p-8">
      <div className="w-full p-4 bg-white rounded-xl shadow">
        <button
          onClick={
            // navigate to the home page
            () => {
              window.location.href = '/';
            }
          }
        >
          <div className="flex justify-center items-center h-20 ">
            <div className="rotate-[175deg] ">
              <VulaLogo />
            </div>
          </div>
        </button>
        <div className="text-2xl font-bold py-4">Something went wrong!</div>

        {formDefinition && 'error' in formDefinition ? (
          renew_string ? (
            <h3 className="text-center">{renew_string}</h3>
          ) : formDefinition.error === 'Token has expired' ? (
            <div className="flex justify-center w-full pt-4">
              <Button
                text="Request a new link"
                asynAction={async () => {
                  await renewToken();
                }}
              />
            </div>
          ) : (
            <>
              <div className="text-sm text-center">
                We have been notified of the issue.
              </div>
              <div className="text-xs text-center pt-2 ">
                Error code: {formDefinition.error}
              </div>

              <div className="flex justify-center pt-4">
                <Button
                  text="Reset the form"
                  asynAction={async () => {
                    await resetForm();
                  }}
                />
              </div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
}
