import React from 'react';
import GrantApplicationQuestions from './GrantApplicationQuestions';
import LetsGo from './ActionSections/LetsGo';

export default function GrantApplicationEditor() {
  return (
    <>
      <div className="" id="application_header">
        <LetsGo />
      </div>
      <GrantApplicationQuestions />
    </>
  );
}
