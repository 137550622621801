import React, { useContext, useState, useEffect } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { IconBulletList } from '../../data/companies';
import EditablePitchText from './EditablePitchText';

interface Props {
  bulletPoints: IconBulletList[];
  initialStoreInCorpus?: boolean;
  corpusEnum?: string;
}

export default function IconBulletPoints(props: Props) {
  const { updateCorpus } = useContext(EditPitchContext);

  const [bulletPoints, setBulletPoints] = useState<IconBulletList[]>([]);

  // on load store data into state
  useEffect(() => {
    // add textual content to state
    setBulletPoints(props.bulletPoints);
  }, []);

  //  whenever the text content changes update the corpus
  useEffect(() => {
    if (props.corpusEnum && bulletPoints.length) {
      // spot changes to data and update the db
      const answer = JSON.stringify(bulletPoints);
      const previousAnswer = JSON.stringify(props.bulletPoints);
      if (answer === previousAnswer) {
        return;
      }

      updateCorpus(props.corpusEnum, answer);
    }
  }, [bulletPoints]);

  const updateBullets = (
    index: number,
    key: 'text' | 'svgUrl',
    text?: string,
  ) => {
    if (text?.length) {
      const newBulletsData = [...bulletPoints];
      newBulletsData[index][key] = text;
      setBulletPoints(newBulletsData);
    }
  };

  if (props.bulletPoints.length === 0) {
    return null;
  }
  return (
    <div className="">
      {props.bulletPoints.map((point, index) => {
        const context =
          'You are the CEO of %%company_name%%. Write a short list of 3 goals that %company_name% should try achieve to develop the company further. Each point should not be longer than 20 words.' +
          ' Here are 2 of the 3: ' +
          props.bulletPoints
            .filter((item, fIndex) => fIndex !== index)
            .map(point => point.text)
            .toString();

        return (
          <div key={point.text} className="flex flex-row items-center m-2">
            {point.svgUrl ? (
              <div className="m-2 sm:m-8 flex justify-center h-[55px] sm:h-[80px] w-[55px] sm:w-[80px]">
                <img
                  alt={point.svgUrl}
                  className="object-cover  h-[50px] sm:h-[75px] w-[50px] sm:w-[75px]"
                  src={point.svgUrl}
                />
              </div>
            ) : null}
            <EditablePitchText
              text={point.text}
              classStyling="w-full p-2 text-left whitespace-pre-wrap	"
              saveFunction={(text: string) =>
                updateBullets(index, 'text', text)
              }
              context={context}
            />
          </div>
        );
      })}
    </div>
  );
}
