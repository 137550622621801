import React, { useContext } from 'react';
import ButtonCard from '../Pitch/PitchHome/ButtonCard';
import { MemoContext } from '../../contexts/MemoContext';
import Spinner from '../utils/Spinner';

export default function MemoPassword() {
  const { memoIsPublic, memoPassword, updateMemoPassword } =
    useContext(MemoContext);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  // hide saved after 2 seconds
  React.useEffect(() => {
    if (saving) {
      setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saving]);

  const passcodeUpdateInput = React.createRef<HTMLInputElement>();
  return (
    <ButtonCard context="memo">
      <div className="px-4  w-full">
        <div className="text-sm font-extrabold">Your memo password</div>

        <div className="flex flex-row  justify-between items-center w-full">
          <input
            disabled={!memoIsPublic}
            className={
              'font-extrabold p-2 m-1 rounded-lg w-full text-left border border-solid border-stone-200' +
              (memoIsPublic ? ' bg-stone-50' : ' bg-stone-200')
            }
            ref={passcodeUpdateInput}
            type="text"
            placeholder={memoPassword}
            maxLength={20}
            onBlur={async () => {
              if (
                passcodeUpdateInput.current &&
                passcodeUpdateInput.current.value !== memoPassword &&
                passcodeUpdateInput.current.value.length > 0 &&
                typeof passcodeUpdateInput !== 'undefined' &&
                updateMemoPassword
              ) {
                setSaving(true);
                await updateMemoPassword(passcodeUpdateInput.current.value);
                setSaving(false);
                setSaved(true);
              }
            }}
          />
          {!memoIsPublic ? null : saving ? (
            <Spinner />
          ) : saved ? (
            <div className="text-sm px-2">Saved</div>
          ) : null}
        </div>
      </div>
    </ButtonCard>
  );
}
