import React from 'react';
import HalfTextHalfImagePage from '../components/utils/HalfTextHalfImagePage';
import LoginButton from '../interfaces/auth0/LoginButton';

export default function Launch() {
  return (
    <HalfTextHalfImagePage
      heading="Let's get you funded"
      image={'https://vula-assets.s3.eu-west-2.amazonaws.com/cubic.webp'}
      imageShouldFillAllSpace={true}
    >
      <LoginButton type="signup" />
      <br />
      <LoginButton type="login" buttonType="secondary" />
    </HalfTextHalfImagePage>
  );
}
