import React, { useCallback, useContext } from 'react';
import Regenerate from '../utils/Regenerate';
import StarIcon from '../Icons/StarIcon';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import { apiUrl } from '../../methods/env';
import { generateText } from '../../methods/generate';
import { LendingProductApplicationQuestion } from '../../contexts/ApplicationContext';
import UploadQuestions from './QuestionComponents/UploadQuestions';
import { MarkdownText } from '../utils/MarkdownText';
import PublicFormHeader from './Public/PublicFormHeader';
import WarnOfExpiry from './WarnOfExpiry';
import VTextArea from '../utils/VTextArea';
import VButton from '../utils/Vbutton';
import { capitalizeFirstLetter, debounce } from '../../methods/UIHelpers';
import VInput from '../utils/Vinput';
import { SmallSpinner } from '../Icons/SmallSpinner';

interface Props {
  question: LendingProductApplicationQuestion;
  saveAnswer: (answer: string) => void;
  isPublicApplication?: boolean;
  partner_name?: string | null;
  errors?: Record<string, string>;
  setErrors?: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

export default function LendingApplicationQuestion(props: Props) {
  const { question, errors } = props;
  const [showRegen, setShowRegen] = React.useState(false);
  const [rowText, setRowText] = React.useState(question.answer);
  const { company_slug } = useContext(LoggedInContext);
  const [loading, setLoading] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();

  // if company_slug exists allow regeneration
  React.useEffect(() => {
    if (!company_slug) setShowRegen(false);
  }, [company_slug]);

  // when props change update the rowText
  React.useEffect(() => {
    setRowText(question.answer);
  }, [question.answer]);

  const debouncedSaveAnswer = useCallback(
    debounce((text: string) => {
      props.saveAnswer(text);
    }, 500),
    [props.saveAnswer],
  );

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const { value } = e.target;
    setRowText(value);
    debouncedSaveAnswer(value);
  };

  React.useEffect(() => {
    return () => {
      debouncedSaveAnswer.cancel();
    };
  }, [debouncedSaveAnswer]);

  const regenerateText = async () => {
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });

    const answer = await generateText({
      prompt: question?.generation_prompt || '',
      previousText: rowText || '',
      company_slug,
      label: question.corpus_label || '',
      auth0Token,
    });
    setRowText(answer);
    props.saveAnswer(answer);
  };

  return (
    <>
      {showRegen ? (
        <Regenerate
          saveFunction={text => {
            setRowText(text);
            props.saveAnswer(text);
          }}
          previousText={rowText || ''}
          label={question.corpus_label}
          closeFunction={() => setShowRegen(false)}
        />
      ) : (
        <>
          {question.type === 'long_question' ? (
            <div
              className="py-2 pb-6 text-sm sm:text-m max-w-[800px] "
              key={question.id + question.answer}
            >
              <VTextArea
                label={<MarkdownText content={question.question} />}
                onChange={handleChange}
                value={rowText}
                className={`!mb-0 ${
                  errors![question.id] ? '!border-red-500/40 ' : ''
                }`}
              />
              {errors![question.id] && (
                <span className="text-red-600 text-xs mb-3">
                  {errors![question.id]}
                </span>
              )}
              {question.enable_vula && company_slug.length ? (
                <VButton
                  type="button"
                  inverted
                  onClick={async () => {
                    if (!rowText?.length) {
                      await regenerateText();
                    } else {
                      setShowRegen(true);
                    }
                  }}
                  className="px-2 mt-2 flex items-center gap-2 font-semibold cursor-pointer"
                  text="Generate with AI"
                  backIcon={
                    loading ? (
                      <span className="spinner">
                        <SmallSpinner />
                      </span>
                    ) : (
                      <StarIcon />
                    )
                  }
                />
              ) : null}
            </div>
          ) : question.type === 'table' ? (
            <div
              className="py-2 pb-4 text-sm sm:text-m max-w-[800px] "
              key={question.id + question.answer}
            >
              <VInput
                label={capitalizeFirstLetter(question.question)}
                onChange={handleChange}
                value={rowText}
                type={
                  question.corpus_label.includes('email') ? 'email' : 'text'
                }
                containerStyles="!mb-0"
                className={`!mb-0 ${
                  errors![question.id] ? ' !border-red-500/40' : ''
                }`}
              />
              {errors![question.id] && (
                <span className="text-red-600 text-xs inline-block mt-2 mb-3">
                  {errors![question.id]}
                </span>
              )}
              {question.enable_vula && company_slug.length ? (
                <VButton
                  type="button"
                  inverted
                  onClick={async () => {
                    setLoading(true);
                    if (!rowText?.length) {
                      await regenerateText();
                    } else {
                      setShowRegen(true);
                    }
                    setLoading(false);
                  }}
                  className="px-2 mt-2 flex items-center gap-2 font-semibold cursor-pointer"
                  text="Generate with AI"
                  backIcon={
                    loading ? (
                      <span className="spinner">
                        <SmallSpinner />
                      </span>
                    ) : (
                      <StarIcon />
                    )
                  }
                />
              ) : null}
            </div>
          ) : question.type === 'upload' ? (
            <div className="py-4 text-sm sm:text-m " key={question.answer}>
              <UploadQuestions
                key={question.answer}
                question={question}
                removeCardBackground
                saveAnswer={async text => {
                  await props.saveAnswer(text);
                }}
                isPublicApplication={props.isPublicApplication}
              />
            </div>
          ) : question.type === 'apply_with_ai' ? (
            <PublicFormHeader />
          ) : question.type === 'warn_of_expiry' ? (
            <WarnOfExpiry />
          ) : null}
        </>
      )}
    </>
  );
}
