import React from 'react';
import OpenCaretIcon from '../Icons/OpenCaretIcon';
import ClosedCaretIcon from '../Icons/ClosedCaretIcon';
import PencilIcon from '../Icons/PencilIcon';
import { ClaimData } from '../../pages/Claim';

interface Props {
  claimData: ClaimData;
}

export default function RequestRow(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <div>
      <button
        disabled={!props.claimData.description}
        onClick={() =>
          props.claimData.description ? setIsOpen(!isOpen) : null
        }
        className="px-4 sm:px-8 flex flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 items-center hover:bg-stone-100 w-full"
      >
        <div className="w-1/8 flex items-center justify-between">
          {props.claimData.icon.length ? (
            <img
              src={props.claimData.icon}
              alt={props.claimData.title}
              className="w-6"
            />
          ) : (
            <PencilIcon />
          )}
        </div>
        <div className="w-full text-left pl-8 sm:pl-8 text-sm sm:text-md">
          {props.claimData.title}{' '}
        </div>
        <div className="w-1/8 flex justify-end ">
          {props.claimData.description ? (
            isOpen ? (
              <OpenCaretIcon />
            ) : (
              <ClosedCaretIcon />
            )
          ) : null}
        </div>
      </button>
      {props.claimData.description && isOpen && (
        <div className=" text-sm sm:text-md relative bg-stone-100 px-4 sm:px-8 flex flex-row py-2 sm:py-4 border border-1 border-solid border-stone-200 border-x-0 border-t-0 ">
          <div className="w-full">{props.claimData.description}</div>
        </div>
      )}
    </div>
  );
}
