import React, { useState } from 'react';

interface Props {
  children: React.ReactNode;
}

export default function PulsingOutlineWrapper(props: Props) {
  const [pulse, setPulseVis] = useState(true);

  if (!pulse) {
    return (
      <div className="m-1 flex w-full flex-row items-center justify-center">
        <div className="p-1">
          <div className=" px-5 py-3">{props.children}</div>
        </div>
      </div>
    );
  }

  return (
    <div className="m-1 flex w-full flex-row items-center justify-center">
      <button
        onBlur={() => setPulseVis(false)}
        className="animate-border duration-500 inline-block rounded-md bg-transparent bg-gradient-to-br to-[#ffffff00] via-[#ffffff00] from-[#AABBDD] bg-[length:400%_400%] p-1"
      >
        <div className="block rounded-sm bg-transparent px-5 py-3 ">
          {props.children}
        </div>
      </button>
    </div>
  );
}
