import axios from 'axios';
import { apiUrl } from '../methods/env';

export const sendNotification = async (
  message: string,
  subject: string,
  target?: 'a', // 'a' for Alex, 'n' for Nic
) => {
  const url = apiUrl + '/monitoring/notify';

  try {
    const response = await axios.post(url, {
      message: message,
      subject: subject,
      target: target,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
