import { routes } from '../pages/routes';

export const junkDrawLinkns = [
  {
    title: 'For SMEs Funders',
    links: [
      {
        title: 'VulaOS',
        link: routes.public.os,
      },
      {
        title: 'Submit grants',
        link: routes.public.grants,
      },
      {
        title: 'Syndication',
        link: routes.public.syndication,
      },
    ],
  },
  {
    title: 'For SMEs',
    links: [
      {
        title: 'FX',
        link: routes.public.fx,
      },
      {
        title: 'Treasury',
        link: routes.public.deposits,
      },
    ],
  },

  {
    title: 'Vula',
    links: [
      {
        title: 'Master Plan',
        link: routes.public.masterPlan,
      },
      {
        title: 'Careers',
        link: routes.public.careers,
      },
      {
        title: 'White Paper',
        link: routes.public.whitePapers,
      },
    ],
  },
  {
    title: 'Sales',
    links: [
      {
        title: 'Contact Sales',
        link: routes.public.introCall,
      },

      {
        title: 'Request a Demo',
        link: routes.public.demoCall,
      },
    ],
  },
  {
    title: 'Media',
    links: [
      {
        title: 'Press Room',
        link: '/press-room',
      },
    ],
  },
];
