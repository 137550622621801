import React from 'react';
import SectionNavigation from './SectionNavigation';
import WelcomeApplicationPage from './Summary';
import SubmitApplication from './SubmitApplication';
import AIP from './AIP';
import Documents from './Documents';
import CompanyInfo from './CompanyInfo';
import FlexibleSectionOfQuestions from './FlexibleSectionOfQuestions';

interface Props {
  visibleTabState: {
    type: string;
    section_title: string;
  };

  hideSectionNavigation?: boolean;
}

export default function ApplicationSectionQuestions(props: Props) {
  const { visibleTabState, hideSectionNavigation } = props;

  return (
    <>
      {visibleTabState?.type === 'company_info' ? (
        <CompanyInfo />
      ) : visibleTabState?.type === 'document_uploads' ? (
        <Documents />
      ) : visibleTabState?.type === 'aip' ? (
        <AIP />
      ) : visibleTabState?.type === 'submit' ? (
        <SubmitApplication />
      ) : visibleTabState?.type === 'welcome' ? (
        <WelcomeApplicationPage />
      ) : (
        <FlexibleSectionOfQuestions type={visibleTabState?.type} />
      )}
      {/* nav */}
      {visibleTabState?.type === 'submit' ||
      visibleTabState?.type === 'welcome' ||
      !visibleTabState ||
      hideSectionNavigation ? null : (
        <SectionNavigation />
      )}
    </>
  );
}
