import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// auto redirects to auth0 login
export default function Signup() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect({
    screen_hint: 'signup',
    redirectUri: window.location.origin + '/onlogin' + window.location.search,
  });

  return <div></div>;
}
