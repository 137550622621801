import React, { useContext, useEffect, useState } from 'react';
import { VulaPitchAPI } from '../../api/pitch';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../../interfaces/mixpanel';
import VulaLogo from '../utils/VulaLogo';
import EditMode from './EditMode';
import PitchEditCompletedButton from './PitchEditCompletedButton';
import PrivatePitch from './PrivatePitch';
import PublicPitch from './PublicPitch';
import { PageWelcomeMessages } from './SimpleHelperEnums';

export default function PitchSite() {
  // context
  const {
    editMode,
    userIsAdmin,
    setPrivatePitchInfo,
    privatePitchInfo,
    interactiveMessage,
    setPageMessage,
    investorMode,
  } = useContext(EditPitchContext);
  const { isAuthenticated } = useContext(LoggedInContext);

  // state
  const [contentIsVisible, setContentVisibility] = useState(false);

  // onload request pitch data
  useEffect(() => {
    if (!interactiveMessage.length) {
      setPageMessage(PageWelcomeMessages.pitchWelcome);
    }
  }, []);

  // onsubmit, check and retrieve private pitch info
  const checkPassphrase = async (passcode: string) => {
    const api = new VulaPitchAPI();

    if (passcode.length) {
      // call api to get content
      try {
        const slug: string = window.location.pathname
          ? window.location.pathname
              .toString()
              .toLowerCase()
              .replaceAll('/', '')
          : '';
        const res = await api.getPitch({
          slug: slug,
          passphrase: passcode,
        });
        track('Passphrase correct', { passphrase: passcode });

        setPrivatePitchInfo(res.data);
        setContentVisibility(true);
        return true;
      } catch (e) {
        track('Passphrase incorrect', { passphrase: passcode });

        return false;
      }
    }
    return false;
  };

  return (
    <div className=" flex flex-col justify-center items-center max-w-[1500px] h-full min-h-[100vh] m-auto">
      {/* Admin edit mode */}
      {isAuthenticated ? <EditMode /> : null}

      <div className=" sm:max-w-[1400px]">
        {/* Public Pitch */}
        <PublicPitch
          contentIsVisible={contentIsVisible}
          checkPassphrase={async passphrase =>
            await checkPassphrase(passphrase)
          }
        />
        {/* Private Pitch */}
        {privatePitchInfo ? (
          <>
            <PrivatePitch />
            {/* Vula logo or save and back button */}
            {isAuthenticated && userIsAdmin && editMode && !investorMode ? (
              <PitchEditCompletedButton />
            ) : (
              <a className="w-full" href="/">
                <div className="rounded-xl w-full flex justify-center bg-stone-50 hover:bg-gray-200 transition-all duration-500">
                  <VulaLogo />
                </div>
              </a>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
