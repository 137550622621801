import React from 'react';
import { capitalizeFirstLetter } from '../../methods/UIHelpers';

interface Props {
  company_name: string;
  logo?: string;
}

export default function InvestorMemoHeader(props: Props) {
  return (
    <div className=" w-full m-auto flex flex-col justify-center items-center ">
      {props?.logo ? (
        <img
          className="px-4 max-h-[60px] sm:max-h-[100px]"
          src={props.logo}
          alt={props.company_name}
        />
      ) : null}

      <div className="mt-4 text-center text-lg sm:text-3xl font-extrabold   ">
        {capitalizeFirstLetter(props.company_name) + "'s investor memo"}
      </div>
    </div>
  );
}
