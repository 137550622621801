import React, { useContext } from 'react';
import { capitalizeFirstLetter } from '../../methods/UIHelpers';
import { EditPitchContext } from '../../contexts/EditPitchContext';

import { MemoContext, MemoItem } from '../../contexts/MemoContext';
import EditableMemoText from './EditableMemoText';
import InvestorMemoHeader from './InvestorMemoHeader';
import MemoPublicPassword from './MemoPublicPassword';
import { LoggedInContext } from '../../contexts/LoggedInContext';

interface Props {
  isEditable?: boolean;
  memoItems?: MemoItem[];
  company_name: string;
  logo?: string;
  checkPasscode?: (passcode: string) => Promise<boolean>;
}

export default function InvestorMemo(props: Props) {
  const { company_slug } = useContext(LoggedInContext);
  const { updateCorpus } = useContext(EditPitchContext);
  const { getMemo } = useContext(MemoContext);

  // state
  const [showContent] = React.useState(false);

  return (
    <div className="m-2 sm:max-h-[80vh] h-full bg-white m-auto relative rounded-xl shadow-2xl overflow-scroll  ">
      <div className="p-4 sm:p-12">
        <InvestorMemoHeader
          company_name={props.company_name}
          logo={props.logo}
        />
        {props.isEditable ? null : !props.memoItems?.length ? (
          // ask investor for password
          <MemoPublicPassword
            contentIsVisible={showContent}
            checkPassphrase={async passcode =>
              props.checkPasscode ? await props.checkPasscode(passcode) : false
            }
          />
        ) : null}
        <table className="table-auto text-left text-sm">
          <tbody>
            {props.memoItems?.map(item => (
              <tr key={item.title}>
                <td className=" bg-stone-100 border-0 border-b-2 border border-solid border-stone-200 px-1 sm:px-4 text-sm sm:text-m py-2">
                  {typeof item.title === 'string'
                    ? capitalizeFirstLetter(item.title)
                    : item.title}
                </td>
                <td className="w-full border border-0 border-b-2 border border-solid border-stone-200  ">
                  {props.isEditable ? (
                    <EditableMemoText
                      key={item.answer}
                      text={item.answer}
                      label={item.label}
                      saveAnswer={async text => {
                        await updateCorpus(item.label, text);
                        await getMemo(company_slug);
                      }}
                    />
                  ) : (
                    <div className="px-4 py-2">{item.answer}</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
