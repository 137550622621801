import React, { useContext, useState } from 'react';
import PitchSite from '../components/Pitch/PitchSite';
import VulaLogo from '../components/utils/VulaLogo';
import { PublicWelcomePage } from '../data/companies';
import Page404 from './404';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';
import { VulaPitchAPI } from '../api/pitch';
import { useAuth0 } from '@auth0/auth0-react';

export default function ExamplePitch() {
  // state
  const [show404, setShow404] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fadeLoadingOut, setFadeOut] = useState(false);
  const [publicInfo, setPublicInfo] = useState<PublicWelcomePage | undefined>(
    undefined,
  );
  const [company_slug] = useState<string>(
    window.location.pathname.split('/')[1],
  );

  const { isLoading } = useContext(LoggedInContext);

  const { user } = useAuth0();

  const getPublicPitch = async () => {
    setLoading(true);
    const api = new VulaPitchAPI();

    // on page load call the api to get public data
    try {
      const res = await api.getPublicPitch({ slug: company_slug });
      setPublicInfo(res.data);
      track('Open pitch ' + company_slug);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      if (e.response.status === 404) {
        // route to 404 page
        setShow404(true);
        track('Incorrect pitch url', { company_slug });
      } else {
        console.log('when can this happen');
      }
    }
    setLoading(false);
  };

  // on load get public pitch
  React.useEffect(() => {
    (async () => await getPublicPitch())();
  }, []);

  if (loading || isLoading) {
    //  start logo fading out
    setTimeout(() => {
      setFadeOut(true);
    }, 1000);
    // then remove it
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    return (
      <div
        className={
          'h-[100vh] w-full flex justify-center items-center transition-all duration-600' +
          (fadeLoadingOut ? ' opacity-0' : '')
        }
      >
        <div className="animate-bounce h-[20px] ">
          <VulaLogo />
        </div>
      </div>
    );
  }

  if (show404) {
    return <Page404 />;
  }

  if (publicInfo) {
    return <PitchSite />;
  }

  return <Page404 />;
}
