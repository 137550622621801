import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import { CompanyParameter } from '../../types/Grants';

interface Props {
  parameter: CompanyParameter;
}

export default function ParamPill(props: Props) {
  const { deleteParam, getCompanyParams } = useContext(GrantContext);
  const { text } = props.parameter;
  if (!props.parameter) {
    return null;
  }
  return (
    <div className="min-w-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-2 rounded-full">
      <div className="px-2">{text}</div>
      <button
        onClick={async () => {
          await deleteParam(props.parameter.id);
          await getCompanyParams();
        }}
        className=" w-[25px] h-[25px] square rounded-full hover:bg-red-300 hover:text-white text-center"
      >
        &#215;
      </button>
    </div>
  );
}
