import React, { useContext } from 'react';
import { LendingProductApplicationQuestion } from '../../../contexts/ApplicationContext';
import { capitalizeFirstLetter } from '../../../methods/UIHelpers';
import EditableMemoText from '../../Memo/EditableMemoText';

interface Props {
  question: LendingProductApplicationQuestion;
  saveAnswer: (answer: string) => void;
}
export default function TableItemsQuestion(props: Props) {
  const { question } = props;
  return (
    <tr className="w-full" key={question.id + question.answer}>
      <td className=" bg-stone-50 border-0 border-b-2 border border-solid border-stone-200 px-4 py-2 w-1/2">
        {capitalizeFirstLetter(question.question)}
      </td>
      <td className="border border-0 border-b-2 border border-solid border-stone-200 w-1/2 ">
        <EditableMemoText
          text={question.answer || ''}
          label={question.corpus_label || ''}
          saveAnswer={async text => {
            await props.saveAnswer(text);
          }}
          disableRegen={!question.enable_vula}
          generationPrompt={question.generation_prompt || ''}
        />
      </td>
    </tr>
  );
}
