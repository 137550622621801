import React from 'react';
import PublicMemo from '../components/Memo/PublicMemo';
import Pitch from './Pitch';
import Page404 from './404';

export default function CustomUrlNavigator() {
  // if url contains /memo navigate to memo page
  if (window.location.pathname.includes('/memo')) {
    return <PublicMemo />;
  }

  // if a url has no additional / then it is a pitch page
  if (window.location.pathname.split('/').length === 2) {
    // note that you can be logged in and viewing a pitch page or can be logged out and viewing a pitch page
    return <Pitch />;
  }

  return <Page404 />;
}
