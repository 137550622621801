import React from 'react';

export default function WhitePaper1() {
  // redirect the user to the s3 url
  React.useEffect(() => {
    window.location.href =
      'https://vula-assets.s3.eu-west-2.amazonaws.com/WP1.pdf';
  }, []);

  return null;
}
