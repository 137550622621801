import React, { useContext, useEffect } from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import InvestorMemo from '../components/Memo/InvestorMemo';
import GenerateMemoCard from '../components/Memo/GenerateMemoCard';
import ShareMemoCard from '../components/Memo/ShareMemoCard';
import MemoPassword from '../components/Memo/MemoPassword';
import MemoPublicity from '../components/Memo/MemoPublicity';
import Loading from './Loading';

import { MemoContext } from '../contexts/MemoContext';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';
import { EditPitchContext } from '../contexts/EditPitchContext';

export default function Memo() {
  const memo = useContext(MemoContext);
  const editPitch = useContext(EditPitchContext);
  const loggedin = useContext(LoggedInContext);

  // on load, record view
  React.useEffect(() => {
    track('Memo view');
  }, []);

  useEffect(() => {
    if (loggedin.company_slug) {
      (async () => {
        await memo.getMemo(loggedin.company_slug);
        await memo.getMemoPassword();
      })();
    }
  }, [loggedin.company_slug]);

  if (loggedin.isLoading || memo.isLoading) return <Loading size="full-page" />;

  return (
    <div>
      <HomeNavBar />
      <div className="flex flex-col sm:flex-row max-w-[1200px] sm:p-8 m-auto min-h-full ">
        <div className=" flex flex-col sm:flex-row justify-between  min-h-[85vh] sm:w-full">
          <div
            className={
              ' flex flex-col justify-between sm:pb-12 h-full sm:pr-12  sm:w-1/2'
            }
          >
            <div className="text-left flex flex-col ">
              <div className="text-left text-xl sm:text-5xl font-extrabold py-4 px-2">
                Your Investor Memo
              </div>
              <div className="text-left text-sm  py-4 px-2 ">
                Pitch decks are often ignored, misunderstood or misinterpreted.
                The average investor spends less than 3 minutes reading a pitch
                deck.
                <br />
                <br /> A memo is a short document that explains your business
                and why it is a good investment. It is a great way to
                communicate your business to investors and it is a great way to
                get feedback on your business.
                <br />
                <br />
                <b>
                  Remember Vula only prewrites, and can make up numbers. You
                  must be able to justify and prove all numbers and statements.
                </b>
              </div>
            </div>
            <div>
              {!memo.memoItems?.length ? (
                <GenerateMemoCard />
              ) : (
                <>
                  <MemoPublicity />
                  <MemoPassword />
                  <ShareMemoCard />
                </>
              )}
            </div>
          </div>
          {memo.memoItems?.length ? (
            <div className=" sm:w-1/2 py-2">
              <InvestorMemo
                company_name={memo.memoCompanyName || ''}
                logo={editPitch.publicPitchInfo?.logoImg}
                memoItems={memo.memoItems}
                isEditable={true}
              />
            </div>
          ) : (
            <div className="sm:w-1/2"></div>
          )}
        </div>
      </div>
    </div>
  );
}
