import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import NewTabIcon from '../components/Icons/NewTabIcon';
import KeyInfoTabs from '../components/InvestorsViews/KeyInfoTabs';
import VulaLogo from '../components/utils/VulaLogo';
import { convertToReadableNumber } from '../methods/UIHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { track } from '../interfaces/mixpanel';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { routes } from './routes';

export default function InvestorsDataroomView() {
  // get user metadata from auth0
  const { user } = useAuth0();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(true);

  // on load record view
  React.useEffect(() => {
    track('Home view');
    // check if user is investor
    if (user) {
      // this needs to be configured in auth0 actions
      const isInvestor = user['https://vula.vc/user_metadata.isInvestor'];

      if (isInvestor) {
        track('Investor view');
      } else {
        track('Non-investor view');

        navigate(routes.private.home);
      }
      setIsLoading(false);
    }
  }, []);

  const renderProgressBar = (target: number, current: number) => {
    const percentage = (current / target) * 100;
    return (
      <div className="relative pt-2">
        <div className="h-5 bg-stone-400 rounded-full w-full">
          <div
            className="h-5 bg-green-300 rounded-full ring ring-1 ring-green-300 ring-solid shadow-lg text-right pr-4 text-stone-700 text-sm"
            style={{ width: `${percentage}%` }}
          >
            ${convertToReadableNumber(current)}
          </div>
        </div>
        <div className="text-stone-900 text-right pt-1">
          ${convertToReadableNumber(target)} target
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Loading size="full-page" />;
  }

  // hard coding Vula for now
  return (
    <div className="pb-12">
      <HomeNavBar />
      <div className=" max-w-[1200px] sm:w-[90%] m-auto px-2 sm:px-20 text-left pt-12">
        <div className="flex items-center">
          <div className="m-3 w-40">
            <VulaLogo size="lg" />
          </div>
          <div className="flex flex-col text-left">
            <h1 className="text-4xl sm:text-7xl font-extrabold">Vula</h1>
            <h3 className="sm:text-lg pt-4">
              The digital fundraising assistant for African SMEs and SME
              funders.
            </h3>
          </div>
        </div>
      </div>

      <div className="max-w-[1400] sm:w-[90%] m-auto pt-8">
        <video
          src="https://vula-assets.s3.eu-west-2.amazonaws.com/temp_investors_view_vula/h264_1080_best.mp4"
          controls
          autoPlay
          muted
          className="w-full rounded-xl shadow-lg p-1"
        />
      </div>

      <div className=" max-w-[1250px] sm:w-[90%] m-auto px-1 sm:px-20 text-left ">
        <div
          className="w-full rounded-xl p-8 bg-stone-900 shadow-lg mt-8 "
          style={{
            // Set background image to the dot.svg file
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
            /* Set background repeat to repeat both horizontally and vertically */
            backgroundRepeat: 'repeat',
          }}
        >
          <div className="flex justify-between items-center">
            <img
              src="https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg"
              className="rounded-full w-28 sm:w-40 h-28 sm:h-40 mr-8"
            />
            <h3 className="sm:order-first text-3xl sm:text-5xl text-stone-100">
              Join Vuyisa's Angel Syndicate
            </h3>
          </div>

          <div className="flex justify-between flex-col sm:flex-row ">
            <div className="pt-12 text-lg text-white  text-right sm:w-1/2">
              "This is the most exciting company I have seen for a long time. I
              am so excited to be part of this journey." - Vuyisa Qabaka
            </div>
            <div className="sm:order-first flex flex-col ">
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className="bg-stone-100 text-stone-900 rounded-lg p-4 mt-4 w-fit flex items-center justify-center"
              >
                <img
                  src="https://www.svgrepo.com/show/475692/whatsapp-color.svg"
                  className="w-4 h-4"
                />
                <div className="pl-2">Chat with Vu</div>
              </a>
              <a
                href="https://chat.whatsapp.com/LQGARiqqSjr9CUs3AcReQ7"
                target="_blank"
                rel="noreferrer"
                className="bg-stone-100 text-stone-900 rounded-lg p-4 mt-4 w-fit flex items-center justify-center"
              >
                <img
                  src="https://www.svgrepo.com/show/475692/whatsapp-color.svg"
                  className="w-4 h-4"
                />
                <div className="pl-2">Join WhatsApp discussion group</div>
              </a>
              {/* button saying Soft commit */}
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className="bg-stone-100 text-stone-900 rounded-lg p-4 mt-4 w-fit flex items-center justify-center"
              >
                <img
                  src="https://www.svgrepo.com/show/502648/email.svg"
                  className="w-4 h-4"
                />
                <div className="pl-2">Soft commit to invest</div>
              </a>
              <a
                href=""
                target="_blank"
                rel="noreferrer"
                className="bg-stone-100 text-stone-900 rounded-lg p-4 mt-4 w-fit flex items-center justify-center"
              >
                <img
                  src="https://www.svgrepo.com/show/502648/email.svg"
                  className="w-4 h-4"
                />
                <div className="pl-2">Buy-in to syndicate</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className=" max-w-[1250px] sm:w-[90%] m-auto px-2 sm:px-20 text-left ">
        <div
          className="w-full rounded-xl p-8 bg-stone-100 shadow-lg mt-8 "
          style={{
            // Set background image to the dot.svg file
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"><circle cx="10" cy="10" r="0.5" fill="%238f8f8f" /></svg>')`,
            /* Set background repeat to repeat both horizontally and vertically */
            backgroundRepeat: 'repeat',
          }}
        >
          <div className="flex flex-col text-left ">
            <h3 className="text-5xl text-stone-900">Vula is raising $2M</h3>
          </div>
          <div className="text-lg pt-4">Committed funding</div>

          {/* Progress bar */}
          {renderProgressBar(2000000, 1000000)}

          <div
            className="
          grid grid-cols-1 sm:grid-cols-2 gap-2 pt-4 text-stone-900
          "
          >
            {[
              { text: 'Vula pitch deck', link: '' },
              { text: 'Vula financials', link: '' },
              { text: 'Vula cap table', link: '' },
              { text: 'Vula term sheet', link: '' },
              { text: 'Vula due diligence', link: '' },
              { text: 'Vula data room', link: '' },
              { text: 'Vula investment memo', link: '' },
              { text: 'Vula SAFE agreement', link: '' },
            ].map(({ text, link }) => (
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                className="underline bg-stone-100  hover:bg-stone-300 transition-all duration-500 text-stone-900 rounded flex items-center px-4 py-2 w-full border border-solid border-1 border-stone-200"
              >
                {text}{' '}
                <span className="scale-[0.7]">
                  <NewTabIcon />
                </span>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className=" max-w-[1250px] sm:w-[90%] m-auto px-2 sm:px-20 text-left ">
        <KeyInfoTabs />
      </div>
    </div>
  );
}
