import React from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import { ApplicationContext } from '../contexts/ApplicationContext';

import ApplicationHeader from '../components/Applications/ApplicationHeader';
import ApplicationSectionQuestions from '../components/Applications/ApplicationSectionQuestions';
import Loading from './Loading';

export default function ApplicationPage() {
  const { visibleTab, lendingProductApplicationDefinition } =
    React.useContext(ApplicationContext);

  // need to store visible tab in state to re-render the component when the tab changes
  const [visibleTabState, setVisibleTabState] = React.useState(visibleTab);

  React.useEffect(() => {
    setVisibleTabState(visibleTab);
  }, [visibleTab]);

  if (visibleTabState === undefined) {
    return <Loading />;
  }

  if (lendingProductApplicationDefinition?.status?.status === 'submitted') {
    return (
      <div>
        <HomeNavBar />
        <div className=" max-w-[1500px] sm:w-[90%] m-auto px-2 sm:px-20 text-left pb-4">
          <div className="sm:h-4"></div>
          <div className="rounded-xl shadow-xl overflow-hidden bg-white max-w-[800px] m-auto p-2 sm:p-4">
            <div className="w-full sm:w-auto py-12  flex flex-row justify-center sm:justify-start items-center">
              <img
                src={lendingProductApplicationDefinition?.owner.logo}
                alt="logo"
                className="min-w-20 min-h-20 max-w-32 max-h-12 m-auto drop-shadow  "
              />
            </div>
            <h1 className="text-center text-2xl font-bold text-green-500 pb-12">
              Application Submitted
            </h1>
            <p className="text-center text-lg pb-12 ">
              Your application has been submitted. <br />
              You will be notified of the decision soon.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <HomeNavBar />
      <div className=" max-w-[1500px] sm:w-[90%] m-auto px-2 sm:px-20 text-left pb-4">
        <ApplicationHeader />

        <div className="sm:h-4"></div>
        <div className="rounded-xl shadow-xl overflow-hidden bg-white max-w-[1500px] m-auto p-2 sm:p-4">
          <ApplicationSectionQuestions visibleTabState={visibleTabState} />
        </div>
      </div>
    </div>
  );
}
