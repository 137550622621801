import React, { ReactNode, useContext } from 'react';
import ClaimLogoHeader from './ClaimLogoHeader';
import { ClaimContext } from '../../contexts/ClaimContext';
import Loading from '../../pages/Loading';
import VulaLogo from '../utils/VulaLogo';

interface Props {
  children: ReactNode;
  title: ReactNode;
  completed?: boolean;
}

export default function ClaimPageTemplate(props: Props) {
  const { codeLoading, showCodeError, claimDefinition } =
    useContext(ClaimContext);

  if (codeLoading) {
    <Loading size="full-page" />;
  }

  if (showCodeError) {
    return (
      <div className="min-w-[100vw] min-h-[100vh] h-full w-full bg-stone-800 p-2 sm:p-8 flex items-center justify-center">
        <div className="rounded-2xl bg-stone-100 shadow-2xl p-4 sm:p-12 w-[95vw] sm:w-[55vw] ">
          <div className="w-full flex justify-center rotate-[155deg] ">
            <VulaLogo />
          </div>
          <div className="text-4xl font-bold text-center py-12">Oops!</div>
          <div>Something is wrong with this code. Sorry!</div>
        </div>
      </div>
    );
  }

  if (!claimDefinition) {
    return <Loading size="full-page" />;
  }

  return (
    <div className="min-w-[100vw] min-h-[100vh] h-full w-full bg-stone-800 p-2 sm:p-8 flex items-center justify-center">
      <div className="rounded-2xl bg-stone-100 shadow-2xl p-4 sm:p-12 w-[95vw] sm:w-[50vw] ">
        <ClaimLogoHeader {...claimDefinition} completed={props.completed} />

        {props.title}

        <div className="text-left bg-stone-50 w-full rounded-xl p-4 sm:p-8 shadow">
          {props.children}
        </div>
      </div>
    </div>
  );
}
