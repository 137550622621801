import React, { useState } from 'react';

interface Props {
  children: React.ReactNode;
}

export default function PulsingDotWrapper(props: Props) {
  const [pulseVis, setPulseVis] = useState(true);

  return (
    <div className="relative  mx-5 my-3" onClick={() => setPulseVis(false)}>
      <div className="absolute top-0 right-0">
        {pulseVis ? (
          <span className="relative flex h-3 w-3">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full  bg-gradient-to-br to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]   opacity-75"></span>
            <span className="relative inline-flex rounded-full h-3 w-3  bg-gradient-to-br to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]   "></span>
          </span>
        ) : null}
      </div>
      <div className="">{props.children}</div>
    </div>
  );
}
