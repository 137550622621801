import React, { useContext, useState } from 'react';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import { apiUrl } from '../../methods/env';
import Spinner from './Spinner';
import { generateText } from '../../methods/generate';

interface Props {
  saveFunction: (answer: string) => void;
  previousText?: string;
  label?: string;
  closeFunction?: () => void;
  context?: string;
}
export default function Regenerate(props: Props) {
  const { getAccessTokenSilently } = useAuth0();

  const { company_slug } = useContext(LoggedInContext);
  const [showResults, setShowResults] = useState(false);
  const [answer, setAnswer] = useState('');
  const [isLoading, setLoading] = useState(false);
  const promptRef = React.useRef<HTMLInputElement>(null);

  const regenerateText = async () => {
    setLoading(true);
    const prompt = promptRef?.current?.value || '';

    track('asked_question', { prompt, company_slug });

    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    const answer = await generateText({
      prompt,
      company_slug,
      label: props.label || '',
      auth0Token,
      context: props.context,
      previousText: props.previousText,
    });

    setAnswer(answer);
    setLoading(false);
    setShowResults(true);
  };

  const saveAnswer = async () => {
    props.saveFunction(answer);
    track('regen_answer', { answer, company_slug });

    // reset
    setAnswer('');
    setLoading(false);
    setShowResults(false);
    props.closeFunction?.();
  };

  if (showResults) {
    return (
      <div className="text-left bg-stone-800 rounded-xl text-white p-6 relative">
        <div className="absolute top-0 right-0 p-2">
          <button
            className="square w-6 h-6 hover:bg-stone-700 rounded-full"
            onClick={() => props.closeFunction?.()}
          >
            ✕
          </button>
        </div>
        <div className="font-bold">Previously</div>
        <div>{props.previousText}</div>
        <div className="font-bold pt-4 pb-2">Vula generated</div>
        <div
          contentEditable={true}
          suppressContentEditableWarning={true}
          className=" text-stone-900 p-2 rounded bg-stone-200"
          onBlur={e => setAnswer(e.currentTarget.innerText)}
        >
          {answer}
        </div>
        <div className="flex flex-row justify-end items-center">
          <button
            className="px-4 mt-4 mr-2  flex flex-row items-center rounded-full hover:bg-stone-600 p-1 text-white underline hover:no-underline"
            onClick={() => {
              setAnswer('');
              setShowResults(false);
            }}
          >
            Retry
          </button>
          <button
            className="px-4 mt-4 flex flex-row items-center rounded-full bg-stone-700 p-1 text-white"
            onClick={() => saveAnswer()}
          >
            Replace and Save
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="text-left bg-stone-800 rounded-xl text-white p-3 relative ">
      <div className="absolute top-0 right-0 p-2">
        <button
          className="square w-6 h-6 hover:bg-stone-700 rounded-full"
          onClick={() => props.closeFunction?.()}
        >
          ✕
        </button>
      </div>
      <div className="font-bold">Previously:</div>
      <div className="pb-4">{props.previousText}</div>
      <div className="flex flex-col sm:flex-row sm:items-center items-end ">
        <input
          autoFocus
          ref={promptRef}
          className="p-2 bg-stone-200 text-stone-900 rounded w-full"
          placeholder="Tell Vula what to change"
        />
        {isLoading ? (
          <div className="pl-4">
            <Spinner />
          </div>
        ) : (
          <button
            onClick={async () => await regenerateText()}
            className="min-w-[150px] w-fit text-center sm:ml-2 sm:mt-0 ml-0 mt-2 p-2 rounded-full hover:bg-stone-600 bg-stone-700  text-white"
          >
            <div className=" whitespace-nowrap">✦ Regenerate</div>
          </button>
        )}
      </div>
    </div>
  );
}
