import React from 'react';

interface Props {
  poiningDirection?: 'left' | 'right';
}

export default function ArrowIcon(props: Props) {
  return (
    <div style={props.poiningDirection === 'right' ? { scale: -1 } : {}}>
      <svg
        width="19"
        height="16"
        viewBox="0 0 19 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          style={
            props.poiningDirection === 'right'
              ? { transform: 'rotate(180deg)', transformOrigin: 'center' }
              : {}
          }
          d="M8.42362 15.5736C8.16912 15.8466 7.82398 16 7.46411 16C7.10424 16 6.7591 15.8466 6.50459 15.5736L0.397359 9.02074C0.14293 8.74766 -7.59672e-07 8.37734 -7.25915e-07 7.99121C-6.92158e-07 7.60508 0.14293 7.23475 0.397359 6.96167L6.5046 0.408774C6.76056 0.143516 7.10338 -0.00326098 7.45923 5.6881e-05C7.81507 0.00337475 8.15546 0.156522 8.40709 0.426513C8.65872 0.696504 8.80145 1.06174 8.80455 1.44355C8.80764 1.82536 8.67084 2.1932 8.42362 2.46784L4.74978 6.53501L17.6428 6.53501C18.0028 6.53501 18.348 6.68843 18.6025 6.96152C18.857 7.23461 19 7.605 19 7.99121C19 8.37742 18.857 8.74781 18.6025 9.0209C18.348 9.29399 18.0028 9.44741 17.6428 9.44741L4.74978 9.44741L8.42362 13.5146C8.67805 13.7877 8.82098 14.158 8.82098 14.5441C8.82098 14.9302 8.67805 15.3006 8.42362 15.5736Z"
          fill="black"
        />
      </svg>
    </div>
  );
}
