import React, { ReactNode, useContext, useEffect } from 'react';
import { EditPitchContext } from '../../../contexts/EditPitchContext';

import EditMode from '../../Pitch/EditMode';

interface Props {
  children: ReactNode;
  incrementStep?: (number: 1 | -1) => void;
  helperMessage: string;
  customNextStep?: () => void;
  customNextStepName?: string;
}

export default function EditPitchWrapper(props: Props) {
  const { setPageMessage } = useContext(EditPitchContext);

  useEffect(() => {
    setPageMessage(props.helperMessage);
  }, [props.helperMessage]); // because we render the same component in succession without keys we must spot when it changes

  return (
    <div className="h-full min-h-[100vh] relative bg-stone-50 m-auto max-w-[1500px]">
      <EditMode
        incrementStep={props.incrementStep}
        customNextStep={props.customNextStep}
        customNextStepName={props.customNextStepName}
      />

      <div
        className="scale-[0.85] sm:absolute sm:top-0 min-h-[100vh] w-full h-full bg-stone-50 sm:pt-16"
        style={{ transform: 'perspective(1px) translateZ(0)' }}
      >
        {props.children}
      </div>
    </div>
  );
}
