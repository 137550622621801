import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import EditPitchProvider, {
  EditPitchContext,
} from '../contexts/EditPitchContext';
import { LoggedInContext } from '../contexts/LoggedInContext';

import { routes } from './routes';
import Loading from './Loading';

import { track } from '../interfaces/mixpanel';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaCompanyAPI } from '../api/company';
import TellUsAboutYou from '../components/Onboarding/TellUsAboutYou';
import UploadOnboardingDocuments from '../components/Onboarding/UploadDocuments';

export default function Onboarding() {
  const navigate = useNavigate();
  const { user, getAccessTokenSilently } = useAuth0();
  const {
    isLoading,
    onboardingProgress,
    setOnboardingProgress,
    company_slug,
    referrer,
  } = useContext(LoggedInContext);
  const { setPageMessage } = useContext(EditPitchContext);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [referralUrl, setReferralUrl] = useState('');

  // check if the user has already done onboarding
  useEffect(() => {
    stagesOfOnboardingCompleted();
  }, [onboardingProgress]);

  const stagesOfOnboardingCompleted = () => {
    if (onboardingProgress === 'completed') {
      return navigate(routes.private.pitchHome);
    }

    // only move them around onboarding if they haven't already started onboarding
    if (onboardingStep === 0) {
      // defined means they have a tagline so have partially completed onboarding, but not created a password
      if (onboardingProgress === 'defined') {
        setOnboardingStep(0);
      }
    }
  };

  const incrementStep = (number: 1 | -1) => {
    setOnboardingStep(prev => prev + number);
  };

  useEffect(() => {
    // after the user has defined their company, we need to check if we need to take any actions with their referrer by calling the backend endpoint
    if (company_slug) {
      (async () => {
        await checkReffererActions();
      })();
    }

    // if the user is an investor, we need to redirect them to the investor home
    if (user) {
      // this needs to be configured in auth0 actions
      const isInvestor = user['https://vula.vc/user_metadata.isInvestor'];

      if (isInvestor) {
        track('Investor new signup');
        navigate(routes.private.home);
      }
    }
  }, [company_slug, referrer]);

  const checkReffererActions = async () => {
    // if the user has a referrer, we need to check if we need to take any actions
    if (referrer) {
      try {
        // call the endpoint with the referrer code
        const token = await getAccessTokenSilently();
        const api = new VulaCompanyAPI({ token });
        const url = await api.handleReferrerCode(company_slug, referrer);
        setReferralUrl(url.data.url);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // if onboarding is completed, go home
  if (onboardingProgress === 'completed') {
    return <Navigate to={routes.private.home} />;
  }

  if (isLoading) {
    return <Loading size="full-page" />;
  }

  const onboarding = [
    // get url, name and elevator pitch
    () => <TellUsAboutYou incrementStep={number => incrementStep(number)} />,
    // get documents
    () => (
      <UploadOnboardingDocuments
        incrementStep={number => incrementStep(number)}
      />
    ),

    () =>
      referralUrl ? (
        <Navigate to={referralUrl} replace={true} />
      ) : (
        <Navigate to={routes.private.myGrantsVerify} replace={true} />
      ),
  ];

  // If already completed, go straight home
  // or if finished onboarding go home
  if (onboardingStep === onboarding.length) {
    setPageMessage(''); // clear the sticky message
    setOnboardingProgress('completed');
    track('Pitch onboarding: completed', {
      company_slug: company_slug,
    });
  }

  return (
    <EditPitchProvider>
      <div>{onboarding[onboardingStep]()}</div>
    </EditPitchProvider>
  );
}
