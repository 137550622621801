import { useAuth0 } from '@auth0/auth0-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { LoggedInContext } from './LoggedInContext';
import { VulaCompanyAPI } from '../api/company';

export interface MemoItem {
  title: string;
  answer: string;
  label: string;
}

interface MemoContextProps {
  isLoading: boolean;
  memoItems: MemoItem[];
  setMemoItems?: (memoItems: MemoItem[]) => void;
  getMemo: (company_slug: string) => Promise<void>;
  generateMemo: () => void;
  memoIsPublic: boolean;
  setMemoIsPublic?: (value: boolean) => void;
  getMemoPassword: () => void;
  memoPassword?: string;
  updateMemoPassword?: (password: string) => void;
  editable?: boolean;
  deleteRow?: (label: string) => void;
  memoCompanyName?: string;
}

export const MemoContext = createContext<MemoContextProps>({
  isLoading: false,
  memoItems: [],
  setMemoItems: undefined,
  getMemo: () => Promise.resolve(),
  generateMemo: () => Promise<void>,
  memoIsPublic: false,
  setMemoIsPublic: undefined,
  getMemoPassword: () => Promise.resolve(),
  memoPassword: '',
  updateMemoPassword: undefined,
  editable: false,
  deleteRow: undefined,
  memoCompanyName: '',
});

const MemoContextProvider = ({ children }: { children: React.ReactNode }) => {
  // context
  const { getAccessTokenSilently } = useAuth0();
  const loggedin = useContext(LoggedInContext);
  const { company_slug } = loggedin;

  const getReqCompanySlug = () => {
    if (!window.location.pathname.includes('/memo')) return '';
    const url = window.location.pathname;
    const urlSplit = url.split('/');
    const company_slug_req = urlSplit[urlSplit.length - 2];
    return company_slug_req;
  };

  // state
  const [company_slug_requested] = useState<string>(getReqCompanySlug());
  const [isLoading, setIsLoading] = useState(false);
  const [memoIsPublic, setMemoIsPublic] = useState(false);
  const [memoItems, setMemo] = useState<MemoItem[]>([]);
  const [memoPassword, setMemoPassword] = useState<string>('');
  const [memoCompanyName, setMemoCompanyName] = useState<string>('');

  // on load get memo items
  useEffect(() => {
    if (!company_slug) return;
    (async () => {
      if (company_slug_requested) {
        await getMemo(company_slug_requested);
      } else {
        await getMemo(company_slug);
        await getMemoPassword();
      }
    })();
  }, [company_slug]);

  useEffect(() => {
    setIsLoading(loggedin.isLoading);
  }, [loggedin.isLoading]);

  const getMemo = async (company_slug: string) => {
    try {
      const auth0Token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token: auth0Token });
      const memo = await api.getMemoDetails(company_slug);

      setMemo(memo.data.memoItems);
      setMemoCompanyName(memo.data.company_full_name);
    } catch (error) {
      const api = new VulaCompanyAPI();
      const memo = await api.getMemoDetails(company_slug);
      setMemo(memo.data.memoItems);
      setMemoCompanyName(memo.data.company_full_name);
    }
  };

  const setMemoItems = async (memoItems: MemoItem[]) => {
    setMemo(memoItems);
  };

  const generateMemo = async () => {
    try {
      const auth0Token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token: auth0Token });
      const memo = await api.generateMemo(company_slug);
      setMemo(memo.data);
      await getMemo(company_slug);
      await getMemoPassword();
    } catch (error) {
      console.log(error);
    }
  };

  const getMemoPassword = async () => {
    try {
      const auth0Token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token: auth0Token });
      const memo = await api.getMemoPassword(company_slug);
      setMemoPassword(memo.data.passphrase);
      setMemoIsPublic(memo.data.public);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMemoPassword = async (password: string) => {
    try {
      const auth0Token = await getAccessTokenSilently();

      const api = new VulaCompanyAPI({ token: auth0Token });
      const memo = await api.updateMemoPassword(company_slug, password);
      setMemoPassword(memo.data.password);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRow = async (label: string) => {
    try {
      const auth0Token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token: auth0Token });
      await api.deleteMemoRow(company_slug, label);
      await getMemo(company_slug);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MemoContext.Provider
      value={{
        isLoading,
        memoItems,
        setMemoItems,
        getMemo,
        generateMemo,
        memoIsPublic,
        setMemoIsPublic,
        getMemoPassword,
        memoPassword,
        updateMemoPassword,
        deleteRow,
        memoCompanyName,
      }}
    >
      {children}
    </MemoContext.Provider>
  );
};

export default MemoContextProvider;
