export const routes = {
  public: {
    // public routes
    landing: '/',
    os: '/os',

    grants: '/grants',
    publicGrants: '/grants/*',
    submitGrant: '/submit-grant',
    fx: '/fx',
    syndication: '/syndication',
    deposits: '/deposits',
    careers: '/careers',
    masterPlan: '/master-plan',
    whitePapers: '/white-papers',
    whitePaper1: '/WP1',
    pressRoom: '/press-room',
    demoCall: '/demo-call',
    introCall: '/intro-call',

    // holding page for now
    publicApplications: '/apply/*',
    publicApplicationsIframe: '/i/apply/*',

    // auth public routes
    launch: '/launch',
    signup: '/signup',
    login: '/login',
    claim: '/claim',
    claimAdditional: '/claim/additional',
    iosWarning: '/ios-warning',
  },

  private: {
    //auth private
    onlogin: '/onlogin',
    // onboarding routes
    onboarding: '/onboarding',

    // misc. app routes
    home: '/home',
    ask: '/ask',
    mySettings: '/my/settings',

    // pitch routes
    pitchHome: '/pitch/home',
    pitchHomeComponents: '/pitch/*',
    sharePitch: '/share-pitch',

    // memo routes
    memo: '/memo',
    memoEdit: '/memo/edit',

    // vc matches
    // myMatches: '/my/matches', - not used

    // grants
    myGrantsVerify: '/my/grants/verify',
    myGrants: '/my/grants',

    // lending applications
    applicationsHome: '/applications/',
    applicationPage: '/applications/:financerName/:productName',

    // investor routes
    invest: '/invest/*',

    // upload
    upload: '/upload',
  },
};
