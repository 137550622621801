import React, { useState } from 'react';
import { Grant } from '../../types/Grants';
import GrantProgressCard from './GrantProgressCard';

interface Props {
  type: 'inProgress' | 'new' | 'archived' | 'awaiting';
  grantMatches?: Grant[];
  animateIn?: boolean;
}

export default function GrantInboxDivider(props: Props) {
  const [animate, setAnimate] = useState(false);

  React.useEffect(() => {
    // Enable animation after a short delay
    const timeout = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);

  const settings = {
    inProgress: {
      title: 'In Progress',
    },
    new: {
      title: 'New',
    },
    archived: {
      title: 'Archived',
    },
    awaiting: {
      title: 'Awaiting',
    },
  };

  // use state to determine if the dropdown is open or closed
  const [isOpen, setIsOpen] = useState(
    props?.grantMatches?.length ? true : false,
  );

  // if grant matches updaates update open state
  React.useEffect(() => {
    setIsOpen(props?.grantMatches?.length ? true : false);
  }, [props.grantMatches]);

  // Exponential delay calculation function with decreasing delays
  const calculateDelay = (index: number) => {
    // Calculate the delay for each card where index=0 delay=X, index=1 delay=X + X/2, index=2 delay=X + X/2 + X/4, etc.
    const X = 200;
    const initialDelay = 0;
    let cumulativeDelay = initialDelay; // set the initial value as less than 0 to allow for the first card to be displayed immediately
    for (let i = 0; i < index; i++) {
      cumulativeDelay += X / Math.pow(1.2, i);
    }

    return cumulativeDelay;
  };

  return (
    <div className="w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white p-4 w-full flex flex-row justify-between hover:bg-gray-100 border-solid border-x-0 border-t-0 border-b-1 border-gray-100"
      >
        <div className="items-center flex flex-row ">
          <span>{settings[props.type].title}</span>
          <span className="flex justify-center items-center text-center m-auto text-xs mx-2 w-[20px] h-[20px] bg-gray-200 rounded-full">
            {props.grantMatches?.length ?? 0}
          </span>
        </div>
        <div className="font-extrabold text-lg">{isOpen ? '˅' : '˄'}</div>
      </button>
      {isOpen && (
        <div className="bg-gray-100">
          {props.grantMatches?.map((grantMatch, index) => (
            <div
              key={grantMatch.id}
              style={
                props.animateIn
                  ? {
                      transition: 'transform 0.5s ease',
                      transform: `translateX(${animate ? 0 : '-100%'})`,
                      transitionDelay: `${calculateDelay(index)}ms`,
                    }
                  : undefined
              }
            >
              <GrantProgressCard grant={grantMatch} grantStatus={props.type} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
