import React from 'react';
import { useNavigate } from 'react-router-dom';
interface Props {
  light?: boolean;
  redirectUrl?: string;
  openInNewTab?: boolean;
}
export default function ApplyWithVulaButton(props: Props) {
  const navigate = useNavigate();

  return (
    <div className="w-full m-auto">
      <button
        className={
          'flex m-auto items-center w-fit rounded-full  p-2 px-4 shadow-xl my-8 border  border-solid hover:scale-[0.98]  ' +
          (props.light
            ? 'bg-white hover:bg-stone-50 border-stone-200 text-stone-900 hover:text-stone-800'
            : ' bg-stone-900 hover:bg-stone-800 border-stone-900 text-stone-100 hover:text-stone-200')
        }
        onClick={() => {
          if (props.openInNewTab) {
            window.open(props.redirectUrl || '/login', '_blank');
          } else {
            navigate(props.redirectUrl || '/login');
          }
        }}
      >
        <img
          src="/logo.svg"
          alt="logo"
          className={'w-8 h-8 ' + (!props.light ? 'filter invert' : '')}
        />
        <div
          className="font-bold pl-4 white-space-nowrap
        "
        >
          apply with vula{' '}
        </div>
      </button>
    </div>
  );
}
