import React from 'react';
import ContentSection from '../../utils/ContentSection';

export default function LetsGo() {
  return (
    <ContentSection background="colourful">
      <div className="">
        <div className="py-4 flex flex-row items-center">
          <div className="text-3xl font-extrabold pr-2">🔥</div>

          <div className="text-3xl font-extrabold">Let's go!</div>
        </div>
        <div className="text-md">
          Vula has tried to answer a few questions for you. Please review and
          edit as needed.
          <br />
          <br /> Each edit will improve Vula's understanding for the next grant
          and hence will always be improving for you.
        </div>
      </div>
    </ContentSection>
  );
}
