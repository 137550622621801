import React, { useContext } from 'react';

import { LoggedInContext } from '../../contexts/LoggedInContext';
import { attachmentEnums } from '../../enums/pitch';
import EditableImageContainer from '../utils/EditableImageContainer';

export default function CompanyLogoChange() {
  const { company_logo } = useContext(LoggedInContext);

  return (
    <div className="flex flex-ro justify-start items-center sm:pl-8 my-4 w-full">
      <div className="text-left text-stone-500 pr-4 text-center">
        <div>Logo</div>
      </div>

      <div className="w-[100px] h-[100px] max-w-[100px] max-h-[100px] bg-stone-50 hover:bg-gray-400 rounded shadow-2xl">
        <EditableImageContainer
          image={company_logo}
          altText={'Logo image'}
          label={attachmentEnums.logo}
        />
      </div>
    </div>
  );
}
