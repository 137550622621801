import React from 'react';

interface VideoComponentProps {
  key: string;
  poster: string;
  src1: string;
  src2: string;
}

const VideoComponent = ({ key, poster, src1, src2 }: VideoComponentProps) => {
  return (
    <video
      key={key}
      controls={false}
      autoPlay
      loop={true}
      muted
      preload="metadata"
      poster={poster}
      playsInline={true}
      className="w-full h-full object-cover rounded-lg "
    >
      <source src={src1} type="video/webm" />
      <source src={src2} type="video/mp4" />
      {/* <!-- Provide a download link for users who can't play the video --> */}
      {/* Your browser does not support the video tag. You can{' '}
  <a href="app_intro.mp4">download the video here</a>. */}
    </video>
  );
};

export default VideoComponent;
