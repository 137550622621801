import React from 'react';

interface Props {
  id?: string;
  children: React.ReactNode;
}

export default function PitchContentContainer(props: Props) {
  return (
    <section
      id={props.id}
      className={
        ' flex flex-col justify-center items-center  max-w-full  w-full text-center flex flex-col justify-center items-center px-2  py-8 sm:py-12'
      }
    >
      {props.children ? (
        <div className="relative sm:my-12 sm:m-8 md:w-3/4 w-full min-h-[400px] ">
          {props.children}
        </div>
      ) : null}
    </section>
  );
}
