import React, { ChangeEvent, FocusEvent, ReactNode } from 'react';

interface TextAreaProps {
  className?: string;
  name?: string;
  id?: string;
  rows?: number;
  label?: ReactNode;
  cols?: number;
  placeholder?: string;
  value?: string | null;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  required?: boolean;
  maxLength?: number;
}

const VTextArea: React.FC<TextAreaProps> = ({
  className,
  name,
  id,
  rows = 4,
  label,
  cols,
  placeholder,
  value,
  defaultValue,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  required = false,
  maxLength,
}) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const autoResize = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  React.useEffect(() => {
    autoResize();
  }, [value]);

  return (
    <div>
      <p className="text-base font-semibold mb-2">{label}</p>
      <textarea
        ref={textAreaRef}
        className={`w-full no-scrollbar text-base focus:border-primary/40 border resize-none border-solid border-strokeLine bg-white rounded-[11px] px-3 py-2 shadow-2xl mb-4 ${className}`}
        name={name}
        id={id}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        value={value ?? defaultValue}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        onInput={autoResize}
        required={required}
        maxLength={maxLength}
      ></textarea>
    </div>
  );
};

export default VTextArea;
