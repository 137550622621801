import React, { useContext } from 'react';
import HomeNavBar from '../components/Home/HomeNavBar';
import QAAWithVula from '../components/utils/QAAWithVula';
import VulaLogo from '../components/utils/VulaLogo';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TypeWriterEffect from 'react-typewriter-effect';
import { useAuth0 } from '@auth0/auth0-react';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';

export default function Ask() {
  const { company_name } = useContext(LoggedInContext);
  const { user } = useAuth0();

  // on load record view
  React.useEffect(() => {
    track('Ask view');
  }, []);

  return (
    <div>
      <HomeNavBar />
      <div className="flex flex-col sm:flex-row max-w-[1000px] sm:p-8 m-auto min-h-full ">
        <div
          className={
            ' flex flex-col justify-between h-full min-h-[90vh]  sm:w-full'
          }
        >
          <div className="px-4">
            <div className="bg-stone-200 rounded-lg  px-8 py-4 my-2 text-right w-fit flex flex-row items-center">
              <div className="hidden sm:block pr-4 min-w-[75px] max-h-[50px]">
                <VulaLogo />
              </div>
              <TypeWriterEffect
                startDelay={0}
                cursor=""
                cursorColor="#00000000"
                multiText={[
                  `Hello ${user?.nickname}, I am Vula and am trained on ${
                    company_name || 'your companies data'
                  }.`,
                ]}
                typeSpeed={1}
              />
            </div>
            <div className="bg-stone-200 rounded-lg  px-8 py-4 my-2 text-right w-fit flex flex-row items-center">
              <div className="hidden sm:block pr-4 min-w-[75px] max-h-[50px]">
                <VulaLogo />
              </div>
              <TypeWriterEffect
                startDelay={300}
                cursor=""
                cursorColor="#00000000"
                multiText={[`What is your question?`]}
                typeSpeed={1}
              />
            </div>
            <QAAWithVula skipAnimatingRepsonse />
          </div>
        </div>
      </div>
    </div>
  );
}
