import React, { useContext } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import PitchContent from './PitchContent';

export default function PrivatePitch() {
  const { editMode } = useContext(EditPitchContext);
  return (
    <div>
      {!editMode ? (
        <div>
          <div
            className={
              'hidden sm:flex pt-8 text-sm flex flex-row justify-center items-center transition-all ease-in duration-700 opacity-30  '
            }
          >
            <div className="flex justify-center items-center p-4">
              Press <span className="bg-gray-200 rounded-md p-2 m-2">⬇︎</span>{' '}
              to navigate
            </div>
          </div>
          <div
            className={
              'text-2xl flex justify-center transition-all ease-in duration-700 opacity-100 animate-bounce duration-150'
            }
          >
            ↓
          </div>
        </div>
      ) : null}

      <div className="transition-all h-full">
        <PitchContent />
      </div>
    </div>
  );
}
