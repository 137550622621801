import React from 'react';

const LoadingSpinner = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <circle
        cx="8.00016"
        cy="8.49998"
        r="6.66667"
        stroke="#C8C7D1"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7611 3.83331C11.5512 2.59907 9.86509 1.83331 8.00016 1.83331C4.31826 1.83331 1.3335 4.81808 1.3335 8.49998C1.3335 10.1907 1.96289 11.7345 3.00016 12.9097"
        stroke="url(#paint0_linear_177_17810)"
        strokeWidth="1.33"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_177_17810"
          x1="7.04731"
          y1="1.83331"
          x2="7.04731"
          y2="12.9097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5541F9" />
          <stop offset="1" stopColor="#CE7DFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LoadingSpinner;
