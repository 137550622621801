import React from 'react';

interface Props {
  children: React.ReactNode;
  background?: 'black' | 'colourful';
  centerContent?: boolean;
  shadow?: boolean;
  removeMargin?: boolean;
}

export default function ContentSection(props: Props) {
  return (
    <div
      className={
        'p-4 sm:p-8  rounded-2xl flex  ' +
        (props.centerContent ? ' justify-center' : ' justify-start ') +
        (props.background === 'colourful'
          ? ' text-white  font-bold text-shadow bg-center bg-[url(https://vula-assets.s3.eu-west-2.amazonaws.com/hopeful.webp)]'
          : ' text-white bg-black') +
        (props.shadow ? ' shadow-2xl' : '') +
        (props.removeMargin ? ' m-0' : ' m-4 sm:m-12 ')
      }
    >
      {props.children}
    </div>
  );
}
