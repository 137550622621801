import React, { ChangeEvent, FocusEvent } from 'react';

interface InputProps {
  className?: string;
  name?: string;
  id?: string;
  type?: string;
  placeholder?: string;
  containerStyles?: string;
  value?: string | null;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
}

const VInput: React.FC<InputProps> = ({
  className,
  name,
  id,
  type,
  placeholder,
  value,
  defaultValue,
  onBlur,
  onChange,
  label,
  containerStyles,
  disabled = false,
  required = false,
}) => {
  return (
    <div className={`mb-4 ${containerStyles}`}>
      {label && (
        <label className="text-base font-semibold" htmlFor={id}>
          {label}
        </label>
      )}
      <input
        type={type}
        className={`w-full cursor-text focus:border-primary/40 border-strokeLine text-base border border-solid transition-all duration-150 bg-white rounded-[11px] shadow-2xl mb-2 h-14 mt-2 pl-3 ${className}`}
        name={name}
        id={id}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value ?? defaultValue}
        onChange={onChange}
        disabled={disabled}
        required={required}
      />
    </div>
  );
};

export default VInput;
