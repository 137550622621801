import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import { VulaUserAPI } from '../api/user';
import CompanyGeosDefinitions from '../components/Grants/CompanyGeosDefinitions';
import CompanyVerticalDefinitions from '../components/Grants/CompanyVerticalDefinitions';
import GrantNotificationEmailInput from '../components/Grants/GrantNotificationEmailInput';
import CompanyLogoChange from '../components/Onboarding/CompanyLogoChange';
import CompanyNameChange from '../components/Onboarding/CompanyNameChange';
import Button from '../components/utils/Button';
import MyContainer from '../components/utils/MyContainer';
import { LoggedInContext } from '../contexts/LoggedInContext';
import { track } from '../interfaces/mixpanel';
import { apiUrl } from '../methods/env';

export default function MySettings() {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [sentFeedback, setSentFeedback] = useState(false);
  const [showDeleteConfirmation, setDeleteConfirmationVis] = useState(false);
  const [deleteError, setDeleteError] = useState('');
  const { company_slug, company_name } = useContext(LoggedInContext);

  const deleteAccount = async () => {
    const token = await getAccessTokenSilently({ audience: apiUrl });
    const api = new VulaUserAPI({ token });
    try {
      await api.deleteUserMe();
      logout({ returnTo: window.location.origin });
    } catch (e) {
      console.log(e);
      setDeleteError(JSON.stringify(e));
    }
  };

  // References
  const feedback = React.createRef<HTMLTextAreaElement>();

  return (
    <MyContainer
      headline="Settings"
      subtitle=""
      background="https://vula-assets.s3.eu-west-2.amazonaws.com/cubic.png"
    >
      <div className="text-left">
        {company_name ? (
          <>
            <div className="pt-20 pb-4 text-xl font-extrabold">
              Your company name
            </div>
            <CompanyNameChange />
            <div className="pt-20 pb-4 text-xl font-extrabold">
              Your company logo
            </div>
            <CompanyLogoChange />
          </>
        ) : null}

        {sentFeedback ? (
          <div className="text-xl pt-20 pb-4  font-extrabold">
            🙏 Thank you - we will review this asap.
          </div>
        ) : (
          <>
            <div className="text-xl pt-20 pb-4 font-extrabold">
              Give us feedback! How could we do better?
            </div>
            <textarea
              ref={feedback}
              className="w-full p-4 rounded-xl bg-white ring-1 ring-black ring-solid"
            ></textarea>
            <Button
              text="Send"
              action={() => {
                track('Feedback - settings', {
                  feedback: feedback.current?.value,
                  company_slug,
                });
                setSentFeedback(true);
              }}
            />
          </>
        )}

        {company_slug.length ? (
          <>
            <div className="pt-20 pb-4 text-xl font-extrabold">
              Your company parameters
            </div>

            <GrantNotificationEmailInput />
            <CompanyVerticalDefinitions />
            <CompanyGeosDefinitions />
          </>
        ) : null}

        <div className="pt-12 pb-4 text-xl font-extrabold">Logout</div>
        <Button
          text="Logout of your account"
          action={() => {
            logout({ returnTo: window.location.origin });
          }}
        />
        {deleteError ? (
          <div className="pt-12 pb-4 text-xl font-extrabold text-red-500">
            {deleteError}
          </div>
        ) : null}
        {showDeleteConfirmation ? (
          <>
            <div className="pt-20 pb-4 text-xl font-extrabold text-red-500">
              You will not be able to undo this. Are you sure?
            </div>
            <Button
              text="Yes. Delete my account"
              action={async () => {
                track('Delete account confirm');
                await deleteAccount();
              }}
            />
          </>
        ) : (
          <>
            <div className="pt-20 pb-4 text-xl font-extrabold">
              Delete your account, delete your data and untrain Vula
            </div>
            <Button
              text="Delete your account"
              action={() => {
                track('Delete account pressed');
                setDeleteConfirmationVis(true);
              }}
            />
          </>
        )}
      </div>
    </MyContainer>
  );
}
