import React, { useContext } from 'react';

import { ApplicationContext } from '../../contexts/ApplicationContext';
import UploadQuestions from './QuestionComponents/UploadQuestions';

export default function Documents() {
  const { lendingProductApplicationDefinition, updateAnswerInApplicationJson } =
    useContext(ApplicationContext);

  const document_uploads = lendingProductApplicationDefinition?.sections.find(
    section => section.type === 'document_uploads',
  );

  return (
    <div className="max-w-[1000px]">
      <div className="text-4xl font-extrabold">{document_uploads?.title}</div>
      <div className="pt-2 ">{document_uploads?.subtitle}</div>
      <div className="grid grid-cols-1 gap-4 mt-4">
        {document_uploads?.questions.map(doc => (
          <UploadQuestions
            question={doc}
            saveAnswer={async text => {
              updateAnswerInApplicationJson(doc.id, text);
            }}
          />
        ))}
      </div>
    </div>
  );
}
