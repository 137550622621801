import React from 'react';

import Button from '../utils/Button';
import CompanyLogoChange from './CompanyLogoChange';
import CompanyNameChange from './CompanyNameChange';
import CompanyPitchChange from './CompanyPitchChange';

import Spinner from '../utils/Spinner';

interface Props {
  incrementStep: (number: 1 | -1) => void;
}

export default function CheckCompanyDefinition(props: Props) {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <div className="w-full items-center flex flex-col sm:flex-row">
        <CompanyNameChange
          reportLoading={(bool: boolean) => setIsLoading(bool)}
          isOnboarding
        />
        <CompanyLogoChange />
      </div>
      <CompanyPitchChange />
      <div className="pt-8">
        {isLoading ? (
          <Spinner />
        ) : (
          <Button text="Next" action={() => props.incrementStep(1)} />
        )}
      </div>
    </>
  );
}
