import React from 'react';

import VulaLogo from '../components/utils/VulaLogo';
import NewTabIcon from '../components/Icons/NewTabIcon';
import JunkDrawer from '../components/Landing/JunkDrawer';

import MailingListComponent from '../components/utils/MailingListComponent';

/*

    Expected details on a Press Room page:
    - Company overview
    - past press coverage
    - brand assets
    - contact details
    */

export default function PressRoom() {
  return (
    <div className="h-full min-h-[100vh] max-w-full m-auto flex items-center flex-col bg-white ">
      <div className=" w-full text-center">
        <div className="w-full bg-stone-900 text-stone-50 text-left py-20 px-4 ">
          <div className="max-w-[800px] m-auto ring ring-8 ring-white ring-solid rounded-2xl bg-black ">
            <div className="w-full flex flex-col sm:flex-row items-center relative">
              <div className="relative h-full pr-8 w-1/3 flex justify-end sm:justify-center">
                <div className="h-full z-10 sm:pl-8 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                  <div className="min-h-[100px] sm:min-h-full h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center  items-center ">
                    <VulaLogo type="light" />
                  </div>
                </div>
              </div>
              <div className="pt-20 sm:pt-0 px-2 sm:px-0 w-full flex flex-col justify-start text-left m-auto py-4">
                <div className="text-m text-left py-4">Welcome to the</div>
                <h1
                  className="text-5xl sm:text-6xl font-extrabold "
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Press Room
                </h1>
              </div>
            </div>
          </div>

          <div className="max-w-[600px] m-auto pt-20">
            <div className="text-4xl font-extrabold">Company Overview</div>
            <div className="py-2">
              Vula is a London-based startup whose mission is is 10x investment
              into African SMEs by 2030. We want to close the $300B SME funding
              gap - to pull the continent out of poverty, transform lives and
              create a more equitable, prosperous world.
            </div>
            <div className="py-2">
              Vula is an{' '}
              <a
                href="https://www.joinef.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:no-underline"
              >
                Entrepreneur First
              </a>{' '}
              backed start up, founded by{' '}
              <a
                href="https://www.linkedin.com/in/nicraw/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:no-underline"
              >
                Nic
              </a>{' '}
              (South Africa, ex-McKinsey) and{' '}
              <a
                href="https://www.linkedin.com/in/alexander-goff/"
                target="_blank"
                rel="noopener noreferrer"
                className="underline hover:no-underline"
              >
                Alex{' '}
              </a>
              (UK, ex-IBM Watson).
            </div>
            <div className="py-2">
              We are doing this by building a technology like Single Sign On for
              African SMEs and startups to instantly and easily apply to funders
              and financiers. We’re growing fast and helping African banks,
              international DFIs, grant-makers and specialist lenders. We
              provide the tech that structures and standardizes company data,
              connects it to the right financial institutions, and helps their
              teams make better, quicker decisions.
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[600px] m-auto pt-20">
        <div className="text-4xl font-extrabold">Press</div>
        <a
          className="py-2 flex flex-row items-center hover:no-underline underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://vula.notion.site/Vula-in-the-Press-62f06073642946249f4e38ae6f27a223?pvs=4"
        >
          <p className="pr-2">View the collection on Notion</p>
          <NewTabIcon />
        </a>
      </div>
      <div className="max-w-[600px] m-auto pt-20">
        <div className="text-4xl font-extrabold">Brand</div>
        <a
          className="py-2 flex flex-row items-center hover:no-underline underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://vula.notion.site/Brand-Assets-f8750c15ae354604a07afea2c649bb39?pvs=4"
        >
          <p className="pr-2">View the assets on Notion</p>
          <NewTabIcon />
        </a>
      </div>
      <div className="max-w-[1000px] m-auto pt-20">
        <div className="text-4xl font-extrabold pb-4">Get in contact</div>
        <MailingListComponent
          completedMessage="📧 Email request sent - we will be in touch soon."
          placeholder="Add your email"
          buttonText="Get in touch"
          listName="Vula Press Room"
        />
      </div>
      <div className="w-full">
        <JunkDrawer />
      </div>
    </div>
  );
}
