import React, { useContext, useState } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import { africanCountries, allOtherCountries } from '../../data/countries';
import Spinner from '../utils/Spinner';

interface Props {
  type: string; // geography or vertical
}

export default function CreateParamPill(props: Props) {
  const { getCompanyParams, saveNewParam, companyParams } =
    useContext(GrantContext);
  const [expanded, setExpanded] = useState(false);
  const [spin, setSpin] = useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const geoSelectRef = React.useRef<HTMLSelectElement>(null);

  const checkEnterKeyPress = async (event: KeyboardEvent) => {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Trigger the button element with a click
      await saveParam();
    }
  };

  const saveParam = async () => {
    const param = {
      text:
        props.type === 'geography'
          ? geoSelectRef.current?.value ?? ''
          : inputRef.current?.value ?? '',

      type: props.type,
      userVerified: true,
    };

    if (param.text === '') {
      return;
    }

    setSpin(true);
    await saveNewParam(param);
    await getCompanyParams();
    setExpanded(false);
    setSpin(false);
  };

  const getSelectableCountries = (
    countries: {
      name: string;
      emoji: string;
    }[],
  ) => {
    // show all if no params
    if (!companyParams) {
      return countries;
    }

    // countires already included in params
    const notSelectable = companyParams.parameters
      .filter(param => param.type === 'geography')
      .map(param => param.text);

    // filter out countries already included in params
    return countries.filter(country => !notSelectable.includes(country.name));
  };

  const renderSelectableCountries = () => {
    const selectableCountries = getSelectableCountries(allOtherCountries);
    return selectableCountries.map(country => (
      <option key={country.name} value={country.name}>
        {country.name}{' '}
        {navigator.userAgent.indexOf('Win') > -1 ? null : country.emoji}
      </option>
    ));
  };
  const renderAfricanSelectableCountries = () => {
    const selectableCountries = getSelectableCountries(africanCountries);
    return selectableCountries.map(country => (
      <option key={country.name} value={country.name}>
        {country.name}{' '}
        {navigator.userAgent.indexOf('Win') > -1 ? null : country.emoji}
      </option>
    ));
  };

  if (expanded) {
    return (
      <div className="min-w-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-2 rounded-full">
        {props.type === 'geography' ? (
          <select
            ref={geoSelectRef}
            className="mx-2 hover:rounded hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-solid border-gray-300  "
            defaultValue={'default'}
            onChange={async () => {
              await saveParam();
            }}
          >
            <option disabled value="default">
              Select from below
            </option>
            <option key={'All Countries'} value={'All countries'}>
              {'All countries'}
            </option>
            <optgroup label="Africa">
              {renderAfricanSelectableCountries()}
            </optgroup>
            <optgroup label="Rest of the world">
              {renderSelectableCountries()}
            </optgroup>
          </select>
        ) : (
          <input
            autoFocus={true}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onKeyDown={e => checkEnterKeyPress(e)}
            ref={inputRef}
            type={'text'}
            className="mx-2 hover:rounded hover:bg-gray-100 border-x-0 border-t-0 border-b-2 border-solid border-gray-300  "
            maxLength={50}
          />
        )}
        {spin ? (
          <div className="scale-[0.5]">
            <Spinner />
          </div>
        ) : (
          <button
            onClick={async () => {
              await saveParam();
            }}
            className=" w-[25px] h-[25px] square rounded-full hover:bg-green-300 hover:text-white text-center"
          >
            +
          </button>
        )}
        <button
          onClick={() => setExpanded(false)}
          className=" w-[25px] h-[25px] square rounded-full hover:bg-red-300 hover:text-white text-center"
        >
          &#215;
        </button>
      </div>
    );
  }
  return (
    <div className="min-w-fit m-1 flex flex-row items-center border border-solid border-2 border-gray-300 hover:bg-gray-200 p-2 rounded-full">
      <button
        onClick={() => setExpanded(true)}
        className=" w-[25px] h-[25px] square rounded-full hover:bg-gray-300 hover:text-white text-center"
      >
        +
      </button>
    </div>
  );
}
