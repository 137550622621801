import React from 'react';
import HalfTextHalfImagePage from '../components/utils/HalfTextHalfImagePage';
import LoginButton from '../interfaces/auth0/LoginButton';
import { track } from '../interfaces/mixpanel';
import Button from '../components/utils/Button';

export default function IOSWarning() {
  // on load record view
  React.useEffect(() => {
    track('iosWarning');
  }, []);

  return (
    <HalfTextHalfImagePage
      heading="Are you on an iOS device?"
      image={'https://vula-assets.s3.eu-west-2.amazonaws.com/cubic.webp'}
      imageShouldFillAllSpace={true}
    >
      <div className="text-left w-full">
        <p className="text-lg font-extrabold ">
          We haven't expanded to support all devices yet!
        </p>
        <p className="py-1">
          You seem to be on an iOS device on Chrome. If you want to continue,
          you can, but you may experience some issues.
        </p>
        <p className="pb-8 pt-4">
          Alternatively, email us{' '}
          <a href="mailto:nic@vula.vc" className="underline">
            {' '}
            here
          </a>{' '}
          and we'll go through onboarding with you!
        </p>
        <LoginButton type="signup" skipWarning={true} />
        <br />
        <LoginButton type="login" buttonType="secondary" skipWarning={true} />
        {navigator?.share ? (
          <>
            <p className="text-lg font-extrabold pt-12 ">
              Or send the link to another browser or to a laptop
            </p>
            <Button
              text="Share link"
              action={() => navigator.share({ url: 'https://vula.vc' })}
            />
          </>
        ) : null}
      </div>
    </HalfTextHalfImagePage>
  );
}
