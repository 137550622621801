import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../components/Icons/ArrowIcon';
import Button from '../components/utils/Button';
import { routes } from './routes';
import SharePitchStructure from '../components/utils/SharePitchStructure';

export default function SharePitch() {
  const navigate = useNavigate();

  return (
    <div className="relative flex flex-col max-w-[1400px]  m-auto">
      <div className="ml-8 pt-[60px] absolute top-0 left-0">
        <button
          className=" hidden sm:flex justify-center flex-row items-center"
          onClick={() => navigate(routes.private.pitchHome)}
        >
          <ArrowIcon />
          <div className="px-2 text-lg font-extrabold ">Home</div>
        </button>
      </div>
      <SharePitchStructure
        nextStepComponent={
          <div className=" py-4 flex justify-center items-center">
            <Button text="Done" action={() => navigate(routes.private.home)} />
          </div>
        }
        title="Share with an investor"
      />
    </div>
  );
}
