import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import { Grant } from '../../types/Grants';

interface Props {
  grant: Grant;
  grantStatus: 'inProgress' | 'new' | 'archived' | 'awaiting';
}

export default function GrantProgressCard(props: Props) {
  const { selectedGrant, defineSelectedGrant } = useContext(GrantContext);
  // extract data from grant
  const { name, monetary_offer, deadline, application_sections } = props.grant;

  // convert deadline to days left
  const daysLeft = Math.floor(
    (new Date(deadline).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  );

  // onclick set as selected grant
  const onClick = () => {
    defineSelectedGrant({
      grant: props.grant,
      status: props.grantStatus,
    });
  };

  return (
    <button
      className={'group relative w-full focus:scale-[0.98]'}
      onClick={onClick}
    >
      {(props.grantStatus === 'new' || props?.grantStatus === 'inProgress') && // only show on incoming grants
      application_sections &&
      application_sections?.length > 0 ? (
        <div className="absolute top-3 right-3 ">
          <div className="bg-gradient-to-br to-[#DF988D] via-[#E3AB58] from-[#BCC0CF] group-hover:to-[#BCC0CF] group-hover:from-[#DF988D] text-white rounded-lg w-full py-1 px-2 text-xs flex flex-row items-center">
            ✦<span className="pl-1 group-hover:hidden ">Auto</span>
            <span className="pl-1 hidden group-hover:flex">
              Auto apply with Vula
            </span>
          </div>
        </div>
      ) : 3 > daysLeft && daysLeft > 0 ? (
        <div className="absolute top-3 right-3">
          <div className="z-10 bg-gray-200 rounded-full w-full py-1 px-4 text-xs">
            {daysLeft} days left
          </div>
        </div>
      ) : null}
      <div
        className={
          'group-focus:bg-gray-100 group-hover:bg-gray-200 rounded-lg shadow-lg p-4 m-2  text-left max-w-full max-h-[150px] overflow-hidden ' +
          (selectedGrant?.grant.id === props.grant.id
            ? ' bg-stone-50'
            : ' bg-white ')
        }
      >
        <div className="whitespace-nowrap overflow-hidden text-ellipsis	">
          <b>{name}</b>
        </div>
        <div className="overflow-hidden text-ellipsis	line-clamp-2">
          {monetary_offer}
        </div>
      </div>
    </button>
  );
}
