import React from 'react';
import Pill from '../components/utils/Pill';
import ContentSection from '../components/utils/ContentSection';
import { track } from '../interfaces/mixpanel';
import ShareIcon from '../components/Icons/ShareIcon';
import { sendNotification } from '../api/monitoring';

export default function WhitePaper() {
  // state
  const [submitted, setSubmitted] = React.useState(false);
  const [copiedToClip, setCopiedToClip] = React.useState(false);

  // ref
  const emailRef = React.useRef<HTMLInputElement>(null);

  const onClickOfImage = () => {
    // focus on the input element
    emailRef.current?.focus();
  };

  // on load record view
  React.useEffect(() => {
    track('Whitepaper page view');
  }, []);

  const notifyUs = async () => {
    await sendNotification(
      `email: ${emailRef.current?.value}`,
      'New whitepaper view request',
    );
  };
  const openWhitePaper = () => {
    // open in new tab
    window.open('https://vula.vc/WP1');
  };

  return (
    <div className="min-w-[100vw] min-h-[100vh] bg-white">
      <div className="w-full h-full flex flex-col sm:flex-row justify-center items-between">
        <div className="relative sm:w-1/2 min-h-[100vh] h-full flex flex-col justify-center bg-stone-900 text-stone-50 text-left px-8 sm:px-20 py-4 sm:py-12">
          <div className="absolute top-0 left-0 px-8 sm:px-20 py-4">
            <Pill>White Paper</Pill>
          </div>
          <div className="h-full">
            <div className="py-12">
              <div className="text-5xl  font-extrabold ">
                Closing Africa’s SME funding gap
              </div>
            </div>
            <div className="text-sm">
              <div>
                The IFC estimates a $330B funding gap for small and medium
                enterprises (SMEs) in Africa. Providing almost half of the
                continent’s GDP and a majority of its jobs, SMEs are a ladder to
                prosperity for all, especially the 12 million young people
                entering the workforce each year.
                <br />
                <br />
                In this paper, we explore Vula’s findings on the power of better
                data, collaboaration and cutting-edge AI and automation tools
                for both funders and SMEs, to help close the funding gap and
                pave the way for SME growth in Africa.
              </div>
            </div>

            <div className="max-w-[600px] m-auto">
              <ContentSection background="colourful" shadow={true}>
                <div className="flex flex-col w-full">
                  {submitted ? (
                    <>
                      <div className="text-xl font-bold pb-2">
                        Please share to others who may be interested.
                      </div>
                      <button
                        className="w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                        onClick={() => {
                          track('White paper 1 shared', {
                            email: emailRef.current?.value,
                          });
                          if (navigator?.share) {
                            navigator.share({
                              title: 'Vula: SME Funding in Africa',
                              text: 'Something interesting? SME finance across Africa using AI',
                              url: 'https://vula.vc/white-papers',
                            });
                          } else {
                            navigator.clipboard.writeText(
                              'Something interesting? SME finance across Africa using AI- https://vula.vc/white-papers',
                            );
                            setCopiedToClip(true);
                          }
                        }}
                      >
                        <div className="fill-stone-200 flex flex-row justify-center ">
                          <ShareIcon />
                          <span className="px-4">
                            {copiedToClip ? 'Copied to clipboard!' : 'Share'}
                          </span>
                        </div>
                      </button>
                    </>
                  ) : (
                    <>
                      <div className="  text-stone-800">
                        <input
                          ref={emailRef}
                          type="text"
                          placeholder="Email"
                          className={
                            'w-full text-stone-500  border bg-stone-200 border-stone-300 rounded-md px-4 py-2 sm:text-md text-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:scale-[1.05]'
                          }
                        />
                      </div>

                      <div className="pt-2">
                        <button
                          className=" w-full bg-stone-900 text-stone-50 rounded-md px-4 py-2"
                          onClick={async () => {
                            if (emailRef.current?.value.length) {
                              await notifyUs();
                              openWhitePaper();
                              track('White paper access request', {
                                email: emailRef.current?.value,
                              });
                              setSubmitted(true);
                            }
                          }}
                        >
                          Get the white paper
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </ContentSection>
            </div>
          </div>
        </div>
        <div className="sm:w-1/2  h-full flex flex-col justify-center bg-white text-stone-800 text-left m-auto px-8 sm:px-20">
          <div className="w-full flex justify-center py-10  ">
            <a href="#" onClick={onClickOfImage}>
              <img
                src="https://vula-assets.s3.eu-west-2.amazonaws.com/WP1Cover.png"
                alt="Vula's White Paper cover"
                className=" max-h-[75vh] shadow-2xl rounded-[20px] hover:scale-110 transition-all duration-200 hover:rotate-3"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
