import React, { useContext, useEffect } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';

import Button from '../utils/Button';
import EditModeContainer from './EditModeContainer';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { PageWelcomeMessages } from './SimpleHelperEnums';

interface Props {
  incrementStep?: (number: 1 | -1) => void;
  customNextStep?: () => void;
  customNextStepName?: string;
}

export default function EditMode(props: Props) {
  const navigate = useNavigate();
  const {
    interactiveMessage,
    investorMode,
    setInvestorMode,
    setPageMessage,
    userIsAdmin,
  } = useContext(EditPitchContext);

  useEffect(() => {
    if (investorMode) {
      setPageMessage(PageWelcomeMessages.investorMode);
    } else {
      setPageMessage(PageWelcomeMessages.pitchWelcome);
    }
  }, [investorMode]);

  const renderHelperMessage = () => {
    return (
      <div
        className={
          'w-full text-left  flex justify-start  ' +
          (interactiveMessage.includes('Saved')
            ? ' text-lime-600'
            : interactiveMessage.includes('❌')
            ? ' text-red-700'
            : ' text-stone-50') +
          (props.incrementStep
            ? ' sm:w-full sm:text-left sm:text-xl'
            : ' sm:text-left text-xs sm:text-sm pr-1')
        }
      >
        {interactiveMessage}
      </div>
    );
  };

  // if user is not admin, do not show edit mode
  if (!userIsAdmin) {
    return null;
  }

  if (investorMode) {
    return (
      <EditModeContainer
        backNavAction={() => setInvestorMode(false)}
        backNavName={'Back to edit mode'}
      >
        {renderHelperMessage()}
        <div className="px-2">
          <Button
            text="Share"
            action={() => navigate(routes.private.sharePitch)}
          />
        </div>
      </EditModeContainer>
    );
  }

  return (
    <EditModeContainer
      backNavAction={
        props.incrementStep
          ? () => props.incrementStep?.(-1)
          : () => navigate(routes.private.pitchHome)
      }
      backNavName={'Back'}
    >
      <div className="w-full flex justify-between items-center">
        {renderHelperMessage()}
        <div className="px-2">
          {typeof props.incrementStep !== 'undefined' ? (
            <Button
              text="Next"
              action={() =>
                props.incrementStep ? props.incrementStep(1) : null
              } // not sure why I have to check twice
            />
          ) : typeof props.customNextStep !== 'undefined' &&
            props.customNextStepName ? (
            <Button
              text={props.customNextStepName}
              action={() =>
                props.customNextStep ? props.customNextStep() : null
              } // not sure why I have to check twice
            />
          ) : (
            <Button text="Preview" action={() => setInvestorMode(true)} />
          )}
        </div>
      </div>
    </EditModeContainer>
  );
}
