import React from 'react';
import ContentSection from '../utils/ContentSection';
import VulaLogo from '../utils/VulaLogo';
import { routes } from '../../pages/routes';
import { addUserToMailingList } from '../../api/marketing';
import { track } from '../../interfaces/mixpanel';

interface JunkDrawerProps {
  // light mode sign up form
  light?: boolean;
}

export default function JunkDrawer(props: JunkDrawerProps) {
  const [signedUp, setSignedUp] = React.useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  // TODO: need to change this to use routes
  const junkDrawLinkns = [
    {
      title: 'Company',
      links: [
        // {
        //   title: 'About',
        //   link: '/about',
        // },
        // {
        //   title: 'Privacy',
        //   link: '/privacy',
        // },
        {
          title: 'Master Plan',
          link: routes.public.masterPlan,
        },
        {
          title: 'Careers',
          link: routes.public.careers,
        },
        {
          title: 'White Paper',
          link: routes.public.whitePapers,
        },
      ],
    },
    {
      title: 'For SMEs',
      links: [
        // {
        //   title: 'Support',
        //   link: '/support',
        // },
        // {
        //   title: 'Requests',
        //   link: '/requests',
        // },
        {
          title: 'FX',
          link: routes.public.fx,
        },
        {
          title: 'Treasury',
          link: routes.public.deposits,
        },
      ],
    },
    {
      title: 'For SMEs Funders',
      links: [
        {
          title: 'VulaOS',
          link: routes.public.os,
        },
        {
          title: 'Submit grants',
          link: routes.public.grants,
        },
        {
          title: 'Syndication',
          link: routes.public.syndication,
        },
      ],
    },
    {
      title: 'Sales',
      links: [
        //         Contact Sales
        {
          title: 'Contact Sales',
          link: routes.public.introCall,
        },

        // Request a Demo
        {
          title: 'Request a Demo',
          link: routes.public.demoCall,
        },
      ],
    },
    {
      title: 'Media',
      links: [
        // {
        //   title: 'Twitter',
        //   link: '/twitter',
        // },
        // {
        //   title: 'LinkedIn',
        //   link: '/linkedin',
        // },
        {
          title: 'Press Room',
          link: '/press-room',
        },
      ],
    },
  ];

  return (
    <div className="m-auto">
      <ContentSection background="black">
        <div className="m-auto sm:max-w-[1000px] flex flex-col sm:flex-row justify-between w-full">
          {/* one third that just has our logo and a copyright */}

          <div className=" rounded-lg p-2 flex flex-row sm:flex-col text-left justify-between sm:min-h-full">
            <a href="/">
              <VulaLogo type="sun-white-text" />
            </a>
            <div className="text-stone-500 text-xs ">© Vula 2024</div>
          </div>

          {/* two thirds which contains a signup form and a set of links underneath that */}
          <div className="h-full sm:w-3/4 flex flex-col text-left">
            <div
              className={
                'rounded-xl p-4 ' +
                (props.light
                  ? 'bg-stone-200 hover:bg-stone-300 text-stone-700  active:border-stone-900 hover:border-stone-900 focus:border-stone-900'
                  : 'bg-stone-800 hover:bg-stone-700 text-stone-300  active:border-stone-100 hover:border-stone-100 focus:border-stone-100 ')
              }
            >
              {signedUp ? (
                <div className="  text-m py-4 ">
                  Welcome to the community! 👋
                </div>
              ) : (
                <>
                  <input
                    className="w-full   p-2 mb-2 rounded-xl border border-solid border-1"
                    placeholder="Join the community"
                    ref={inputRef}
                  />
                  <div className="flex justify-between">
                    <div className=" text-xs px-2 mt-2">
                      We'll send you updates on our progress
                    </div>
                    <button
                      className={
                        '  font-extrabold text-m  px-4 rounded-full py-1 ' +
                        (props.light
                          ? 'bg-stone-300 text-stone-800'
                          : 'bg-stone-700 text-stone-200')
                      }
                      style={{ whiteSpace: 'nowrap' }}
                      onClick={async () => {
                        // add to email list
                        await addUserToMailingList(
                          inputRef.current?.value || '',
                          'Vula Community',
                        );
                        track('community-signup');
                        setSignedUp(true);
                      }}
                    >
                      Sign up
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="text-white text-stone-300 text-xs py-4 ">
              {/* a grid of links with the titles : Product (links support, requests, syndication,fx), Company (About, Privacy, Careers, Master Plan, White papers, Press room), Socials (Twitter, LinkedIn) */}
              <div className="grid grid-cols-3 sm:grid-cols-4 gap-4">
                {junkDrawLinkns.map(section => (
                  <div className="flex flex-col" key={section.title}>
                    <div className="p-2 font-bold text-sm">{section.title}</div>
                    {section.links.map(link => (
                      <a
                        key={link.title}
                        className="w-fit p-2 hover:text-stone-100 hover:underline  hover:bg-stone-800 rounded transition-all duration-200 ease-in-out"
                        href={link.link}
                      >
                        {link.title}
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ContentSection>
    </div>
  );
}
