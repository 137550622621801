import React from 'react';
import Button from '../utils/Button';
import { ApplicationContext } from '../../contexts/ApplicationContext';

export default function WelcomeApplicationPage() {
  const { moveToNextSection, lendingProductApplicationDefinition } =
    React.useContext(ApplicationContext);

  const welcome_section = lendingProductApplicationDefinition?.sections?.find(
    section => section.type === 'welcome',
  );
  return (
    <div>
      <div className=" rounded-lg  overflow-hidden">
        <img
          src={lendingProductApplicationDefinition?.image}
          alt="logo"
          className="w-full"
        />
      </div>
      <div className="p-2 sm:p-4">
        <div className="text-xl sm:text-2xl font-bold sm:py-4">
          {welcome_section?.title}
        </div>
        <div className="text-sm sm:text-m" style={{ whiteSpace: 'pre-wrap' }}>
          {welcome_section?.subtitle}
        </div>
        <div className="h-4"></div>
        <div className="flex w-full justify-center sm:justify-start pt-4">
          <Button text="Let's start" action={() => moveToNextSection(1)} />
        </div>
      </div>
    </div>
  );
}
