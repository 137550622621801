import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';
import { VulaCompanyAPI } from '../../api/company';
import { VulaCorpusAPI } from '../../api/corpus';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { track } from '../../interfaces/mixpanel';
import { apiUrl } from '../../methods/env';

export default function CompanyPitchChange() {
  // Contexts
  const { company_elevator_pitch, company_slug } = useContext(LoggedInContext);

  const { getAccessTokenSilently, user } = useAuth0();

  // State
  const [savedMessage, setSavedMessage] = useState('');
  const [errorText, setErrorText] = useState('');
  const [reportButtonVis, showReportButton] = useState(true);
  const [elevatorPitchText, setElevatorPitchText] = useState(
    company_elevator_pitch,
  );

  // References
  const elevatorPitchInput = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    // only show messaaging to users for a short period of time
    if (errorText.length || savedMessage.length)
      setTimeout(() => {
        clearMessages();
      }, 2000);
  }, [errorText, savedMessage]);

  const clearMessages = () => {
    setErrorText('');
    setSavedMessage('');
  };

  const updateElevatorPitch = async () => {
    if (
      !elevatorPitchInput.current?.value ||
      elevatorPitchInput.current?.value.length < 10
    ) {
      setErrorText('elevator pitch must be at least 10 characters');
    } else if (company_elevator_pitch !== elevatorPitchInput.current?.value) {
      await saveElevatorPitch(elevatorPitchInput.current.value);
    }
  };

  const saveElevatorPitch = async (pitch: string) => {
    // save pitch to the api if different

    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });
    const api = new VulaCorpusAPI({ token: auth0Token });
    try {
      await api.updateCorpus({
        slug: company_slug,
        label: 'elevator_pitch',
        answer: pitch,
      });
      setSavedMessage('✅ Saved');
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorText(JSON.stringify(error.message));
      }
    }
  };

  const deleteScrape = async () => {
    track('Poor elevator pitch', { user, company_elevator_pitch });
    const auth0Token = await getAccessTokenSilently({
      audience: apiUrl,
    });

    const api = new VulaCompanyAPI({ token: auth0Token });
    try {
      await api.deleteCompanyScrape(company_slug);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorText(JSON.stringify(error.message));
      }
    }
  };

  const reportElevatorPitch = async () => {
    // clear the text box
    if (elevatorPitchInput.current?.defaultValue) {
      elevatorPitchInput.current.value = '';
    }
    // delete the scrape
    await deleteScrape();
    // delete the corpus
    saveElevatorPitch('');
    // show undo button
    showReportButton(false);
  };

  const undoDeletedElevatorPitch = () => {
    track('Poor elevator pitch - undo', { user, company_elevator_pitch });

    showReportButton(true);
    if (elevatorPitchInput.current?.defaultValue) {
      elevatorPitchInput.current.value = elevatorPitchText;
      saveElevatorPitch(elevatorPitchText);
    }
  };

  return (
    <div className="flex flex-col py-8">
      <div className="text-left text-stone-500  pb-4">
        <div>Your elevator pitch</div>
        {company_elevator_pitch ? (
          <div className="text-xs pr-4">
            Check, edit or report this elevator pitch - this is what Vula thinks
            you do!
            {errorText ? (
              <div className="text-red-600 text-sm">{errorText}</div>
            ) : null}
            {savedMessage ? (
              <div className="text-green-600 text-sm">{savedMessage}</div>
            ) : null}
          </div>
        ) : (
          <div className="text-xs pr-4">
            Please write a summary of what your business does, who your
            customers are and what makes you different.
          </div>
        )}
      </div>
      <div className="relative h-[500px] sm:h-[20vh] w-full text-left ">
        <textarea
          ref={elevatorPitchInput}
          maxLength={2000}
          className=" border border-solid border-stone-200 p-2 rounded shadow-2xl h-full w-full "
          placeholder={`Write a summary of what your business does.`}
          defaultValue={company_elevator_pitch}
          onChange={e => setElevatorPitchText(e.target.value)}
          onBlur={() => updateElevatorPitch()}
        ></textarea>

        {reportButtonVis ? (
          <div className="group absolute -bottom-16 sm:bottom-0 right-0 ">
            <div className="group-hover:block hidden relative">
              <div className="absolute bottom-0 text-center bg-stone-900 text-stone-100 p-2 rounded-xl">
                We will delete Vula's current understanding of your business and
                ask you to write the elevator pitch
              </div>
            </div>
            <button
              className={
                'm-4 rounded-full p-2 px-4 text-sm bg-stone-100 opacity-50 hover:opacity-100 hover:bg-stone-300 border border-1 border-solid border-stone-200' +
                (reportButtonVis ? ' ' : ' hidden')
              }
              onClick={() => reportElevatorPitch()}
            >
              Report bad answer
            </button>
          </div>
        ) : (
          <button
            className={
              'absolute bottom-0 right-0 m-4 rounded-full p-2 px-4 text-sm bg-stone-100 opacity-30 hover:opacity-100 hover:bg-stone-300 border border-1 border-solid border-stone-200'
            }
            onClick={() => undoDeletedElevatorPitch()}
          >
            Undo delete
          </button>
        )}
      </div>
    </div>
  );
}
