import React from 'react';
import Spinner from './Spinner';

interface Props {
  icon: React.ReactNode;
  tooltipText: string;
  onClick: () => void;
  fadeIn?: boolean;
  hiddenOnMobile?: boolean;
}

export default function EditTextButtons(props: Props) {
  const { fadeIn, onClick, hiddenOnMobile, icon, tooltipText } = props;

  const [showSpinner, setShowSpinner] = React.useState(false);

  return (
    <button
      className={
        ' group-hover:mx-1 drop-shadow-xl bg-stone-50 border border-solid border-stone-300 rounded border-1 h-fit fill-stone-500 sm:fill-stone-400 hover:fill-stone-500' + //
        ' transition-all duration-300 ease-in-out transform sm:scale-0 ' + // animation
        (fadeIn
          ? ' group-hover:scale-100 relative sm:scale-0 group-hover:scale-100 '
          : ' group-hover:scale-100 sm:scale-100 ') +
        (hiddenOnMobile ? ' hidden sm:block' : '') +
        // add the group label
        ' group/icon'
      }
      onClick={() => {
        setShowSpinner(true);
        onClick();
        setTimeout(() => {
          setShowSpinner(false);
        }, 1000);
      }}
    >
      {showSpinner ? (
        <div className="scale-[0.6]">
          <Spinner />
        </div>
      ) : (
        <div
          className={
            'group-hover/icon:p-2 p-2  rounded transition-all group-hover/icon:border-stone-800 text-stone-500 ' +
            (fadeIn
              ? ' w-0 w-[32px] h-[32px] group-hover/icon:w-full transition-all duration-300 ease-in-out transform sm:scale-0 group-hover:scale-100'
              : ' w-[32px] h-[32px]')
          }
        >
          {icon}
          <div
            className={
              'text-xs absolute -top-4 -left-4 w-full text-center text-white group-hover/icon:text-stone-800 scale-0 group-hover/icon:scale-100 transition-all duration-300 delay-1000 ease-in-out '
            }
          >
            {tooltipText}
          </div>
        </div>
      )}
    </button>
  );
}
