import React from 'react';
import Navbar from '../components/Landing/LandingNavbar';
import JunkDrawer from '../components/Landing/JunkDrawer';
import VulaLogo from '../components/utils/VulaLogo';
import ContentSection from '../components/utils/ContentSection';
import AfricaIcon from '../components/Icons/Africa';
import MailingListComponent from '../components/utils/MailingListComponent';

export default function Careers() {
  return (
    <div className="h-full min-h-[100vh] max-w-full m-auto flex items-center flex-col bg-white ">
      <div className=" w-full text-center">
        <div className="w-full bg-stone-900 text-stone-50 text-left py-20 px-4 ">
          <div
            className="max-w-[800px] m-auto ring ring-4 ring-white ring-solid rounded-2xl bg-black animate-rotate"
            style={{ animationFillMode: 'forwards' }}
          >
            <div
              className="max-w-[900px] m-auto ring ring-4 ring-white ring-solid rounded-2xl bg-black animate-rotate"
              style={{ animationFillMode: 'forwards' }}
            >
              <div
                className="max-w-[900px] m-auto ring ring-4 ring-white ring-solid rounded-2xl bg-black animate-rotate"
                style={{ animationFillMode: 'forwards' }}
              >
                <div
                  className="max-w-[900px] m-auto ring ring-4 ring-white ring-solid rounded-2xl bg-black animate-rotate "
                  style={{ animationFillMode: 'forwards' }}
                >
                  <ContentSection background="colourful" removeMargin>
                    <div className="w-full flex flex-col sm:flex-row items-center relative">
                      <div className="relative h-full pr-8 pt-40 sm:pt-0 w-1/3 flex justify-end sm:justify-center">
                        <div className="h-full z-10 pl-8 absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center">
                          <VulaLogo type="light" />
                        </div>
                        <div className="h-full absolute top-0 bottom-0 left-0 right-0 flex justify-center  items-center ">
                          <AfricaIcon />
                        </div>
                      </div>
                      <div className="sm:pt-20 sm:pt-0 w-full flex flex-col justify-start text-left m-auto py-4">
                        <div className="text-m text-left py-4">In isiXhosa</div>
                        <h1 className="text-4xl sm:text-5xl font-extrabold  ">
                          Vula means "open"
                        </h1>
                      </div>
                    </div>
                  </ContentSection>
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-[800px] text-center m-auto pt-20">
            <h2 className="text-lg ">
              Open to new ideas, new people, new ways of doing things.
            </h2>
          </div>

          <div className="max-w-[1000px] m-auto pt-40">
            <div className="text-4xl font-extrabold">Join the team</div>
            <div className="py-2">
              No roles are currently open, but we're always looking for great
              people.
            </div>
            <div className="py-2">
              If you're interested in Vula we should talk.
            </div>
            <div className="py-2">
              Add your email below and we will get in touch.
            </div>
            <MailingListComponent
              completedMessage="💪 Speak to you shortly!"
              placeholder="Get on our radar"
              buttonText="Get in touch"
              listName="Vula Careers"
            />
          </div>

          <div className="p-4 px-8 mt-40 text-center m-auto max-w-[500px] ring ring-8 ring-white ring-solid rounded-xl">
            We are closing Africa’s $300B+ SME funding gap - to pull the
            contient out of poverty, transform lives and create a more
            equitable, prosperous world.
          </div>
        </div>
        <JunkDrawer />
        <Navbar />
      </div>
    </div>
  );
}
