import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import CreateParamPill from './CreateParamPill';
import DefinitionsFrame from './DefinitionsFrame';
import ParamPill from './ParamPill';
import { allOtherCountries, africanCountries } from '../../data/countries';
import Button from '../utils/Button';

export default function CompanyGeosDefinitions() {
  const { companyParams, getCompanyParams } = useContext(GrantContext);

  const geosAreValid = () => {
    // check if geos are in the correct format

    // for each param, check if it's in allCountries
    const companyGeos = companyParams?.parameters
      .filter(param => param.type === 'geography')
      .map(param => {
        return param.text;
      });

    const geosInAllCountries = companyGeos?.filter(geo =>
      allOtherCountries
        .concat(africanCountries)
        .map(country => country.name)
        .includes(geo),
    );

    // if geo is not in allCountries, return true
    if (companyGeos?.length === geosInAllCountries?.length) {
      return true;
    }
    // if geos contain All countries, return true
    if (companyGeos?.includes('All countries')) {
      return true;
    }

    return false;
  };

  return (
    <DefinitionsFrame
      helperText="Countries that you operate in."
      label="Geos"
      errorText='You must have at least one "Geography"'
      showError={
        // if no geos, show error
        !companyParams?.parameters ||
        companyParams?.parameters?.filter(param => param.type === 'geography')
          .length === 0 ||
        // if not a geo in countries or africanCountries, show error
        !geosAreValid()
      }
    >
      <div className="border border-solid border-2 border-gray-300 p-2 rounded-lg">
        <div className="flex flex-wrap items-center ">
          {companyParams?.parameters ? (
            companyParams.parameters
              .filter(param => param.type === 'geography')
              .map(param => {
                return <ParamPill key={param.id} parameter={param} />;
              })
          ) : (
            <Button
              text="Ask Vula"
              asynAction={() => (async () => await getCompanyParams())()}
            />
          )}
          <CreateParamPill type="geography" />
        </div>
      </div>
    </DefinitionsFrame>
  );
}
