import React, { useEffect, useState } from 'react';

interface Props {
  pretext?: string;
  leftText: string;
  rightText: string;
  stateChange: () => void;
  highlightWhenOn?: boolean;
  initialState?: boolean;
  leftIsOff?: boolean;
}

export default function SimpleToggle(props: Props) {
  const [toggleLeft, toggleIsLeft] = useState(!props.initialState);

  useEffect(() => {
    toggleIsLeft(!props.initialState); // if initial state is true, toggle is left
  }, [props.initialState]);

  const userToggled = () => {
    props.stateChange();
    toggleIsLeft(!toggleLeft);
  };

  return (
    <div className="w-full flex flex-col justify-center py-4">
      {props.pretext ? (
        <div className="text-2xl font-black pb-4  bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]">
          {props.pretext}
        </div>
      ) : null}
      <div className="flex justify-center w-full">
        <div className="flex flex-row justify-center items-center w-full">
          {/* LHS */}
          <button
            onClick={() => toggleIsLeft(true)}
            className={
              'text-2xl sm:text-3xl pr-4 text-right ' +
              (toggleLeft
                ? ' font-bold  bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] from-[#E3AB58] '
                : ' text-gray-400 ')
            }
            style={{ width: '42%' }} // hack to stop it moving
          >
            {props.leftText}
          </button>

          {/* TOGGLE */}
          <button
            onClick={() => userToggled()}
            className={
              'rounded-full h-10 sm:h-12 px-2 sm:px-3 grid items-center w-full  ' +
              (toggleLeft ? ' justify-items-start' : ' justify-items-end') +
              (props.highlightWhenOn && !toggleLeft // if highlight when on
                ? ' bg-gradient-to-br to-[#DF988D] via-[#E3AB58] from-[#BCC0CF]'
                : '') +
              (props.leftIsOff && toggleLeft
                ? ' bg-stone-400 '
                : ' bg-gradient-to-br to-[#DF988D] via-[#E3AB58] from-[#BCC0CF] ')
            }
            style={{
              animationFillMode: 'forwards',
              width: '7%',
              minWidth: '90px',
            }}
          >
            <div className="rounded-full ring-2 sm:ring-4 ring-white w-4 sm:w-5 h-4 sm:h-5 "></div>
          </button>

          {/* RHS */}
          <button
            onClick={() => toggleIsLeft(false)}
            className={
              'text-2xl sm:text-3xl pl-4 text-left  ' +
              (!toggleLeft
                ? ' font-bold  bg-gradient-to-r bg-clip-text text-transparent to-[#DF988D] from-[#E3AB58]'
                : ' text-gray-400')
            }
            style={{ width: '42%' }} // hack to stop it moving
          >
            {props.rightText}
          </button>
        </div>
      </div>
    </div>
  );
}
