import React from 'react';

/* a box like above, but with tabs that show key information, team, questions, contact */

export default function KeyInfoTabs() {
  const [tab, setTab] = React.useState<'key-info' | 'team' | 'Q&A' | 'contact'>(
    'key-info',
  );

  const tabs: {
    key: 'key-info' | 'team' | 'Q&A' | 'contact';
    title: string;
  }[] = [
    {
      key: 'key-info',
      title: 'Key Info',
    },
    {
      key: 'team',
      title: 'Team',
    },
    // {
    //   key: 'Q&A',
    //   title: 'Q&A',
    // },
    {
      key: 'contact',
      title: 'Contact',
    },
  ];

  const tabButtons = () => {
    return (
      <div className=" flex flex-row border border-t-0 border-x-0 border-solid border-1 border-stone-100">
        {tabs.map(tabTemplate => (
          <button
            className={`border border-solid border-1 border-stone-100 shadow p-3 rounded-b-none rounded-t-3xl hover:bg-white w-full sm:w-1/5 sm:mr-3 transition-all duration-500 text-sm ${
              tab === tabTemplate.key ? 'bg-white' : ' '
            }`}
            onClick={() => setTab(tabTemplate.key)}
          >
            {tabTemplate.title}
          </button>
        ))}
      </div>
    );
  };

  const tabContent = () => {
    switch (tab) {
      case 'key-info':
        return keyInfo();
      case 'team':
        return team();
      // case 'Q&A':
      //   return questions();
      case 'contact':
        return contact();
    }
  };

  const keyInfo = () => {
    return (
      <div className="flex flex-col">
        {/* Headlines and text about problem, market size, solution */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 pt-4">
          <div className="flex flex-col">
            <h4 className="text-2xl text-stone-900">Problem</h4>
            <p className="text-stone-900">The problem is...</p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-2xl text-stone-900">Market size</h4>
            <p className="text-stone-900">The market size is...</p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-2xl text-stone-900">Solution</h4>
            <p className="text-stone-900">The solution is...</p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-2xl text-stone-900">Traction</h4>
            <p className="text-stone-900">The traction is...</p>
          </div>
          <div className="flex flex-col">
            <h4 className="text-2xl text-stone-900">Growth strategy</h4>
            <p className="text-stone-900">The strategy is...</p>
          </div>
        </div>
      </div>
    );
  };
  const team = () => {
    return (
      <div className="flex flex-col">
        <div
          className="grid  grid-cols-1 sm:grid-cols-2 gap-4 pt-4
        "
        >
          {[
            {
              founderName: 'Founder 1',
              img: 'https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg',
              subtitle: 'Blurb',
              linkedin: 'https://www.linkedin.com/in/vuyisa-qabaka-95b1b1/',
            },
            {
              founderName: 'Founder 2',
              img: 'https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg',
              subtitle: 'Blurb',
              linkedin: 'https://www.linkedin.com/in/vuyisa-qabaka-95b1b1/',
            },
          ].map(founder => (
            <div className="p-3  w-full bg-white rounded-xl shadow">
              <div className="flex flex-row items-center justify-between ">
                <img src={founder.img} className="rounded-full w-32 h-32 " />
                <div className="flex flex-col justify-end text-right">
                  <h4 className="text-3xl text-stone-900">
                    {founder.founderName}
                  </h4>
                  <p className="text-stone-900">{founder.subtitle}</p>
                  <a
                    href={founder.linkedin}
                    target="_blank"
                    rel="noreferrer"
                    className="text-stone-900 flex items-center justify-end w-full"
                  >
                    <img
                      src="https://www.svgrepo.com/show/506517/linkedin.svg"
                      className="w-8 h-8"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <h4 className="text-xl text-stone-900 pl-4 pt-4">Advisors</h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 ">
          {[
            {
              advisorName: 'Advisor 1',
              img: 'https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg',
              subtitle: 'Subtitle',
              linkedIn: 'https://www.linkedin.com/in/',
            },
            {
              advisorName: 'Advisor 1',
              img: 'https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg',
              subtitle: 'Subtitle',
              linkedIn: 'https://www.linkedin.com/in/',
            },
            {
              advisorName: 'Advisor 1',
              img: 'https://assets-global.website-files.com/62a1db362f4c742516937d9f/62da6655e440ccad11f08bb6_95.-Vuyisa.jpeg',
              subtitle: 'Subtitle',
              linkedIn: 'https://www.linkedin.com/in/',
            },
          ].map(advisor => (
            <div className="flex flex-row items-center m-2 p-3 rounded-xl bg-white shadow">
              <img src={advisor.img} className="rounded-full w-20 h-20 mr-8" />

              <div className="flex flex-col">
                <h4 className="text-3xl text-stone-900">
                  {advisor.advisorName}
                </h4>
                <p className="text-stone-900">{advisor.subtitle}</p>
                <a
                  href={advisor.linkedIn}
                  target="_blank"
                  rel="noreferrer"
                  className="text-stone-900 flex items-center justify-start w-full"
                >
                  <img
                    src="https://www.svgrepo.com/show/506517/linkedin.svg"
                    className="w-8 h-8"
                  />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const questions = () => {
    return (
      <div className="flex flex-col">
        <h3 className="text-m text-stone-900">
          Sourced by the community of investors
        </h3>
        <div className="grid grid-cols-1 gap-4 pt-4">
          {[
            {
              question: 'Question 1',
              answer: 'Answer 1',
            },
            {
              question: 'Question 2',
              answer: 'Answer 2',
            },
            {
              question: 'Question 3',
              answer: 'Answer 3',
            },
          ].map(qa => (
            <div className="flex flex-col p-3 rounded-lg bg-white">
              <h4 className="font-bold text-stone-900">{qa.question}</h4>
              <p className="text-stone-900">{qa.answer}</p>
            </div>
          ))}
          {/* invite users to ask a question */}
          <div className="flex flex-col  rounded-lg bg-white">
            <h4 className="font-bold text-stone-900 p-3">Ask a question</h4>
            <div className="flex">
              <input
                type="text"
                placeholder="Your question"
                className="m-2 p-1 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-stone-200 focus:ring-opacity-50"
              />
              <button className="mx-3 mb-3 bg-stone-100 text-stone-900 rounded-lg px-8 p-4 mt-4">
                Ask
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const contact = () => {
    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-1  gap-4 pt-4 text-stone-900">
          {[
            {
              contact: 'Email with Nic (CEO)',
              link: '',
              icon: 'https://www.svgrepo.com/show/502648/email.svg',
            },
            {
              contact: 'Chat with Nic (CEO)',
              icon: 'https://www.svgrepo.com/show/475692/whatsapp-color.svg',
              link: '',
            },
          ].map(contact => (
            <a
              href={contact.link}
              target="_blank"
              rel="noreferrer"
              className="flex items-center bg-white hover:bg-stone-50 text-stone-900 rounded-lg p-4 w-fit"
            >
              {contact.icon ? (
                <img src={contact.icon} className="w-6 h-6 mr-2" />
              ) : null}
              {contact.contact}
            </a>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="mt-8">
      {tabButtons()}
      <div className="w-full rounded-b-xl  shadow-lg border border-solid border-1 border-stone-100 ">
        <div className="p-4">{tabContent()}</div>
      </div>
    </div>
  );
}
