import React from 'react';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import Button from '../utils/Button';

export default function SectionNavigation() {
  const { moveToNextSection, allRequiredAnswersAreCompleted, visibleTab } =
    React.useContext(ApplicationContext);
  return (
    <div className=" p-4   flex justify-between items-center ">
      <Button text="Back" action={() => moveToNextSection(-1)} />
      {allRequiredAnswersAreCompleted && (
        <div className="flex  items-center ">
          <div className="text-green-400 text-xs px-4">
            All required answers are complete
          </div>
        </div>
      )}
      {visibleTab?.type === 'submit' ? null : (
        <Button text="Next" action={() => moveToNextSection(1)} />
      )}
    </div>
  );
}
