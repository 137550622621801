import React, { useContext } from 'react';
import ContentSection from '../../utils/ContentSection';
import Button from '../../utils/Button';
import { GrantContext } from '../../../contexts/GrantContext';

export default function DoYouWantToApply() {
  const { updateGrantStatus, selectedGrant, defineSelectedGrant } =
    useContext(GrantContext);

  return (
    <ContentSection background="colourful">
      <div className="flex flex-col w-full">
        <div className="text-white text-2xl font-extrabold ">
          Do you want to apply?
        </div>

        <div className="pt-4 flex flex-row ">
          <Button
            action={async () => {
              if (updateGrantStatus && selectedGrant) {
                defineSelectedGrant({
                  ...selectedGrant,
                  status: 'inProgress',
                });
                await updateGrantStatus('inProgress');
              }
            }}
            text="Yes"
          />
          <div className="pl-4">
            <Button
              buttonType="secondary"
              action={async () => {
                if (updateGrantStatus && selectedGrant) {
                  defineSelectedGrant({
                    ...selectedGrant,
                    status: 'archived',
                  });
                  await updateGrantStatus('archived');
                }
              }}
              text="No"
            />
          </div>
        </div>
      </div>
    </ContentSection>
  );
}
