import React, { useContext } from 'react';
import ContentSection from '../../utils/ContentSection';
import { track } from '../../../interfaces/mixpanel';
import QAAWithVula from '../../utils/QAAWithVula';
import VulaLogo from '../../utils/VulaLogo';
import { GrantContext } from '../../../contexts/GrantContext';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';

export default function AskVulaGrantQuestions() {
  const { user } = useAuth0();
  const { company_name } = useContext(LoggedInContext);
  const { selectedGrant } = useContext(GrantContext);
  const [askVula, setAskVula] = React.useState(
    selectedGrant?.status === 'inProgress',
  );
  const [question, setQuestion] = React.useState('');

  if (!selectedGrant?.grant) {
    return null;
  }

  return (
    <div className="flex flex-col  m-4 sm:m-12 pt-2 sm:pt-4 bg-stone-50 shadow rounded-xl ">
      <div className="title text-xl sm:text-2xl  pb-2 px-2 sm:px-6 ">
        Get Vula's help
      </div>
      {/* border line */}
      <div className="border-b-2 border-solid border-stone-100 w-full"></div>

      {/* Vula's hello */}
      <div className="flex flex-row  items-top align-center pt-4 pb-2 px-2 sm:px-6 ">
        <div className="w-fit h-fit min-w-[40px] bg-white border-1 border-solid border-stone-200 border rounded-xl p-1 sm:p-2">
          <VulaLogo />
        </div>
        <div className="pl-4 text-sm sm:text-lg  ">
          <p>
            {`Hello ${user?.nickname}, I am Vula and am trained on ${
              company_name || 'your companies data'
            }.`}
          </p>
          <p>
            {`Ask me the questions on the application form and I can write the answers for you.`}
          </p>
        </div>
      </div>
      {/* example questions */}
      {!question.length ? (
        <div className="pt-4 flex flex-col items-end justify-end w-full p-2 text-sm sm:px-6 ">
          <div className="pb-2"> For example:</div>
          <button
            onClick={() => setQuestion('What SDGs do you address?')}
            className="hover:bg-stone-100 border border-solid border-1 border-stone-300 rounded-lg px-4 py-2  w-fit"
          >
            What SDGs do you address?
          </button>
          <button
            onClick={() =>
              setQuestion('What specific problem are you trying to solve?')
            }
            className="hover:bg-stone-100 border border-solid border-1 border-stone-300 rounded-lg px-4 py-2 mt-1  w-fit"
          >
            What specific problem are you trying to solve?
          </button>
        </div>
      ) : null}

      {/* border line */}
      <div className="border-b-2 border-solid border-stone-100 w-full"></div>
      {/* input */}
      <div
        className="flex flex-row items-center align-center text-sm sm:text-lg sm:px-6 "
        onClick={async () => {
          if (!askVula) {
            track('asked_for_grant_help', {
              company_name: company_name,
              grant_name: selectedGrant?.grant.name,
              grant_id: selectedGrant?.grant.id,
            });

            setAskVula(true);
          }
        }}
      >
        <QAAWithVula
          question={question ?? undefined}
          addToPrompt={
            'We are applying to this grant: ' + selectedGrant?.grant.summary
          }
          onQuestionChange={setQuestion}
        />
      </div>
    </div>
  );
}
