import React from 'react';
import { sendNotification } from '../../api/monitoring';
import { track } from '../../interfaces/mixpanel';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { GrantContext } from '../../contexts/GrantContext';

export default function MatchingFeedback() {
  const { company_slug } = React.useContext(LoggedInContext);
  const { matchingCompanyGrantParams, selectedGrant } =
    React.useContext(GrantContext);
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);

  const feedbackRef = React.useRef<HTMLInputElement>(null);

  // notify us of the feedback
  const submitFeedback = () => {
    if (feedbackRef.current?.value) {
      sendNotification(
        feedbackRef.current?.value +
          ' - ' +
          company_slug +
          '         ' +
          selectedGrant?.grant.name +
          '         ' +
          selectedGrant?.grant.id +
          '         ' +
          JSON.stringify(matchingCompanyGrantParams),
        'Grant matching feedback',
        'a',
      );
      track('Grant matching feedback', {
        feedback: feedbackRef.current?.value,
      });
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <div className="m-1 p-1">Sent to the Vula team.</div>;
  }

  if (showFeedback) {
    return (
      // an input box to capture the feedback
      <div className="m-1 rounded-full ring ring-1 ring-stone-400 ring-solid ">
        <input
          ref={feedbackRef}
          className="  pl-4 "
          type="text"
          placeholder="What is wrong?"
        />
        <button
          onClick={() => submitFeedback()}
          className=" p-1 bg-stone-900 pl-3 pr-4 rounded-r-full text-white"
        >
          Submit
        </button>
      </div>
    );
  }
  return (
    <button
      onClick={() => setShowFeedback(true)}
      className="group flex flex-row m-1 p-1 px-4 text-sm rounded-full ring ring-1 ring-stone-400 ring-solid w-fit text-center"
    >
      <img
        width={20}
        src={'https://www.svgrepo.com/show/525339/flag.svg'}
        alt="flag"
      />
      {/* on hover of this button - show the text below within the ring by increasing the with of the parent div*/}
      <div className="hidden group:block ">Something wrong?</div>
    </button>
  );
}
