import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationContextPublic } from '../../../contexts/ApplicationContextPublic';
import Loading from '../../../pages/Loading';
import SomethingWrongPublicForm from './SomethingWrongPublicForm';
import PublicApplicationForm from './PublicApplicationForm';
import { MarkdownText } from '../../utils/MarkdownText';

export default function StandAlonePage() {
  const navigate = useNavigate();
  const [startPublicForm, setStartPublicForm] = React.useState(false);
  const {
    formDefinition,
    publicFormIsLoading,
    applicationStepName,
    resetForm,
  } = React.useContext(ApplicationContextPublic);

  if (publicFormIsLoading) {
    return <Loading />;
  }

  if (!formDefinition || 'error' in formDefinition) {
    // todo: turn into component to send us an email if it happens
    return <SomethingWrongPublicForm />;
  }

  return (
    <div
      className="min-h-[100vh] min-w-[100vw] h-full w-full"
      style={{ backgroundColor: formDefinition.bg_color }}
    >
      <div className="w-full flex flex-col items-center justify-center pt-4">
        <img
          src={formDefinition.partner_logo}
          alt="logo"
          className="max-h-8 "
        />
      </div>
      <div className="max-w-[1000px] m-auto p-1  pb-2 pt-2  h-full">
        <div className="w-full  bg-white shadow rounded-xl mt-4">
          <img
            className="w-full max-h-[400px] object-cover overflow-hidden rounded-t-xl"
            src={formDefinition.banner_img}
            alt="website header image"
          />
          <div className="text-left p-4 rounded-b-xl">
            <h1 className="text-3xl"> {formDefinition.title}</h1>
            <MarkdownText content={formDefinition.description} />
            <p className="text-xs py-2">{formDefinition.estimated_time}</p>
          </div>
          {applicationStepName === 'completed' ? (
            <div className="rounded-lg bg-white shadow m-2 flex flex-col justify-center text-center p-4">
              <img
                src={formDefinition.partner_logo}
                alt="logo"
                className="max-h-8 w-fit m-auto"
              />
              <h1 className="text-lg"> {' Application submitted'}</h1>
              {/* reset form */}
              <button
                className="text-center w-full"
                onClick={async () => {
                  await resetForm();
                }}
              >
                <div className="underline text-xs hover:text-stone-400 text-stone-300 pt-3 transition-all duration-300">
                  Start a new application
                </div>
              </button>
            </div>
          ) : startPublicForm ? (
            <PublicApplicationForm />
          ) : (
            <div className="flex flex-col sm:flex-row justify-center items-center  pb-4">
              <button
                className="flex items-center rounded-full w-fit p-2 px-4 shadow bg-white hover:bg-stone-50 hover:scale-[0.98]  border border-stone-200 border-solid
                "
                onClick={() => {
                  navigate(formDefinition.apply_with_vula_url);
                }}
              >
                <img src="/logo.svg" alt="logo" className="w-8 h-8" />
                <div className="font-bold pl-4">login and apply </div>
              </button>
              <div className="h-full flex items-center px-8">or</div>
              <button
                className="flex items-center rounded-full w-fit p-2 px-4 shadow bg-white hover:bg-stone-50 hover:scale-[0.98]  border border-stone-200 border-solid "
                onClick={() => {
                  setStartPublicForm(true);
                }}
              >
                <div className="font-bold p-1">Start application now </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
