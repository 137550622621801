import React from 'react';
import {
  ApplicationContext,
  LendingProductApplicationQuestion,
} from '../../../contexts/ApplicationContext';
import RepeatingQuestions from './RepeatingQuestions';
import BinIcon from '../../Icons/BinIcon';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaCorpusAPI } from '../../../api/corpus';
import { LoggedInContext } from '../../../contexts/LoggedInContext';
import { VulaCompanyAPI } from '../../../api/company';

export default function RepeatingQsCard() {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = React.useContext(LoggedInContext);
  const {
    removeQuestionFromApplicationJson,
    addQuestionToApplicationJson,
    visibleTab,
    lendingProductApplicationDefinition,
  } = React.useContext(ApplicationContext);

  const [groupedQuestionsToRender, setGroupedQuestionsToRender] =
    React.useState<{ [key: string]: LendingProductApplicationQuestion[] }>({});

  const [title, setTitle] = React.useState<string>('');

  // when the tab changes, update the questions to render and the title
  React.useEffect(() => {
    // find all the questions visible on the current tab
    const visibleQuestions =
      lendingProductApplicationDefinition?.sections?.find(
        section => section.type === visibleTab?.type,
      )?.questions;
    // get all the repeated questions on the visible tab
    const visibleRepeatingQuestions = visibleQuestions?.filter(
      question => question.repeating_label,
    );

    if (!visibleRepeatingQuestions) return;
    visibleRepeatingQuestions?.sort((a, b) => a.order - b.order);

    const title = visibleRepeatingQuestions[0].additional_info || '';
    setTitle(title);

    const groupedRepeatingQuestions = visibleRepeatingQuestions?.reduce(
      (acc, question) => {
        if (!question.repeating_index) return acc;
        if (acc[question.repeating_index]) {
          acc[question.repeating_index].push(question);
        } else {
          acc[question.repeating_index] = [question];
        }
        return acc;
      },
      {} as { [key: string]: LendingProductApplicationQuestion[] },
    );

    // sort by order
    Object.keys(groupedRepeatingQuestions).forEach(key => {
      groupedRepeatingQuestions[key].sort((a, b) => a.order - b.order);
    });

    setGroupedQuestionsToRender(groupedRepeatingQuestions);
  }, [visibleTab, lendingProductApplicationDefinition]);

  function extractLabelFromModifiedRepeatingLabel(string: string) {
    // Use regular expression to split the string
    const parts = string.split(/_\d+_?/);

    // Filter out empty parts and return the last one
    const filteredParts = parts.filter(part => part.trim() !== '');
    return filteredParts[filteredParts.length - 1];
  }

  const addEmptyGroupToRender = () => {
    // take the first group as a template
    const firstGroup = Object.keys(groupedQuestionsToRender)[0];
    // get the end group and increment the name by 1, note that the length cannot be trusted but order can
    const lastGroup = Object.keys(groupedQuestionsToRender).pop();
    if (lastGroup === undefined) return;
    const newGroup = parseInt(lastGroup?.split('_')[1]) + 1;

    const newQuestions = groupedQuestionsToRender[firstGroup].map(question => ({
      ...question,
      repeating_index: 'group_' + newGroup,
      answer: '',
      label:
        question.repeating_label +
        '_' +
        newGroup +
        '_' +
        extractLabelFromModifiedRepeatingLabel(question.corpus_label),
      id: question.id + '_' + newGroup,
    }));
    // sort by order
    newQuestions.sort((a, b) => a.order - b.order);

    // add each question to context
    const adjacentQuestionId = groupedQuestionsToRender[lastGroup][0].id;

    addQuestionToApplicationJson(newQuestions, adjacentQuestionId);

    setGroupedQuestionsToRender({
      ...groupedQuestionsToRender,
      ['group_' + newGroup]: newQuestions,
    });
  };

  const deleteRepeatingRecord = async (groupIndex: string) => {
    // remove them from state
    const newGroupedQuestionsToRender = { ...groupedQuestionsToRender };
    delete newGroupedQuestionsToRender[groupIndex];

    // get the questions to remove
    const questionsToDelete = groupedQuestionsToRender[groupIndex];

    // remove the questions from context
    questionsToDelete.forEach(question =>
      removeQuestionFromApplicationJson(question.id),
    );

    // remove from the db (if they exist)
    questionsToDelete.forEach(async question => {
      try {
        const token = await getAccessTokenSilently();
        // delete from db

        const api = new VulaCorpusAPI({ token });
        await api.deleteFromCorpus({
          slug: company_slug,
          label: question.corpus_label,
        });
      } catch (error) {
        console.error(error);
      }
    });

    setGroupedQuestionsToRender(newGroupedQuestionsToRender);
  };

  return (
    <div className="rounded-2xl drop-shadow bg-white py-3 pb-4 px-4">
      <div className="text-xl font-extrabold mb-2">{title}</div>
      {
        // for each group render
        Object.keys(groupedQuestionsToRender).map((key, i) => (
          <div className="relative rounded-lg border border-solid border-stone-200 p-4 my-2  bg-stone-50">
            <button
              onClick={() => deleteRepeatingRecord(key)}
              className="w-8 h-8 text-center items-center flex justify-center absolute right-4 top-4 rounded-full  hover:bg-red-300"
            >
              <BinIcon />
            </button>
            <div>
              {groupedQuestionsToRender[key]
                .filter(question => question.repeating_index === key)
                ?.map(question => (
                  <div className="py-1" key={question.id}>
                    <RepeatingQuestions question={question} index={i} />
                  </div>
                ))}
            </div>
          </div>
        ))
      }

      <button
        className="hover:bg-stone-100 w-full rounded-lg border border-solid border-stone-200 p-4 mt-2 drop-shadow bg-stone-50"
        onClick={() => addEmptyGroupToRender()}
      >
        + Add new
      </button>
    </div>
  );
}
