import React, { useContext, useEffect } from 'react';
import { GrantContext } from '../../contexts/GrantContext';

import GrantApplicationEditor from './GrantApplicationEditor';
import GetVulasHelp from './ActionSections/GetVulasHelp';
import HaveYouApplied from './ActionSections/HaveYouApplied';

export default function InProgressGrantSummary() {
  const { selectedGrant, getCompanyGrantNotificationEmail } =
    useContext(GrantContext);

  useEffect(() => {
    (async () => await getCompanyGrantNotificationEmail())();
  }, []);

  if (!selectedGrant?.grant) {
    return null;
  }

  // if there are sections for questions show that
  if (selectedGrant?.grant?.application_sections?.length) {
    return <GrantApplicationEditor />;
  }

  // else aassume it is unsupported
  return (
    <div>
      <GetVulasHelp />
      <HaveYouApplied />
    </div>
  );
}
