import React, { useContext } from 'react';

import VulaLogo from '../components/utils/VulaLogo';
import { useNavigate } from 'react-router-dom';
import { routes } from './routes';
import axios from 'axios';
import { apiUrl } from '../methods/env';
import GrantSummary from '../components/Grants/GrantSummary';
import { GrantContext } from '../contexts/GrantContext';
import ContentSection from '../components/utils/ContentSection';
import { track } from '../interfaces/mixpanel';
import Button from '../components/utils/Button';
import { LoggedInContext } from '../contexts/LoggedInContext';
import GrantParameters from '../components/Grants/GrantParameters';

interface GrantParameter {
  type: string;
  text: string;
}

export default function PublicGrant() {
  const navigate = useNavigate();

  // store the grant id in the state
  const [grantId, setGrantId] = React.useState<string | null>(null);
  const [grant_params, setGrantParams] = React.useState<GrantParameter[] | []>(
    [],
  );
  // store the grant data in the context
  const { defineSelectedGrant } = useContext(GrantContext);
  const { isAuthenticated } = useContext(LoggedInContext);

  // get the grant id from the url
  React.useEffect(() => {
    getGrantIdFromUrl();
  }, []);

  // get graant id from url
  const getGrantIdFromUrl = () => {
    // if the url has an grant id then set the selected grant
    // get the if from the url, after my/grants/
    const url = window.location.href;
    const urlSplit = url.split(
      routes.public.publicGrants.substring(
        0,
        routes.public.publicGrants.length - 1,
      ), // tricky as routes.publicGrants has a trailing "/*"
    )[1];
    // get the guid from the url
    const grantId = urlSplit.slice(-36);
    setGrantId(grantId);
    track('Public Grant view', { grantId: grantId });
  };

  // if the url has an grant id then get the selected grant
  React.useEffect(() => {
    if (grantId) {
      // call the api for the grant
      const getGrant = async () => {
        // get the grant from the api
        return axios.get(apiUrl + '/grants/' + grantId + '/public');
      };
      (async () =>
        await getGrant().then(response => {
          // set the selected grant
          defineSelectedGrant({ grant: response.data, status: 'new' });
          setGrantParams(response.data.grant_parameters);
        }))();
    }
  }, [grantId]);

  return (
    <div className="min-h-[100vh] h-full bg-stone-50 sm:p-12 text-left">
      <div className="w-full flex flex-col sm:flex-row ">
        <div className="sm:w-1/2">
          <button
            className="flex flex-row items-center justify-left p-4  sm:p-8"
            onClick={() => navigate(routes.public.landing)}
          >
            <VulaLogo />
            <h1 className="pl-12 text-lg font-bold text-center text-stone-900">
              match - prewrite - apply
            </h1>
          </button>

          <GrantSummary />
          <GrantParameters grant_params={grant_params} />
        </div>
        <div className="order-first sm:order-last sm:w-1/2">
          <ContentSection background="colourful" centerContent={false}>
            <div className="flex flex-col text-stone-900 text-left justify-start w-full">
              <h1 className="text-2xl font-extrabold w-full">
                {isAuthenticated ? 'View in your inbox' : 'Vula helps you.'}
              </h1>
              <div className="sm:text-lg py-4 w-full">
                {isAuthenticated
                  ? 'Click here to go to your inbox'
                  : 'Find matching grants, prewrite your applications and apply in one click'}
              </div>
              <Button
                text="Get Started"
                action={() => {
                  track('Public Grant Signup', { grantId: grantId });
                  navigate(routes.public.launch);
                }}
              />
              <button
                className="hover:scale-[1.01] text-left flex flex-col rounded-2xl h-full w-full mt-4 "
                onClick={() => {
                  track('Public Grant Signup', { grantId: grantId });
                  navigate(routes.public.launch);
                }}
              >
                <video
                  key={'videoOfGrants'}
                  controls={false}
                  autoPlay
                  loop={true}
                  muted
                  preload="metadata"
                  poster={
                    'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.png'
                  }
                  playsInline={true}
                  className="w-full h-full object-cover rounded-xl "
                >
                  {/* <!-- Fallback content for non-supported browsers --> */}
                  <source
                    src={
                      'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.webm'
                    }
                    type="video/webm"
                  />
                  <source
                    src={
                      'https://vula-assets.s3.eu-west-2.amazonaws.com/abilities_video/AskVulaGrants.mp4'
                    }
                    type="video/mp4"
                  />
                </video>
              </button>
            </div>
          </ContentSection>
        </div>
      </div>
    </div>
  );
}
