import React, { useContext, useState } from 'react';
// import AskVulaGrantQuestions from '../Grants/ActionSections/AskVulaGrantQuestions';
import VulaLogo from '../utils/VulaLogo';
import { ApplicationContext } from '../../contexts/ApplicationContext';
import { VulaCompanyAPI } from '../../api/company';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';

export default function AIP() {
  const { company_slug } = useContext(LoggedInContext);
  const { lendingProductApplicationDefinition, updateAnswerInApplicationJson } =
    useContext(ApplicationContext);
  const { getAccessTokenSilently } = useAuth0();
  const aip_section = lendingProductApplicationDefinition?.sections.find(
    section => section.type === 'aip',
  );

  const vula_summary = aip_section?.questions.find(
    question => question.type === 'vula_summary',
  );

  const aip_agreement = aip_section?.questions.find(
    question => question.type === 'aip_agreement',
  );

  // Function to handle checkbox change
  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (aip_agreement?.id) {
      const checked = event.target.checked;
      const checkedAsString = checked ? 'true' : '';
      // update the company details to state that aip has been agreed to
      const token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token });
      await api.updateCompanyDetails(
        company_slug,
        aip_agreement.corpus_label,
        checkedAsString,
      );

      setIsChecked(checked);
      updateAnswerInApplicationJson(aip_agreement.id, checkedAsString);
    }
  };
  // State to track the checkbox status
  const [isChecked, setIsChecked] = useState(aip_agreement?.answer === 'true');

  return (
    <>
      <div className="flex flex-col sm:flex-row rounded-lg  ">
        <div className="sm:w-1/2 text-left">
          <div className="text-3xl font-bold ">{aip_section?.title}</div>
          <div className="text pt-4">{aip_section?.subtitle}</div>

          {/* vula summary */}
          <div className="drop-shadow bg-black text-white my-2 mt-8 p-4 rounded-lg">
            <div className="flex  ">
              <VulaLogo size="sm" />
              <div className="font-bold text-lg pb-2">Vula's Summary</div>
            </div>
            <div className="text-sm rounded  p-2">{vula_summary?.answer}</div>
            <div className="text-xs p-2">This is not financial advice.</div>
          </div>

          {/* ask Vula */}
          {/* <AskVulaGrantQuestions /> */}
          {/* A large check box with the label - I agree in principle to this contract and wish too proceed  */}
          <div className="hidden sm:flex drop-shadow flex-row items-center px-8 p-4 mt-8 bg-white border border-1 border-solid border-stone-300 rounded-lg">
            <input
              defaultChecked={isChecked}
              type="checkbox"
              className="w-8 h-8"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <div className="pl-4">{aip_agreement?.question}</div>
          </div>
        </div>
        {/* pdf preview */}
        <div className="sm:w-1/2  h-[80vh]  px-4">
          <div className=" rounded-xl overflow-hidden shadow-xl w-full h-full">
            <iframe
              src={lendingProductApplicationDefinition?.aip_agreement_url}
              width="100%"
              height="100%"
              frameBorder="0"
              className=""
              allowFullScreen
            ></iframe>
          </div>
        </div>
        {/* A large check box with the label - I agree in principle to this contract and wish too proceed  */}
        <div className="sm:hidden block flex drop-shadow flex-row items-center px-8 p-4 mt-8 bg-white border border-1 border-solid border-stone-300 rounded-lg">
          <input
            defaultChecked={isChecked}
            type="checkbox"
            className="w-8 h-8"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <div className="pl-4">{aip_agreement?.question}</div>
        </div>
      </div>
    </>
  );
}
