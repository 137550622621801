import React, { useContext, useState } from 'react';
import Dropzone from 'react-dropzone';
import { LoggedInContext } from '../../contexts/LoggedInContext';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaCompanyAPI } from '../../api/company';
import axios, { AxiosProgressEvent } from 'axios';

interface Props {
  label: string;
  afterUpload: (uploaded_filenames: string[]) => void;
}

// A component that allows users to drag and drop or select multiple files to upload and it will show a progress bar for each file
// and once uploaded it will show the file to the user as an icon and the file name
export default function UploadMultiple(props: Props) {
  const { company_slug } = useContext(LoggedInContext);
  const { getAccessTokenSilently } = useAuth0();

  // uploading states
  const [error, setError] = useState(false);
  const [clickedUpload, setClickedUpload] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0);
  const [uploadedFileNames, setUploadedFileNames] = useState<string[]>([]);

  const getFileMimeType = (title: string) => {
    if (title.includes('.pdf')) {
      return 'application/pdf';
    } else if (title.includes('.mp4')) {
      return 'video/mp4';
    } else if (
      title.includes('.png') ||
      title.includes('.jpeg') ||
      title.includes('.jpg')
    ) {
      return 'image/png';
    } else if (title.includes('.gif')) {
      return 'image/gif';
    } else if (title.includes('.webp')) {
      return 'image/webp';
    } else {
      return 'application/octet-stream';
    }
  };

  const uploadFile = async (
    file: File,
    type: string,
    api: VulaCompanyAPI,
    index: number,
  ) => {
    try {
      // get presigned url
      const presignRes = await api.getPresignedUploadURL(company_slug, type);

      const mimeType = getFileMimeType(file.name);
      const options = {
        onUploadProgress: (p: AxiosProgressEvent) => {
          if (p.total) {
            const percentageUploaded = Math.round((p.loaded * 100) / p.total);
            setPercentageUploaded(percentageUploaded);
          }
        },
        headers: {
          Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
          Key: presignRes.data.filename,
          'Content-Type': mimeType,
        },
      };

      // Record the file in the db
      await axios
        .put(presignRes.data.url, file, options)
        .then(async () => {
          // Add the attachment to the backend it has saved
          const s3Location = presignRes.data.url
            .split('.com/')[1]
            .split('?')[0];

          // Add the attachment to the backend
          await api
            .postUploaded(
              company_slug,
              s3Location,
              props.label + '_' + index,
              mimeType,
              file.name,
            )
            .then(async () => {
              //   reset upload progress
              setPercentageUploaded(0);
              setClickedUpload(false);
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(error => {
          setError(true);
          console.log(error);
        });
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onDrop = async (files: T[]) => {
    // get presigned url
    const token = await getAccessTokenSilently();
    const api = new VulaCompanyAPI({ token });
    try {
      // sequentially upload files but await them all
      await Promise.all(
        files.map(async (file, index) => {
          setTimeout(async () => {
            await uploadFile(
              file,
              file.type,
              api,
              index + uploadedFileNames.length,
            );
          }, 5000 * index);
        }),
      );

      const newFileNames = files.map(file => file.name) as string[];

      // add file names to state
      setUploadedFileNames([...uploadedFileNames, ...newFileNames]);

      // call after upload
      props.afterUpload([...uploadedFileNames, ...newFileNames]);
      // reset error
      setError(false);
      setClickedUpload(false);
    } catch (error) {
      setError(true);
      console.log(error);
      return;
    }
  };

  return (
    <div className="min-w-[200px] sm:min-w-[330px] sm:p-2 p-1">
      <Dropzone
        onDrop={files => onDrop(files)}
        onFileDialogOpen={() => setClickedUpload(true)}
        onFileDialogCancel={() => setClickedUpload(false)}
        accept={{
          'image/*': ['.jpeg', '.png'],
          'video/mp4': ['.mp4', '.MP4'],
          'application/pdf': ['.pdf'],
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className=" cursor-pointer w-full h-full min-h-[100px] sm:min-h-[150px] border border-1 border-dashed border-stone-300 bg-stone-50 rounded-xl text-stone-400 flex flex-col justify-center items-center"
          >
            <div className="w-full flex items-center justify-center flex-wrap">
              {
                // loop through uploaded files and display them
                uploadedFileNames.map((fileName, index) => {
                  return (
                    <div key={index} className="flex flex-col items-center p-2">
                      <img
                        src="https://www.svgrepo.com/show/532762/file-check-alt.svg"
                        alt="file"
                        className="w-10 h-10 mr-2"
                      />
                      <p className="truncate">{fileName}</p>
                    </div>
                  );
                })
              }
            </div>
            <div>
              <input className="" {...getInputProps()} />
              <div className="sm:p-4 p-8 w-full h-full flex flex-col justify-center items-center">
                <p>
                  {error
                    ? 'Something went wrong!'
                    : percentageUploaded === 100
                    ? 'Upload completed'
                    : percentageUploaded > 0
                    ? percentageUploaded.toString() + '% uploaded'
                    : clickedUpload
                    ? 'Opening your files'
                    : 'Drop files here, or click to select files'}
                </p>
              </div>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}
