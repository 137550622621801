import React, { useContext, useState } from 'react';
import { EditPitchContext } from '../../contexts/EditPitchContext';
import { CompetData } from '../../data/companies';
import Button from '../utils/Button';
import Pill from '../utils/Pill';
import UploadZone from '../utils/UploadZone';
import PitchContentContainer from './PitchContentContainer';
import EditableImageContainer from '../utils/EditableImageContainer';

interface Props {
  id?: string;
  mode?: 'full';
}

export default function Competitors(props: Props) {
  const { editMode, privatePitchInfo, investorMode, getPitchData } =
    useContext(EditPitchContext);
  const [uploadedImageURL, setUploadedImageURL] = useState<string>('');

  const renderRows = (competData: CompetData[]) => {
    // get all the comparison names
    const rowTopics = Object.keys(competData[0].feilds);

    const baseStyling = 'text-lg items-center align-middle w-1/4 text-center ';
    const headerStyling = baseStyling + ' text-2xl font-extrabold p-4';
    const cellStyling = baseStyling + '  p-4 ';
    const rowStyling = 'border-b  border-gray-200 border-solid ';

    return rowTopics.map((topic, index) => {
      if (topic === 'title') {
        return (
          <thead key={topic + index} className={rowStyling}>
            <tr>
              <td className={headerStyling}></td>
              {competData.map((row, index) => {
                return (
                  <th key={index} className={headerStyling}>
                    {renderHeaderCellContent(row)}
                  </th>
                );
              })}
            </tr>
          </thead>
        );
      } else {
        return (
          <tbody key={topic} className={rowStyling}>
            <tr className="">
              <td className={cellStyling}>{topic}</td>
              {competData.map((row, index) => {
                return (
                  <td key={row.feilds[topic] + index} className={cellStyling}>
                    {renderCellContent(row.feilds[topic])}
                  </td>
                );
              })}
            </tr>
          </tbody>
        );
      }
    });
  };
  const renderHeaderCellContent = (row: CompetData) => {
    if (row.metadata.logoImg && row.metadata.url) {
      return (
        <a href={row.metadata.url} className="flex justify-center">
          <img
            className="max-w-[100px] lg:max-w-[150px]"
            src={row.metadata.logoImg}
            alt={row.feilds.title}
          />
        </a>
      );
    } else if (row.metadata.logoImg) {
      return (
        <div className="flex justify-center w-full">
          <img
            className="max-w-[100px] lg:max-w-[150px] "
            src={row.metadata.logoImg}
            alt={row.feilds.title}
          />
        </div>
      );
    } else if (row.metadata.url) {
      return (
        <a className="max-w-[100px] lg:max-w-[150px]" href={row.metadata.url}>
          {row.feilds.title}
        </a>
      );
    }
    return row.feilds.title;
  };
  const renderCellContent = (string: string) => {
    if (string === 'false') {
      return '❌';
    } else if (string === 'true') {
      return '✅';
    } else {
      return string;
    }
  };

  const renderCompetitionImage = (img: string) => {
    return (
      <PitchContentContainer id={props.id}>
        <section className="m-auto flex w-full flex-col justify-center items-center text-center hidden sm:block">
          <Pill>Competition</Pill>

          <div className="pt-8 w-full flex justify-center">
            <EditableImageContainer
              image={img}
              altText="Competition image"
              label="competition"
            />
          </div>
        </section>
      </PitchContentContainer>
    );
  };

  // show after image uploaded
  if (uploadedImageURL) {
    return renderCompetitionImage(uploadedImageURL);
  }

  // allow upload of image
  if (!privatePitchInfo?.compets && editMode && !investorMode) {
    return (
      <div className="relative flex justify-center items-center relative  w-full  h-[60vh] mt-4 p-4 ">
        <div
          className="absolute z-10  shadow-2xl rounded-2xl h-full h-[600px]  sm:max-w-[60%] w-4/5"
          style={{ backdropFilter: 'blur(20px)' }}
        >
          <div className="w-full h-full flex justify-center items-center p-4">
            <div
              className="m-2 flex flex-col justify-center items-center rounded-2xl p-4 "
              style={{ backdropFilter: 'blur(20px)' }}
            >
              <div className="text-xl font-extrabold pb-4">
                Show that you know your market.
              </div>
              <div className="py-4 max-w-[600px]">
                Here is a template but feel free to upload your own.
              </div>
              <div className="pb-4">
                <Button
                  text="Open template"
                  action={() =>
                    window.open(
                      'https://docs.google.com/presentation/d/1LnPChLquU2xB_MnU5r9_bTMHtSjpwsqWK9U92PGEQK4/edit?usp=sharing',
                      '_blank',
                    )
                  }
                />
              </div>
              <UploadZone
                label="competition"
                afterUpload={async url => {
                  await getPitchData();
                  setUploadedImageURL(url);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // show image from db
  if (privatePitchInfo?.compets?.img) {
    return renderCompetitionImage(privatePitchInfo.compets.img);
  }

  //show data from db
  if (privatePitchInfo?.compets?.competTableData) {
    return (
      <PitchContentContainer id={props.id}>
        <section className=" flex w-full flex-col justify-center items-center text-center hidden sm:block">
          <Pill>Competition</Pill>
          <div className="flex justify-center ">
            {/* currently hidden on mobile */}
            <table className="border-collapse border border-slate-500 table-fixed ">
              {renderRows(privatePitchInfo.compets.competTableData)}
            </table>
          </div>
        </section>
      </PitchContentContainer>
    );
  }

  return null;
}
