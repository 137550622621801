import React, { useContext } from 'react';
import ButtonCard from '../Pitch/PitchHome/ButtonCard';
import SimpleToggle from '../utils/SimpleToggle';
import { track } from '../../interfaces/mixpanel';
import { MemoContext } from '../../contexts/MemoContext';
import { useAuth0 } from '@auth0/auth0-react';
import { VulaCompanyAPI } from '../../api/company';
import { LoggedInContext } from '../../contexts/LoggedInContext';

export default function MemoPublicity() {
  const { getAccessTokenSilently } = useAuth0();
  const { company_slug } = useContext(LoggedInContext);
  const { memoIsPublic, setMemoIsPublic } = useContext(MemoContext);
  if (memoIsPublic === undefined || setMemoIsPublic === undefined) return null;

  const updatePublicity = async () => {
    track('Public Pitch toggle clicked');
    try {
      const auth0Token = await getAccessTokenSilently();
      const api = new VulaCompanyAPI({ token: auth0Token });
      await api.updateMemoPublicity(company_slug, !memoIsPublic);
      if (setMemoIsPublic) {
        await setMemoIsPublic(!memoIsPublic);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ButtonCard context="memo" isToggle>
      <div className="px-4 flex flex-row items-center justify-between w-full">
        <div>
          <div className="text-sm font-extrabold">
            Your memo {!memoIsPublic && 'not'} is live
          </div>
          <div className="text-xs pt-2">
            Investors can see your memo if they have the link and password
          </div>
        </div>
        <div className="scale-[0.66] ">
          <SimpleToggle
            leftText={''}
            rightText={''}
            stateChange={async () => {
              await updatePublicity();
            }}
            initialState={memoIsPublic}
            leftIsOff
          />
        </div>
      </div>
    </ButtonCard>
  );
}
