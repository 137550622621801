import React, { useContext } from 'react';
import { GrantContext } from '../../contexts/GrantContext';
import CreateParamPill from './CreateParamPill';
import DefinitionsFrame from './DefinitionsFrame';
import ParamPill from './ParamPill';
import Button from '../utils/Button';

export default function CompanyVerticalDefinitions() {
  const { companyParams, getCompanyParams } = useContext(GrantContext);

  return (
    <DefinitionsFrame
      helperText="Verticals are the industries or sectors that your company operates in."
      label="Verticals"
      errorText="You must have at least one vertical"
      showError={
        !companyParams?.parameters ||
        companyParams?.parameters.filter(param => param.type === 'vertical')
          .length === 0
      }
    >
      <div
        className="border border-solid border-2 border-gray-300 p-2 rounded-lg"
        key={companyParams?.parameters?.length} // to force re-render
      >
        <div className="flex flex-wrap items-center w-fit">
          {companyParams?.parameters ? (
            companyParams.parameters
              ?.filter(param => param.type === 'vertical')
              .map(param => {
                return <ParamPill key={param.id} parameter={param} />;
              })
          ) : (
            <Button
              text="Ask Vula"
              asynAction={() => (async () => await getCompanyParams())()}
            />
          )}
          <CreateParamPill type={'vertical'} />
        </div>
      </div>
    </DefinitionsFrame>
  );
}
