import axios from 'axios';
import { apiUrl } from '../methods/env';

export class PublicFormsAPI {
  async getPublicForm({
    partner_name,
    product_code,
  }: {
    partner_name: string;
    product_code: string | null;
  }) {
    if (product_code === null)
      return axios.get(`${apiUrl}/public-forms/${partner_name}`);
    return axios.get(`${apiUrl}/public-forms/${partner_name}/${product_code}`);
  }

  async getPrefilledApplicationFormWithToken({
    partner_name,
    product_code,
    token,
  }: {
    partner_name: string;
    product_code: string;
    token: string;
  }) {
    return axios.get(
      `${apiUrl}/public-forms/${partner_name}/${product_code}/${token}`,
    );
  }

  async startApplication({
    partner_name,
    product_code,
    url,
  }: {
    partner_name: string;
    product_code: string | null;
    url?: string;
  }) {
    return axios.post(`${apiUrl}/public-forms/application`, {
      partner_name,
      product_code,
      url,
    });
  }

  async updateAnswer({
    token,
    question_id,
    answer,
  }: {
    token: string;
    question_id: string;
    answer: string | '' | null;
  }) {
    return axios.put(`${apiUrl}/public-forms/answer`, {
      token,
      question_id,
      answer,
    });
  }

  async getPresignedUrl({
    token,
    label,
    type,
    summary,
  }: {
    token: string;
    label: string;
    type: string;
    summary?: string;
  }) {
    return axios.post(`${apiUrl}/public-forms/${token}/presigned`, {
      label,
      type,
      summary,
    });
  }

  async areAllDocsAnalysed({ token }: { token: string }) {
    return axios.get(`${apiUrl}/public-forms/${token}/attachments/analysed`);
  }

  async submitApplication({ token }: { token: string }) {
    return axios.post(`${apiUrl}/public-forms/${token}/submit`);
  }

  async getApplicationStatus({ token }: { token: string }) {
    return axios.get(`${apiUrl}/public-forms/${token}/status`);
  }

  async deleteApplication({ token }: { token: string }) {
    return axios.delete(`${apiUrl}/public-forms/${token}`);
  }

  async renewExpiry({
    partner_name,
    product_code,
    token,
  }: {
    partner_name: string;
    product_code: string;
    token: string;
  }) {
    return axios.get(
      `${apiUrl}/public-forms/${partner_name}/${product_code}/${token}/renew_expiry`,
    );
  }
}
