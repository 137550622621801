import React from 'react';
import { track } from '../../interfaces/mixpanel';

interface Props {
  question: string;
  answer: string;
  index: number;
}

export default function FAQs(props: Props) {
  const [show, setShow] = React.useState(false);
  const { question, answer, index } = props;
  return (
    <div className="relative">
      <button
        key={index}
        onClick={() => {
          if (!show) {
            track('VulaOS: FAQ-open ' + question);
          }
          setShow(!show);
        }}
        className={
          'w-full h-full bg-stone-100 rounded-xl shadow-xl shadow-stone-500 p-1 sm:p-4 sm:m-4 text-stone-800 transition-all duration-500 animate-fade-in-up'
        }
      >
        <div className="text-xl py-2 font-bold">{question}</div>
        <div
          className={
            'overflow-hidden transition-all duration-500 ' +
            (show
              ? 'max-h-[1000px] opacity-100'
              : 'max-h-0 opacity-0 pointer-events-none')
          }
          style={{ maxHeight: show ? '1000px' : '1px' }}
        >
          {show ? <div className="text-stone-900 py-2">{answer}</div> : null}
        </div>
      </button>
    </div>
  );
}
