import React, { useContext } from 'react';

import { GrantContext } from '../../contexts/GrantContext';
import { formatDate } from '../../methods/UIHelpers';
import Pill from '../utils/Pill';
import Button from '../utils/Button';
import MatchingFeedback from './MatchingFeedback';

export default function GrantSummary() {
  const { selectedGrant, matchingCompanyGrantParams } =
    useContext(GrantContext);

  if (!selectedGrant?.grant) {
    return null;
  }
  return (
    <div className=" mx-4 sm:mx-12  pt-4 rounded-xl bg-white">
      {selectedGrant.grant.application_sections?.length ? (
        <Pill>
          <div className="text-sm">
            <span className="pr-2">✦</span> Prewrite & Auto-apply with Vula
          </div>
        </Pill>
      ) : null}
      <div className="pt-4 text-3xl font-extrabold ">
        {selectedGrant?.grant.name}
      </div>
      <div className="pt-4 font-extrabold ">Deadline</div>
      <div className="pt-1 ">
        {selectedGrant?.grant.deadline
          ? formatDate(selectedGrant?.grant.deadline)
          : null}
      </div>
      <div className="pt-4 font-extrabold ">Offered</div>
      <div className="pt-1 text-sm">{selectedGrant?.grant.monetary_offer}</div>
      <div className="pt-1 text-sm ">{selectedGrant?.grant.other_benefits}</div>

      <div className="pt-4 font-extrabold ">Summary</div>
      <div className="pt-1 text-sm ">{selectedGrant?.grant.summary}</div>

      <div className="pt-4 text-sm font-extrabold underline hover:no-underline">
        <a
          href={
            (selectedGrant?.grant.apply_here
              ? selectedGrant?.grant.apply_here
              : selectedGrant?.grant.scraped_urls[0]) +
            // if the above has a question mark we do not need one
            (selectedGrant?.grant.apply_here?.includes('?') ? '&' : '?') + // this will fail if the apply_here does not exist but should work 99% of the time
            'utm_source=vula.vc&ref=vula.vc&utm_medium=vula.vc-grants&utm_campaign=vula.vc-grants'
          }
          target={'_blank'}
          rel="noreferrer"
        >
          <Button text="View grant site" />
        </a>
      </div>
      {matchingCompanyGrantParams?.length ? (
        <>
          <div className="pt-4 pb-1 font-extrabold ">Matched on:</div>
          <div className="flex  w-full">
            {/* loop through all matching params */}
            {matchingCompanyGrantParams?.map(param => (
              <div
                key={param.company_text}
                className="m-1 p-1 px-4 text-sm rounded-full ring ring-1 ring-stone-400 ring-solid w-fit text-center flex items-center"
              >
                {param.company_text}
              </div>
            ))}

            <MatchingFeedback />
          </div>
        </>
      ) : null}
    </div>
  );
}
